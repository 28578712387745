import styled from 'styled-components';

import { IContainerProps } from './types';

export const Container = styled.div<IContainerProps>`
  position: ${props => (props.position ? props.position : 'relative')};
  top: ${props => (props.top ? props.top : 'unset')};
  bottom: ${props => (props.bottom ? props.bottom : 'unset')};
  left: ${props => (props.left ? props.left : 'unset')};
  right: ${props => (props.right ? props.right : 'unset')};
  width: ${props => (props.width ? props.width : '100%')};
  height: ${props => (props.height ? props.height : '100%')};
  display: ${props => (props.display ? props.display : 'inline-block')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
`;
