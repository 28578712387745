/* eslint-disable no-useless-escape */
/* eslint-disable prefer-regex-literals */
export const valueEmpty = (input: string | undefined): boolean => {
  if (
    input === undefined ||
    input === '' ||
    input === null ||
    (input as string).replace(/\s/g, '') === ''
  ) {
    return true;
  }
  return false;
};

export const emailValid = (email: string): boolean => {
  if (/^\w+(([\.-]|\+)?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(email)) {
    return true;
  }
  return false;
};

export const passwordValid = (password: string): boolean => {
  // no newline,tabs
  if (!new RegExp(/^[\S]+.*[\S]+$/g).test(password)) {
    return false;
  }
  // special chars
  if (!new RegExp(/[!@_#$%^&*]/g).test(password)) {
    return false;
  }
  // numbers
  if (!new RegExp(/\d+/g).test(password)) {
    return false;
  }
  // lowletters
  if (!new RegExp(/[a-z]/g).test(password)) {
    return false;
  }
  // upperletters
  if (!new RegExp(/[A-Z]/g).test(password)) {
    return false;
  }
  // min8chars
  if (!(password.length >= 8)) {
    return false;
  }
  return true;
};

export const urlValid = (url: string): boolean => {
  if (
    !new RegExp(
      /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=\+\$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=\+\$,\w]+@)[A-Za-z0-9.-]+)((?:\/[\+~%\/.\w-_]*)?\??(?:[-\+=&;%@.\w_]*)#?(?:[\w]*))?)/,
    ).test(url)
  ) {
    return false;
  }
  return true;
};
