import { UncontrolledTooltip } from 'reactstrap';
import styled from 'styled-components';

export const PopOverInfo = styled.a.attrs(() => ({
  className: '--hide-from-pdf',
}))`
  margin-left: 0.5rem;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  svg {
    font-size: small;
    color: #8492a6;

    &:focus {
      color: #162b39;
    }
  }

  & > span {
    display: inline-flex;
    align-items: center;
  }
`;

// Default values
const backgroundColor = '#fff';
const borderColor = '#eeeeee';

// pointer-events to fix the tooltip flickering when mouseover
export const CustomTooltip = styled(UncontrolledTooltip)`
  & > *:first-child {
    z-index: 1000;
  }
  opacity: 1 !important;
  pointer-events: none;

  .tooltip-inner {
    display: inline-block;
    width: auto !important;
    max-width: 20rem;
    position: relative;
    text-align: center;
    color: #333;
    background-color: ${backgroundColor};
    border: 0.1rem solid ${borderColor};
    border-radius: 0.5rem;
    padding: 1rem 1.5rem;
    margin: 0 0 1rem 0;
    filter: drop-shadow(rgba(0, 0, 0, 0.6) 0 0.2rem 0.3rem);
  }

  .arrow {
    display: none !important;
  }

  .tooltip-inner::before,
  .tooltip-inner::after {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    width: 0;
    height: 0;
    border-style: solid;
  }

  .tooltip-inner::after {
    right: calc(50% - 0.8rem);
    border-color: ${backgroundColor} transparent transparent transparent;
    border-width: 0.7rem;
  }

  .tooltip-inner::before {
    right: calc(50% - 1rem);
    border-color: ${borderColor} transparent transparent transparent;
    border-width: 0.9rem;
  }
`;
