/* eslint-disable import/extensions */
import styled from 'styled-components';
import { BiX } from 'react-icons/bi';

import { IModalContainerProps, IModal } from './types';

export const ModalContainer = styled.div<IModalContainerProps>`
  position: fixed;
  left: ${props => (props.menuNavbarVisible ? '6.08rem' : '0')};
  right: 0;
  top: ${props => (props.menuNavbarVisible ? '6.08rem' : '0')};
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props =>
    props.show ? 'rgba(22, 43, 57, 0.8)' : 'rgba(22, 43, 57, 0)'};
  pointer-events: ${props => (props.show ? 'all' : 'none')};
  padding-bottom: ${props => (props.show ? '0' : '10rem')};
  transition: all ease-in-out 0.5s;
  z-index: 9999999999999999999999;
  overflow-x: hidden;
  overflow-y: auto;

  @media (max-width: 600px) {
    top: 5.7rem;
    left: 0;
  }
`;

export const Modal = styled.div<IModal>`
  background-color: ${props => props.theme.container['background-color-1']};
  padding: 4rem 0;
  border-radius: 2rem;
  border: ${props =>
    `${props.theme.modal['border-width']} ${props.theme.modal['border-style']} ${props.theme.modal['border-color']}`};
  width: ${props => (props.width ? props.width : '48%')};
  display: ${props => (props.show ? 'block' : 'none')};
  transition: all ease-in-out 0.5s;
  max-height: 85vh;
  overflow-y: hidden;

  @media (max-width: 930px) {
    width: 85%;
  }
`;

export const ModalInnerContainer = styled.div`
  max-height: calc(85vh - 8rem);
  width: 100%;
  overflow-y: auto;
  padding: 0 5rem;

  @media (max-width: 360px) {
    padding: 0 3rem;
  }
`;

export const ModalTitleContainer = styled.div`
  color: ${props => props.theme.text['color-12']};
  font-size: 2.24rem;
  font-weight: 700;
  font-family: ${props => props.theme.text['font-family-1']};
  position: relative;
  display: grid;
`;

export const ModalTitle = styled.div`
  max-width: calc(100% - 3rem);
`;

export const ModalSubTitle = styled.span`
  color: #8492a6;
  font-size: 1.3rem;
  font-weight: 400;
  margin-top: 0.5rem;
`;

export const ModalBody = styled.div`
  padding: 4rem 0;
  color: ${props => props.theme.text['color-8']};
  font-size: 1.6rem;
  font-weight: 300;
  font-family: ${props => props.theme.text['font-family-1']};
`;

export const ModalFooter = styled.div`
  display: flex;
`;

export const MainButtonsContainer = styled.div`
  display: flex;
  margin-left: auto;
`;

export const Cross = styled(BiX)`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 3rem;
  color: ${props => props.theme.text['color-16']};
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.modal['cross-hover-color']};
  }
`;
