/* eslint-disable eqeqeq */
/* eslint-disable import/extensions */
import styled, { useTheme } from 'styled-components';
import { useState, useEffect } from 'react';

import SVG from './Styles';

interface IMagnifyingGlassIconProps {
  containerWidth?: string;
  containerHeight?: string;
  containerPaddingTop?: string;
  containerPaddingBottom?: string;
  containerPaddingLeft?: string;
  containerPaddingRight?: string;
  containerMarginTop?: string;
  containerMarginBottom?: string;
  containerMarginLeft?: string;
  containerMarginRight?: string;
  usage?: 'socialNetworkAccountSelection';
  svgWidth?: string;
  svgHeight?: string;
  cursor?: string;
}

interface IContainerProps {
  width?: string;
  height?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
}

const Container = styled.div<IContainerProps>`
  border-width: 0;
  outline: 0;
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '0')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '0')};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '0')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '0')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const MagnifyingGlassIcon = (props: IMagnifyingGlassIconProps) => {
  const themeGlobal = useTheme();
  const [svgColor, setSvgColor] = useState<string>(() => {
    if (props.usage == 'socialNetworkAccountSelection') {
      return (themeGlobal as any).socialNetworkAccountSelectionComponent[
        'magnifying-glass'
      ].color;
    }

    return (themeGlobal as any).socialNetworkAccountSelectionComponent[
      'magnifying-glass'
    ].color;
  });

  useEffect(() => {
    if (props.usage == 'socialNetworkAccountSelection') {
      setSvgColor(
        (themeGlobal as any).socialNetworkAccountSelectionComponent[
          'magnifying-glass'
        ].color,
      );
    } else {
      setSvgColor(
        (themeGlobal as any).socialNetworkAccountSelectionComponent[
          'magnifying-glass'
        ].color,
      );
    }
  }, [(themeGlobal as any).darkMode]);

  return (
    <Container
      width={props.containerWidth}
      height={props.containerHeight}
      paddingTop={props.containerPaddingTop}
      paddingBottom={props.containerPaddingBottom}
      paddingLeft={props.containerPaddingLeft}
      paddingRight={props.containerPaddingRight}
      marginTop={props.containerMarginTop}
      marginBottom={props.containerMarginBottom}
      marginLeft={props.containerMarginLeft}
      marginRight={props.containerMarginRight}
    >
      <SVG
        viewBox="0 0 32 32"
        width={props.svgWidth ? props.svgWidth : '100%'}
        height={props.svgHeight ? props.svgHeight : '100%'}
        cursor={props.cursor}
      >
        <path
          fill={svgColor}
          d="M29.179 27.541l-5.478-5.478c1.704-2.069 2.728-4.718 2.728-7.607 0-6.613-5.361-11.974-11.974-11.974s-11.973 5.361-11.973 11.974 5.361 11.974 11.974 11.974c2.889 0 5.539-1.024 7.607-2.728l5.478 5.478c0.452 0.453 1.186 0.453 1.639 0s0.453-1.186-0-1.639zM4.799 14.455c0-5.333 4.323-9.656 9.656-9.656s9.656 4.323 9.656 9.656-4.323 9.656-9.656 9.656-9.656-4.323-9.656-9.656z"
        />
      </SVG>
    </Container>
  );
};

export default MagnifyingGlassIcon;
