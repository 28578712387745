/* eslint-disable import/extensions */
/* eslint-disable no-param-reassign */
/* eslint-disable block-scoped-var */
/* eslint-disable func-names */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable no-use-before-define */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable no-empty-function */
import { useState } from 'react';
import { withTranslation } from 'react-i18next';

import MetricsInputControl from '../../MetricsInputControl/MetricsInputControl';
import VerifiedIconV1 from '../../../../assets/iconComponents/VerifiedIconV1';
import EllipsisIcon from '../../../../assets/iconComponents/EllipsisIcon';
import FunnelIcon from '../../../../assets/iconComponents/FunnelIcon';
import {
  DEFAULT_USER_AVATAR,
  SocialNetworks,
} from '../../../../Services/Utils/types';
import {
  convertNumberToHumanStringfiedFormat,
  getSocialNetworkIcon,
} from '../../../../Services/Utils/Utils';
import {
  getShortenedMonthResourceKey,
  getTimeIntervalInDays,
} from '../../../../Services/Utils/dateUtils';
import * as S from './styles';
import { IKpisUserCard, IUserPresentationalCardProps } from './types';

const UserPresentationalCard = (props: IUserPresentationalCardProps) => {
  const [showHeaderFiltersModal, setShowHeaderFiltersModal] = useState(false);
  const [verified, setVerified] = useState(() => {
    if (props.publicUser.socialUserId) {
      if (
        props.user.socialUsers?.find(
          item => String(item.socialUserId) === props.publicUser.socialUserId,
        )
      ) {
        return true;
      }
    }
    return false;
  });

  const renderPicture = (image: string) => {
    return image ?? DEFAULT_USER_AVATAR;
  };

  const renderName = (name: string) => {
    return <S.NameContent>{name}</S.NameContent>;
  };

  const getProfileLink = (network: SocialNetworks, handler: string) => {
    switch (network) {
      case SocialNetworks.FACEBOOK:
      case SocialNetworks.FACEBOOKPAGE:
        return `https://www.facebook.com/${handler}`;
      case SocialNetworks.INSTAGRAM:
      case SocialNetworks.FACEBOOKPAGEINSTAGRAM:
        return `https://www.instagram.com/${handler}`;
      case SocialNetworks.YOUTUBE: {
        const networkProfileId = props.publicUser.socialNetwork.profileId;
        if (networkProfileId) {
          return `https://www.youtube.com/channel/${networkProfileId}`;
        }
        return `https://www.youtube.com/@${handler}`;
      }
    }
  };

  const RenderPublicUserHandler = (
    networkSelectedId: SocialNetworks,
    handler: string,
  ) => {
    return (
      <S.UserHandlerVerifiedIconContainer>
        <S.UserHandlerText
          onClick={() => {
            window.open(getProfileLink(networkSelectedId, handler), '_blank');
          }}
        >
          <S.UserHandlerTextSmall>{`@${handler}`}</S.UserHandlerTextSmall>
        </S.UserHandlerText>
        {verified && (
          <VerifiedIconV1
            containerWidth="1.7rem"
            containerHeight="1.7rem"
            containerMarginLeft="0.3rem"
          />
        )}
      </S.UserHandlerVerifiedIconContainer>
    );
  };

  const renderPublicUserBiography = (biography: string | null) => {
    return (
      <S.UserBiographyContainer>
        {biography ?? props.t(`mediakit.userPresentationalCard.noBiography}`)}
      </S.UserBiographyContainer>
    );
  };

  const renderUserHashTags = (hashtags: string[]) => {
    return (
      <S.UserHashtagsOuterContainer>
        {hashtags.map((hashtag: string) => {
          return <S.UserHashTagsContainer>{hashtag}</S.UserHashTagsContainer>;
        })}
      </S.UserHashtagsOuterContainer>
    );
  };

  const renderSocialNetworkIcon = (item: string) => {
    return getSocialNetworkIcon({
      socialNetwork: item,
      width: '3.4rem',
      height: '3.4rem',
      gradientColored: true,
    });
  };

  const RenderNetWorkRow = (network: SocialNetworks, kpis: IKpisUserCard) => {
    const networks = [network];

    const res = networks.map((item: string) => {
      return (
        <S.UserSocialNetworkContainer>
          <S.UserSocialNetworkColumns>
            <S.SocialNetworkContainer>
              <S.UserSocialNetworkCircle network={item}>
                {renderSocialNetworkIcon(item)}
              </S.UserSocialNetworkCircle>
              <S.UserSocialNetworkLink>
                {props.t(`socialNetworks.${item}`)}
              </S.UserSocialNetworkLink>
            </S.SocialNetworkContainer>
            <S.UserCardKpisContainer>
              {renderKpis(kpis)}
            </S.UserCardKpisContainer>
          </S.UserSocialNetworkColumns>
        </S.UserSocialNetworkContainer>
      );
    });

    if (res.length > 0) {
      return <div>{res}</div>;
    }
    return <div></div>;
  };

  const renderKpis = (kpis: IKpisUserCard) => {
    const totalPostsLabel =
      props.publicUser.socialNetwork.network === SocialNetworks.YOUTUBE
        ? props.t(`mediaKit.userPresentationalCard.totalVideos`)
        : props.t(`mediaKit.userPresentationalCard.totalPosts`);

    return (
      <>
        <S.UserKpisContainer>
          <S.UserKpisValue>
            <MetricsInputControl
              fieldType={'text'}
              freeText={true}
              normalizeNumber={true}
              roundNumber={true}
              value={convertNumberToHumanStringfiedFormat(kpis.followers, 2)}
            ></MetricsInputControl>
          </S.UserKpisValue>
          <S.UserKpisTitle>{props.t(`generic.followers`)}</S.UserKpisTitle>
        </S.UserKpisContainer>

        <S.UserKpisContainer>
          <S.UserKpisValue>
            <MetricsInputControl
              fieldType={'text'}
              freeText={true}
              normalizeNumber={true}
              roundNumber={true}
              value={convertNumberToHumanStringfiedFormat(
                Math.round(kpis.postCount),
                0,
              )}
            ></MetricsInputControl>
          </S.UserKpisValue>
          <S.UserKpisTitle>{totalPostsLabel}</S.UserKpisTitle>
        </S.UserKpisContainer>

        <S.UserKpisContainer>
          <S.UserKpisValue>
            <MetricsInputControl
              fieldType={'perc'}
              freeText={true}
              normalizeNumber={true}
              roundNumber={false}
              value={(kpis.engRateAvg * 100).toFixed(1)}
            ></MetricsInputControl>
          </S.UserKpisValue>
          <S.UserKpisTitle>
            {props.t(`mediaKit.userPresentationalCard.avgEngRate`)}
          </S.UserKpisTitle>
        </S.UserKpisContainer>

        <S.UserKpisContainer>
          <S.UserKpisValue>
            <MetricsInputControl
              fieldType={'text'}
              freeText={true}
              normalizeNumber={true}
              roundNumber={true}
              value={convertNumberToHumanStringfiedFormat(
                kpis.likesAvg.toFixed(2),
                2,
              )}
            ></MetricsInputControl>
          </S.UserKpisValue>
          <S.UserKpisTitle>
            {props.t(`mediaKit.userPresentationalCard.avgLikes`)}
          </S.UserKpisTitle>
        </S.UserKpisContainer>

        <S.UserKpisContainer>
          <S.UserKpisValue>
            <MetricsInputControl
              fieldType={'text'}
              freeText={true}
              normalizeNumber={true}
              roundNumber={true}
              value={convertNumberToHumanStringfiedFormat(
                kpis.commentsAvg.toFixed(2),
                2,
              )}
            ></MetricsInputControl>
          </S.UserKpisValue>
          <S.UserKpisTitle>
            {props.t(`mediaKit.userPresentationalCard.avgComments`)}
          </S.UserKpisTitle>
        </S.UserKpisContainer>
      </>
    );
  };

  const getTimeIntervalLabel = () => {
    const getStartDateLabel = () => {
      return `${props.t(
        getShortenedMonthResourceKey(props.startDate),
      )} ${props.startDate.getDate()}${
        props.startDate.getFullYear() !== props.endDate.getFullYear()
          ? `, ${props.startDate.getFullYear()}`
          : ''
      }`;
    };

    const getEndDateLabel = () => {
      return `${props.t(
        getShortenedMonthResourceKey(props.endDate),
      )} ${props.endDate.getDate()}, ${props.endDate.getFullYear()}`;
    };

    const daysSpan = getTimeIntervalInDays(props.startDate, props.endDate);
    let daysSpanText = '';

    if (daysSpan) {
      daysSpanText = ` (${daysSpan} ${props.t(
        `mediaKit.userPresentationalCard.${daysSpan === 1 ? 'day' : 'days'}`,
      )})`;
    }

    return `${getStartDateLabel()} - ${getEndDateLabel()}${daysSpanText}`;
  };

  const showEllipsis = () => {
    return Boolean(
      props.keywordFilters.length > 1 ||
        (props.keywordFilters?.[0] && props.keywordFilters[0].length >= 15) ||
        (props.hasPostPicking && props.keywordFilters.length > 1),
    );
  };

  return (
    <>
      <S.UserCardOuterContainer>
        <S.UserCardInnerContainer>
          <S.UserAnalyticsPanelContainer>
            <S.UserAnalyticsPanelPictureContainer>
              <S.UserAnalyticsPanelPicturePadding>
                <S.UserAnalyticsPanelPicturePosition>
                  <S.UserAnalyticsPanelPictureBackground>
                    <S.UserAnalyticsPanelPictureBackgroundCircle></S.UserAnalyticsPanelPictureBackgroundCircle>
                  </S.UserAnalyticsPanelPictureBackground>
                  <S.UserAnalyticsPanelPictureUser>
                    <S.UserAnalyticsPanelPictureUserImage
                      src={renderPicture(props.publicUser.image)}
                    />
                  </S.UserAnalyticsPanelPictureUser>
                </S.UserAnalyticsPanelPicturePosition>
              </S.UserAnalyticsPanelPicturePadding>
            </S.UserAnalyticsPanelPictureContainer>

            <S.UserAnalyticsPanelMidSectionContainer>
              <S.UserAnalyticsPanelMidSectionRow>
                <S.UserAnalyticsPanelMidSectionRowInner>
                  {renderName(props.publicUser.name)}
                  {RenderPublicUserHandler(
                    props.publicUser.socialNetwork.network,
                    props.publicUser.socialNetwork.username,
                  )}
                  {renderPublicUserBiography(props.publicUser.biography)}
                </S.UserAnalyticsPanelMidSectionRowInner>
              </S.UserAnalyticsPanelMidSectionRow>

              <S.UserHashTagsMainContainer>
                {props.publicUserHashtagsLatest.length > 0 && (
                  <S.UserHashtagsInnerContainer>
                    <S.UserHashTagsIcon></S.UserHashTagsIcon>
                    {renderUserHashTags(props.publicUserHashtagsLatest)}
                  </S.UserHashtagsInnerContainer>
                )}
              </S.UserHashTagsMainContainer>
            </S.UserAnalyticsPanelMidSectionContainer>
            <S.UserAnalyticsPanelMidSectionRightContainer>
              <S.FiltersOuterContainer>
                <S.FiltersContainer>
                  <S.FilterEntry>{getTimeIntervalLabel()}</S.FilterEntry>
                  {props.hasPostPicking === true && (
                    <S.FilterEntry>
                      {props.t('mediaKit.userPresentationalCard.customized')}
                      {/* <InfoIconV2
                      containerHeight="1.4rem"
                      containerWidth="1.4rem"
                      containerMarginLeft="0.6rem"
                    /> */}
                    </S.FilterEntry>
                  )}
                  {props.keywordFilters?.[0] && (
                    <S.FilterEntry maxWidth="12.6rem">
                      <span>{props.keywordFilters[0]}</span>
                    </S.FilterEntry>
                  )}
                  {showEllipsis() && (
                    <S.FilterEntry
                      cursor="pointer"
                      onClick={() =>
                        setShowHeaderFiltersModal(prevState => !prevState)
                      }
                    >
                      <EllipsisIcon
                        containerHeight="2rem"
                        containerWidth="2rem"
                      />
                    </S.FilterEntry>
                  )}
                </S.FiltersContainer>
              </S.FiltersOuterContainer>
            </S.UserAnalyticsPanelMidSectionRightContainer>
          </S.UserAnalyticsPanelContainer>

          <S.UserAnalyticsPanelMargin>
            {RenderNetWorkRow(
              props.publicUser.socialNetwork.network,
              props.kpis,
            )}
          </S.UserAnalyticsPanelMargin>
        </S.UserCardInnerContainer>
      </S.UserCardOuterContainer>

      {showHeaderFiltersModal && (
        <>
          <S.FiltersModalContainer
            onClick={() => setShowHeaderFiltersModal(false)}
          />
          <S.FiltersModal onClick={(e: any) => e.preventDefault()}>
            <S.FiltersModalHeader>
              <FunnelIcon
                containerHeight="2.7rem"
                containerWidth="2.7rem"
                containerMarginRight="2rem"
              />
              {props.t('generic.allFilters')}
            </S.FiltersModalHeader>
            <S.FiltersModalBody>
              <S.FilterEntry>{getTimeIntervalLabel()}</S.FilterEntry>
              {props.keywordFilters.map(keyword => (
                <S.FilterEntry>{keyword}</S.FilterEntry>
              ))}
            </S.FiltersModalBody>
          </S.FiltersModal>
        </>
      )}
    </>
  );
};

export default withTranslation()(UserPresentationalCard);
