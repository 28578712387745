/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable import/extensions */
/* eslint-disable default-param-last */
import { useMutation, useQuery } from 'react-query';

import SkorrApi from '../../Services/SkorrApi/new';
import {
  DeleteUserBrandPictureParams,
  GenerateExternalMediaKitUrlParams,
  GeneratePortfolioUrlParams,
  GetPortfolioByBrandPictureInput,
  PortfolioMediaKit,
  UpdateExternalMediaKitByIdParams,
  UpdatePortfolioByIdParams,
  UploadPortfolioImageInput,
  UploadUserBrandPictureParams,
} from '../../Services/SkorrApi/new/ExternalMediaKit/types';
import { FileSourceEnum } from '../../Services/SkorrApi/new/Files/types';
import { IGetPublicPostsByPublicUserIdFilteredByKeywordsParams } from '../../Services/SkorrApi/new/Post';
import { GetPublicUserHashtagsSortedByMetricsParams } from '../../Services/SkorrApi/new/Post/types';
import { IErrorObject } from '../../Services/Utils/types';
import {
  isAuthenticated,
  logApiRequestError,
} from '../../Services/Utils/Utils';
import {
  GeneratePortfolioUrlCustomHookParams,
  UpdatePortfolioByIdCustomHookParams,
} from './types';

const { post, externalMediaKit } = SkorrApi;

export const useGetPublicUserHashtagsLatest = (
  enabled: boolean = false,
  params: GetPublicUserHashtagsSortedByMetricsParams,
) => {
  return useQuery(
    [],
    async () => {
      try {
        const data = await post.getPublicUserHashtagsSortedByMetrics(params);
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: false,
    },
  );
};

export const useGetPublicPostsByPublicUserIdFilteredByKeywords = (
  enabled: boolean = false,
  params: IGetPublicPostsByPublicUserIdFilteredByKeywordsParams,
) => {
  return useQuery(
    [
      'get-public-posts-by-public-user-id-filtered-by-keywords',
      JSON.stringify(params),
    ],
    async () => {
      try {
        const data = await post.getPublicPostsByPublicUserIdFilteredByKeywords(
          params,
        );
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: false,
    },
  );
};

export const useGetExternalMediaKitConfigurationById = (
  enabled: boolean = false,
  id: string,
) => {
  return useQuery(
    ['get-external-media-kit-configuration-by-id', id],
    async () => {
      try {
        const data = await externalMediaKit.getExternalMediaConfigurationById(
          id,
        );

        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: false,
    },
  );
};

export const useGenerateExternalMediaKitUrl = () => {
  return useMutation(
    async (params: GenerateExternalMediaKitUrlParams) => {
      try {
        const response = await externalMediaKit.generateExternalMediaKitUrl(
          params,
        );
        return response;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};

export const useUpdateExternalMediaKitById = () => {
  return useMutation(
    async (params: UpdateExternalMediaKitByIdParams) => {
      try {
        const response = await externalMediaKit.updateExternalMediaKitById(
          params,
        );

        return response;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};

export const useGetPortfolioByBrandPicture = (
  enabled: boolean = false,
  params: GetPortfolioByBrandPictureInput,
) => {
  return useQuery(
    [
      'get-portfolio-by-brand-picture',
      JSON.stringify({
        ...params,
        userId: isAuthenticated()!.businessProfileUserId,
      }),
    ],
    async () => {
      try {
        const data = await externalMediaKit.getPortfolioByBrandPicture(params);

        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: false,
    },
  );
};

export const useGeneratePortfolioUrl = () => {
  return useMutation(
    async (params: GeneratePortfolioUrlCustomHookParams) => {
      try {
        const generatePortfolioUrlParams: GeneratePortfolioUrlParams = {
          name: params.name,
          ...(params.expiredAt && { expiredAt: params.expiredAt }),
          mediaKits: [],
          layout: params.layout,
          themeColor: params.themeColor,
          buttonsUIColor: params.buttonsUIColor,
          contextName: params.contextName,
          tags: params.tags,
          ...(params.countryId && { countryId: params.countryId }),
          ...(params.about && { about: params.about }),
          ...(params.partnersTitle && { partnersTitle: params.partnersTitle }),
          partners: params.partners,
        };

        let portfolioId = null;

        for (const mediaKit of params.mediaKits) {
          const mediaKitLocal = { ...mediaKit };

          if ((mediaKit.picture as any)?.data) {
            const imageFormData = new FormData();
            imageFormData.append('file', (mediaKit.picture as any).data);
            imageFormData.append(
              'source',
              FileSourceEnum.PORTFOLIO_MEDIAKIT_PICTURE,
            );
            imageFormData.append('mediaKitId', mediaKit.id);
            if (portfolioId) {
              imageFormData.append('id', portfolioId);
            }

            const { url: imageUrl, id } =
              await externalMediaKit.uploadPortfolioImage(imageFormData);

            portfolioId = id;
            mediaKitLocal.picture = imageUrl;
          }

          generatePortfolioUrlParams.mediaKits.push(
            mediaKitLocal as PortfolioMediaKit,
          );
        }

        if ((params.picture as any)?.data) {
          const imageFormData = new FormData();
          imageFormData.append('file', (params.picture as any).data);
          imageFormData.append('source', FileSourceEnum.PORTFOLIO_PICTURE);
          if (portfolioId) {
            imageFormData.append('id', portfolioId);
          }

          const { url: imageUrl, id } =
            await externalMediaKit.uploadPortfolioImage(imageFormData);

          portfolioId = id;
          generatePortfolioUrlParams.picture = imageUrl;
        }

        if ((params.pictureBackground as any)?.data) {
          const imageFormData = new FormData();
          imageFormData.append('file', (params.pictureBackground as any).data);
          imageFormData.append('source', FileSourceEnum.PORTFOLIO_BACKGROUND);
          if (portfolioId) {
            imageFormData.append('id', portfolioId);
          }

          const { url: imageUrl, id } =
            await externalMediaKit.uploadPortfolioImage(imageFormData);

          portfolioId = id;
          generatePortfolioUrlParams.pictureBackground = imageUrl;
        }

        const result = await externalMediaKit.generatePortfolioUrl(
          generatePortfolioUrlParams,
        );

        return result;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};

export const useUpdatePortfolioById = () => {
  return useMutation(
    async (params: UpdatePortfolioByIdCustomHookParams) => {
      try {
        const updatePortfolioByIdParams: UpdatePortfolioByIdParams = {
          id: params.id,
          name: params.name,
          ...(params.expiredAt && { expiredAt: params.expiredAt }),
          mediaKits: [],
          layout: params.layout,
          themeColor: params.themeColor,
          buttonsUIColor: params.buttonsUIColor,
          contextName: params.contextName,
          tags: params.tags,
          ...(params.countryId && { countryId: params.countryId }),
          ...(params.about && { about: params.about }),
          ...(params.partnersTitle && { partnersTitle: params.partnersTitle }),
          partners: params.partners,
        };

        for (const mediaKit of params.mediaKits) {
          const mediaKitLocal = { ...mediaKit };

          if ((mediaKit.picture as any)?.data) {
            const imageFormData = new FormData();
            imageFormData.append('file', (mediaKit.picture as any).data);
            imageFormData.append(
              'source',
              FileSourceEnum.PORTFOLIO_MEDIAKIT_PICTURE,
            );
            imageFormData.append('mediaKitId', mediaKit.id);
            imageFormData.append('id', params.id);

            const { url: imageUrl } =
              await externalMediaKit.uploadPortfolioImage(imageFormData);

            mediaKitLocal.picture = imageUrl;
          }

          updatePortfolioByIdParams.mediaKits.push(
            mediaKitLocal as PortfolioMediaKit,
          );
        }

        if ((params.picture as any)?.data) {
          const imageFormData = new FormData();
          imageFormData.append('file', (params.picture as any).data);
          imageFormData.append('source', FileSourceEnum.PORTFOLIO_PICTURE);
          imageFormData.append('id', params.id);

          const { url: imageUrl } = await externalMediaKit.uploadPortfolioImage(
            imageFormData,
          );

          updatePortfolioByIdParams.picture = imageUrl;
        }

        if ((params.pictureBackground as any)?.data) {
          const imageFormData = new FormData();
          imageFormData.append('file', (params.pictureBackground as any).data);
          imageFormData.append('source', FileSourceEnum.PORTFOLIO_BACKGROUND);
          imageFormData.append('id', params.id);

          const { url: imageUrl } = await externalMediaKit.uploadPortfolioImage(
            imageFormData,
          );

          updatePortfolioByIdParams.pictureBackground = imageUrl;
        }

        const result = await externalMediaKit.updateExternalMediaKitById(
          updatePortfolioByIdParams,
        );

        return result;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};

export const useUploadUserBrandPicture = () => {
  return useMutation(
    async (params: UploadUserBrandPictureParams) => {
      try {
        const result = await externalMediaKit.uploadUserBrandPicture(params);

        return result;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};

export const useDeleteUserBrandPicture = () => {
  return useMutation(
    async (params: DeleteUserBrandPictureParams) => {
      try {
        const result = await externalMediaKit.deleteUserBrandPicture(params);

        return result;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};

export const useGetUserBrandPictures = (enabled: boolean = false) => {
  return useQuery(
    ['get-user-brands', isAuthenticated()!.businessProfileUserId],
    async () => {
      try {
        const data = await externalMediaKit.getUserBrandPictures();

        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: false,
    },
  );
};
