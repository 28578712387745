/* eslint-disable import/extensions */
import { useTheme } from 'styled-components';

import SVG from './Styles';

interface IBackArrowIconProps {
  width?: string;
  height?: string;
  onClick?: any;
}

const BackArrowIcon = (props: IBackArrowIconProps) => {
  const themeGlobal = useTheme();
  const svgColor = (themeGlobal as any).signin[
    'signin-forgot-password-back-arrow-color'
  ];

  return (
    <SVG
      viewBox="0 0 32 32"
      width={props.width ? props.width : '100%'}
      height={props.height ? props.height : '100%'}
      onClick={props.onClick}
    >
      <path
        fill={svgColor}
        d="M29.333 16c0 0.736-0.596 1.333-1.333 1.333h-20.781l8.391 8.391c0.521 0.521 0.521 1.364 0 1.885-0.26 0.26-0.601 0.391-0.943 0.391s-0.683-0.131-0.943-0.391l-10.667-10.667c-0.123-0.123-0.22-0.271-0.288-0.433-0.135-0.327-0.135-0.693 0-1.019 0.068-0.163 0.165-0.311 0.288-0.433l10.667-10.667c0.521-0.521 1.364-0.521 1.885 0s0.521 1.364 0 1.885l-8.391 8.391h20.781c0.737 0 1.333 0.597 1.333 1.333z"
      />
    </SVG>
  );
};

export default BackArrowIcon;
