/* eslint-disable import/extensions */
import { useTheme } from 'styled-components';

import SVG from './Styles';

interface IForwardArrowIconProps {
  width?: string;
  height?: string;
  onClick?: any;
}

const ForwardArrowIcon = (props: IForwardArrowIconProps) => {
  const themeGlobal = useTheme();
  const svgColor = (themeGlobal as any).signin[
    'signin-forgot-password-back-arrow-color'
  ];

  return (
    <SVG
      viewBox="0 0 32 32"
      width={props.width ? props.width : '100%'}
      height={props.height ? props.height : '100%'}
      onClick={props.onClick}
    >
      <path
        fill={svgColor}
        d="M2.493 17.569h23.227l-8.307 8.307c-0.613 0.613-0.613 1.606 0 2.219s1.606 0.613 2.219 0l10.986-10.986c0.145-0.145 0.26-0.318 0.339-0.51 0.159-0.384 0.159-0.816 0-1.2-0.080-0.192-0.194-0.366-0.339-0.51l-10.986-10.986c-0.306-0.306-0.708-0.46-1.11-0.46s-0.803 0.153-1.11 0.46c-0.613 0.613-0.613 1.606 0 2.219l8.307 8.307h-23.227c-0.867 0-1.569 0.703-1.569 1.569s0.702 1.57 1.569 1.57z"
      />
    </SVG>
  );
};

export default ForwardArrowIcon;
