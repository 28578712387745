/* eslint-disable import/extensions */
import styled from 'styled-components';

import SVG from './Styles';

interface IPortfolioVerticalLayoutTypeIconProps {
  containerWidth?: string;
  containerHeight?: string;
  containerPaddingTop?: string;
  containerPaddingBottom?: string;
  containerPaddingLeft?: string;
  containerPaddingRight?: string;
  containerMarginTop?: string;
  containerMarginBottom?: string;
  containerMarginLeft?: string;
  containerMarginRight?: string;
  svgWidth?: string;
  svgHeight?: string;
  cursor?: string;
  onClick?: any;
  id?: string;
}

interface IContainerProps {
  width?: string;
  height?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
}

const Container = styled.div<IContainerProps>`
  border-width: 0;
  outline: 0;
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '0')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '0')};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '0')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '0')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
  display: inline-flex;
  align-items: center;
`;

const PortfolioVerticalLayoutTypeIcon = (
  props: IPortfolioVerticalLayoutTypeIconProps,
) => {
  return (
    <Container
      width={props.containerWidth}
      height={props.containerHeight}
      paddingTop={props.containerPaddingTop}
      paddingBottom={props.containerPaddingBottom}
      paddingLeft={props.containerPaddingLeft}
      paddingRight={props.containerPaddingRight}
      marginTop={props.containerMarginTop}
      marginBottom={props.containerMarginBottom}
      marginLeft={props.containerMarginLeft}
      marginRight={props.containerMarginRight}
      id={props.id}
    >
      <SVG
        viewBox="0 0 80 121"
        width={props.svgWidth ? props.svgWidth : '100%'}
        height={props.svgHeight ? props.svgHeight : '100%'}
        cursor={props.cursor}
      >
        <g clip-path="url(#clip0_5440_26028)">
          <path
            d="M70.3615 0.518066H9.63855C4.31533 0.518066 0 4.83339 0 10.1566V110.398C0 115.721 4.31533 120.036 9.63855 120.036H70.3615C75.6847 120.036 80 115.721 80 110.398V10.1566C80 4.83339 75.6847 0.518066 70.3615 0.518066Z"
            fill="#D9DEE6"
          />
          <path d="M80 39.0723H0V82.4458H80V39.0723Z" fill="#EFF1F6" />
          <path
            d="M28.9157 45.3373C28.9157 38.9495 23.7373 33.7711 17.3495 33.7711C10.9616 33.7711 5.7832 38.9495 5.7832 45.3373C5.7832 51.7252 10.9616 56.9036 17.3495 56.9036C23.7373 56.9036 28.9157 51.7252 28.9157 45.3373Z"
            fill="#EFF1F6"
          />
          <path
            d="M26.988 45.3373C26.988 40.0141 22.6727 35.6988 17.3495 35.6988C12.0263 35.6988 7.71094 40.0141 7.71094 45.3373C7.71094 50.6606 12.0263 54.9759 17.3495 54.9759C22.6727 54.9759 26.988 50.6606 26.988 45.3373Z"
            fill="#D9DEE6"
          />
          <path
            d="M56.385 42.9277H33.7344C32.6697 42.9277 31.8066 43.7908 31.8066 44.8554C31.8066 45.9201 32.6697 46.7832 33.7344 46.7832H56.385C57.4496 46.7832 58.3127 45.9201 58.3127 44.8554C58.3127 43.7908 57.4496 42.9277 56.385 42.9277Z"
            fill="#D9DEE6"
          />
          <path
            d="M34.2175 61.241H10.1211C9.05642 61.241 8.19336 62.104 8.19336 63.1687C8.19336 64.2333 9.05642 65.0964 10.1211 65.0964H34.2175C35.2821 65.0964 36.1452 64.2333 36.1452 63.1687C36.1452 62.104 35.2821 61.241 34.2175 61.241Z"
            fill="#D9DEE6"
          />
          <path
            d="M34.2175 67.0241H10.1211C9.05642 67.0241 8.19336 67.8872 8.19336 68.9518C8.19336 70.0165 9.05642 70.8795 10.1211 70.8795H34.2175C35.2821 70.8795 36.1452 70.0165 36.1452 68.9518C36.1452 67.8872 35.2821 67.0241 34.2175 67.0241Z"
            fill="#D9DEE6"
          />
          <path
            d="M50.6015 66.0603C50.6015 63.931 48.8753 62.2048 46.746 62.2048C44.6168 62.2048 42.8906 63.931 42.8906 66.0603C42.8906 68.1895 44.6168 69.9157 46.746 69.9157C48.8753 69.9157 50.6015 68.1895 50.6015 66.0603Z"
            fill="#D9DEE6"
          />
          <path
            d="M61.205 66.0603C61.205 63.931 59.4789 62.2048 57.3496 62.2048C55.2203 62.2048 53.4941 63.931 53.4941 66.0603C53.4941 68.1895 55.2203 69.9157 57.3496 69.9157C59.4789 69.9157 61.205 68.1895 61.205 66.0603Z"
            fill="#D9DEE6"
          />
          <path
            d="M71.8065 66.0603C71.8065 63.931 70.0804 62.2048 67.9511 62.2048C65.8218 62.2048 64.0957 63.931 64.0957 66.0603C64.0957 68.1895 65.8218 69.9157 67.9511 69.9157C70.0804 69.9157 71.8065 68.1895 71.8065 66.0603Z"
            fill="#D9DEE6"
          />
          <path
            d="M7.71094 82.4458C7.71094 80.8487 9.00554 79.5542 10.6025 79.5542H14.9399C16.5368 79.5542 17.8314 80.8487 17.8314 82.4458H7.71094Z"
            fill="#D9DEE6"
          />
          <path
            d="M7.71094 94.0121L7.71094 108.47C7.71094 110.599 9.43707 112.325 11.5664 112.325H68.4338C70.5631 112.325 72.2893 110.599 72.2893 108.47V94.0121C72.2893 91.8828 70.5631 90.1566 68.4338 90.1566H11.5664C9.43707 90.1566 7.71094 91.8828 7.71094 94.0121Z"
            fill="#EFF1F6"
          />
          <path
            d="M61.6862 96.4217H37.5898C36.5252 96.4217 35.6621 97.2848 35.6621 98.3494C35.6621 99.414 36.5252 100.277 37.5898 100.277H61.6862C62.7509 100.277 63.6139 99.414 63.6139 98.3494C63.6139 97.2848 62.7509 96.4217 61.6862 96.4217Z"
            fill="#D9DEE6"
          />
          <path
            d="M61.6862 102.205H37.5898C36.5252 102.205 35.6621 103.068 35.6621 104.133C35.6621 105.197 36.5252 106.06 37.5898 106.06H61.6862C62.7509 106.06 63.6139 105.197 63.6139 104.133C63.6139 103.068 62.7509 102.205 61.6862 102.205Z"
            fill="#D9DEE6"
          />
          <path
            d="M26.5057 100.759C26.5057 97.0328 23.485 94.012 19.7587 94.012C16.0324 94.012 13.0117 97.0328 13.0117 100.759C13.0117 104.485 16.0324 107.506 19.7587 107.506C23.485 107.506 26.5057 104.485 26.5057 100.759Z"
            fill="#D9DEE6"
          />
        </g>
        <defs>
          <clipPath id="clip0_5440_26028">
            <rect
              width="80"
              height="119.518"
              fill="white"
              transform="translate(0 0.518066)"
            />
          </clipPath>
        </defs>
      </SVG>
    </Container>
  );
};

export default PortfolioVerticalLayoutTypeIcon;
