/* eslint-disable import/extensions */
import * as S from './Styles';
import { IButtonProps } from './types';

const Button = (props: IButtonProps) => {
  return (
    <S.StyledButton
      id={props.id}
      themeStyles={props.themeStyles}
      disabled={props.disabled}
      disabledNotNative={props.disabledNotNative}
      cursor={props.cursor}
      cursorOnDisable={props.cursorOnDisable}
      onClick={props.onClick}
      width={props.width}
      height={props.height}
      color={props.color}
      fontSize={props.fontSize}
      fontWeight={props.fontWeight}
      fontFamily={props.fontFamily}
      hoverColor={props.hoverColor}
      backgroundColor={props.backgroundColor}
      hoverBackgroundColor={props.hoverBackgroundColor}
      borderColor={props.borderColor}
      borderWidth={props.borderWidth}
      borderRadius={props.borderRadius}
      borderStyle={props.borderStyle}
      hoverBorderColor={props.hoverBorderColor}
      marginTop={props.marginTop}
      marginBottom={props.marginBottom}
      marginLeft={props.marginLeft}
      marginRight={props.marginRight}
      paddingTop={props.paddingTop}
      paddingBottom={props.paddingBottom}
      paddingLeft={props.paddingLeft}
      paddingRight={props.paddingRight}
      opacity={props.opacity}
      pointerEvents={props.pointerEvents}
      transition={props.transition}
      disabledBackgroundColor={props.disabledBackgroundColor}
      hoverDisabledBackgroundColor={props.hoverDisabledBackgroundColor}
      disabledBorderColor={props.disabledBorderColor}
      hoverDisabledBorderColor={props.hoverDisabledBorderColor}
      display={props.display}
      justifyContent={props.justifyContent}
      alignItems={props.alignItems}
      textAlign={props.textAlign}
      position={props.position}
      marginBlock={props.marginBlock}
      blockHoverStylesOnDisable={props.blockHoverStylesOnDisable}
      pointerEventsOnDisable={props.pointerEventsOnDisable}
      type={props.type}
      outline={props.outline}
      flexGrow={props.flexGrow}
      maxWidth={props.maxWidth}
      flexBasis={props.flexBasis}
      topOffset={props.topOffset}
      bottomOffset={props.bottomOffset}
      leftOffset={props.leftOffset}
      rightOffset={props.rightOffset}
      blockHoverStyles={props.blockHoverStyles}
    >
      {props.children}
    </S.StyledButton>
  );
};

export default Button;
