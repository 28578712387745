/* eslint-disable import/extensions */
/* eslint-disable no-nested-ternary */
import { withTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { SocialNetworks } from '../../../../Services/Utils/types';
import {
  IPublicPost,
  PublicPostTypes,
} from '../../../../Services/SkorrApi/new/Post/types';
import { IPublicPostsProps } from './types';
import * as S from './styles';
import Post from '../Post';

const Posts = (props: IPublicPostsProps) => {
  const [posts, setPosts] = useState<IPublicPost[]>([]);
  const [stories, setStories] = useState<IPublicPost[]>([]);

  useEffect(() => {
    const postsLocal: IPublicPost[] = [];
    const storiesLocal: IPublicPost[] = [];

    props.posts.forEach(post => {
      if (post.type === PublicPostTypes.STORY) {
        storiesLocal.push(post);
      } else {
        postsLocal.push(post);
      }
    });

    setPosts(postsLocal);
    setStories(storiesLocal);
  }, [props.posts]);

  if (posts.length || stories.length) {
    return (
      <>
        {stories.length ? (
          <S.OuterContainer>
            <S.Title>{props.t('mediaKit.stories.title')}</S.Title>
            <S.PostsContainer>
              {stories.map((post: IPublicPost) => (
                <Post post={post} user={props.user} key={post.id} />
              ))}
            </S.PostsContainer>
          </S.OuterContainer>
        ) : (
          <></>
        )}

        {posts.length ? (
          <S.OuterContainer>
            <S.Title>
              {props.t(
                props.user.socialNetwork?.network === SocialNetworks.YOUTUBE
                  ? 'generic.videos'
                  : 'mediaKit.posts.title',
              )}
            </S.Title>
            <S.PostsContainer>
              {posts.map((post: IPublicPost) => (
                <Post post={post} user={props.user} key={post.id} />
              ))}
            </S.PostsContainer>
          </S.OuterContainer>
        ) : (
          <></>
        )}
      </>
    );
  }

  return null;
};

export default withTranslation()(Posts);
