/* eslint-disable no-param-reassign */
/* eslint-disable import/extensions */
import { useMutation } from 'react-query';

import { DEFAULT_USER_AVATAR, IErrorObject } from '../../Services/Utils/types';
import {
  IUseCreateBusinessProfileParams,
  IUseUpdateBusinessProfileParams,
} from './types';
import SkorrApi from '../../Services/SkorrApi/new';
import { logApiRequestError } from '../../Services/Utils/Utils';

const { user } = SkorrApi;
const { files } = SkorrApi;

export const useCreateBusinessProfile = () => {
  return useMutation(
    async (params: IUseCreateBusinessProfileParams) => {
      try {
        const { data: imageUrl }: { data: string } =
          params.businessProfilePictureData
            ? await files.uploadImage(params.businessProfilePictureData!)
            : { data: DEFAULT_USER_AVATAR };
        params.businessProfileCreationData.displayPicture = imageUrl;
        const { data: result } = await user.createBusinessProfile(
          params.businessProfileCreationData,
        );
        return result._id;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};

export const useUpdateBusinessProfile = () => {
  return useMutation(
    async (params: IUseUpdateBusinessProfileParams) => {
      try {
        const { data: imageUrl }: { data: string } =
          params.businessProfilePictureData
            ? await files.uploadImage(params.businessProfilePictureData!)
            : { data: '' };
        if (imageUrl) {
          params.businessProfileUpdateData.displayPicture = imageUrl;
        } else {
          params.businessProfileUpdateData.displayPicture =
            params.businessProfileInitialPicture || DEFAULT_USER_AVATAR;
        }
        const { data: modifiedCount } = await user.updateBusinessProfile(
          params.businessProfileUpdateData,
        );
        return modifiedCount;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};

export const useDeleteBusinessProfile = () => {
  return useMutation(
    async (businessProfileId: string) => {
      try {
        await user.deleteBusinessProfile(businessProfileId);
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};
