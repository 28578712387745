/* eslint-disable import/extensions */
import styled, { useTheme } from 'styled-components';
import { useState, useEffect } from 'react';

import SVG from './Styles';

interface IBulletListIconV1Props {
  containerWidth?: string;
  containerHeight?: string;
  containerPaddingTop?: string;
  containerPaddingBottom?: string;
  containerPaddingLeft?: string;
  containerPaddingRight?: string;
  containerMarginTop?: string;
  containerMarginBottom?: string;
  containerMarginLeft?: string;
  containerMarginRight?: string;
  svgWidth?: string;
  svgHeight?: string;
  rotate?: string;
  cursor?: string;
}

interface IContainerProps {
  width?: string;
  height?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
}

const Container = styled.div<IContainerProps>`
  border-width: 0;
  outline: 0;
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '0')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '0')};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '0')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '0')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const BulletListIconV1 = (props: IBulletListIconV1Props) => {
  const themeGlobal = useTheme();
  const [svgColor, setSvgColor] = useState<string>(() => {
    return (themeGlobal as any).icons.bulletListIconV1;
  });

  useEffect(() => {
    setSvgColor((themeGlobal as any).icons.bulletListIconV1);
  }, [(themeGlobal as any).darkMode]);

  return (
    <Container
      width={props.containerWidth}
      height={props.containerHeight}
      paddingTop={props.containerPaddingTop}
      paddingBottom={props.containerPaddingBottom}
      paddingLeft={props.containerPaddingLeft}
      paddingRight={props.containerPaddingRight}
      marginTop={props.containerMarginTop}
      marginBottom={props.containerMarginBottom}
      marginLeft={props.containerMarginLeft}
      marginRight={props.containerMarginRight}
    >
      <SVG
        viewBox="0 0 32 32"
        width={props.svgWidth ? props.svgWidth : '100%'}
        height={props.svgHeight ? props.svgHeight : '100%'}
        rotateNotNative={props.rotate}
        cursor={props.cursor}
      >
        <path
          fill={svgColor}
          d="M28.8 25.6c0 0.96-0.64 1.6-1.44 1.6h-14.72c-0.8 0-1.44-0.64-1.44-1.6s0.64-1.6 1.44-1.6h14.72c0.8 0 1.44 0.64 1.44 1.6zM27.36 14.4h-14.72c-0.8 0-1.44 0.64-1.44 1.6s0.64 1.6 1.44 1.6h14.72c0.8 0 1.44-0.64 1.44-1.6s-0.64-1.6-1.44-1.6zM27.36 4.8h-14.72c-0.8 0-1.44 0.64-1.44 1.6s0.64 1.6 1.44 1.6h14.72c0.8 0 1.44-0.64 1.44-1.6s-0.64-1.6-1.44-1.6zM5.6 4c-1.28 0-2.4 1.12-2.4 2.4s1.12 2.4 2.4 2.4 2.4-1.12 2.4-2.4-1.12-2.4-2.4-2.4zM5.6 13.6c-1.28 0-2.4 1.12-2.4 2.4s1.12 2.4 2.4 2.4 2.4-1.12 2.4-2.4-1.12-2.4-2.4-2.4zM5.6 23.2c-1.28 0-2.4 1.12-2.4 2.4s1.12 2.4 2.4 2.4 2.4-1.12 2.4-2.4c0-1.28-1.12-2.4-2.4-2.4z"
        ></path>
      </SVG>
    </Container>
  );
};

export default BulletListIconV1;
