/* eslint-disable consistent-return */
import { UncontrolledTooltip } from 'reactstrap';
import styled, { css } from 'styled-components';

import {
  AddedMediaKitsInnerContainerProps,
  AddMediaKitButtonContainerProps,
  AddMediaKitPanelInnerContainerProps,
  CustomizePortfolioColorProps,
  CustomizePortfolioColorsInnerProps,
  CustomizePortfolioHeaderColoredProps,
  CustomizePortfolioTitleProps,
  ExpirationDateArrowContainerProps,
  ExpirationDateInnerContainerProps,
  MainSectionContainerProps,
  MainSectionsContainerProps,
  PortfolioColorActiveCheckMarkProps,
  PortfolioLayoutTypeOptionProps,
  PortfolioLayoutTypes,
  PortfolioPartnerImageProps,
  PortfolioPartnerSelectedProps,
  SocialAccountNetworkIconContainerProps,
  SocialAccountRightContainerProps,
  SocialAccountSubTextContainerProps,
  SocialAccountUsernameProps,
} from './types';

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: start;
  padding-left: 0.8rem;
`;

export const InnerContainer = styled.div`
  width: 95%;
  border-radius: 2.4rem;
  height: calc(100vh - 10.5rem);
  background-color: ${props =>
    props.theme['additional-data-step']['background-color']};
  box-shadow: ${props =>
    `0rem 1.6rem 2.4rem ${props.theme['additional-data-step']['box-shadow-color-1']}, 0rem 0.6rem 3rem ${props.theme['additional-data-step']['box-shadow-color-2']}, 0rem 0.8rem 1rem ${props.theme['additional-data-step']['box-shadow-color-3']}`};
  margin: 2rem 0;

  @media (max-width: 1200px) {
    width: 98%;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: calc(100vh - 5.7rem - 4.4rem);
  }
`;

export const Card = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const HeaderOuterContainer = styled.div`
  height: 10rem;
  margin-bottom: 1rem;
  border-bottom: 0.1rem solid
    ${props => props.theme.signin['signin-content-divider-color']};
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  padding: 0 3.2rem;
  font-size: 2rem;
  font-weight: 600;
  color: ${props => props.theme.text['color-26']};

  @media (max-width: 600px) {
    padding: 0 1.6rem;
  }
`;

export const HeaderBackArrowContainer = styled.div`
  height: 4.2rem;
  width: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  transition: all 0.08s;
  opacity: 1;
  border-radius: 0.8rem;
  cursor: pointer;
  & path {
    fill: ${props =>
      props.theme.signin['signin-forgot-password-back-arrow-color']};
  }

  &:hover {
    background-color: ${props =>
      props.theme.signin[
        'signin-forgot-password-back-arrow-container-background-color-hover'
      ]};
  }
`;

export const HeaderTitlesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
`;

export const HeaderTitleContainer = styled.div`
  font-size: 2rem;
  font-weight: 600;
  color: ${props => props.theme.text['text-26']};
  text-align: center;
  margin-bottom: 0.7rem;
`;

export const HeaderTitleTextContainer = styled.div`
  & > span:last-child {
    color: #5870f6;
  }
`;

export const HeaderSubtitleContainer = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  color: #676767;
  text-align: center;
`;

export const PageCrossIconContainer = styled.div`
  height: 4rem;
  width: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover svg path {
    fill: #8492a6;
  }
`;

export const CardInnerContainer = styled.div`
  padding: 0rem 3.2rem 0 3.2rem;
  display: flex;
  align-items: flex-start;
  height: calc(100% - 19.5rem);
  flex-wrap: wrap;

  @media (max-width: 600px) {
    padding: 0 1.6rem;
  }
`;

export const MainSectionsOuterMostContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const MainSectionsOuterContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
`;

export const MainSectionsContainer = styled.div<MainSectionsContainerProps>`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: calc(100% - 9.6rem);
  visibility: ${props => (props.visible ? 'all' : 'hidden')};

  @media (max-width: 1380px) {
    position: relative;
  }
`;

export const MainSectionsLeftContainer = styled.div<MainSectionContainerProps>`
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  @media (max-width: 1380px) {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: ${props => (props.hide ? 'none' : 'block')};
  }
`;

export const AddMediaKitButtonContainer = styled.div<AddMediaKitButtonContainerProps>`
  display: flex;
  justify-content: center;
  width: 100%;

  & button {
    border-radius: 0.6rem;
    border-width: 0rem;
    height: 4rem;
    font-size: 1.6rem;
    text-align: center;
    font-weight: 500;
    width: 20rem;

    ${props => {
      if (props.mediaKitsPanelVisible) {
        return css`
          background-color: rgba(88, 112, 246, 0.9);
        `;
      }
    }}
  }
`;

export const AddMediaKitPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 5rem;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow-y: auto;
`;

export const AddMediaKitPanelTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.8rem;
  color: #292929;
  margin-bottom: 1rem;
`;

export const AddMediaKitPanelInnerContainer = styled.div<AddMediaKitPanelInnerContainerProps>`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  row-gap: 2rem;
  padding-top: 1rem;

  ${props => {
    if (props.mediaKitCount >= 3) {
      return css`
        width: 80%;

        & > div {
          width: 50%;
        }

        @media (max-width: 1685px) {
          width: 90%;
        }

        @media (max-width: 1510px) {
          width: 100%;
        }

        @media (max-width: 1380px) {
          width: 85%;

          & > div {
            width: 33.3%;
          }
        }

        @media (max-width: 1200px) {
          width: 100%;
        }

        @media (max-width: 1020px) {
          width: 80%;

          & > div {
            width: 50%;
          }
        }

        @media (max-width: 1020px) {
          width: 90%;
        }

        @media (max-width: 795px) {
          width: 100%;
        }

        @media (max-width: 730px) {
          width: 28rem;

          & > div {
            width: 100%;
          }
        }
      `;
    }
    if (props.mediaKitCount === 2) {
      return css`
        width: 80%;

        & > div {
          width: 50%;
        }

        @media (max-width: 1685px) {
          width: 90%;
        }

        @media (max-width: 1510px) {
          width: 100%;
        }

        @media (max-width: 1380px) {
          width: 58%;
        }

        @media (max-width: 1200px) {
          width: 65%;
        }

        @media (max-width: 1060px) {
          width: 75%;
        }

        @media (max-width: 945px) {
          width: 85%;
        }

        @media (max-width: 850px) {
          width: 28.1rem;

          & > div {
            width: 100%;
          }
        }
      `;
    }
    if (props.mediaKitCount === 1) {
      return css`
        width: auto;
      `;
    }
  }}
`;

export const MainSectionsRightContainer = styled.div<MainSectionContainerProps>`
  width: 50%;
  display: flex;
  justify-content: center;
  height: 100%;
  overflow-y: auto;

  @media (max-width: 1380px) {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: ${props => (props.hide ? 'none' : 'block')};
  }
`;

export const MainSectionsRightInnerContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;

  @media (max-width: 700px) {
    padding-left: 7rem;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  align-items: end;
  justify-content: end;
  padding: 2rem 3.2rem;
  border-top: 0.1rem solid
    ${props => props.theme.signin['signin-content-divider-color']};
  margin-top: auto;
`;

export const MediaKitEditionMessage = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
`;

export const MediaKitEditionMessageInner = styled.div`
  background-color: #e0e5ea;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.1rem;
  color: #666f7c;
  padding: 0.8rem 1.4rem;
  border-radius: 1rem;
`;

export const SocialAccountOuterMostContainer = styled.div`
  display: flex;
  justify-content: start;
`;

export const SocialAccountOuterContainer = styled.div`
  cursor: pointer;
  border: 0.2rem solid #f4f4f4;
  border-radius: 0.6rem;
  padding: 0.5rem;

  &:hover {
    background-color: #f4f4f4;
  }

  & * {
    cursor: pointer;
  }
`;

export const SocialAccountContainer = styled.div`
  height: 6rem;
  width: 26.5rem;
  display: flex;
`;

export const SocialAccountLeftContainer = styled.div`
  height: 6rem;
  width: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const SocialAccountImage = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  user-select: none;
  object-fit: cover;
`;

export const SocialAccountNetworkIconContainer = styled.div<SocialAccountNetworkIconContainerProps>`
  ${props => {
    if (!props.single) {
      return css`
        padding: 0.2rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.5rem;
        position: absolute;
        top: 60%;
        left: 60%;
        background-color: white;
      `;
    }

    return css`
      height: 100%;
      width: 100%;
      border-radius: 50%;
      user-select: none;
    `;
  }}
`;

export const SocialAccountRightContainer = styled.div<SocialAccountRightContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding-left: ${props => (props.single ? '0' : '0.8rem')};
`;

export const SocialAccountUsernameContainer = styled.div`
  display: flex;
  user-select: none;
`;

export const SocialAccountSubTextContainer = styled.div<SocialAccountSubTextContainerProps>`
  display: flex;
  align-items: end;
  user-select: none;
  margin-top: ${props => (props.single ? 0 : '0.5rem')};
  height: 1.9rem;

  & svg {
    margin-top: 0 !important;
  }

  & > div {
    margin-top: 0 !important;
    padding: 0 !important;
  }

  & > div:nth-child(2) {
    display: inline-flex;
    align-items: end;
  }

  ${props => {
    if (props.single) {
      return css`
        & div:first-child {
          color: ${props => props.theme.text['color-26']};
          font-size: 1.5rem;
          font-weight: 600;
          height: 1.5rem;
        }
      `;
    }
  }}
`;

export const SocialAccountUsername = styled.div<SocialAccountUsernameProps>`
  margin-bottom: ${props => (props.single ? '0' : '0.9rem')};
  color: ${props => props.theme.text['color-26']};
  font-size: 1.5rem;
  font-weight: 600;
  max-width: 14rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SocialAccountNetworkName = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.2rem;
  color: ${props => props.theme.text['color-27']};
  position: relative;
  display: inline-block;
  height: 1.4rem;
  margin-top: 0.9rem;
  user-select: none;
  max-width: 14rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const SocialAccountUsernameTooltip = styled(UncontrolledTooltip)`
  & > *:first-child {
    z-index: 1000;
  }
  opacity: 1 !important;
  pointer-events: none;

  .tooltip-inner {
    display: inline-block;
    width: auto !important;
    position: relative;
    color: #333;
    background-color: #fff;
    border: 0.1rem solid #eeeeee;
    border-radius: 0.5rem;
    padding: 1rem 1.5rem;
    margin: 0 0 1rem 0;
    filter: drop-shadow(rgba(0, 0, 0, 0.6) 0 0.2rem 0.3rem);
    top: 0.5rem;
  }

  .arrow {
    display: none !important;
  }
`;

export const VerifiedIconContainer = styled.div`
  margin-left: auto;

  & svg {
    margin-top: -0.3rem;
  }
`;

export const SocialAccountAddButton = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  flex-grow: 0;
  width: 3.5rem;
  height: 100%;
`;

export const SocialAccountAddButtonInner = styled.div`
  width: 2.7rem;
  height: 2.7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
`;

export const MediaKitAddedCheckMark = styled.div`
  width: 2.25rem;
  height: 2.25rem;
  background-color: #5870f6;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  & svg path {
    fill: #ffffff;
  }
`;

export const EmptyMediaKitsDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  align-items: center;
  margin-top: 2rem;

  & svg path {
    opacity: 0.8;
  }
`;

export const EmptyMediaKitsMessage = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  text-align: center;

  & > div:first-child {
    margin-bottom: 2rem;
    color: #292929;
    font-weight: 600;
  }

  & > div:last-child {
    padding: 0 10rem;
    line-height: 2.3rem;
    color: #676767;
  }
`;

export const AddedMediaKitsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  height: calc(100% - 6rem);
  overflow-y: auto;
  margin-top: 2rem;
`;

export const AddedMediaKitsInnerContainer = styled.div<AddedMediaKitsInnerContainerProps>`
  display: flex;
  justify-content: start;
  align-items: start;
  flex-wrap: wrap;
  row-gap: 2rem;
  height: auto;
  padding-top: 1rem;

  ${props => {
    if (props.mediaKitCount >= 3) {
      return css`
        width: 80%;

        & > div {
          width: 50%;
        }

        @media (max-width: 1670px) {
          width: 90%;
        }

        @media (max-width: 1500px) {
          width: 100%;
        }

        @media (max-width: 1380px) {
          width: 80%;

          & > div {
            width: 33.3%;
          }
        }

        @media (max-width: 1255px) {
          width: 90%;
        }

        @media (max-width: 1105px) {
          width: 100%;
        }

        @media (max-width: 1015px) {
          width: 80%;

          & > div {
            width: 50%;
          }
        }

        @media (max-width: 875px) {
          width: 100%;
        }

        @media (max-width: 727px) {
          width: 28rem;

          & > div {
            width: 100%;
          }
        }
      `;
    }
    if (props.mediaKitCount === 2) {
      return css`
        width: 80%;

        & > div {
          width: 50%;
        }

        @media (max-width: 1670px) {
          width: 90%;
        }

        @media (max-width: 1500px) {
          width: 100%;
        }

        @media (max-width: 1380px) {
          width: 60%;

          & > div {
            width: 50%;
          }
        }

        @media (max-width: 1230px) {
          width: 80%;
        }

        @media (max-width: 875px) {
          width: 100%;
        }

        @media (max-width: 727px) {
          width: 28rem;

          & > div {
            width: 100%;
          }
        }
      `;
    }
    if (props.mediaKitCount === 1) {
      return css`
        width: auto;
      `;
    }
  }}
`;

export const AddedMediaKitOuterMostContainer = styled.div`
  display: flex;
  justify-content: start;
`;

export const AddedMediaKitOuterContainer = styled.div`
  cursor: pointer;
  border: 0.2rem solid #f4f4f4;
  border-radius: 0.6rem;
  padding: 0.5rem;
  position: relative;

  & * {
    cursor: pointer;
  }

  &:hover:not(:has(.added-media-kit-cross:hover))
    > div:first-child
    > div:last-child
    svg
    path {
    fill: #8492a6;
  }
`;

export const AddedMediaKitContainer = styled.div`
  height: 6rem;
  width: 26rem;
  display: flex;
`;

export const AddedMediaKitCross = styled.div`
  width: 1.8rem;
  height: 1.8rem;
  cursor: pointer;
  position: absolute;
  top: -0.8rem;
  right: -0.8rem;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.07) 0rem 0.8rem 1.2rem,
    rgba(0, 0, 0, 0.06) 0rem 0.3rem 1.5rem,
    rgba(0, 0, 0, 0.1) 0rem 0.4rem 0.5rem;
  z-index: 9999999;
  background-color: #ffffff;

  & svg path {
    fill: #ff0000;
  }
`;

export const AddedMediaKitArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  width: 3rem;
  height: 100%;

  & svg path {
    fill: #bfbfbf;
  }
`;

export const AddedMediaDropdownOptions = styled.div`
  position: absolute;
  left: -0.8rem;
  width: calc(100% + 1.6rem);
  top: 109%;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.2);
  border: 0.2rem solid #f4f4f4;
  border-radius: 0.6rem;
  z-index: 9999999999999999999999;
`;

export const AddedMediaDropdownOption = styled.div`
  display: flex;
  align-items: center;
  padding: 0.7rem 0.5rem;
  background-color: #ffffff;

  &:hover {
    background-color: #f4f4f4;
  }
`;

export const AddedMediaDropdownOptionLeft = styled.div`
  width: auto;
  padding-left: 0.1rem;
`;

export const AddedMediaDropdownOptionRight = styled.div`
  padding-left: 4rem;
  flex-grow: 1;
  text-align: start;
`;

export const CustomizeViewOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const CustomizeViewContainer = styled.div`
  height: calc(100% - 6.4rem);
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CustomizeViewInnerContainer = styled.div`
  max-height: calc(100% - 4.2rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  width: 100%;
`;

export const CustomizeViewFooter = styled.div`
  display: flex;
  width: 100%;
  justify-content: start;
  margin-top: 2rem;
  padding-left: 5rem;
`;

export const CustomizeViewTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.8rem;
  color: #292929;
  margin-bottom: 2rem;
  padding-top: 1rem;
`;

export const ConfigurationUnderCustomizationOuterContainer = styled.div`
  border: 0.2rem solid #f4f4f4;
  border-radius: 0.6rem;
  padding: 0.5rem;
  position: relative;
  margin-bottom: 2rem;
`;

export const ConfigurationUnderCustomizationInnerContainer = styled.div`
  height: 6rem;
  width: auto;
  display: flex;
`;

export const CustomizableMediaKitNameInputContainer = styled.div`
  width: 40rem;
  margin-bottom: 2rem;
`;

export const CustomImageButtonsContainer = styled.div`
  display: flex;
  margin-bottom: 2rem;
`;

export const CustomViewGeneralSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 40rem;
`;

export const CustomViewGeneralSettingsTitle = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  color: #676767;
  margin-bottom: 2rem;
`;

export const CustomViewGeneralSettingsInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CustomViewGeneralSettingsEntry = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
`;

export const RemoveUploadedImageButton = styled.div`
  display: flex;
  align-items: center;
  background-color: #5870f6;
  color: #ffffff;
  font-size: 1.44rem;
  font-weight: 500;
  border-radius: 0.6rem;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  margin-left: 1rem;
  cursor: pointer;

  & svg path {
    fill: #ffffff;
  }

  & > span {
    padding-left: 0.7rem;
  }
`;

export const CustomizePortfolioButtonOuterContainer = styled.div`
  width: 40rem;
  margin-bottom: 3rem;

  @media (max-width: 700px) {
    width: 37rem;
  }

  @media (max-width: 600px) {
    width: 40rem;
  }
`;

export const RightArrowIconContainer = styled.div`
  cursor: pointer;
  width: auto;

  & svg path {
    fill: #bfbfbf !important;
  }
`;

export const CustomizePortfolioButtonTitle = styled.div`
  display: block;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1rem;
  margin-bottom: 1.4rem;
  color: #676767;
  padding-top: 1.5rem;
`;

export const CustomizePortfolioButtonInnerContainer = styled.div`
  height: 8.3rem;
  border: 0.2rem solid rgb(244, 244, 244);
  border-radius: 0.6rem;
  display: flex;
  justify-content: space-between;
  padding: 0 0.8rem;
  align-items: center;
  cursor: pointer;

  &:hover svg path {
    fill: #8492a6 !important;
  }
`;

export const CustomizePortfolioButtonLabel = styled.div`
  display: block;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.3rem;
  color: #7d888d;
  margin-left: 0.5rem;
  width: 33rem;
`;

export const ExpirationDateArrowContainer = styled.div<ExpirationDateArrowContainerProps>`
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  margin: 0;
  margin-left: auto;
  height: 100%;
  cursor: pointer;
  color: #bfbfbf;

  &: hover {
    color: #8492a6;
  }

  ${props => {
    if (props.disabled) {
      return css`
        pointer-events: none;
      `;
    }
  }}
`;

export const ExpirationDateContainer = styled.div`
  width: 40rem;

  @media (max-width: 700px) {
    width: 37rem;
  }

  @media (max-width: 600px) {
    width: 40rem;
  }
`;

export const ExpirationDateTitle = styled.div`
  display: block;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1rem;
  margin-bottom: 1.4rem;
  color: #676767;
`;

export const ExpirationDateInnerContainer = styled.div<ExpirationDateInnerContainerProps>`
  height: 5rem;
  border: 0.2rem solid rgb(244, 244, 244);
  border-radius: 0.6rem;
  display: flex;
  align-items: center;
  padding: 0 0.8rem;
  position: relative;
  cursor: ${props => (props.active ? 'pointer' : 'default')};

  ${props => {
    if (props.active) {
      return css`
        &:hover > div > svg:first-of-type path {
          fill: #8492a6;
        }
      `;
    }
  }}
`;

export const ExpirationDateLabel = styled.div`
  display: block;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1rem;
  color: #7d888d;
  margin-left: 0.5rem;
`;

export const ExpirationDatePickerContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  z-index: 99999;

  & .rdrMonthAndYearWrapper {
    margin-top: -3rem;
    background-color: #fff;
  }
`;

export const PortfolioNameInputContainer = styled.div`
  width: 40rem;
  margin-top: 3rem;
  display: flex;
  flex-direction: column;

  & input {
    color: #7d888d;
  }

  @media (max-width: 700px) {
    width: 37rem;
  }

  @media (max-width: 600px) {
    width: 40rem;
  }
`;

export const PortfolioNameInputTitle = styled.div`
  display: block;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1rem;
  margin-bottom: 1.4rem;
  color: #676767;
`;

export const CustomizePortfolioOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`;

export const CustomizePortfolioInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 6.5rem);
  overflow-y: auto;
  width: 100%;
`;

export const CustomizePortfolioTitle = styled.div<CustomizePortfolioTitleProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.8rem;
  color: ${props =>
    !props.defaultThemeColor || props.backgroundCoverImageSelected
      ? '#ffffff'
      : '#292929'};
  padding-top: 1rem;
  z-index: 9999999999;
`;

export const CustomizePortfolioFooter = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 2rem;
  padding: 0 2rem;

  & button {
    border-radius: 0.6rem;
  }

  & > button:last-child {
    margin-right: 0.5rem;
  }
`;

export const CustomizePortfolioHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2rem;
`;

export const CustomizePortfolioHeaderColored = styled.div<CustomizePortfolioHeaderColoredProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: start;
  position: relative;

  ${props => {
    if (!props.backgroundCoverImage) {
      return css`
        height: ${props.layoutType === PortfolioLayoutTypes.HORIZONTAL_LAYOUT
          ? '8rem'
          : '12rem'};
        background-color: ${props.themeColor === '#ffffff'
          ? '#f7f8fa'
          : props.themeColor};
      `;
    }
    return css`
      height: ${props.layoutType === PortfolioLayoutTypes.HORIZONTAL_LAYOUT
        ? '12rem'
        : '16rem'};
    `;
  }}
`;

export const PortfolioHeaderBottomContainer = styled.div`
  background-color: #ffffff;
  display: flex;
  justify-content: end;
  padding-top: 2.5rem;
`;

export const PortfolioHeaderUploadImagesButton = styled.div`
  display: flex;
  margin-right: 7rem;
  color: #292929;
  cursor: pointer;

  & svg path {
    fill: #292929;
  }
`;

export const PortfolioHeaderProfilePicture = styled.img`
  border-radius: 50%;
  border: 0.4rem solid #ffffff;
  position: absolute;
  height: 11rem;
  width: 11rem;
  bottom: -6rem;
  left: 11rem;
  object-fit: cover;

  @media (max-width: 730px) {
    bottom: -6.5rem;
  }
`;

export const PortfolioSettingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 2rem !important;
`;

export const PortfolioSettingsLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 3rem;
`;

export const PortfolioLayoutTypeSelectorContainer = styled.div`
  width: 20rem;
  position: relative;
`;

export const PortfolioLayoutTypeSelectorInnerContainer = styled.div`
  height: 5rem;
  border-width: 0.2rem;
  border-style: solid;
  border-color: rgb(244, 244, 244);
  border-radius: 0.6rem;
  display: flex;
  justify-content: space-between;
  padding: 0 0.8rem;
  align-items: center;
  cursor: pointer;

  &:hover svg path {
    fill: #8492a6 !important;
  }
`;

export const PortfolioLayoutTypeSelectorTitle = styled.div`
  display: block;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1rem;
  margin-bottom: 1.4rem;
  color: #676767;
`;

export const LayoutTypeSelectorArrowContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  width: 3rem;
  height: 100%;

  & svg path {
    fill: #bfbfbf;
  }
`;

export const PortfolioLayoutTypeSelectedLabel = styled.div`
  display: block;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 2.3rem;
  color: #7d888d;
  margin-left: 0.5rem;
  width: 33rem;
`;

export const SelectPortfolioLayoutTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.8rem;
  color: #292929;
  padding-top: 1rem;
`;

export const SelectPortfolioLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 3rem;
  padding: 0 2rem;

  & > div:first-child {
    margin-bottom: 1rem;
  }
`;

export const PortfolioLayoutTypeOption = styled.div<PortfolioLayoutTypeOptionProps>`
  width: 40rem;
  height: 19rem;
  display: flex;
  border: ${props =>
    props.active ? '0.2rem solid #5870f6' : '0.2rem solid #f4f4f4'};
  border-radius: 1rem;
  cursor: pointer;
  padding: 0.6rem 0;
  position: relative;

  &:hover {
    border: 0.2rem solid #5870f6;
  }
`;

export const PortfolioLayoutTypeOptionLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding-left: 2rem;
  flex-grow: 1;

  & > div:first-child {
    color: #292929;
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  & > div:nth-child(2) {
    color: #676767;
    font-size: 1.4rem;
    font-weight: 400;
  }
`;

export const PortfolioLayoutTypeOptionRight = styled.div`
  width: 16rem;
`;

export const PortfolioLayoutTypeActiveCheckmark = styled.div`
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  border: 0.4rem solid #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 1.9rem;
  right: 0.6rem;
  background-color: #5870f6;

  & svg path {
    fill: #ffffff;
  }
`;

export const CustomizePortfolioColorsOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2rem;
  row-gap: 3rem;
`;

export const CustomizePortfolioColorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CustomizePortfolioColorsTitle = styled.div`
  display: block;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1rem;
  margin-bottom: 1.4rem;
  color: #676767;
`;

export const CustomizePortfolioColorsInner = styled.div<CustomizePortfolioColorsInnerProps>`
  height: 7rem;
  display: flex;
  align-items: start;
  column-gap: 0.8rem;
  width: 100%;
  overflow-x: auto;
  position: relative;
  scroll-behavior: smooth;
`;

export const CustomizePortfolioColor = styled.div<CustomizePortfolioColorProps>`
  width: 5rem;
  height: 5rem;
  border-radius: 0.6rem;
  cursor: pointer;
  flex-shrink: 0;
  background-color: ${props => props.color};
  position: relative;

  ${props => {
    if (props.white) {
      return css`
        border: 0.1rem solid #bfbfbf;
      `;
    }
  }}
`;

export const PortfolioColorActiveCheckMark = styled.div<PortfolioColorActiveCheckMarkProps>`
  position: absolute;
  bottom: 0.3rem;
  right: 0.3rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: ${props =>
    props.activeColor === '#ffffff' ? '#8492a6' : '#ffffff'};

  & svg path {
    fill: ${props => props.activeColor};
  }
`;

export const UploadPortfolioImagesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const UploadPortfolioImagesOptionOuter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: auto;

  & > div:first-child {
    margin-bottom: 2rem;
  }
`;

export const UploadPortfolioImagesOption = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  width: auto;
  color: #292929;
  font-weight: 400;
  cursor: pointer;
  background-color: #ffffff;

  & > *:last-child {
    padding-left: 1rem;
  }

  & svg path {
    fill: #292929;
  }
`;

export const PortfolioHeaderBackgroundPicture = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const PortfolioSettingsNameContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  margin-bottom: 3rem;
  border-top: 0.1rem solid rgba(219, 219, 219, 0.5);
`;

export const PortfolioSettingsNameInnerContainer = styled.div`
  width: 45rem;

  & input {
    color: #7d888d;
  }

  & input::placeholder {
    color: #8492a6;
  }
`;

export const PortfolioSettingsNameTitle = styled.div`
  display: block;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1rem;
  margin-bottom: 1.4rem;
  color: #676767;
`;

export const PortfolioSettingsTagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  width: 100%;
`;

export const PortfolioSettingsTagsInnerContainer = styled.div`
  width: 100%;
`;

export const PortfolioSettingsTagsTitle = styled.div`
  display: block;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1rem;
  margin-bottom: 1.4rem;
  color: #676767;
`;

export const PortfolioSettingsCountryContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
  width: 30rem;
`;

export const PortfolioSettingsCountryInnerContainer = styled.div`
  width: 100%;
`;

export const PortfolioSettingsCountryTitle = styled.div`
  display: block;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1rem;
  margin-bottom: 1.4rem;
  color: #676767;
`;

export const PortfolioSettingsSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 3rem;
`;

export const PortfolioSettingsSectionInnerContainer = styled.div`
  width: 100%;

  & input,
  & textarea {
    color: #7d888d;
  }

  & input::placeholder,
  & textarea::placeholder {
    color: #8492a6;
  }
`;

export const PortfolioSettingsSectionTitle = styled.div`
  display: block;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1rem;
  margin-bottom: 1.4rem;
  color: #676767;
`;

export const PortfolioSettingsSectionTitleInput = styled.div`
  width: 45rem;
`;

export const PortfolioSettingsSectionDescriptionInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;
`;

export const PortfolioSettingsSectionDescriptionCopyContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  margin-top: 0.7rem;
`;

export const PortfolioSettingsSectionDescriptionCopy = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  position: relative;
`;

export const PortfolioSettingsSectionDescriptionCopyInner = styled.div`
  display: flex;
  align-items: center;
  width: auto;
  cursor: pointer;

  & svg path {
    fill: #292929;
  }
`;

export const CopiedToClipboard = styled.span`
  position: absolute;
  opacity: 0;
  top: -5rem;
  left: 50%;
  transform: translateX(-50%);
  padding: 1rem 0.5rem;
  background-color: #14b56f;
  color: #fff;
  font-size: 600;
  width: 8rem;
  text-align: center;
  border-radius: 0.5rem;
  filter: drop-shadow(rgba(0, 0, 0, 0.6) 0 0.2rem 0.3rem);
  pointer-events: none;

  transition: all 0.6s ease-out;
`;

export const PortfolioSettingsPartnersSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PortfolioSettingsPartnersSectionInnerContainer = styled.div`
  width: 100%;

  & input {
    color: #7d888d;
  }

  & input::placeholder {
    color: #8492a6;
  }
`;

export const PortfolioSettingsPartnersSectionTitle = styled.div`
  display: block;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1rem;
  margin-bottom: 1.4rem;
  color: #676767;
`;

export const PortfolioSettingsPartnersSectionTitleInputContainer = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 1480px) and (min-width: 1381px) {
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }
`;

export const PortfolioSettingsPartnersContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
  column-gap: 2rem;
  row-gap: 1.7rem;
`;

export const PortfolioSettingsPartnersSectionTitleInput = styled.div`
  flex-basis: 45rem;

  @media (max-width: 1505px) {
    flex-basis: unset;
    width: 45rem;
  }
`;

export const AddPartnersButton = styled.div`
  flex-basis: 12rem;

  & button {
    border-radius: 0.6rem;
  }

  @media (max-width: 1505px) and (min-width: 1381px) {
    flex-basis: unset;
    width: 12rem;
    margin-top: 1rem;
  }

  @media (max-width: 800px) {
    flex-basis: unset;
    width: 12rem;
    margin-top: 1rem;
  }
`;

export const SelectPortfolioPartnersTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.8rem;
  color: #292929;
  padding-top: 1rem;
`;

export const SelectPortfolioPartnersSelectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 3rem;
  padding: 0 2rem;
  max-height: calc(100% - 6.2rem);
`;

export const SelectPortfolioPartnersSelectionBrandsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  column-gap: 2rem;
  row-gap: 1.5rem;
  max-height: calc(100% - 7.3rem);
  overflow-y: auto;
`;

export const PortfolioPartner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 9rem;
  height: 11rem;
  position: relative;
`;

export const PortfolioPartnerImage = styled.img<PortfolioPartnerImageProps>`
  width: 7rem;
  height: 7rem;
  object-fit: cover;
  border-radius: 50%;
  border: 0.1rem solid #eeeeee;
  cursor: ${props => (props.clickable ? 'pointer' : 'not-allowed')};
`;

export const PortfolioAddCustomPartnerIcon = styled.div`
  width: 7rem;
  height: 7rem;
  object-fit: cover;
  border-radius: 50%;
  border: 0.1rem solid #eeeeee;
  cursor: pointer;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PortfolioPartnerName = styled.div`
  color: #292929;
  margin-top: 0.8rem;
  padding-bottom: 0.5rem;
  text-align: center;
  max-width: 100%;
  white-space: nowrap;
  overflow-x: auto;
`;

export const PartnerAddedCheckMark = styled.div`
  width: 2.25rem;
  height: 2.25rem;
  background-color: #5870f6;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  top: 38%;
  left: 70%;
  z-index: 99999999;

  & svg path {
    fill: #ffffff;
  }
`;

export const CustomPartnerRemoveContainer = styled.div`
  width: 2.25rem;
  height: 2.25rem;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  top: 38%;
  left: 70%;
  cursor: pointer;
  box-shadow: 0px 1px 3px 0px #00000040;

  & svg path {
    fill: #676767;
  }
`;

export const SelectPortfolioPartnersFirstRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3rem;
  width: 100%;

  @media (max-width: 1440px) and (min-width: 1381px) {
    flex-direction: column;
  }

  @media (max-width: 760px) {
    flex-direction: column;
  }
`;

export const SelectPortfolioPartnersSearchInput = styled.div`
  width: 35rem;
  position: relative;

  &:hover input {
    border-color: rgb(41, 41, 41);
  }
`;

export const MagnifyingGlassContainer = styled.div`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
`;

export const SelectPortfolioPartnersTotalSelectedCount = styled.div`
  display: flex;
  color: #292929;
  font-size: 1.8rem;
  font-weight: 500;

  & > span {
    text-align: center;
    width: 3rem;
  }

  @media (max-width: 1440px) and (min-width: 1381px) {
    margin-top: 1rem;
  }

  @media (max-width: 760px) {
    margin-top: 1rem;
  }
`;

export const CreatePartnerManuallyOuter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const CreatePartnerManuallyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: auto;
`;

export const CreatePartnerManuallyImageOuterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  position: relative;
`;

export const CreatePartnerManuallyImageContainer = styled.div`
  width: 10rem;
  height: 10rem;
  object-fit: cover;
  border-radius: 50%;
  border: 0.1rem solid #eeeeee;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
`;

export const CreatePartnerManuallyImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

export const CreatePartnerManuallyImageRequiredMessage = styled.div`
  position: absolute;
  color: #b00020;
  font-size: 1.2rem;
  font-weight: 400;
  bottom: -1.5rem;
  left: 50%;
  transform: translateX(-50%);
`;

export const CreatePartnerManuallyNameContainer = styled.div`
  width: 45rem;
  margin-top: 3.5rem;

  & input {
    color: #7d888d;
  }

  & input::placeholder {
    color: #8492a6;
  }
`;

export const CreatePartnerManuallyNameTitle = styled.div`
  display: block;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1rem;
  margin-bottom: 1.4rem;
  color: #676767;
`;

export const CreatePartnerManuallySaveButtonContainer = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  width: 100%;
  & button {
    border-radius: 0.6rem;
  }
`;

export const NotifyInnerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

export const PartnersCreatedManuallyAlreadyExistingDivider = styled.div`
  height: 0;
  width: 100%;
  border-top: 0.1rem solid #eeeeee;
  margin-bottom: 1rem;
`;

export const PortfolioPartnerSelected = styled.div<PortfolioPartnerSelectedProps>`
  display: flex;
  position: relative;
  z-index: 99;
  cursor: ${props => props.cursor};
`;

export const PortfolioPartnerSelectedImage = styled.img`
  width: 9rem;
  height: 9rem;
  object-fit: cover;
  border-radius: 50%;
  border: 0.1rem solid #eeeeee;
  z-index: 999;
`;

export const AddedPartnerCross = styled.div`
  width: 1.8rem;
  height: 1.8rem;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0.5rem;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.07) 0rem 0.8rem 1.2rem,
    rgba(0, 0, 0, 0.06) 0rem 0.3rem 1.5rem,
    rgba(0, 0, 0, 0.1) 0rem 0.4rem 0.5rem;
  z-index: 9999999;
  background-color: #ffffff;

  & svg path {
    fill: #ff0000;
  }
`;

export const PartnerDeletionModalOuterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const PartnerDeletionModalLoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30rem;
`;

export const PartnerDeletionModalLoadingIconContainer = styled.div`
  width: 4rem;
  height: 4rem;
  margin-bottom: 2rem;
`;

export const PartnerDeletionModalLoadingMessage = styled.div`
  color: #292929;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 300;
  width: 100%;
  text-align: center;
`;

export const PartnerDeletionModalConfirm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30rem;
`;

export const PartnerDeletionModalConfirmMessage = styled.div`
  color: #292929;
  display: flex;
  flex-direction: column;

  & > span:last-child {
    margin-top: 1rem;
  }
`;

export const PartnerDeletionModalConfirmButtons = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
  padding: 0 3rem;
  width: 30rem;

  & button {
    border-radius: 0.6rem;
  }
`;

export const PartnerDeletionModalDeniedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30rem;
`;

export const PartnerDeletionPortfoliosContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 26.7rem;
  overflow-y: auto;
  padding: 0 2rem;
`;

export const PartnerDeletionPortfolioOuterContainer = styled.div`
  border: 0.2rem solid #f4f4f4;
  border-radius: 0.6rem;
  padding: 0.5rem;
  position: relative;
  margin-bottom: 1.5rem;
`;

export const PartnerDeletionPortfolioInnerContainer = styled.div`
  height: 6rem;
  width: auto;
  display: flex;
`;

export const PartnerDeletionPortfolioLeftContainer = styled.div`
  height: 6rem;
  width: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const PartnerDeletionPortfolioSocialAccountImage = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  user-select: none;
`;

export const PartnerDeletionPortfolioSocialAccountRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding-left: 0.8rem;
`;

export const PartnerDeletionPortfolioSocialAccountUsernameContainer = styled.div`
  display: flex;
  user-select: none;
`;

export const PartnerDeletionPortfolioSocialAccountUsername = styled.div`
  margin-bottom: 0.9rem;
  color: ${props => props.theme.text['color-26']};
  font-size: 1.5rem;
  font-weight: 600;
  max-width: 23rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const PartnerDeletionPortfolioSocialAccountSubTextContainer = styled.div`
  display: flex;
  align-items: end;
  user-select: none;
  margin-top: 0.5rem;
  height: 1.9rem;

  & svg {
    margin-top: 0 !important;
  }

  & > div {
    margin-top: 0 !important;
    padding: 0 !important;
  }

  & > div:nth-child(2) {
    display: inline-flex;
    align-items: end;
  }
`;

export const PartnerDeletionPortfolioSocialAccountNetworkName = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.2rem;
  color: ${props => props.theme.text['color-27']};
  position: relative;
  display: inline-block;
  height: 1.4rem;
  margin-top: 0.9rem;
  user-select: none;
  max-width: 14rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const PartnerDeletionDeniedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const PartnerDeletionDeniedMessage = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
`;

export const PartnerDeletionDeniedMessageIcon = styled.div`
  width: 5rem;
  height: 5rem;

  & svg path {
    fill: #bfbfbf;
  }
`;

export const PartnerDeletionDeniedMessageText = styled.div`
  padding-left: 1.5rem;
  font-size: 1.6rem;
  font-weight: 400;
  color: #676767;
`;

export const PartnerDeletionDeniedExtraPortfoliosCountContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1rem;
`;

export const PartnerDeletionDeniedExtraPortfoliosIcon = styled.div`
  width: 2.5rem;
  height: 2.5rem;

  & svg path {
    fill: #bfbfbf;
  }
`;

export const PartnerDeletionDeniedExtraPortfoliosCount = styled.div`
  padding-left: 0.8rem;
  color: #676767;
`;

export const ResponsiveActiveSectionIcon = styled.div`
  position: absolute;
  top: 2rem;
  left: 1rem;
  transform: translateY(-50%);
  width: 3.5rem;
  height: 3.5rem;
  cursor: pointer;
  z-index: 99999999999999999;
  display: none;
  border-radius: 50%;
  padding: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.07) 0rem 0.8rem 1.2rem,
    rgba(0, 0, 0, 0.06) 0rem 0.3rem 1.5rem,
    rgba(0, 0, 0, 0.1) 0rem 0.4rem 0.5rem;

  & svg path {
    fill: #bfbfbf;
  }

  @media (max-width: 1380px) {
    display: block;
  }

  &:hover {
    svg path {
      fill: #8492a6 !important;
    }

    box-shadow: rgba(0, 0, 0, 0.2) 0rem 0.8rem 1.2rem,
      rgba(0, 0, 0, 0.15) 0rem 0.3rem 1.5rem,
      rgba(0, 0, 0, 0.25) 0rem 0.4rem 0.5rem;
  }
`;

export const ResponsiveSectionTransitionIconTooltip = styled(
  UncontrolledTooltip,
)`
  & > *:first-child {
    z-index: 1000;
  }
  opacity: 1 !important;
  pointer-events: none;

  .tooltip-inner {
    display: inline-block;
    text-align: center;
    width: 11rem !important;
    position: relative;
    color: #333;
    background-color: #fff;
    border: 0.1rem solid #eeeeee;
    border-radius: 0.5rem;
    padding: 1rem 1.5rem;
    margin: 0 0 1rem 0;
    filter: drop-shadow(rgba(0, 0, 0, 0.6) 0 0.2rem 0.3rem);
    top: 0.5rem;
  }

  .arrow {
    display: none !important;
  }
`;
