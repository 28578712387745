/* eslint-disable import/extensions */
/* eslint-disable eqeqeq */
import styled, { useTheme } from 'styled-components';
import { useState, useEffect } from 'react';

import SVG from './Styles';

interface IInputCheckMarkIconProps {
  containerWidth?: string;
  containerHeight?: string;
  containerMarginRight?: string;
  usage?: 'input' | 'notifyCustom';
  svgWidth?: string;
  svgHeight?: string;
}

interface IContainerProps {
  width?: string;
  height?: string;
  marginRight?: string;
}

const Container = styled.div<IContainerProps>`
  border-width: 0;
  outline: 0;
  width: ${props => (props.width ? props.width : '100%')};
  height: ${props => (props.height ? props.height : '100%')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
`;

const InputCheckMarkIcon = (props: IInputCheckMarkIconProps) => {
  const themeGlobal = useTheme();
  const [svgColor, setSvgColor] = useState<string>();

  useEffect(() => {
    if (props.usage && props.usage == 'notifyCustom') {
      setSvgColor(
        (themeGlobal as any).signin['success-notify']['check-mark-icon-color'],
      );
    } else {
      setSvgColor((themeGlobal as any).inputFieldV2['check-mark-icon-color']);
    }
  }, []);

  if (props.usage == 'notifyCustom') {
    return (
      <Container
        width={props.containerWidth}
        height={props.containerHeight}
        marginRight={props.containerMarginRight}
      >
        <SVG
          viewBox="0 0 32 32"
          width={props.svgWidth ? props.svgWidth : '100%'}
          height={props.svgHeight ? props.svgHeight : '100%'}
        >
          <path
            fill={svgColor}
            d="M11.905 26.668l-10.238-10.238 2.887-2.887 7.351 7.33 15.541-15.541 2.887 2.908-18.428 18.429z"
          />
        </SVG>
      </Container>
    );
  }

  return (
    <SVG
      viewBox="0 0 32 32"
      width={props.svgWidth ? props.svgWidth : '100%'}
      height={props.svgHeight ? props.svgHeight : '100%'}
    >
      <path
        fill={svgColor}
        d="M11.905 26.668l-10.238-10.238 2.887-2.887 7.351 7.33 15.541-15.541 2.887 2.908-18.428 18.429z"
      />
    </SVG>
  );
};

export default InputCheckMarkIcon;
