/* eslint-disable import/extensions */
import SVG from './Styles';

interface IPostTypeGalleryIconProps {
  width?: string;
  height?: string;
  onClick?: any;
  fill?: string;
}

const PostTypeGalleryIcon = (props: IPostTypeGalleryIconProps) => {
  return (
    <SVG
      viewBox="0 0 32 32"
      width={props.width ? props.width : '100%'}
      height={props.height ? props.height : '100%'}
      onClick={props.onClick}
    >
      <path
        fill={props.fill || 'none'}
        d="M24 5.333h-16c-1.473 0-2.667 1.193-2.667 2.667v16c0 1.473 1.193 2.667 2.667 2.667h16c1.473 0 2.667-1.193 2.667-2.667v-16c0-1.473-1.193-2.667-2.667-2.667zM18.997 9.872c1.277 0.007 2.317 1.056 2.312 2.333-0.004 1.279-1.049 2.316-2.331 2.312-1.277-0.004-2.321-1.055-2.315-2.329s1.061-2.321 2.333-2.316zM23.961 23.715c-0.145 0.287-0.437 0.283-0.708 0.284-2.408 0.003-4.815 0.001-7.223 0.001-2.373 0-4.745 0.001-7.119-0.003-0.171 0-0.348-0.011-0.511-0.057-0.319-0.091-0.473-0.371-0.399-0.695 0.029-0.127 0.080-0.251 0.139-0.367 1.296-2.573 2.595-5.144 3.893-7.716 0.035-0.068 0.071-0.135 0.112-0.199 0.281-0.435 0.736-0.491 1.072-0.096 0.389 0.457 0.745 0.944 1.111 1.421 0.964 1.257 1.924 2.516 2.887 3.773 0.084 0.109 0.172 0.22 0.277 0.307 0.416 0.347 0.941 0.308 1.363-0.101 0.352-0.341 0.685-0.705 1.047-1.036 0.368-0.336 0.888-0.317 1.24 0.017 0.080 0.076 0.148 0.164 0.212 0.255 0.811 1.155 1.621 2.309 2.428 3.465 0.155 0.224 0.323 0.461 0.179 0.745z"
      ></path>
      <path
        fill={props.fill || 'none'}
        d="M24.639 2.661h-14c-0.987 0-1.839 0.543-2.299 1.339h15.66c2.205 0 4 1.795 4 4v15.611c0.779-0.465 1.305-1.308 1.305-2.281v-14c0-2.579-2.089-4.668-4.667-4.668z"
      ></path>
    </SVG>
  );
};

export default PostTypeGalleryIcon;
