/* eslint-disable no-empty */
/* eslint-disable consistent-return */
/* eslint-disable import/extensions */
import styled, { useTheme } from 'styled-components';
import { useState, useEffect } from 'react';

import SVG from './Styles';

interface IFacebookPageIconProps {
  containerWidth?: string;
  containerHeight?: string;
  containerPaddingTop?: string;
  containerPaddingBottom?: string;
  containerPaddingLeft?: string;
  containerPaddingRight?: string;
  containerMarginTop?: string;
  containerMarginBottom?: string;
  containerMarginLeft?: string;
  containerMarginRight?: string;
  useCase?: string;
  svgWidth?: string;
  svgHeight?: string;
  rotate?: string;
  cursor?: string;
}

interface IContainerProps {
  width?: string;
  height?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
}

const Container = styled.div<IContainerProps>`
  border-width: 0;
  outline: 0;
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '0')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '0')};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '0')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '0')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
`;

const FacebookPageIcon = (props: IFacebookPageIconProps) => {
  const themeGlobal = useTheme();
  const [svgColor, setSvgColor] = useState<string>(() => {
    if (props.useCase) {
    } else {
      return (themeGlobal as any)['social-networks']['facebook-page'].color;
    }
  });

  useEffect(() => {
    if (props.useCase) {
    } else {
      setSvgColor(
        (themeGlobal as any)['social-networks']['facebook-page'].color,
      );
    }
  }, [(themeGlobal as any).darkMode]);

  return (
    <Container
      width={props.containerWidth}
      height={props.containerHeight}
      paddingTop={props.containerPaddingTop}
      paddingBottom={props.containerPaddingBottom}
      paddingLeft={props.containerPaddingLeft}
      paddingRight={props.containerPaddingRight}
      marginTop={props.containerMarginTop}
      marginBottom={props.containerMarginBottom}
      marginLeft={props.containerMarginLeft}
      marginRight={props.containerMarginRight}
    >
      <SVG
        viewBox="0 0 32 32"
        width={props.svgWidth ? props.svgWidth : '100%'}
        height={props.svgHeight ? props.svgHeight : '100%'}
        rotateNotNative={props.rotate}
        cursor={props.cursor}
      >
        <path
          fill={svgColor}
          d="M27.778 18.956h-19.979v7.554c0 0.988-0.801 1.788-1.788 1.788s-1.788-0.8-1.788-1.788v-22.809h23.556l-4.361 7.628 4.361 7.627z"
        />
      </SVG>
    </Container>
  );
};

export default FacebookPageIcon;
