/* eslint-disable no-use-before-define */
import {
  GENDERS,
  ISocialNetworkAccountSignUp,
  PublicProfile,
  SocialNetworks,
} from '../../../Utils/types';

export interface ICreateBusinessProfileParams {
  name: string;
  description: string;
  displayPicture?: string;
  countryCode?: string;
  countryId?: string;
  topicsInterest?: string;
  socialTokens: any[];
}

export interface IUpdateBusinessProfileParams {
  name: string;
  description: string;
  displayPicture: string;
  socialTokens: [];
  businessProfileId: string;
  userId: string;
}

export interface ICompleteAdditionalDataStepParams {
  companyName?: string;
  companyWebsite?: string;
  businessProfileName?: string;
  businessProfileDescription?: string;
  businessProfilePictureUrl?: string;
  socialTokens?: ISocialNetworkAccountSignUp[];
  influencerName?: string;
  topicsOfInterest?: String[];
  countryCode?: string;
  countryId?: string;
  country?: string;
}

export interface IUpdateAccountParams {
  name: string | null;
  countryId: string | null;
  countryCode: string | null;
  dateOfBirth: Date | null;
  gender: GENDERS | null;
  displayPicture: string;
}

export type GetPublicUserImageByIdResponse = {
  id: string;
  image?: string;
}[];

export type GetPublicUserBasicInformationWithExternalProviderParams = {
  username: string;
  socialNetwork: SocialNetworks;
};

export type GetPublicUserBasicInformationWithExternalProviderResponse = {
  username: string;
  socialNetwork: SocialNetworks;
  publicUserId: string | null;
  displayPicture?: string;
};

export type UpdateUserPublicProfilesParams = {
  publicProfiles: PublicProfile[];
};

export type GetKpisOverviewInput = {
  publicUserIds: string[];
  startDate?: number;
  endDate?: number;
  types?: PostTypes[];
  keywords?: string[];
  includePostIds?: string[];
  excludePostIds?: string[];
};

export type GetKpisOverviewOutput = {
  kpis: IKpisOverview;
}[];

export interface IKpisOverview {
  total: {
    followers: number;
    likes: number;
    reactions: number;
    engRate: number;
    posts: number;
    comments: number;
    impressions: number;
    reach: number;
  };
  avg: {
    followers: number;
    likes: number;
    reactions: number;
    engRate: number;
    posts: number;
    comments: number;
    impressions: number;
    reach: number;
  };
}

export enum PostTypes {
  IMAGE = 'IMAGE',
  CAROUSEL = 'CAROUSEL',
  VIDEO = 'VIDEO',
  TEXT = 'TEXT',
  STORY = 'STORY',
}

export enum AllKpisOverviewDaily {
  FOLLOWERS = 'followers',
  UNFOLLOWERS = 'unfollowers',
  LIKES = 'likes',
  COMMENTS = 'comments',
  SHARES = 'shares',
  REACTIONS = 'reactions',
  IMPRESSIONS = 'impressions',
  REACH = 'reach',
  POSTS = 'posts',
  ENG_RATE = 'engRate',
}

export type GetKpisOverviewDailyInput = {
  publicUserIds: string[];
  startDate?: number;
  endDate?: number;
  types?: PostTypes[];
  growthDays?: number;
  kpis?: AllKpisOverviewDaily[];
  socialNetwork: SocialNetworks;
  keywords?: string[];
  includePostIds?: string[];
  excludePostIds?: string[];
};

export enum KpisOverviewDailyAggregators {
  TOTAL = 'total',
  AVG = 'avg',
}

export type GetKpisOverviewDailyOutput = {
  data: KpiOverview;
  publicUserId: string;
}[];

export type KpiOverview = {
  [key in Partial<AllKpisOverviewDaily>]: {
    v: number;
    date: string;
  }[];
};

export type AudienceReachabilityDistribution = {
  [key: string]: number;
};

export type GetPublicUsersInfoInput = {
  publicUserIds: string[];
  startDate?: number;
  endDate?: number;
};

export type AudienceAgeDistribution = {
  '13-17'?: number;
  '18-24'?: number;
  '25-34'?: number;
  '35-44'?: number;
  '45-54'?: number;
  '55-64'?: number;
  '65+'?: number;
};

export type GetAudienceGendersPerAgeOutput = {
  publicUserId: string;
  data: {
    code: string;
    male: number;
    female: number;
  }[];
  audience: number;
}[];

export type AudienceGenderDistribution = {
  male: number;
  female: number;
};

export type GetAudienceGendersOutput = {
  publicUserId: string;
  data: {
    code: string;
    weight: number;
  }[];
}[];

export type AudienceGeo = {
  countries: {
    [key: string]: {
      weight: number;
      code: string;
    };
  };
  cities: {
    [key: string]: number;
  };
};

export type GetPublicUserAudienceGeoOutput = {
  publicUserId: string;
  data: {
    countries: Country[];
    cities: City[];
  };
  audience: number;
}[];

export type Country = {
  id: string;
  name: string;
  code: string;
  weight: number;
  capital_location?: {
    lat?: number;
    lon?: number;
  };
  mergeWeight?: number;
};

export type City = {
  id: string;
  name: string;
  coords: {
    lat: number;
    lon: number;
  };
  weight: number;
  country: Omit<Country, 'weight'>;
};

export type AudienceTypesDistribution = {
  massFollowers: number;
  suspicious: number;
  influencers: number;
  real: number;
};

export type GetPublicUserAudienceTypesOutput = {
  data: {
    code: 'mass_followers' | 'suspicious' | 'influencers' | 'real';
    weight: number;
  }[];
}[];

export type GetPublicUserAudienceReachabilityOutput = {
  data: {
    code: string;
    weight: number;
  }[];
}[];

export type BusinessProfile = {
  _id: string;
  name: string;
  description: string;
  partnerId: string;
  userId?: string;
  displayPicture: string;
  deleted?: boolean | null;
  deletedAt?: Date | null;
  default?: boolean;
  topicsInterest?: string[];
  coutryCode?: string | null;
  createdAt?: Date;
  updatedAt?: Date;
};
