export const isLeapYear = (date: Date) => {
  const year = date.getFullYear();
  // Leap years are divisible by 4, but not by 100 unless also divisible by 400
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

export const sameYear = (startDate: Date, endDate: Date) => {
  return startDate.getFullYear() === endDate.getFullYear();
};

export const timeIntervalSpansWholeMonth = (startDate: Date, endDate: Date) => {
  if (Number(startDate.getDate()) !== 1) {
    return false;
  }

  if (
    startDate.getMonth() === endDate.getMonth() &&
    startDate.getFullYear() === endDate.getFullYear()
  ) {
    switch (startDate.getMonth()) {
      case 0:
      case 2:
      case 4:
      case 6:
      case 7:
      case 9:
      case 11: {
        return endDate.getDate() === 31;
      }
      case 1: {
        if (isLeapYear(endDate)) {
          return endDate.getDate() === 29;
        }
        return endDate.getDate() === 28;
      }
      default: {
        return endDate.getDate() === 30;
      }
    }
  }

  return false;
};

export const getTimeIntervalInDays = (startDate: Date, endDate: Date) => {
  const startDateTimestamp = startDate.getTime();
  const endDateTimestamp = endDate.getTime();

  const timeIntervalInMiliseconds = endDateTimestamp - startDateTimestamp;

  return Number((timeIntervalInMiliseconds / 1000 / 60 / 60 / 24).toFixed(0));
};

export const timeIntervalSpansWholeYear = (startDate: Date, endDate: Date) => {
  return (
    startDate.getFullYear() === endDate.getFullYear() &&
    startDate.getMonth() === 1 &&
    endDate.getMonth() === 11 &&
    startDate.getDate() === 1 &&
    endDate.getDate() === 31
  );
};

export const getFullMonthResourceKey = (date: Date) => {
  switch (date.getMonth()) {
    case 0:
      return 'months.january';
    case 1:
      return 'months.february';
    case 2:
      return 'months.march';
    case 3:
      return 'months.april';
    case 4:
      return 'months.may';
    case 5:
      return 'months.june';
    case 6:
      return 'months.july';
    case 7:
      return 'months.august';
    case 8:
      return 'months.september';
    case 9:
      return 'months.october';
    case 10:
      return 'months.november';
    default:
      return 'months.december';
  }
};

export const getShortenedMonthResourceKey = (date: Date) => {
  switch (date.getMonth()) {
    case 0:
      return 'months.shortened.january';
    case 1:
      return 'months.shortened.february';
    case 2:
      return 'months.shortened.march';
    case 3:
      return 'months.shortened.april';
    case 4:
      return 'months.shortened.may';
    case 5:
      return 'months.shortened.june';
    case 6:
      return 'months.shortened.july';
    case 7:
      return 'months.shortened.august';
    case 8:
      return 'months.shortened.september';
    case 9:
      return 'months.shortened.october';
    case 10:
      return 'months.shortened.november';
    default:
      return 'months.shortened.december';
  }
};

export const getShortenedLowerCasedMonthResourceKey = (date: Date) => {
  switch (date.getMonth()) {
    case 0:
      return 'months.shortenedLowerCased.january';
    case 1:
      return 'months.shortenedLowerCased.february';
    case 2:
      return 'months.shortenedLowerCased.march';
    case 3:
      return 'months.shortenedLowerCased.april';
    case 4:
      return 'months.shortenedLowerCased.may';
    case 5:
      return 'months.shortenedLowerCased.june';
    case 6:
      return 'months.shortenedLowerCased.july';
    case 7:
      return 'months.shortenedLowerCased.august';
    case 8:
      return 'months.shortenedLowerCased.september';
    case 9:
      return 'months.shortenedLowerCased.october';
    case 10:
      return 'months.shortenedLowerCased.november';
    default:
      return 'months.shortenedLowerCased.december';
  }
};

export const getTimePeriodAgoLabel = (
  timestamp: number,
  translator: (path: string) => string,
): string => {
  const diffInMilliseconds = Date.now() - timestamp;

  const seconds = Math.trunc(diffInMilliseconds / 1000);

  if (seconds < 60) {
    return `${seconds} ${
      seconds === 1
        ? translator('generic.second')
        : translator('generic.seconds')
    } ${translator('generic.ago')}`;
  }

  const minutes = Math.trunc(seconds / 60);

  if (minutes < 60) {
    return `${minutes} ${
      minutes === 1
        ? translator('generic.minute')
        : translator('generic.minutes')
    } ${translator('generic.ago')}`;
  }

  const hours = Math.trunc(minutes / 60);

  if (hours < 24) {
    return `${hours} ${
      hours === 1 ? translator('generic.hour') : translator('generic.hours')
    } ${translator('generic.ago')}`;
  }

  const days = Math.trunc(hours / 24);

  if (days < 7) {
    return `${days} ${
      days === 1 ? translator('generic.day') : translator('generic.days')
    } ${translator('generic.ago')}`;
  }

  if (days >= 7 && days < 30) {
    const weeks = Math.round(days / 7);

    return `${weeks} ${
      weeks === 1 ? translator('generic.week') : translator('generic.weeks')
    } ${translator('generic.ago')}`;
  }

  if (days >= 30 && days < 365) {
    const months = Math.round(days / 30);

    return `${months} ${
      months === 1 ? translator('generic.month') : translator('generic.months')
    } ${translator('generic.ago')}`;
  }

  const years = Math.round(days / 365);

  return `${years} ${
    years === 1 ? translator('generic.year') : translator('generic.years')
  } ${translator('generic.ago')}`;
};

export const getFormattedTimeFromDate = (
  date: Date,
  config?: { hours?: boolean; minutes?: boolean; seconds?: boolean },
): string => {
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  const segments: string[] = [];

  if (config?.hours !== false) {
    segments.push(`${hours}h`);
  }
  if (config?.minutes !== false) {
    segments.push(`${minutes}m`);
  }
  if (config?.seconds !== false) {
    segments.push(`${seconds}s`);
  }

  return segments.join(':');
};
