/* eslint-disable no-empty */
/* eslint-disable import/extensions */
import styled, { css, useTheme } from 'styled-components';
import { useState, useEffect } from 'react';

import SVG from './Styles';

interface IEnvelopeIconProps {
  width?: string;
  height?: string;
  color?: string;
  usage?: string;
  cursor?: string;
  id?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
}

interface IContainerProps {
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
}

const Container = styled.div<IContainerProps>`
  margin-top: ${props => props.marginTop ?? '0'};
  margin-bottom: ${props => props.marginBottom ?? '0'};
  margin-left: ${props => props.marginLeft ?? '0'};
  margin-right: ${props => props.marginRight ?? '0'};
  padding-top: ${props => props.paddingTop ?? '0'};
  padding-bottom: ${props => props.paddingBottom ?? '0'};
  padding-left: ${props => props.paddingLeft ?? '0'};
  padding-right: ${props => props.paddingRight ?? '0'};
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const EnvelopeIcon = (props: IEnvelopeIconProps) => {
  const themeGlobal = useTheme();
  const [svgColor, setSvgColor] = useState<string | undefined>();

  useEffect(() => {
    if (props.color) setSvgColor(props.color);
    else if (props.usage) {
    } else setSvgColor((themeGlobal as any).signup['envelope-color']);
  }, [props.color]);

  return (
    <Container
      marginTop={props.marginTop}
      marginBottom={props.marginBottom}
      marginLeft={props.marginLeft}
      marginRight={props.marginRight}
      paddingTop={props.paddingTop}
      paddingBottom={props.paddingBottom}
      paddingLeft={props.paddingLeft}
      paddingRight={props.paddingRight}
    >
      <SVG
        viewBox="0 0 32 32"
        width={props.width ? props.width : '100%'}
        height={props.height ? props.height : '100%'}
      >
        <path
          fill={svgColor}
          d="M15.999 16.404l12.221-10.693c0.041-0.036 0.090-0.050 0.135-0.077-0.739-0.643-1.691-1.046-2.745-1.046h-19.221c-1.054 0-2.007 0.404-2.746 1.047 0.044 0.027 0.094 0.042 0.134 0.077l12.221 10.693zM29.423 7.046c-0.007 0.006-0.010 0.016-0.017 0.022l-12.814 11.212c-0.17 0.148-0.381 0.222-0.593 0.222s-0.423-0.074-0.593-0.222l-12.815-11.212c-0.007-0.006-0.009-0.014-0.016-0.021-0.245 0.533-0.391 1.121-0.391 1.745v14.415c0 2.319 1.886 4.206 4.205 4.206h19.221c2.318 0 4.204-1.886 4.204-4.206v-14.415c0-0.625-0.146-1.213-0.391-1.747z"
        />
      </SVG>
    </Container>
  );
};

export default EnvelopeIcon;
