/* eslint-disable import/extensions */
import * as S from './Styles';
import { INotifyCustomProps } from './types';

const NotifyCustom = (props: INotifyCustomProps) => {
  return (
    <S.Container
      themeStyles={props.themeStyles}
      location={props.location}
      paddingTop={props.paddingTop}
      paddingBottom={props.paddingBottom}
      paddingLeft={props.paddingLeft}
      paddingRight={props.paddingRight}
      borderStyle={props.borderStyle}
      borderWidth={props.borderWidth}
      borderRadius={props.borderRadius}
      borderColor={props.borderColor}
      backgroundColor={props.backgroundColor}
      color={props.color}
      fontSize={props.fontSize}
      fontWeight={props.fontWeight}
      show={props.show}
      cursor={props.cursor}
      pointerEvents={props.pointerEvents}
      width={props.width}
      height={props.height}
      topInitialOffset={props.topInitialOffset}
      bottomInitialOffset={props.bottomInitialOffset}
      display={props.display}
      justifyContent={props.justifyContent}
      alignItems={props.alignItems}
      opacity={props.opacity}
      relativeTo={props.relativeTo}
      minWidth={props.minWidth}
      textAlign={props.textAlign}
      transition={props.transition}
    >
      {props.children}
    </S.Container>
  );
};

export default NotifyCustom;
