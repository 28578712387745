/* eslint-disable import/extensions */
/* eslint-disable func-names */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import { withTranslation } from 'react-i18next';
import { ApexOptions } from 'apexcharts';
import { CircleFlag } from 'react-circle-flags';
import ReactApexChart from 'react-apexcharts';

import EmptyInfo from '../../EmptyInfo/EmptyInfo';
import GlobeIcon from '../../../../assets/iconComponents/Globe';
import { getCountryCodeFromCountryName } from '../../../../Services/Utils/Utils';
import * as S from './styles';
import { IUserDemographicChartProps } from './types';

export const defaultColors = [
  '#a3e2f6',
  '#0084ff',
  '#39539E',
  '#1ab7ea',
  '#0077B5',
  '#087E8B',
  '#21A7B5',
];

const UserDemographicChart = (props: IUserDemographicChartProps) => {
  const getTop5 = (values: any) => {
    const res: any = {};
    if (props.title === 'country') {
      let top5 = [];
      const ordered = [];
      let totalOthers = 0;
      let total = 0;

      for (const i in values) {
        total += values[i].weight;
        if (i !== 'OTHER') {
          ordered.push({
            key: i,
            value: values[i].weight,
            code: values[i].code,
          });
        } else {
          totalOthers = values[i].weight;
        }
      }

      if (props.userAudiencePolarity !== true) {
        ordered.sort(function (a, b) {
          return b.value - a.value;
        });
      }

      if (ordered.length >= 6) {
        const top4 = ordered.slice(0, 4);
        top5 = top4;
        const others = ordered.slice(4, ordered.length);
        for (const i in others) {
          totalOthers += others[i].value;
        }
        top5.push({
          key: 'Others',
          value: Number(totalOthers.toFixed(2)),
          code: 'Others',
        });
      } else {
        top5 = ordered;
      }

      total = 0;
      top5.forEach(entry => {
        total += entry.value;
      });
      for (let i = 0; i < top5.length; i += 1) {
        top5[i].value = Number(((top5[i].value / total) * 100).toFixed(2));
      }

      res.total = total;
      res.values = top5;
    } else {
      let top5 = [];
      const ordered = [];
      let totalOthers = 0;
      let total = 0;

      for (const i in values) {
        total += values[i];
        if (i !== 'OTHER') {
          ordered.push({
            key: i,
            value: values[i],
          });
        } else {
          totalOthers = values[i];
        }
      }

      if (props.userAudiencePolarity !== true) {
        ordered.sort(function (a, b) {
          return b.value - a.value;
        });
      }

      if (ordered.length >= 6) {
        const top4 = ordered.slice(0, 4);
        top5 = top4;
        const others = ordered.slice(4, ordered.length);
        for (const i in others) {
          totalOthers += others[i].value;
        }
        top5.push({
          key: props.t('generic.others'),
          value: Number(totalOthers.toFixed(2)),
        });
      } else {
        top5 = ordered;
      }

      total = 0;
      top5.forEach(entry => {
        total += entry.value;
      });
      for (let i = 0; i < top5.length; i += 1) {
        top5[i].value = Number(((top5[i].value / total) * 100).toFixed(2));
      }

      res.total = total;
      res.values = top5;
    }

    return res;
  };

  const getDistributionKeyWithResources = (
    distributionEntry: { key: string; code: string },
    graphTitle: string,
  ) => {
    switch (graphTitle) {
      case 'gender': {
        return props.t(
          `mediaKit.audienceDemographic.gender.${distributionEntry.key}`,
        );
      }
      case 'country': {
        return props.t(`countryCode.${distributionEntry.code}`);
      }
      case 'type': {
        return props.t(
          `mediaKit.audienceDemographic.type.${distributionEntry.key}`,
        );
      }
      case 'ethnicity': {
        return props.t(
          `mediaKit.audienceDemographic.ethnicity.${distributionEntry.key}`,
        );
      }
      case 'reachability': {
        return props.t(
          `mediaKit.audienceDemographic.reachability.${distributionEntry.key}`,
        );
      }
      default:
        return distributionEntry.key;
    }
  };

  const renderDistribution = (valuesTop: any, graphTitle: string) => {
    const { total } = valuesTop;
    const arr = [];

    for (const item in valuesTop.values) {
      arr.push(
        <S.UserDemographicTableContentBlock>
          <S.UserDemographicTableRow>
            {props.resource ? (
              <S.UserDemographicTableColInner
                justifyContent={'start'}
                flexBasis={'70%'}
              >
                {props.t(`${props.resource}.${valuesTop.values[item].key}`)}
              </S.UserDemographicTableColInner>
            ) : (
              <S.UserDemographicTableColInner
                justifyContent={'start'}
                flexBasis={'70%'}
              >
                {props.countryFlag && (
                  <S.UserDemographicTableColCountryFlag>
                    {valuesTop.values[item].key.toLowerCase() !== 'others' ? (
                      <CircleFlag
                        countryCode={getCountryCodeFromCountryName(
                          valuesTop.values[item].key,
                        )}
                        height="17"
                      />
                    ) : (
                      <S.GlobeIconContainer>
                        <GlobeIcon containerHeight="1.7rem" />
                      </S.GlobeIconContainer>
                    )}
                  </S.UserDemographicTableColCountryFlag>
                )}
                {getDistributionKeyWithResources(
                  valuesTop.values[item],
                  graphTitle,
                )}
              </S.UserDemographicTableColInner>
            )}
            <S.UserDemographicTableColInner
              justifyContent={'end'}
              flexBasis={'30%'}
            >
              {total !== 0
                ? ((valuesTop.values[item].value / total) * 100).toFixed(1)
                : 0}
              %
            </S.UserDemographicTableColInner>
          </S.UserDemographicTableRow>
        </S.UserDemographicTableContentBlock>,
      );
    }

    return (
      <S.DistributionTableContainer graphCount={props.numberOfGraphs}>
        <div>
          <S.UserDemographicTableRow marginBottom="1.1rem">
            <S.UserDemographicTableCol textAlign={'start'}>
              {props.t(`mediaKit.audienceDemographic.${props.title}.title`)}
            </S.UserDemographicTableCol>
            <S.UserDemographicTableCol textAlign={'end'}>
              % {props.t('generic.followers')}
            </S.UserDemographicTableCol>
          </S.UserDemographicTableRow>
        </div>
        <S.UserDemographicTableContent>{arr}</S.UserDemographicTableContent>
      </S.DistributionTableContainer>
    );
  };

  const renderChart = (valuesTop: any) => {
    const labels = [];
    const values = [];
    if (valuesTop !== undefined) {
      for (const i in valuesTop.values) {
        labels.push(valuesTop.values[i].key);
        values.push(valuesTop.values[i].value);
      }
    }
    const chartOptions = {
      options: {
        plotOptions: {},
        colors: props.colors ? props.colors : defaultColors,
        labels,
        legend: {
          show: false,
          floating: true,
          fontSize: '1.6rem',
          position: 'left',
          offsetX: 360,
          offsetY: 0,
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 0,
          },
          itemMargin: {
            horizontal: 1,
          },
        },
        yaxis: {
          labels: {
            formatter(val: any) {
              return `${val} %`;
            },
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: true,
              },
            },
          },
        ],
      },
      series: values,
    };

    return (
      <ReactApexChart
        options={chartOptions.options as ApexOptions}
        series={chartOptions.series}
        type="donut"
      />
    );
  };

  const values = getTop5(props.data);

  return (
    <span>
      {props.data && Object.keys(props.data).length > 0 && (
        <div>
          <S.UserDemographicTitle>
            <S.UserDemographicTitleSmall>
              {props.t(`mediaKit.audienceDemographic.${props.title}.title`)}
            </S.UserDemographicTitleSmall>
          </S.UserDemographicTitle>
          <S.UserDemographicChartContainer>
            {renderChart(values)}
          </S.UserDemographicChartContainer>
          {props.showDistribution !== false && (
            <S.UserDemographicTableContainer>
              {renderDistribution(values, props.title)}
            </S.UserDemographicTableContainer>
          )}
        </div>
      )}
      {props.data && Object.keys(props.data).length === 0 && (
        <EmptyInfo
          icon={<S.EmptyInfoIcon />}
          header={props.t('mediaKit.noData.noDataFound')}
          legend={props.t('mediaKit.noData.noInfoToRender')}
          justifyContent={'space-evenly'}
        />
      )}
    </span>
  );
};

export default withTranslation()(UserDemographicChart);
