/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable import/extensions */
/* eslint-disable func-names */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React, { useState } from 'react';
import moment from 'moment';
import ReactApexChart from 'react-apexcharts';
import { withTranslation } from 'react-i18next';

import MetricsInputControl from '../../MetricsInputControl/MetricsInputControl';
import { IKpisOverview } from '../../../../Services/SkorrApi/new/User/types';
import {
  convertNumberToHumanStringfiedFormat,
  formatNumberByGroupingDigits,
  getSocialNetworkIcon,
  renderTooltip,
  round,
} from '../../../../Services/Utils/Utils';
import { SocialNetworks } from '../../../../Services/Utils/types';
import * as S from './styles';
import { ICardChartEvolutionPerformanceProps } from './types';

const CardChartEvolutionPerformance: React.FC<
  ICardChartEvolutionPerformanceProps | any
> = (props: ICardChartEvolutionPerformanceProps) => {
  const [kpi, setKpi] = useState(undefined);

  const handleChange = (value: any) => {
    setKpi(value);
  };

  const getSparkLineOptions = () => {
    const categories = [];
    if (props.data && props.data.length > 0) {
      for (const i in props.data) {
        const d = props.data[i];
        if (d[props.sortedBy] !== undefined) {
          categories.push(d[props.sortedBy]);
        }
      }
    }
    if (props.keyId === 'audience') {
      return {
        chart: {
          type: 'line',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        colors: props.color,
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 3,
          curve: 'smooth',
        },
        xaxis: {
          type: 'datetime',
          tickAmount: 6,
          categories,
          labels: {
            offsetY: -0,
            rotate: -45,
            trim: false,
            rotateAlways: false,
            hideOverlappingLabels: true,
            formatter(timestamp: number) {
              return moment.utc(timestamp).format('YYYY/MM/DD');
            },
          },
        },
        markers: {
          size: 0,
          opacity: 0.9,
          colors: props.color,
          strokeColor: '#fff',
          strokeWidth: 1,
          hover: {
            size: 5,
          },
        },
        yaxis: {
          tickAmount: getMax() > 15 ? 15 : getMax(),
          decimalsInFloat: 0,
          min: getMax() > 0 ? undefined : 0,
          labels: {
            show: true,
            offsetX: 7,
            formatter(tickAmount: number) {
              return convertNumberToHumanStringfiedFormat(
                tickAmount.toFixed(2),
                2,
              );
            },
          },
        },
        tooltip: {
          y: {
            formatter: (yAxisValue: any) =>
              formatNumberByGroupingDigits(yAxisValue),
            title: {
              formatter: (seriesName: any) => seriesName,
            },
          },
        },
      };
    }
    if (props.keyId === 'engRate' || props.keyId === 'postEng') {
      return {
        chart: {
          type: 'line',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        colors: props.color,
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 3,
          curve: 'smooth',
        },
        xaxis: {
          type: 'datetime',
          tickAmount: 6,
          categories,
          labels: {
            offsetY: -0,
            rotate: -45,
            trim: false,
            rotateAlways: false,
            hideOverlappingLabels: true,
            formatter(timestamp: number) {
              return moment.utc(timestamp).format('YYYY/MM/DD');
            },
          },
        },
        markers: {
          size: 0,
          opacity: 0.9,
          colors: props.color,
          strokeColor: '#fff',
          strokeWidth: 1,
          hover: {
            size: 5,
          },
        },
        yaxis: {
          tickAmount: getMax() > 0.0 ? 15 : getMax(),
          decimalsInFloat: 1,
          min: 0,
          max: getMax(),
          labels: {
            show: true,
            offsetX: 7,
            formatter(tickAmount: number) {
              return `${tickAmount.toFixed(1)} %`;
            },
          },
        },
      };
    }
    if (
      props.keyId === 'postCount' ||
      props.keyId === 'postCount1' ||
      props.keyId === 'storyCount'
    ) {
      return {
        chart: {
          type: 'line',
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        colors: props.color,
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 3,
          curve: 'smooth',
        },
        xaxis: {
          type: 'datetime',
          tickAmount: 6,
          categories,
          labels: {
            offsetY: -0,
            rotate: -45,
            trim: false,
            rotateAlways: false,
            hideOverlappingLabels: true,
            formatter(timestamp: number) {
              return moment.utc(timestamp).format('YYYY/MM/DD');
            },
          },
        },
        markers: {
          size: 0,
          opacity: 0.9,
          colors: props.color,
          strokeColor: '#fff',
          strokeWidth: 1,
          hover: {
            size: 5,
          },
        },
        yaxis: {
          tickAmount: getMax() > 20 ? 14 : getMax(),
          min: 0,
          max: getMax(),
          labels: {
            show: true,
            offsetX: 7,
            formatter(tickAmount: number) {
              return tickAmount.toFixed();
            },
          },
        },
      };
    }
    return {
      chart: {
        type: 'line',
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      colors: props.color,
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 3,
        curve: 'smooth',
      },
      xaxis: {
        type: 'datetime',
        tickAmount: 6,
        categories,
        labels: {
          offsetY: -0,
          rotate: -45,
          trim: false,
          rotateAlways: false,
          hideOverlappingLabels: true,
          formatter(timestamp: number) {
            return moment.utc(timestamp).format('YYYY/MM/DD');
          },
        },
      },
      markers: {
        size: 0,
        opacity: 0.9,
        colors: props.color,
        strokeColor: '#fff',
        strokeWidth: 1,
        hover: {
          size: 5,
        },
      },
      yaxis: {
        tickAmount: getMax() > 10 ? 10 : getMax(),
        decimalsInFloat: 0,
        min: 0,
        max: getMax(),
        labels: {
          show: true,
          offsetX: 7,
          formatter(tickAmount: number) {
            return convertNumberToHumanStringfiedFormat(
              tickAmount.toFixed(2),
              2,
            );
          },
        },
      },
      tooltip: {
        y: {
          formatter: (yAxisValue: any) =>
            formatNumberByGroupingDigits(yAxisValue.toFixed(0)),
          title: {
            formatter: (seriesName: any) => seriesName,
          },
        },
      },
    };
  };

  const getSparkLineSeries = (skpiKpi: boolean) => {
    const series: any = {};
    for (const i in props.series) {
      const s = props.series[i];
      if (skpiKpi || kpi === undefined || s.key === kpi) {
        series[s.key] = [];
      }
    }

    if (props.data && props.data.length > 0) {
      for (const i in props.data) {
        const item: any = {};
        for (const j in props.series) {
          const serie = props.series[j];
          const d = props.data[i];
          if (skpiKpi || kpi === undefined || serie.key === kpi) {
            if (d[serie.key] !== undefined) {
              item[serie.key] = d[serie.key];
              if (props.valueType === 'perc') {
                series[serie.key].push(d[serie.key] * 100);
              } else {
                series[serie.key].push(d[serie.key]);
              }
            } else {
              series[serie.key].push(null);
            }
          }
        }
      }
    }

    const res = [];
    for (const i in props.series) {
      const s = props.series[i];
      if (skpiKpi || kpi === undefined || s.key === kpi) {
        res.push({
          name: s.label,
          data: series[s.key],
          key: s.key,
          type: props.type ? props.type : 'line',
        });
      }
    }

    return res;
  };

  const getKpisOptions = () => {
    const options = [];
    for (const i in props.series) {
      const s = props.series[i];
      options.push({ label: s.label, value: s.key });
    }

    return options;
  };

  const getSerie = (key: string, series: any) => {
    for (const i in series) {
      if (series[i].key === key) {
        return series[i];
      }
    }
  };

  const getMax = () => {
    for (const i in props.totals) {
      const t = props.totals[i];
      const series = getSparkLineSeries(true);
      const serie = getSerie(t.key, series);
      if (serie.data !== undefined) {
        const value = Math.max(...serie.data);
        return value;
      }
    }

    return 0;
  };

  const convertPerformanceLegendWithResources = (rawLegend: string) => {
    let resourcedLegend = rawLegend;

    if (rawLegend.includes('AVG')) {
      resourcedLegend = rawLegend.replace(
        'AVG',
        props.t('mediaKit.evolutionPerformance.label.avg'),
      );
    }
    if (rawLegend.includes('Total')) {
      resourcedLegend = rawLegend.replace(
        'Total',
        props.t('mediaKit.evolutionPerformance.label.total'),
      );
    }
    if (rawLegend.includes('Evolution in this Period')) {
      resourcedLegend = rawLegend.replace(
        'Evolution in this Period',
        props.t('mediaKit.evolutionPerformance.label.evolutionInThisPeriod'),
      );
    }
    if (rawLegend.includes('VS prev. Period')) {
      resourcedLegend = rawLegend.replace(
        'VS prev. Period',
        props.t('mediaKit.evolutionPerformance.label.vsPrevPeriod'),
      );
    }

    return resourcedLegend.replace(/ (Youtube|Instagram)/, '');
  };

  const getSortByKpiLabelPopup = (key: string) => {
    const isNetworkYoutube = props.socialNetwork === SocialNetworks.YOUTUBE;

    if (key.includes('AVG') && key.includes('audience')) {
      key = 'AVG_audience';
    }
    if (key.includes('Evolution') && key.includes('audience')) {
      key = 'Evolution in this Period_audience';
    }
    if (
      key.includes('AVG') &&
      (key.includes('post_engagement_rate') || key.includes('p_engagement'))
    ) {
      key = 'AVG_post_engagement_rate';
    } else if (key.includes('AVG') && key.includes('engagement_rate')) {
      key = 'AVG_engagement_rate';
    } else if (key.includes('AVG') && key.includes('engagement')) {
      key = 'AVG_engagement';
    }
    if (key.includes('VS')) {
      key = 'VS_kpi';
    }
    if (key.includes('Total') && key.includes('engagement')) {
      key = 'Total_engagement';
    }
    if (key.includes('Total') && key.includes('p_engagement')) {
      key = 'Total_p_engagement';
    }
    if (key.includes('AVG') && key.includes('likes')) {
      key = 'AVG_likes';
    }
    if (key.includes('AVG') && key.includes('engRate')) {
      key = 'AVG_engRate';
    }
    if (key.includes('Total') && key.includes('likes')) {
      key = 'Total_likes';
    }
    if (key.includes('AVG') && key.includes('comments')) {
      key = 'AVG_comments';
    }
    if (key.includes('Total') && key.includes('comments')) {
      key = 'Total_comments';
    }
    if (
      key.includes('AVG') &&
      (key.includes('post_impressions') || key.includes('p_impressions'))
    ) {
      key = 'AVG_post_impressions';
    }
    if (
      key.includes('Total') &&
      (key.includes('post_impressions') || key.includes('p_impressions'))
    ) {
      key = 'Total_post_impressions';
    }
    if (
      key.includes('AVG') &&
      (key.includes('post_reach') || key.includes('p_reach'))
    ) {
      key = 'AVG_post_reach';
    }
    if (
      key.includes('Total') &&
      (key.includes('post_reach') || key.includes('p_reach'))
    ) {
      key = 'Total_post_reach';
    }
    if (
      key.includes('AVG') &&
      (key.includes('post_cnt') || key.includes('posts'))
    ) {
      key = 'AVG_post_cnt';
    }
    if (
      key.includes('Total') &&
      (key.includes('post_cnt') || key.includes('posts'))
    ) {
      key = 'Total_post_cnt';
    }
    if (
      key.includes('AVG') &&
      (key.includes('story_impressions') || key.includes('s_impressions'))
    ) {
      key = 'AVG_story_impressions';
    }
    if (
      key.includes('Total') &&
      (key.includes('story_impressions') || key.includes('s_impressions'))
    ) {
      key = 'Total_story_impressions';
    }
    if (
      key.includes('AVG') &&
      (key.includes('story_reach') || key.includes('s_reach'))
    ) {
      key = 'AVG_story_reach';
    }
    if (
      key.includes('Total') &&
      (key.includes('story_reach') || key.includes('s_reach'))
    ) {
      key = 'Total_story_reach';
    }
    if (key.includes('AVG') && key.includes('story_cnt')) {
      key = 'AVG_story_cnt';
    }
    if (
      key.includes('Total') &&
      (key.includes('story_cnt') || key.includes('stories'))
    ) {
      key = 'Total_story_cnt';
    }
    if (key.includes('AVG') && key.includes('shares')) {
      key = 'AVG_shares';
    }
    if (key.includes('Total') && key.includes('shares')) {
      key = 'Total_shares';
    }

    let resourceKey = '';

    switch (key) {
      case 'AVG_audience':
        resourceKey = 'mediaKit.evolutionPerformance.tooltip.avgAudience';
        break;
      case 'Evolution in this Period_audience':
        resourceKey =
          'mediaKit.evolutionPerformance.tooltip.evolutionInThisPeriodAudience';
        break;
      case 'AVG_engagement_rate':
        resourceKey = isNetworkYoutube
          ? 'mediaKit.evolutionPerformance.tooltip.youtube.avgEngagementRate'
          : 'mediaKit.evolutionPerformance.tooltip.avgEngagementRate';
        break;
      case 'VS_kpi':
        resourceKey = 'mediaKit.evolutionPerformance.tooltip.vsKpi';
        break;
      case 'AVG_likes':
        resourceKey = isNetworkYoutube
          ? 'mediaKit.evolutionPerformance.tooltip.youtube.avgLikes'
          : 'mediaKit.evolutionPerformance.tooltip.avgLikes';
        break;
      case 'AVG_engRate':
        resourceKey = 'mediaKit.evolutionPerformance.tooltip.avgEngRate';
        break;
      case 'Total_likes':
        resourceKey = 'mediaKit.evolutionPerformance.tooltip.totalLikes';
        break;
      case 'AVG_comments':
        resourceKey = isNetworkYoutube
          ? 'mediaKit.evolutionPerformance.tooltip.youtube.avgComments'
          : 'mediaKit.evolutionPerformance.tooltip.avgComments';
        break;
      case 'Total_comments':
        resourceKey = 'mediaKit.evolutionPerformance.tooltip.totalComments';
        break;
      case 'AVG_engagement':
        resourceKey = isNetworkYoutube
          ? 'mediaKit.evolutionPerformance.tooltip.youtube.avgEngagement'
          : 'mediaKit.evolutionPerformance.tooltip.avgEngagement';
        break;
      case 'Total_engagement':
        resourceKey = 'mediaKit.evolutionPerformance.tooltip.totalEngagement';
        break;
      case 'AVG_post_impressions':
        resourceKey = isNetworkYoutube
          ? 'mediaKit.evolutionPerformance.tooltip.youtube.avgPostImpressions'
          : 'mediaKit.evolutionPerformance.tooltip.avgPostImpressions';
        break;
      case 'Total_post_impressions':
        resourceKey = isNetworkYoutube
          ? 'mediaKit.evolutionPerformance.tooltip.youtube.totalPostImpressions'
          : 'mediaKit.evolutionPerformance.tooltip.totalPostImpressions';
        break;
      case 'AVG_post_reach':
        resourceKey = isNetworkYoutube
          ? 'mediaKit.evolutionPerformance.tooltip.youtube.avgPostReach'
          : 'mediaKit.evolutionPerformance.tooltip.avgPostReach';
        break;
      case 'Total_post_reach':
        resourceKey = isNetworkYoutube
          ? 'mediaKit.evolutionPerformance.tooltip.youtube.totalPostReach'
          : 'mediaKit.evolutionPerformance.tooltip.totalPostReach';
        break;
      case 'AVG_post_engagement_rate':
        resourceKey =
          'mediaKit.evolutionPerformance.tooltip.avgPostEngagementRate';
        break;
      case 'AVG_post_cnt':
        resourceKey = isNetworkYoutube
          ? 'mediaKit.evolutionPerformance.tooltip.youtube.avgPostCnt'
          : 'mediaKit.evolutionPerformance.tooltip.avgPostCnt';
        break;
      case 'Total_post_cnt':
        resourceKey = isNetworkYoutube
          ? 'mediaKit.evolutionPerformance.tooltip.youtube.totalPostCnt'
          : 'mediaKit.evolutionPerformance.tooltip.totalPostCnt';
        break;
      case 'AVG_story_impressions':
        resourceKey =
          'mediaKit.evolutionPerformance.tooltip.avgStoryImpressions';
        break;
      case 'Total_story_impressions':
        resourceKey =
          'mediaKit.evolutionPerformance.tooltip.totalStoryImpressions';
        break;
      case 'AVG_story_reach':
        resourceKey = 'mediaKit.evolutionPerformance.tooltip.avgStoryReach';
        break;
      case 'Total_story_reach':
        resourceKey = 'mediaKit.evolutionPerformance.tooltip.totalStoryReach';
        break;
      case 'AVG_story_cnt':
        resourceKey = 'mediaKit.evolutionPerformance.tooltip.avgStoryCnt';
        break;
      case 'Total_story_cnt':
        resourceKey = 'mediaKit.evolutionPerformance.tooltip.totalStoryCnt';
        break;
      case 'AVG_shares':
        resourceKey = 'mediaKit.evolutionPerformance.tooltip.avgShares';
        break;
      case 'Total_shares':
        resourceKey = 'mediaKit.evolutionPerformance.tooltip.totalShares';
        break;
      case 'Total_p_engagement':
        resourceKey = 'mediaKit.evolutionPerformance.tooltip.totalPEngagement';
        break;
    }

    return props.t(resourceKey);
  };

  const getPerformanceLegendValue = (
    fnType: any,
    serieKey: any,
    value: any,
  ) => {
    if (
      Number.isNaN(value) ||
      (fnType === 'avg' && serieKey.includes('audience'))
    ) {
      return value;
    }
    return Number(value).toFixed(2);
  };

  const renderPerformanceLegend = (
    series: any,
    filteredKpis: any,
    kpisOverview: IKpisOverview,
    convertNumberToHumanStringfiedFormat: boolean = false,
  ) => {
    const arr = [];
    for (const i in props.totals) {
      const t = props.totals[i];
      const serie = getSerie(t.key, series);
      if (serie) {
        let value =
          serie && serie.data.length > 0
            ? serie.data[serie.data.length - 1] || 0
            : 0;
        if (t.fnType && serie && serie.data.length > 0) {
          if (t.fnType === 'avg') {
            if (serie.key.includes('audience')) {
              value = serie.data[serie.data.length - 1];
            } else if (serie.key.includes('likes')) {
              value = kpisOverview?.avg.likes;
            } else if (serie.key.includes('engagement')) {
              value = kpisOverview?.avg.reactions;
            } else if (serie.key.includes('engagement_rate')) {
              value = kpisOverview?.avg.engRate;
            } else if (serie.key.includes('posts')) {
              value = kpisOverview?.avg.posts.toFixed(1);
            } else if (serie.key.includes('comments')) {
              value = kpisOverview?.avg.comments.toFixed(1);
            } else if (serie.key.includes('insight_post_impressions')) {
              value = kpisOverview?.avg.impressions;
            } else if (serie.key.includes('insight_post_reach')) {
              value = kpisOverview?.avg.reach;
            }
          } else if (t.fnType === 'rate') {
            if (t.key.includes('engagement_rate')) {
              value = round(kpisOverview?.avg.engRate * 100, 3);
            } else {
              let sum = 0;
              let nEntries = 0;

              for (const day of props.data) {
                if (
                  day[t.key] !== undefined &&
                  day[t.key] != null &&
                  day[t.key] !== 0
                ) {
                  sum += day[t.key] * 100;
                  nEntries++;
                }
              }
              if (sum === 0) {
                value = 0;
              } else if (props.public) {
                value = (sum / nEntries).toFixed(2);
              } else {
                value = (sum / nEntries).toFixed(2);
              }
            }
          } else if (t.fnType === 'total') {
            if (t.keyType === 'VS') {
              const newSerie = serie.data.filter(function (el: number) {
                return el != null && el !== 0;
              });
              const firstDay = newSerie[0];
              const lastDay = newSerie[newSerie.length - 1];
              if (serie.key.includes('engagement_rate')) {
                if (firstDay === lastDay) {
                  value = 0;
                } else {
                  value = (lastDay - firstDay) / firstDay;
                }
              } else if (firstDay === lastDay) {
                value = 0;
              } else {
                value = ((lastDay - firstDay) / firstDay) * 100;
              }
            } else if (t.keyType === 'VS2') {
              let sumOlder = 0;
              let olderCount = 0;
              for (const day of props.olderData) {
                if (
                  day[t.key] !== undefined &&
                  day[t.key] != null &&
                  day[t.key] !== 0
                ) {
                  sumOlder += day[t.key];
                  olderCount++;
                }
              }
              if (sumOlder === 0) {
                value = 0;
              }
              let sumCurrent = 0;
              let currCount = 0;
              for (const day of props.data) {
                if (
                  day[t.key] !== undefined &&
                  day[t.key] != null &&
                  day[t.key] !== 0
                ) {
                  sumCurrent += day[t.key];
                  currCount++;
                }
              }
              if (serie.key.includes('engagement_rate')) {
                if (olderCount === 0 || currCount === 0) {
                  value = 0;
                } else {
                  value =
                    (sumCurrent * 100) / currCount -
                    (sumOlder * 100) / olderCount;
                }
              } else if (sumCurrent === 0) {
                value = 0;
              } else if (sumOlder === 0) {
                value = 0;
              } else {
                value =
                  ((sumCurrent / currCount - sumOlder / olderCount) /
                    (sumCurrent / currCount)) *
                  100;
              }
            } else if (serie.key.includes('audience')) {
              value = serie.data[serie.data.length - 1];
            } else if (serie.key.includes('likes')) {
              value = kpisOverview?.total.likes;
            } else if (serie.key.includes('engagement')) {
              value = kpisOverview?.total.reactions;
            } else if (serie.key.includes('engagement_rate')) {
              value = kpisOverview?.total.engRate;
            } else if (serie.key.includes('posts')) {
              value = kpisOverview?.total.posts;
            } else if (serie.key.includes('comments')) {
              value = kpisOverview?.total.comments;
            } else if (serie.key.includes('insight_post_impressions')) {
              value = kpisOverview?.total.impressions;
            } else if (serie.key.includes('insight_post_reach')) {
              value = kpisOverview?.total.reach;
            }
          }
        }
        if (
          (t.label.includes('AVG') && props.showAvg !== false) ||
          (t.label.includes('Total') && props.showTotal !== false) ||
          (t.label.includes('Evolution') &&
            serie.key.includes('audience') &&
            props.showEvolution !== false) ||
          (t.label.includes('VS') &&
            !serie.key.includes('audience') &&
            props.showEvolution !== false)
        ) {
          if (
            !(
              (series[0].key.includes('audience') &&
                t.label.includes('Total')) ||
              (series[0].key.includes('engagement_rate') &&
                t.label.includes('Total'))
            )
          ) {
            const key_h = `${t.label}_${series[0].key}`;
            arr.push(
              <S.ChartValuesCircleContent>
                {arr.length === 0 && (
                  <S.ChartValuesCircleInnerContainer>
                    <S.ChartValuesCircle bg={t.color}>
                      {getSocialNetworkIcon({
                        socialNetwork: props.socialNetwork,
                        gradientColored: true,
                      })}
                    </S.ChartValuesCircle>
                  </S.ChartValuesCircleInnerContainer>
                )}

                <S.ChartValuesCircleInfoContainer>
                  <S.ChartValuesCircleInfoValue>
                    <S.ChartValuesCircleInfoValueText>
                      <MetricsInputControl
                        freeText={true}
                        normalizeNumber={true}
                        roundNumber={false}
                        fieldType={t.type}
                        convertNumberToReadableString={
                          t.keyType === 'VS' || t.keyType === 'VS2'
                            ? false
                            : convertNumberToHumanStringfiedFormat
                        }
                        value={getPerformanceLegendValue(
                          t.fnType,
                          serie.key,
                          value,
                        )}
                      />
                    </S.ChartValuesCircleInfoValueText>
                    {(t.keyType === 'VS' || t.keyType === 'VS2') && (
                      <S.ChartValuesCircleInfoValueUpDownContainer>
                        {value < 0 ? (
                          <S.DownIcon />
                        ) : value === 0 ? (
                          <S.EqualIcon />
                        ) : (
                          <S.UpIcon />
                        )}
                      </S.ChartValuesCircleInfoValueUpDownContainer>
                    )}
                  </S.ChartValuesCircleInfoValue>

                  {key_h === 'AVG_audience' ? (
                    <S.ChartValuesCircleInfoLegend>
                      {props.t('generic.audience')}
                      {renderTooltip(
                        <S.InfoIcon />,
                        key_h,
                        getSortByKpiLabelPopup(key_h),
                      )}
                    </S.ChartValuesCircleInfoLegend>
                  ) : (
                    <S.ChartValuesCircleInfoLegend>
                      {convertPerformanceLegendWithResources(t.label)}
                      {renderTooltip(
                        <S.InfoIcon />,
                        key_h,
                        getSortByKpiLabelPopup(key_h),
                      )}
                    </S.ChartValuesCircleInfoLegend>
                  )}
                </S.ChartValuesCircleInfoContainer>
              </S.ChartValuesCircleContent>,
            );
          }
        }
      }
    }

    return arr;
  };

  if (props.data && props.data.length > 0) {
    props.data.sort((a: any, b: any) => a[props.sortedBy] - b[props.sortedBy]);
  }
  const series = getSparkLineSeries(false);

  const apexChartOptionsInitial = getSparkLineOptions();
  const apexChartOptions = {
    ...apexChartOptionsInitial,
    xaxis: { ...apexChartOptionsInitial.xaxis },
  };

  if (!series[0]?.data?.length) {
    return null;
  }

  return (
    <S.ChartPerformanceContainer>
      {/* <Loader show={props.loading} message={props.t('generic.loading')}> */}
      {
        <S.ChartPerformanceHeader>
          <S.ChartPerformanceHeaderTitle>
            {props.title}
          </S.ChartPerformanceHeaderTitle>
          {!props.disableCombo && (
            <div>
              <div>
                {/* <Dropdown
                  type="multi"
                  valueKey={'kpi'}
                  valuesAvailable={getKpisOptions()}
                  value={kpi}
                  onChange={handleChange}
                  isClearable={true}
                  isFilterable={true}
                /> */}
              </div>
            </div>
          )}
        </S.ChartPerformanceHeader>
      }

      <S.ChartPerformanceBody>
        <ReactApexChart
          options={apexChartOptions as any}
          series={series}
          type={props.type ? props.type : 'line'}
          height={props.height}
        />
      </S.ChartPerformanceBody>
      <S.ChartValuesCircleContainer>
        {renderPerformanceLegend(
          getSparkLineSeries(true),
          props.data,
          props.kpisOverview,
          props.convertNumberToReadableString,
        )}
      </S.ChartValuesCircleContainer>
      {/* </Loader> */}
    </S.ChartPerformanceContainer>
  );
};

export default withTranslation()(CardChartEvolutionPerformance);
