/* eslint-disable import/extensions */
/* eslint-disable default-param-last */
import { useQuery } from 'react-query';

import SkorrApi from '../../Services/SkorrApi/new';
import { IErrorObject } from '../../Services/Utils/types';
import { logApiRequestError } from '../../Services/Utils/Utils';

const { user } = SkorrApi;

export const useGetBusinessProfilesByPartnerId = (
  enabled: boolean = false,
  partnerId: string,
) => {
  return useQuery(
    ['get-business-profiles-by-partner-id', partnerId],
    async () => {
      try {
        const data = await user.getBusinessProfilesByPartnerId();

        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: false,
    },
  );
};
