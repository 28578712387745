/* eslint-disable consistent-return */
import styled, { css } from 'styled-components';

interface ISvgProps {
  width?: string;
  height?: string;
  rotateNotNative?: string;
  cursor?: string;
}

export const Icon = styled.svg.attrs({
  version: '1.1',
  xmlns: 'http://www.w3.org/2000/svg',
})``;

export default styled(Icon)<ISvgProps>`
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  ${props => {
    if (props.rotateNotNative) {
      return css`
        transform: rotate(${props.rotateNotNative});
      `;
    }
  }};
  ${props => {
    if (props.cursor) {
      return css`
        cursor: ${props.cursor};
      `;
    }
  }};
`;
