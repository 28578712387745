/* eslint-disable no-empty */
/* eslint-disable import/extensions */
import { useTheme } from 'styled-components';
import { useState, useEffect } from 'react';

import SVG from './Styles';

interface IAppleButtonProps {
  width?: string;
  height?: string;
  color?: string;
  backgroundColor?: string;
  borderColor?: string;
  usage?: string;
  cursor?: string;
  onClick?: any;
}

const AppleButton = (props: IAppleButtonProps) => {
  const themeGlobal = useTheme();
  const [svgColor, setSvgColor] = useState<string | undefined>();
  const [svgBackgroundColor, setSvgBackgroundColor] = useState<
    string | undefined
  >();
  const [svgBorderColor, setSvgBorderColor] = useState<string | undefined>();

  useEffect(() => {
    if (props.color) setSvgColor(props.color);
    else if (props.usage) {
    } else setSvgColor((themeGlobal as any).signup['apple-button'].color);
    if (props.backgroundColor) setSvgBackgroundColor(props.backgroundColor);
    else if (props.usage) {
    } else
      setSvgBackgroundColor(
        (themeGlobal as any).signup['apple-button']['background-color'],
      );
    if (props.borderColor) setSvgBorderColor(props.borderColor);
    else if (props.usage) {
    } else
      setSvgBorderColor(
        (themeGlobal as any).signup['apple-button']['border-color'],
      );
  }, [props.color, props.backgroundColor, props.borderColor]);

  return (
    <SVG
      viewBox="0 0 141 47"
      width={props.width ? props.width : '100%'}
      height={props.height ? props.height : '100%'}
      onClick={() => props.onClick && props.onClick()}
    >
      <path
        d="M129.425 0.345703H11.8085C11.3408 0.345703 10.9901 0.345703 10.5224 0.345703C10.1717 0.345703 9.82091 0.345703 9.47017 0.345703C8.65176 0.345703 7.95027 0.460899 7.13186 0.576095C6.31345 0.691291 5.49504 1.03688 4.79355 1.38247C4.09206 1.72806 3.50748 2.18884 2.9229 2.64962C2.33833 3.22561 1.87066 3.91678 1.51992 4.60796C1.16917 5.29913 0.935341 5.99031 0.818426 6.79668C0.70151 7.60306 0.584595 8.29423 0.584595 9.10061C0.584595 9.44619 0.584595 9.79178 0.584595 10.1374V36.7477C0.584595 37.0933 0.584595 37.4388 0.584595 37.7844C0.584595 38.5908 0.70151 39.282 0.818426 40.0883C0.935341 40.8947 1.16917 41.5859 1.51992 42.2771C1.87066 42.9683 2.33833 43.5442 2.9229 44.1202C3.50748 44.6962 4.09206 45.157 4.79355 45.5026C5.49504 45.8482 6.31345 46.0785 7.01494 46.3089C7.83335 46.4241 8.53484 46.5393 9.35325 46.5393C9.704 46.5393 10.0547 46.5393 10.4055 46.5393C10.8732 46.5393 11.2239 46.5393 11.6916 46.5393H129.308C129.776 46.5393 130.127 46.5393 130.595 46.5393C130.945 46.5393 131.296 46.5393 131.647 46.5393C132.465 46.5393 133.167 46.4241 133.985 46.3089C134.803 46.1937 135.505 45.9633 136.206 45.6178C136.908 45.2722 137.493 44.8114 138.077 44.2354C138.662 43.6594 139.129 43.0834 139.48 42.3923C139.831 41.7011 140.065 41.0099 140.182 40.2035C140.299 39.3972 140.415 38.706 140.415 37.8996C140.415 37.554 140.415 37.2084 140.415 36.8629C140.415 36.4021 140.415 36.0565 140.415 35.5957V11.4045C140.415 10.9437 140.415 10.5982 140.415 10.1374C140.415 9.79178 140.415 9.44619 140.415 9.10061C140.415 8.29423 140.299 7.60306 140.182 6.79668C140.065 5.99031 139.831 5.29913 139.48 4.60796C138.779 3.22561 137.609 2.07364 136.206 1.38247C135.505 1.03688 134.803 0.806487 133.985 0.691291C133.167 0.576095 132.465 0.460899 131.647 0.460899C131.296 0.460899 130.945 0.460899 130.595 0.460899C130.244 0.345703 129.776 0.345703 129.425 0.345703Z"
        fill={svgBorderColor}
      />
      <path
        d="M10.5224 45.5027C10.1717 45.5027 9.82092 45.5027 9.47017 45.5027C8.76868 45.5027 7.95027 45.3875 7.24878 45.2723C6.54729 45.1571 5.96271 44.9267 5.26122 44.6963C4.67664 44.3507 4.09206 44.0051 3.6244 43.5443C3.15674 43.0835 2.68908 42.5076 2.45525 41.9316C2.1045 41.3556 1.98759 40.6644 1.87067 39.9733C1.75376 39.2821 1.63684 38.5909 1.63684 37.7845C1.63684 37.5541 1.63684 36.7478 1.63684 36.7478V10.1375C1.63684 10.1375 1.63684 9.3311 1.63684 9.10071C1.63684 8.40953 1.75376 7.60316 1.87067 6.91198C1.98759 6.2208 2.22142 5.64482 2.45525 4.95365C2.80599 4.37767 3.15674 3.80169 3.6244 3.3409C4.09206 2.88012 4.67664 2.41933 5.26122 2.18894C5.8458 1.84335 6.54729 1.72816 7.24878 1.61296C7.95027 1.49776 8.65177 1.38257 9.47017 1.38257H10.5224H130.711H131.764C132.465 1.38257 133.167 1.49776 133.985 1.61296C134.687 1.72816 135.271 1.95855 135.973 2.18894C137.142 2.76492 138.194 3.80169 138.779 4.95365C139.129 5.52963 139.246 6.2208 139.363 6.79678C139.48 7.48796 139.597 8.29433 139.597 8.98551C139.597 9.3311 139.597 9.67669 139.597 10.0223C139.597 10.4831 139.597 10.8286 139.597 11.2894V35.3654C139.597 35.8262 139.597 36.1718 139.597 36.6326C139.597 36.9782 139.597 37.3237 139.597 37.6693C139.597 38.3605 139.48 39.0517 139.363 39.8581C139.246 40.5492 139.012 41.1252 138.779 41.8164C138.428 42.3924 138.077 42.9684 137.609 43.4291C137.142 43.8899 136.557 44.3507 135.973 44.5811C135.388 44.9267 134.687 45.1571 133.985 45.1571C133.284 45.2723 132.582 45.3875 131.764 45.3875C131.413 45.3875 131.062 45.3875 130.711 45.3875H129.425L10.5224 45.5027Z"
        fill={svgBackgroundColor}
      />
      <path
        d="M29.5796 23.7305C29.5796 21.7721 30.6318 19.929 32.3856 18.8922C31.3333 17.3947 29.5796 16.4731 27.7089 16.3579C25.7214 16.1275 23.8507 17.5099 22.7985 17.5099C21.7463 17.5099 20.2264 16.3579 18.5895 16.3579C16.3681 16.4731 14.3806 17.6251 13.3283 19.4682C11.107 23.2697 12.7438 29.0295 14.9652 32.1398C16.0174 33.6373 17.3035 35.3653 18.9403 35.3653C20.5771 35.2501 21.1617 34.3285 23.1492 34.3285C25.1368 34.3285 25.6045 35.3653 27.3582 35.3653C29.1119 35.3653 30.1642 33.8677 31.2164 32.255C32.0348 31.2182 32.6194 29.9511 32.9701 28.6839C30.8657 27.9927 29.5796 26.0344 29.5796 23.7305Z"
        fill={svgColor}
      />
      <path
        d="M26.4229 14.5148C27.3582 13.3628 27.8259 11.9805 27.709 10.4829C26.306 10.5981 24.903 11.2893 23.9677 12.4412C23.0323 13.478 22.5647 14.8604 22.6816 16.3579C24.0846 16.2427 25.4876 15.5515 26.4229 14.5148Z"
        fill={svgColor}
      />
      <path
        d="M50.0398 31.6792H44.5448L43.2587 35.5958H40.9204L46.1816 21.3115H48.6368L53.7811 35.4806H51.4428L50.0398 31.6792ZM45.1294 29.836H49.5722L47.4677 23.6154H47.3508L45.1294 29.836Z"
        fill={svgColor}
      />
      <path
        d="M65.1219 30.2968C65.1219 33.5223 63.3682 35.5958 60.6791 35.5958C59.2761 35.711 57.99 34.9047 57.4055 33.7527V38.9365H55.1841V25.113H57.2886V26.8409C57.9901 25.689 59.2761 24.9978 60.6791 24.9978C63.3682 24.9978 65.1219 27.0713 65.1219 30.2968ZM62.9005 30.2968C62.9005 28.2233 61.8483 26.8409 60.0945 26.8409C58.4577 26.8409 57.2886 28.2233 57.2886 30.2968C57.2886 32.3704 58.4577 33.7527 60.0945 33.7527C61.7313 33.8679 62.9005 32.4855 62.9005 30.2968Z"
        fill={svgColor}
      />
      <path
        d="M76.6965 30.2968C76.6965 33.5223 74.9427 35.5958 72.2537 35.5958C70.8507 35.711 69.5646 34.9047 68.9801 33.7527V38.9365H66.7587V25.113H68.8631V26.8409C69.5646 25.689 70.8507 24.9978 72.2537 24.9978C74.9427 24.9978 76.6965 27.0713 76.6965 30.2968ZM74.4751 30.2968C74.4751 28.2233 73.4228 26.8409 71.6691 26.8409C70.0323 26.8409 68.8631 28.2233 68.8631 30.2968C68.8631 32.3704 70.0323 33.7527 71.6691 33.7527C73.4228 33.8679 74.4751 32.4855 74.4751 30.2968Z"
        fill={svgColor}
      />
      <path
        d="M84.4129 31.5639C84.5298 32.9463 85.9328 33.8678 87.9203 33.8678C89.791 33.8678 91.077 32.9463 91.077 31.6791C91.077 30.5271 90.2586 29.9512 88.388 29.4904L86.5173 29.0296C83.8283 28.3384 82.6591 27.1864 82.6591 25.2281C82.6591 22.809 84.8805 21.0811 87.9203 21.0811C90.9601 21.0811 93.0646 22.809 93.1815 25.2281H90.9601C90.8432 23.8458 89.674 22.9242 87.9203 22.9242C86.1666 22.9242 84.9974 23.8458 84.9974 25.1129C84.9974 26.1497 85.8158 26.7257 87.6865 27.1864L89.3233 27.532C92.2462 28.2232 93.5323 29.3752 93.5323 31.4487C93.5323 34.0982 91.3109 35.8262 87.9203 35.8262C84.6467 35.8262 82.5422 34.2134 82.4253 31.5639H84.4129Z"
        fill={svgColor}
      />
      <path
        d="M98.0921 22.5786V24.9977H100.08V26.7257H98.0921V32.4855C98.0921 33.407 98.4429 33.7526 99.3782 33.7526C99.612 33.7526 99.8459 33.7526 100.08 33.7526V35.4806C99.729 35.5958 99.2613 35.5958 98.9106 35.5958C96.8061 35.5958 95.9877 34.7894 95.9877 32.8311V26.8409H94.4678V25.1129H95.8708V22.6938H98.0921V22.5786Z"
        fill={svgColor}
      />
      <path
        d="M101.249 30.2968C101.249 27.0713 103.236 24.9978 106.276 24.9978C109.316 24.9978 111.304 27.0713 111.304 30.2968C111.304 33.6375 109.316 35.5958 106.276 35.5958C103.119 35.711 101.249 33.6375 101.249 30.2968ZM109.082 30.2968C109.082 27.9929 108.03 26.7257 106.276 26.7257C104.522 26.7257 103.47 28.1081 103.47 30.2968C103.47 32.6007 104.522 33.8679 106.276 33.8679C108.03 33.8679 109.082 32.6007 109.082 30.2968Z"
        fill={svgColor}
      />
      <path
        d="M113.057 25.113H115.162V26.8409C115.395 25.6889 116.565 24.8826 117.734 24.9978C117.968 24.9978 118.201 24.9978 118.435 25.113V27.0713C118.084 26.9561 117.734 26.9561 117.5 26.9561C116.331 26.9561 115.279 27.8777 115.279 29.0296C115.279 29.1448 115.279 29.26 115.279 29.3752V35.5958H113.057V25.113Z"
        fill={svgColor}
      />
      <path
        d="M128.49 32.4855C128.139 34.3287 126.269 35.711 123.93 35.711C120.891 35.711 118.903 33.6375 118.903 30.412C118.903 27.1865 120.774 24.9978 123.814 24.9978C126.736 24.9978 128.607 26.9561 128.607 30.1816V30.8728H121.124V30.988C121.008 32.4855 122.06 33.7527 123.58 33.9831C123.697 33.9831 123.814 33.9831 123.93 33.9831C124.983 34.0983 126.035 33.5223 126.386 32.4855H128.49ZM121.124 29.3753H126.386C126.503 27.9929 125.333 26.8409 123.93 26.7257C123.814 26.7257 123.814 26.7257 123.697 26.7257C122.411 26.7257 121.124 27.8777 121.124 29.3753Z"
        fill={svgColor}
      />
      <path
        d="M44.7787 10.4831C46.5324 10.3679 47.9354 11.6351 48.0523 13.2478C48.0523 13.4782 48.0523 13.5934 48.0523 13.8238C48.0523 16.0125 46.8832 17.2797 44.7787 17.2797H42.2065V10.3679H44.7787V10.4831ZM43.3757 16.3581H44.6618C45.8309 16.4733 46.8832 15.5517 47.0001 14.2846C47.0001 14.1694 47.0001 13.939 47.0001 13.8238C47.117 12.6718 46.2986 11.5199 45.1294 11.4047C45.0125 11.4047 44.8956 11.4047 44.6618 11.4047H43.3757V16.3581Z"
        fill={svgColor}
      />
      <path
        d="M49.3383 14.7454C49.2214 13.363 50.1567 12.2111 51.5597 12.0959C52.9627 11.9807 54.1319 12.9023 54.2488 14.2846C54.2488 14.3998 54.2488 14.6302 54.2488 14.7454C54.3657 16.1277 53.4304 17.2797 52.0274 17.3949C50.6244 17.5101 49.4553 16.5885 49.3383 15.2062C49.3383 15.091 49.3383 14.8606 49.3383 14.7454ZM53.1965 14.7454C53.1965 13.5934 52.7289 13.0175 51.7936 13.0175C50.8582 13.0175 50.3906 13.7086 50.3906 14.7454C50.3906 15.8974 50.8582 16.5885 51.7936 16.5885C52.7289 16.4733 53.1965 15.8974 53.1965 14.7454Z"
        fill={svgColor}
      />
      <path
        d="M60.913 17.2795H59.8608L58.8086 13.4781H58.6916L57.5225 17.2795H56.4702L55.0673 12.0957H56.1195L57.0548 16.0124H57.1717L58.224 12.0957H59.2762L60.3285 16.0124H60.4454L61.3807 12.0957H62.4329L60.913 17.2795Z"
        fill={svgColor}
      />
      <path
        d="M63.6021 12.0957H64.6543V12.9021H64.7712C65.005 12.3261 65.7065 11.9805 66.2911 11.9805C67.2264 11.8653 68.0448 12.5565 68.1617 13.5933C68.1617 13.7085 68.1617 13.8237 68.1617 13.9389V17.2796H67.1095V14.1693C67.1095 13.3629 66.7588 12.9021 65.9404 12.9021C65.2389 12.9021 64.6543 13.3629 64.6543 14.0541C64.6543 14.1693 64.6543 14.1693 64.6543 14.2845V17.2796H63.6021V12.0957Z"
        fill={svgColor}
      />
      <path
        d="M69.6816 10.1375H70.7339V17.2796H69.6816V10.1375Z"
        fill={svgColor}
      />
      <path
        d="M72.1368 14.7454C72.0199 13.363 72.9552 12.2111 74.3582 12.0959C75.7612 11.9807 76.9303 12.9023 77.0472 14.2846C77.0472 14.3998 77.0472 14.6302 77.0472 14.7454C77.1642 16.1277 76.2288 17.2797 74.8259 17.3949C73.4229 17.5101 72.2537 16.5885 72.1368 15.2062C72.1368 15.091 72.1368 14.8606 72.1368 14.7454ZM76.1119 14.7454C76.1119 13.5934 75.6443 13.0175 74.7089 13.0175C73.7736 13.0175 73.306 13.7086 73.306 14.7454C73.306 15.8974 73.7736 16.5885 74.7089 16.5885C75.5273 16.4733 76.1119 15.8974 76.1119 14.7454Z"
        fill={svgColor}
      />
      <path
        d="M78.2165 15.8972C78.2165 14.9756 78.918 14.3996 80.2041 14.3996L81.6071 14.2844V13.8236C81.6071 13.2477 81.2563 13.0173 80.5548 13.0173C79.9703 13.0173 79.6195 13.2477 79.5026 13.5933H78.4504C78.5673 12.6717 79.3857 12.0957 80.5548 12.0957C81.8409 12.0957 82.6593 12.7869 82.6593 13.8236V17.2795H81.6071V16.5883H81.4901C81.1394 17.1643 80.5548 17.3947 79.8533 17.3947C79.0349 17.5099 78.2165 16.8187 78.0996 16.0124C78.2165 16.0124 78.2165 15.8972 78.2165 15.8972ZM81.6071 15.4364V14.9756L80.321 15.0908C79.6195 15.0908 79.2688 15.4364 79.2688 15.782C79.2688 16.2428 79.7364 16.4732 80.2041 16.4732C80.9056 16.5884 81.4902 16.1276 81.6071 15.4364Z"
        fill={svgColor}
      />
      <path
        d="M84.0623 14.7453C84.0623 13.1325 84.8807 12.0958 86.2837 12.0958C86.9851 12.0958 87.5697 12.4414 87.9205 13.0174H88.0374V10.1375H89.0896V17.2796H88.0374V16.4732H87.9205C87.5697 17.0492 86.9851 17.3948 86.2837 17.3948C84.8807 17.3948 84.0623 16.358 84.0623 14.7453ZM85.1145 14.7453C85.1145 15.8973 85.6991 16.4732 86.5175 16.4732C87.3359 16.4732 87.9205 15.7821 87.9205 14.7453C87.9205 13.7085 87.3359 13.0174 86.5175 13.0174C85.5822 13.0174 85.1145 13.5933 85.1145 14.7453Z"
        fill={svgColor}
      />
      <path
        d="M93.1817 14.7454C93.0648 13.363 94.0001 12.2111 95.4031 12.0959C96.8061 11.9807 97.9753 12.9023 98.0922 14.2846C98.0922 14.3998 98.0922 14.6302 98.0922 14.7454C98.2091 16.1277 97.2738 17.2797 95.8708 17.3949C94.4678 17.5101 93.2986 16.5885 93.1817 15.2062C93.1817 15.091 93.1817 14.8606 93.1817 14.7454ZM97.1568 14.7454C97.1568 13.5934 96.6892 13.0175 95.7539 13.0175C94.8185 13.0175 94.3509 13.7086 94.3509 14.7454C94.3509 15.8974 94.8185 16.5885 95.7539 16.5885C96.5723 16.4733 97.1568 15.8974 97.1568 14.7454Z"
        fill={svgColor}
      />
      <path
        d="M99.612 12.0957H100.547V12.9021H100.664C100.898 12.3261 101.6 11.9805 102.184 11.9805C103.119 11.8653 103.938 12.5565 104.055 13.5933C104.055 13.7085 104.055 13.8237 104.055 13.9389V17.2796H103.003V14.1693C103.003 13.3629 102.652 12.9021 101.833 12.9021C101.132 12.9021 100.547 13.3629 100.547 14.0541C100.547 14.1693 100.547 14.1693 100.547 14.2845V17.2796H99.4951V12.0957H99.612Z"
        fill={svgColor}
      />
      <path
        d="M109.901 10.8286V12.0958H111.07V12.9021H109.901V15.5517C109.901 16.1276 110.134 16.358 110.602 16.358C110.719 16.358 110.836 16.358 110.953 16.358V17.2796C110.719 17.2796 110.602 17.2796 110.368 17.2796C109.199 17.2796 108.731 16.934 108.731 15.8972V13.0173H107.913V12.211H108.731V10.8286H109.901Z"
        fill={svgColor}
      />
      <path
        d="M112.473 10.1375H113.525V13.0174H113.642C113.876 12.4414 114.577 11.9806 115.279 12.0958C116.214 12.0958 117.032 12.787 117.149 13.7085C117.149 13.8237 117.149 13.9389 117.149 14.0541V17.2796H116.097V14.1693C116.097 13.3629 115.746 12.9022 114.928 12.9022C114.226 12.9022 113.642 13.3629 113.642 14.0541C113.642 14.1693 113.642 14.1693 113.642 14.2845V17.2796H112.59L112.473 10.1375Z"
        fill={svgColor}
      />
      <path
        d="M123.112 15.8972C122.878 16.8187 121.826 17.5099 120.774 17.3947C119.488 17.3947 118.319 16.358 118.319 15.0908C118.319 14.9756 118.319 14.8604 118.319 14.7452C118.085 13.4781 119.02 12.2109 120.423 12.0957C120.54 12.0957 120.657 12.0957 120.774 12.0957C122.294 12.0957 123.112 13.1325 123.112 14.7452V14.9756H119.371C119.254 15.782 119.838 16.358 120.657 16.4732H120.774C121.241 16.5884 121.826 16.2428 122.06 15.8972H123.112ZM119.488 14.2844H122.177C122.177 13.5933 121.709 13.0173 121.008 12.9021H120.891C120.072 12.9021 119.488 13.4781 119.488 14.2844Z"
        fill={svgColor}
      />
      <defs>
        <clipPath id="clip0_3897_8868">
          <rect
            width={props.width ? props.width : '100%'}
            height={props.height ? props.height : '100%'}
            fill={svgColor}
          />
        </clipPath>
      </defs>
    </SVG>
  );
};

export default AppleButton;
