/* eslint-disable eqeqeq */
/* eslint-disable import/extensions */
import styled, { useTheme } from 'styled-components';
import { useState, useEffect } from 'react';

import SVG from './Styles';

interface ITwitterIconProps {
  containerWidth?: string;
  containerHeight?: string;
  containerPaddingTop?: string;
  containerPaddingBottom?: string;
  containerPaddingLeft?: string;
  containerPaddingRight?: string;
  containerMarginTop?: string;
  containerMarginBottom?: string;
  containerMarginLeft?: string;
  containerMarginRight?: string;
  useCase?: 'additionalDataStep';
  svgWidth?: string;
  svgHeight?: string;
  rotate?: string;
  cursor?: string;
}

interface IContainerProps {
  width?: string;
  height?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
}

const Container = styled.div<IContainerProps>`
  border-width: 0;
  outline: 0;
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '0')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '0')};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '0')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '0')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const TwitterIcon = (props: ITwitterIconProps) => {
  const themeGlobal = useTheme();
  const [svgColor, setSvgColor] = useState<string>(() => {
    if (props.useCase == 'additionalDataStep') {
      return (themeGlobal as any)['additional-data-step']['twitter-icon-color'];
    }

    return (themeGlobal as any)['social-networks'].twitter.color;
  });

  useEffect(() => {
    if (props.useCase == 'additionalDataStep') {
      setSvgColor(
        (themeGlobal as any)['additional-data-step']['twitter-icon-color'],
      );
    } else {
      setSvgColor((themeGlobal as any)['social-networks'].twitter.color);
    }
  }, [(themeGlobal as any).darkMode]);

  return (
    <Container
      width={props.containerWidth}
      height={props.containerHeight}
      paddingTop={props.containerPaddingTop}
      paddingBottom={props.containerPaddingBottom}
      paddingLeft={props.containerPaddingLeft}
      paddingRight={props.containerPaddingRight}
      marginTop={props.containerMarginTop}
      marginBottom={props.containerMarginBottom}
      marginLeft={props.containerMarginLeft}
      marginRight={props.containerMarginRight}
    >
      <SVG
        viewBox="0 0 32 32"
        width={props.svgWidth ? props.svgWidth : '100%'}
        height={props.svgHeight ? props.svgHeight : '100%'}
        rotateNotNative={props.rotate}
        cursor={props.cursor}
      >
        <path
          fill={svgColor}
          d="M30.077 4.79c-1.226 0.845-2.582 1.491-4.018 1.914-0.771-0.866-1.795-1.48-2.934-1.759s-2.339-0.208-3.436 0.201-2.039 1.138-2.699 2.088c-0.66 0.95-1.005 2.075-0.989 3.222v1.251c-2.249 0.057-4.477-0.431-6.487-1.419s-3.737-2.448-5.030-4.247c0 0-5.119 11.257 6.399 16.261-2.636 1.749-5.775 2.625-8.958 2.502 11.517 6.254 25.594 0 25.594-14.384-0.001-0.348-0.035-0.696-0.102-1.038 1.306-1.259 2.228-2.848 2.662-4.59z"
        />
      </SVG>
    </Container>
  );
};

export default TwitterIcon;
