/* eslint-disable eqeqeq */
/* eslint-disable consistent-return */
/* eslint-disable default-case */
/* eslint-disable import/extensions */
import styled, { useTheme } from 'styled-components';
import { useState, useEffect } from 'react';

import SVG from './Styles';

interface IInstagramIconProps {
  containerWidth?: string;
  containerHeight?: string;
  containerPaddingTop?: string;
  containerPaddingBottom?: string;
  containerPaddingLeft?: string;
  containerPaddingRight?: string;
  containerMarginTop?: string;
  containerMarginBottom?: string;
  containerMarginLeft?: string;
  containerMarginRight?: string;
  useCase?: 'additionalDataStep';
  gradientColored?: boolean;
  disabledColored?: boolean;
  noAssignColor?: boolean;
  svgWidth?: string;
  svgHeight?: string;
  rotate?: string;
  cursor?: string;
}

interface IContainerProps {
  width?: string;
  height?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
}

const Container = styled.div<IContainerProps>`
  border-width: 0;
  outline: 0;
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '0')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '0')};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '0')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '0')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const InstagramIcon = (props: IInstagramIconProps) => {
  const themeGlobal = useTheme();
  const [svgColor, setSvgColor] = useState<string>(() => {
    if (props.noAssignColor) {
      return undefined;
    }
    if (props.disabledColored) {
      return (themeGlobal as any)['social-networks'].instagram.disabled.color;
    }
    if (props.useCase) {
      switch (props.useCase) {
        case 'additionalDataStep': {
          return (themeGlobal as any)['additional-data-step'][
            'instagram-icon-color'
          ];
        }
      }
    } else {
      if (props.gradientColored) {
        return (themeGlobal as any)['social-networks'].instagram.gradient.color;
      }
      return (themeGlobal as any)['social-networks'].instagram.color;
    }
  });

  useEffect(() => {
    if (props.noAssignColor != true) {
      if (props.disabledColored) {
        setSvgColor(
          (themeGlobal as any)['social-networks'].instagram.disabled.color,
        );
      } else if (props.useCase) {
        switch (props.useCase) {
          case 'additionalDataStep': {
            setSvgColor(
              (themeGlobal as any)['additional-data-step'][
                'instagram-icon-color'
              ],
            );
          }
        }
      } else if (props.gradientColored) {
        setSvgColor(
          (themeGlobal as any)['social-networks'].instagram.gradient.color,
        );
      } else {
        setSvgColor((themeGlobal as any)['social-networks'].instagram.color);
      }
    }
  }, [(themeGlobal as any).darkMode]);

  return (
    <Container
      width={props.containerWidth}
      height={props.containerHeight}
      paddingTop={props.containerPaddingTop}
      paddingBottom={props.containerPaddingBottom}
      paddingLeft={props.containerPaddingLeft}
      paddingRight={props.containerPaddingRight}
      marginTop={props.containerMarginTop}
      marginBottom={props.containerMarginBottom}
      marginLeft={props.containerMarginLeft}
      marginRight={props.containerMarginRight}
    >
      <SVG
        viewBox="0 0 20 20"
        width={props.svgWidth ? props.svgWidth : '100%'}
        height={props.svgHeight ? props.svgHeight : '100%'}
        rotateNotNative={props.rotate}
        cursor={props.cursor}
      >
        <path
          d="M19.0679 4.37732C18.8594 2.56382 17.7097 1.33382 15.9239 0.978323C15.1447 0.823073 14.3354 0.777323 13.5382 0.763823C11.5694 0.730823 9.59918 0.736823 7.63043 0.743573C6.49943 0.747323 5.36618 0.733073 4.24568 0.929573C2.53343 1.23032 1.35893 2.31632 1.00943 4.01957C0.858678 4.75457 0.808428 5.51957 0.792678 6.27257C0.754428 8.08682 0.740178 9.90257 0.759678 11.7176C0.773928 12.9873 0.810678 14.2601 0.929178 15.5238C1.03868 16.6968 1.57568 17.6838 2.57243 18.3693C3.34193 18.8988 4.22168 19.1013 5.13443 19.1358C6.75668 19.1973 8.38043 19.2221 10.0042 19.2618C10.0042 19.2506 10.0049 19.2386 10.0049 19.2273C10.6004 19.2273 11.1959 19.2446 11.7907 19.2236C13.0672 19.1778 14.3527 19.1891 15.6164 19.0353C17.2844 18.8313 18.4267 17.8878 18.9314 16.2476C19.1632 15.4953 19.2022 14.7153 19.2112 13.9368C19.2344 11.9381 19.2629 9.93932 19.2449 7.94057C19.2344 6.75182 19.2044 5.55707 19.0687 4.37807L19.0679 4.37732ZM10.0072 15.7706C6.81293 15.7818 4.20593 13.1823 4.19468 9.97607C4.18418 6.78332 6.79268 4.17332 9.99518 4.17257C13.1939 4.17257 15.7717 6.74207 15.7934 9.95282C15.8152 13.1441 13.2142 15.7593 10.0072 15.7706ZM15.9944 5.29307C15.2489 5.28107 14.6564 4.65557 14.6729 3.89882C14.6894 3.16082 15.3059 2.57732 16.0552 2.59232C16.8127 2.60732 17.3924 3.21857 17.3729 3.98207C17.3534 4.72607 16.7444 5.30507 15.9944 5.29307ZM13.7534 9.98807C13.7437 12.0693 12.0659 13.7336 9.98318 13.7276C7.90268 13.7216 6.23318 12.0438 6.23768 9.96332C6.24218 7.87307 7.92893 6.19982 10.0184 6.21257C12.0989 6.22457 13.7624 7.90682 13.7527 9.98807H13.7534Z"
          fill={svgColor}
        />
        {props.gradientColored && (
          <defs>
            <linearGradient
              id="paint0_linear_110_310"
              x1="1.00001"
              y1="17.8751"
              x2="19.0001"
              y2="1.00019"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#FFEA00" />
              <stop offset="1" stop-color="#FF00E1" />
            </linearGradient>
          </defs>
        )}
      </SVG>
    </Container>
  );
};

export default InstagramIcon;
