/* eslint-disable import/extensions */
/* eslint-disable default-param-last */
import { useMutation, useQuery } from 'react-query';

import { logApiRequestError } from '../../Services/Utils/Utils';
import { IErrorObject, SocialNetworks } from '../../Services/Utils/types';
import SkorrApi from '../../Services/SkorrApi/new';
import {
  GetPublicUserBasicInformationWithExternalProviderResponse,
  UpdateUserPublicProfilesParams,
} from '../../Services/SkorrApi/new/User/types';

const { user } = SkorrApi;

export const useGetPublicUserBasicInformationWithExternalProvider = (
  enabled: boolean = false,
  params: { username: string; socialNetwork: SocialNetworks | undefined },
) => {
  return useQuery(
    [
      'get-public-user-basic-information-with-external-provider',
      params.username,
      params.socialNetwork,
    ],
    async () => {
      try {
        const {
          data,
        }: {
          data: GetPublicUserBasicInformationWithExternalProviderResponse;
        } = await user.getPublicUserBasicInformationWithExternalProvider(
          params as { username: string; socialNetwork: SocialNetworks },
        );
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      staleTime: 0,
      retry: false,
    },
  );
};

export const useUpdateUserPublicProfiles = () => {
  return useMutation(
    async (params: UpdateUserPublicProfilesParams) => {
      try {
        await user.updateUserPublicProfiles(params);
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};
