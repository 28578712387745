/* eslint-disable import/extensions */
import { useTheme } from 'styled-components';
import { useState, useEffect } from 'react';

import SVG from './Styles';

interface IEmptyPictureIconProps {
  width?: string;
  height?: string;
}

const EmptyPictureIcon = (props: IEmptyPictureIconProps) => {
  const themeGlobal = useTheme();
  const [svgColor, setSvgColor] = useState<string>();

  useEffect(() => {
    setSvgColor(
      (themeGlobal as any)['additional-data-step']['empty-picture-icon-color'],
    );
  }, []);

  return (
    <SVG
      viewBox="0 0 32 32"
      width={props.width ? props.width : '100%'}
      height={props.height ? props.height : '100%'}
    >
      <path
        fill={svgColor}
        d="M3.667 24.286c0-5.524 0-11.049 0-16.573 0.015-0.036 0.035-0.072 0.046-0.109 0.206-0.741 0.782-1.193 1.553-1.193 7.154-0.002 14.308-0.003 21.462 0 0.939 0 1.605 0.675 1.605 1.616 0.002 5.315 0.002 10.631-0.002 15.946-0 0.181-0.028 0.37-0.083 0.543-0.213 0.675-0.799 1.074-1.568 1.074-7.122 0.001-14.244 0.001-21.366 0.001-0.040 0-0.080 0-0.12-0.002-0.603-0.029-1.053-0.309-1.341-0.837-0.079-0.145-0.124-0.31-0.185-0.466zM5.252 19.001c0.084-0.091 0.137-0.146 0.187-0.204 1.822-2.117 3.643-4.235 5.467-6.35 0.594-0.688 1.372-0.68 1.953 0.015 1.932 2.307 3.864 4.616 5.795 6.924 0.091 0.108 0.183 0.216 0.308 0.364 0.074-0.096 0.12-0.173 0.182-0.235 0.947-0.949 1.89-1.902 2.848-2.84 0.166-0.162 0.382-0.302 0.601-0.374 0.413-0.136 0.791-0.009 1.111 0.282 0.945 0.857 1.89 1.716 2.834 2.574 0.060 0.055 0.123 0.106 0.211 0.183 0-3.815 0-7.577 0-11.354-7.172 0-14.328 0-21.499 0-0 3.664-0 7.316-0 11.015zM24.42 12.079c-0.005-1.18-0.97-2.144-2.149-2.147-1.19-0.003-2.167 0.971-2.162 2.157 0.004 1.182 0.964 2.139 2.148 2.144 1.193 0.004 2.168-0.967 2.163-2.154z"
      />
    </SVG>
  );
};

export default EmptyPictureIcon;
