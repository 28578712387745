/* eslint-disable consistent-return */
/* eslint-disable default-case */
import styled from 'styled-components';
import { FaChartLine } from 'react-icons/fa';

import {
  IDistributionTableContainerProps,
  IUserDemographicTableColInnerProps,
  IUserDemographicTableColProps,
  IUserDemographicTableRowProps,
} from './types';

export const UserDemographicTitle = styled.h5`
  margin: 0.4rem 0.8rem;
  line-height: 1.2;
`;

export const UserDemographicTitleSmall = styled.small`
  font-size: 1.44rem;
  font-weight: 400;
  color: #8492a6;
`;

export const UserDemographicChartContainer = styled.div`
  position: relative;
`;

export const UserDemographicTableContainer = styled.div`
  margin-top: 4.8rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
`;

// export const EmptyInfoIcon = styled(FaChartLine)``;
export const EmptyInfoIcon = FaChartLine;

export const UserDemographicTableContentBlock = styled.div`
  border-bottom: 0.1rem solid #ebeef1;
  padding-bottom: 0.72rem;
  margin-bottom: 0.72rem;

  &:last-child {
    border-bottom: none;
  }
`;

export const UserDemographicTableRow = styled.div<IUserDemographicTableRowProps>`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: ${({ marginBottom }) => marginBottom ?? 'unset'};
`;

export const UserDemographicTableColInner = styled.div<IUserDemographicTableColInnerProps>`
  font-size: 1.28rem;
  color: #8492a6;
  font-weight: 300;
  padding-right: 0;
  padding-left: 0;
  height: 1.7rem;
  flex: 0 0 ${({ flexBasis }) => flexBasis ?? '50%'};
  max-width: ${({ flexBasis }) => flexBasis ?? '50%'};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent }) => justifyContent ?? 'unset'};
`;

export const UserDemographicTableColCountryFlag = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 0.5rem;
  height: 1.7rem;
`;

export const GlobeIconContainer = styled.div`
  fill: #8492a6;
`;

export const UserDemographicTableCol = styled.div<IUserDemographicTableColProps>`
  padding-right: 0;
  padding-left: 0;
  text-align: ${({ textAlign }) => textAlign ?? 'unset'};
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  width: 100%;
`;

export const UserDemographicTableContent = styled.div`
  max-height: 19.5rem;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 0.8rem;
`;

export const DistributionTableContainer = styled.div<IDistributionTableContainerProps>`
  @media (max-width: 1528px) {
  }

  @media (max-width: 1175px) {
  }

  @media (max-width: 786px) {
  }

  @media (max-width: 520px) {
  }
`;
