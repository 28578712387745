/* eslint-disable import/extensions */
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

import { ILoaderFixedProps } from './types';
import { Container, MessageContainer } from './Styles';
import { isNavbarDisplayed } from '../../../Services/Utils/Utils';

const LoaderFixed = (props: ILoaderFixedProps) => {
  const location = useLocation();
  const isNavbarDisplayedLocal: boolean = useMemo(
    () => isNavbarDisplayed(location.pathname),
    [],
  );

  if (!props.show) {
    return <>{props.children && props.children}</>;
  }

  return (
    <>
      {props.children && props.children}
      <Container menuNavbarVisible={isNavbarDisplayedLocal}>
        <MessageContainer menuNavbarVisible={isNavbarDisplayedLocal}>
          {props.message}
        </MessageContainer>
      </Container>
    </>
  );
};

export default LoaderFixed;
