/* eslint-disable import/extensions */
import { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import SVG from './Styles';

interface IEditIconV2Props {
  containerWidth?: string;
  containerHeight?: string;
  containerPaddingTop?: string;
  containerPaddingBottom?: string;
  containerPaddingLeft?: string;
  containerPaddingRight?: string;
  containerMarginTop?: string;
  containerMarginBottom?: string;
  containerMarginLeft?: string;
  containerMarginRight?: string;
  svgWidth?: string;
  svgHeight?: string;
  cursor?: string;
  onClick?: any;
  id?: string;
}

interface IContainerProps {
  width?: string;
  height?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
}

const Container = styled.div<IContainerProps>`
  border-width: 0;
  outline: 0;
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '0')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '0')};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '0')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '0')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
  display: inline-flex;
  align-items: center;
`;

const EditIconV2 = (props: IEditIconV2Props) => {
  const themeGlobal = useTheme();
  const [svgColor, setSvgColor] = useState<string>(() => {
    return (themeGlobal as any).icons.editIconV2;
  });

  useEffect(() => {
    setSvgColor((themeGlobal as any).icons.editIconV2);
  }, [(themeGlobal as any).darkMode]);

  return (
    <Container
      width={props.containerWidth}
      height={props.containerHeight}
      paddingTop={props.containerPaddingTop}
      paddingBottom={props.containerPaddingBottom}
      paddingLeft={props.containerPaddingLeft}
      paddingRight={props.containerPaddingRight}
      marginTop={props.containerMarginTop}
      marginBottom={props.containerMarginBottom}
      marginLeft={props.containerMarginLeft}
      marginRight={props.containerMarginRight}
      id={props.id}
    >
      <SVG
        viewBox="0 0 32 32"
        width={props.svgWidth ? props.svgWidth : '100%'}
        height={props.svgHeight ? props.svgHeight : '100%'}
        cursor={props.cursor}
      >
        <path
          fill={svgColor}
          d="M30.163 4.596c-0.257-0.623-0.631-1.179-1.105-1.653-0.475-0.476-1.032-0.848-1.656-1.107-1.245-0.516-2.661-0.516-3.907 0-0.624 0.259-1.181 0.631-1.656 1.107l-18 18c-0.164 0.164-0.283 0.368-0.344 0.592l-2 7.333c-0.125 0.461 0.005 0.955 0.344 1.293 0.253 0.253 0.593 0.391 0.943 0.391 0.117 0 0.235-0.016 0.351-0.047l7.333-2c0.224-0.061 0.428-0.18 0.592-0.344l18-18c0.475-0.475 0.848-1.032 1.108-1.657 0.256-0.623 0.387-1.279 0.387-1.952s-0.131-1.329-0.389-1.956zM27.7 7.483c-0.124 0.299-0.303 0.565-0.528 0.792l-17.751 17.751-4.74 1.293 1.293-4.741 17.749-17.749c0.228-0.227 0.493-0.405 0.792-0.528 0.593-0.247 1.271-0.247 1.864 0 0.299 0.124 0.564 0.301 0.792 0.528 0.227 0.227 0.405 0.493 0.528 0.789 0.124 0.299 0.187 0.613 0.187 0.935s-0.064 0.636-0.187 0.931z"
        />
      </SVG>
    </Container>
  );
};

export default EditIconV2;
