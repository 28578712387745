/* eslint-disable import/extensions */
import { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import Button from '../../components/shared/Button/Button';
import * as S from './Styles';
import { ISignUpProps } from './types';
import SkorrLogo from '../../assets/logo-skorr-vh.svg';
import SkorrLogoSmallWindow from '../../assets/logo-skorr-vh-noslogan.svg';
import InputControlV2 from '../../components/shared/InputControlV2/InputControlV2';
import { emailValid, valueEmpty } from '../../validations/validationFunctions';
import GenericModal from '../../components/shared/GenericModal/GenericModal';
import NotifyCustom from '../../components/shared/NotifyCustom/NotifyCustom';
import InputSpinnerIcon from '../../assets/iconComponents/InputSpinnerIcon/InputSpinnerIcon';
import EmailSentSuccessfullyImage from '../../assets/skorr-email-sent.jpg';
import { useSendAccountDeletionEmail } from './RQCustomHooks';

const DeleteAccount = (props: ISignUpProps) => {
  const [email, setEmail] = useState<string>('');
  const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
  const [isEmailInputFocused, setIsEmailInputFocused] =
    useState<boolean>(false);
  const [forceEmailErrorDisplay, setForceEmailErrorDisplay] = useState<
    string | undefined
  >();
  const [hasError, setHasError] = useState<boolean>(false);
  const [msgError, setMsgError] = useState<string>('');
  const [showAccountDeletionEmailSent, setShowAccountDeletionEmailSent] =
    useState<boolean>(false);
  const {
    isSuccess: isSuccessSendAccountDeletionEmail,
    isError: isErrorSendAccountDeletionEmail,
    isLoading: isLoadingSendAccountDeletionEmail,
    mutate: sendAccountDeletionEmailMutation,
  } = useSendAccountDeletionEmail();
  const themeGlobal = useTheme();

  useEffect(() => {
    if (isEmailInputFocused) {
      if (forceEmailErrorDisplay) setForceEmailErrorDisplay(undefined);
    }
  }, [isEmailInputFocused]);

  useEffect(() => {
    if (isErrorSendAccountDeletionEmail) {
      setHasError(true);
      setMsgError(props.t('deleteAccount.couldNotSendAccountDeletionEmail'));
    }
  }, [isErrorSendAccountDeletionEmail]);

  useEffect(() => {
    if (isSuccessSendAccountDeletionEmail) {
      setShowAccountDeletionEmailSent(true);
    }
  }, [isSuccessSendAccountDeletionEmail]);

  const isDeleteAccountButtonEnabled = () => {
    if (isEmailValid) return true;
    return false;
  };

  const deleteAccountActionInvoked = () => {
    if (!isDeleteAccountButtonEnabled()) {
      if (valueEmpty(email))
        setForceEmailErrorDisplay(props.t('generic.requiredField'));
    } else {
      sendAccountDeletionEmailMutation({ email });
    }
  };

  return (
    <S.Container>
      <S.Navbar>
        <S.NavbarInnerContainer>
          <S.NavbarAnchorTag>
            <S.NavbarImage
              src={SkorrLogo}
              onClick={() => window.open('https://www.skorr.social', '_blank')}
            />
            <S.NavbarImageSmallWindow
              src={SkorrLogoSmallWindow}
              onClick={() => window.open('https://www.skorr.social', '_blank')}
            />
          </S.NavbarAnchorTag>
        </S.NavbarInnerContainer>
      </S.Navbar>

      <S.FormSectionContainer>
        <S.FormOuterContainer>
          <S.FormContainer>
            {!showAccountDeletionEmailSent ? (
              <S.FormContainerFirstHalf>
                <S.FormContainerFirstHalfInnerContainer>
                  <S.Title>
                    {props.t('accountDeletionAuthenticationless.title')}
                  </S.Title>

                  <S.PageDescriptionContainer>
                    <S.PageDescription>
                      {props.t('accountDeletionAuthenticationless.description')}
                    </S.PageDescription>
                  </S.PageDescriptionContainer>

                  <S.FormInputContainer>
                    <InputControlV2
                      value={email}
                      onChange={(inputValue: string) => setEmail(inputValue)}
                      setIsInvalidOrProcessing={(
                        isInvalidOrProcessing: boolean,
                      ) => setIsEmailValid(!isInvalidOrProcessing)}
                      validationFunction={emailValid}
                      verifyEmailAvailability={true}
                      emailBeingInUseNecessary={true}
                      title={props.t('generic.email')}
                      setFocused={focused => setIsEmailInputFocused(focused)}
                      setBlurred={blurred => setIsEmailInputFocused(!blurred)}
                      forceErrorDisplay={forceEmailErrorDisplay}
                      validateOnFirstRender={true}
                      focusOnMount={true}
                      allowGreenBorder={true}
                      allowCheckMarkIcon={true}
                      invalidFieldWarningMessage={props.t(
                        'generic.invalidEmail',
                      )}
                    />
                  </S.FormInputContainer>

                  <Button
                    borderRadius={'0.6rem'}
                    borderWidth={'0rem'}
                    height={'4rem'}
                    fontSize={'1.6rem'}
                    display={'block'}
                    width={'100%'}
                    textAlign={'center'}
                    marginTop={'4.7rem'}
                    marginBottom={'0rem'}
                    paddingTop={'0.1rem'}
                    paddingBottom={'0.1rem'}
                    paddingLeft={'0.6rem'}
                    paddingRight={'0.6rem'}
                    fontWeight={'500'}
                    cursorOnDisable={'default'}
                    blockHoverStylesOnDisable={true}
                    pointerEventsOnDisable={'all'}
                    disabledNotNative={!isDeleteAccountButtonEnabled()}
                    onClick={() => deleteAccountActionInvoked()}
                  >
                    {props.t(
                      'accountDeletionAuthenticationless.sendConfirmationEmail',
                    )}
                  </Button>
                </S.FormContainerFirstHalfInnerContainer>
              </S.FormContainerFirstHalf>
            ) : (
              <S.EmailSentSuccessfullyPageContainer>
                <S.EmailSentSuccessfullyImageContainer
                  src={EmailSentSuccessfullyImage}
                />
                <S.EmailSentSuccessfullyHeaderMessage>
                  {props.t(
                    'accountDeletionAuthenticationless.emailSentSuccessfullyHeaderMessage',
                  )}
                </S.EmailSentSuccessfullyHeaderMessage>
                <S.EmailSentSuccessfullyDescriptionMessage>
                  {props.t(
                    'accountDeletionAuthenticationless.emailSentSuccessfullyDescriptionMessage',
                  )}
                </S.EmailSentSuccessfullyDescriptionMessage>
              </S.EmailSentSuccessfullyPageContainer>
            )}
          </S.FormContainer>
        </S.FormOuterContainer>
      </S.FormSectionContainer>

      <NotifyCustom
        location={'bottom'}
        show={isLoadingSendAccountDeletionEmail}
        themeStyles={(themeGlobal as any).signin['regular-notify']}
      >
        <S.NotifyInnerContainer>
          <InputSpinnerIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
          />
          {props.t(
            'accountDeletionAuthenticationless.sendingAccountDeletionCodeEmail',
          )}
        </S.NotifyInnerContainer>
      </NotifyCustom>

      <GenericModal
        show={hasError}
        title={props.t('generic.uhoh')}
        body={msgError}
        primaryButtonText={'Close'}
        primaryButtonAction={() => setHasError(false)}
      />
    </S.Container>
  );
};

export default withTranslation()(DeleteAccount);
