import { AxiosInstance } from 'axios';

import config from '../../../config';
import * as T from './types';

const createExporterInstanceApi = (instance: AxiosInstance) => {
  const exportPDF = (params: T.IExportPDFParams) =>
    instance.post(config.ENDPOINTS.EXPORTER.EXPORT_PDF, params, {
      headers: {
        'Content-Type': 'application/json',
      },
      responseType: 'arraybuffer',
    });

  return {
    exportPDF,
  };
};

export default {
  createExporterInstanceApi,
};
