/* eslint-disable consistent-return */
import styled, { css } from 'styled-components';
import { TiTimes } from 'react-icons/ti';

import { IFilePickerContainer, IUploadImageIconContainerProps } from './types';

export const FilePickerContainer = styled.div<IFilePickerContainer>`
  margin-top: ${props => props.marginTop};
  margin-bottom: ${props => props.marginBottom};
  margin-left: ${props => props.marginLeft};
  margin-right: ${props => props.marginRight};
  padding-top: ${props => props.paddingTop};
  padding-bottom: ${props => props.paddingBottom};
  padding-left: ${props => props.paddingLeft};
  padding-right: ${props => props.paddingRight};
`;

export const FileInputText = styled.span`
  margin-left: 6px;
`;

interface FileInputLabelProps {
  htmlFor: any;
  disabled: boolean;
  borderRadius?: string;
  paddingLeft?: string;
  paddingRight?: string;
  height?: string;
  customButton?: any;
}

export const FileInputLabel = styled.label<FileInputLabelProps>`
  position: relative;
  display: flex;
  align-items: center;

  ${props => {
    if (!props.customButton) {
      return css`
        cursor: pointer;
        background-color: ${props =>
          props.theme.filePicker['background-color']};
        border-color: ${props => props.theme.filePicker['border-color']};
        color: ${props => props.theme.filePicker.color};
        font-weight: ${props => props.theme.filePicker['font-weight']};
        font-family: ${props => props.theme.filePicker['font-family']};
        font-size: ${props => props.theme.filePicker['font-size']};
        padding-left: ${props.paddingLeft ?? '2rem'};
        padding-right: ${props.paddingRight ?? '2rem'};
        pointer-events: ${props.disabled ? 'none' : 'all'};
        height: ${props.height ?? props.theme.filePicker.height};
        border-radius: ${props.borderRadius ??
        props.theme.filePicker['border-radius']};
      `;
    }
  }}

  &:hover {
    background-color: ${props =>
      props.theme.filePicker['background-color-hover']};
    border-color: ${props => props.theme.filePicker['border-color-hover']};
  }
`;

interface FileInputProps {
  id: any;
  disabled?: boolean;
  ref?: any;
}

export const FileInput = styled.input<FileInputProps>`
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
  display: none;

  &:focus {
    outline: 0;
  }
`;

interface FileNamesContainerProps {}

export const FileNamesContainer = styled.div<FileNamesContainerProps>`
  display: inline-block;
  position: absolute;
  left: 6px;
  top: calc(100% + 1px);
  width: 150%;
  max-height: 42px;
  overflow-y: auto;
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: ${props => props.theme.filePicker['scrollbar-width']};
    height: ${props => props.theme.filePicker['scrollbar-height']};
  }
  &::-webkit-scrollbar-track {
    background: ${props =>
      props.theme.filePicker['scrollbar-track-background-color']};
    border-radius: ${props =>
      props.theme.filePicker['scrollbar-track-border-radius']};
  }
  &::-webkit-scrollbar-corner {
    background: ${props => props.theme.filePicker['scrollbar-corner']};
  }
  &::-webkit-scrollbar-thumb {
    background: ${props =>
      props.theme.filePicker['scrollbar-thumb-background-color']};
    border-radius: ${props =>
      props.theme.filePicker['scrollbar-thumb-border-radius']};
  }
  &::-webkit-scrollbar-thumb:hover {
    background: ${props => props.theme.filePicker['scrollbar-thumb-hover']};
  }
  scrollbar-color: ${props =>
      props.theme.container['scrollbar-thumb-background-color']}
    ${props => props.theme.container['scrollbar-track-background-color']};
  scrollbar-width: thin;
`;

export const FilePickerButtonOuterContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FilePickerButtonContainer = styled.div`
  display: inline-block;
  position: relative;
`;

export const FileNameContainer = styled.span`
  display: inline-block;
  margin-right: 5px;
`;

interface FileNameInnerContainerProps {}

export const FileNameInnerContainer = styled.span<FileNameInnerContainerProps>`
  font-size: ${props => props.theme.filePicker['file-selected-font-size']};
  font-weight: ${props => props.theme.filePicker['file-selected-font-weight']};
  font-family: ${props => props.theme.filePicker['file-selected-font-family']};
  color: ${props => props.theme.filePicker['file-selected-color']};
  display: flex;
  align-items: center;
`;

interface CrossContainerProps {}

export const CrossContainer = styled.span<CrossContainerProps>`
  margin-left: 2px;
  font-size: ${props => props.theme.filePicker['cross-font-size']};
  font-weight: ${props => props.theme.filePicker['cross-font-weight']};
  font-family: ${props => props.theme.filePicker['cross-font-family']};
  color: ${props => props.theme.filePicker['cross-color']};
  & > * {
    cursor: pointer;
  }
`;

interface ISingleImageContainerProps {
  width?: string;
  height?: string;
}

export const SingleImageContainer = styled.div<ISingleImageContainerProps>`
  width: ${props => (props.width ? props.width : '16.8rem')};
  height: ${props => (props.height ? props.height : '13.8rem')};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const SingleImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

export const SingleImageCrossContainer = styled.div`
  position: absolute;
  top: 0.3rem;
  right: 0.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1.7rem;
`;

export const EmptyPictureContainer = styled.div`
  background-color: ${props =>
    props.theme['additional-data-step'][
      'empty-picture-container-background-color'
    ]};
  border-radius: 100%;
  width: 16.8rem;
  height: 13.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SingleImageCross = styled(TiTimes)``;

export const UploadImageIconContainer = styled.div<IUploadImageIconContainerProps>`
  display: inline-flex;
  align-items: center;
  margin-right: ${props => props.marginRight ?? '0'};

  & svg path {
    fill: ${props => props.iconColor ?? '#ffffff'};
  }
`;
