import { AxiosInstance } from 'axios';

import config from '../../../config';
import * as T from './types';

const createFilesInstanceApi = (instance: AxiosInstance) => {
  const uploadImage = (imageData: FormData) =>
    instance.post(config.ENDPOINTS.FILES.CREATE_CLOUD_URL, imageData);

  return {
    uploadImage,
  };
};

export default {
  createFilesInstanceApi,
};
