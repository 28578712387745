import styled from 'styled-components';

import {
  IOuterContainerProps,
  ICheckBoxContainerProps,
  ICheckBoxInnerContainerProps,
  ILabelProps,
} from './types';

export const OuterContainer = styled.div<IOuterContainerProps>`
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '0')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '0')};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '0')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '0')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
  display: ${props => (props.display ? props.display : 'block')};
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'unset'};
  align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
  cursor: pointer;
  white-space: nowrap;
`;

export const CheckBoxContainer = styled.div<ICheckBoxContainerProps>`
  width: ${props => (props.width ? props.width : '3.2rem')};
  height: ${props => (props.height ? props.height : '3.2rem')};
`;

export const CheckBoxInnerContainer = styled.div<ICheckBoxInnerContainerProps>`
  height: 100%;
  border: solid ${props => props.borderWidth ?? '0.32rem'};
  border-color: ${props => {
    if (props.checked) return props.theme.checkBox['border-color-checked'];
    return props.theme.checkBox['border-color'];
  }};
  border-radius: ${props =>
    props.borderRadius ? props.borderRadius : '0.32rem'};
  transition: background-color 140ms, border-color 140ms;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props =>
    props.checked ? props.theme.checkBox['background-color-checked'] : 'unset'};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : 0)};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : 0)};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : 0)};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : 0)};
`;

export const Label = styled.label<ILabelProps>`
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '2.56rem')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '0')};
  color: ${props => props.theme.text['color-27']};
  cursor: pointer;
`;
