/* eslint-disable consistent-return */
/* eslint-disable default-case */
import styled, { css } from 'styled-components';

import { IInnerContainerProps, IUserDemographicColProps } from './types';

export const OuterContainer = styled.div`
  padding: 1rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  @media (max-width: 1150px) {
    max-width: 100%;
    margin: 0;
  }
`;

export const Title = styled.h2`
  font-size: 2.4rem;
  margin-bottom: 2.6rem;
  margin-left: 1.2rem;
  margin-top: 1.2rem;
  font-weight: 700;
`;

export const TitleSmall = styled.small`
  font-size: 80%;
  font-weight: 400;
`;

export const InnerContainer = styled.div<IInnerContainerProps>`
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  width: 100%;

  ${props => {
    switch (props.graphCount) {
      case 2:
      case 1:
        return css`
          justify-content: center;
        `;
    }
  }}

  @media (max-width: 786px) {
    justify-content: center;
  }

  @media (max-width: 520px) {
    margin-left: 0;
    margin-right: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const UserDemographicCol = styled.div<IUserDemographicColProps>`
  position: static;
  padding-right: 1rem;
  padding-left: 1rem;
  overflow-y: hidden;
  overflow-x: hidden;
  max-width: 100%;

  ${props => {
    switch (props.graphCount) {
      case 7:
      case 6:
      case 5:
      case 4:
        return css`
          flex: 0 0 25%;
          @media (max-width: 1528px) {
            flex: 0 0 33.33%;
          }
        `;
      case 3:
      case 2:
        return css`
          flex: 0 0 33.33%;
        `;
      case 1:
        return css`
          flex: 0 0 33%;
        `;
    }
  }}

  @media (max-width: 1175px) {
    flex: 0 0 50%;
  }

  @media (max-width: 786px) {
    min-width: 39rem;
    padding-left: 0;
    padding-right: 0;
  }

  @media (max-width: 520px) {
    flex: 0 0 100%;
    min-width: unset;
  }
`;

export const UserDemographicCard = styled.div`
  height: 97%;
  background-color: #fff;
  padding: 2rem;
  border-radius: 2rem;
  box-shadow: rgba(216, 221, 230, 0.35) 0px 0.1rem 0px 0.1rem;
`;
