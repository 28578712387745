/* eslint-disable default-case */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable func-names */
import * as S from './Styles';
import Globe from '../../../../assets/globe.png';
import InstagramPro from '../../../../assets/instagram_business.png';
import Instagram from '../../../../assets/instagram.png';
import Twitter from '../../../../assets/twitter.png';
import FacebookPages from '../../../../assets/facebook_page.png';
import Facebook from '../../../../assets/facebook.png';

export const roundTo = (v: number) => {
  if (v !== 0 && v < 1) {
    const places = -Math.floor(Math.log(Math.abs(v)) / Math.log(10) + 1) + 2;
    return Math.round((v + Number.EPSILON) * 10 ** places) / 10 ** places;
  }
  return Math.round((v + Number.EPSILON) * 100) / 100;
};

export const equalsArray = (a: any, b: any) => {
  if (a.length !== b.length) {
    return false;
  }

  const seen: any = {};
  a.forEach(function (v: any) {
    const key = typeof v + v;
    if (!seen[key]) {
      seen[key] = 0;
    }
    seen[key] += 1;
  });

  return b.every(function (v: any) {
    const key = typeof v + v;
    if (seen[key]) {
      seen[key] -= 1;
      return true;
    }
  });
};

export const chunkArray = function (array: any, n: number) {
  const R = [];
  for (let i = 0, len = array.length; i < len; i += n)
    R.push(array.slice(i, i + n));
  return R;
};

export const renderSocialNetworkList = (
  data: any,
  key: any,
  networkSelected?: any,
) => {
  if (data[key] === undefined || data[key].length <= 0) {
    return;
  }

  const elemsNet: any = [];

  data[key].map((el: any, i: any) => {
    if (networkSelected && networkSelected.selected === true) {
      if (el === networkSelected.network[i]) {
        switch (el) {
          case 'FACEBOOKPAGE':
            elemsNet.push(
              <S.NetworkList>
                <S.SocialNetworkContainer size={true} network="facebook-pages">
                  <S.SocialNetworkImage
                    size={true}
                    src={FacebookPages}
                    alt=""
                  />
                </S.SocialNetworkContainer>
              </S.NetworkList>,
            );
            break;
          case 'FACEBOOK':
            elemsNet.push(
              <S.NetworkList>
                <S.SocialNetworkContainer size={true} network="facebook">
                  <S.SocialNetworkImage size={true} src={Facebook} alt="" />
                </S.SocialNetworkContainer>
              </S.NetworkList>,
            );
            break;
          case 'TWITTER':
            elemsNet.push(
              <S.NetworkList>
                <S.SocialNetworkContainer size={true} network="twitter">
                  <S.SocialNetworkImage size={true} src={Twitter} alt="" />
                </S.SocialNetworkContainer>
              </S.NetworkList>,
            );
            break;
          case 'INSTAGRAM':
            elemsNet.push(
              <S.NetworkList>
                <S.SocialNetworkContainer size={true} network="instagram">
                  <S.SocialNetworkImage size={true} src={Instagram} alt="" />
                </S.SocialNetworkContainer>
              </S.NetworkList>,
            );
            break;
          case 'FACEBOOKPAGEINSTAGRAM':
            elemsNet.push(
              <S.NetworkList>
                <S.SocialNetworkContainer size={true} network="instagram-pro">
                  <S.SocialNetworkImage size={true} src={InstagramPro} alt="" />
                </S.SocialNetworkContainer>
              </S.NetworkList>,
            );
            break;
        }
      }
    } else if (
      data &&
      data.kpisPerSocialNetwork &&
      el === Object.keys(data.kpisPerSocialNetwork)[0]
    ) {
      switch (el) {
        case 'FACEBOOKPAGE':
          elemsNet.push(
            <S.NetworkList>
              <S.SocialNetworkContainer network="facebook-pages">
                <S.SocialNetworkImage src={FacebookPages} alt="" />
              </S.SocialNetworkContainer>
            </S.NetworkList>,
          );
          break;
        case 'FACEBOOK':
          elemsNet.push(
            <S.NetworkList>
              <S.SocialNetworkContainer network="facebook">
                <S.SocialNetworkImage src={Facebook} alt="" />
              </S.SocialNetworkContainer>
            </S.NetworkList>,
          );
          break;
        case 'TWITTER':
          elemsNet.push(
            <S.NetworkList>
              <S.SocialNetworkContainer network="twitter">
                <S.SocialNetworkImage src={Twitter} alt="" />
              </S.SocialNetworkContainer>
            </S.NetworkList>,
          );
          break;
        case 'INSTAGRAM':
          elemsNet.push(
            <S.NetworkList>
              <S.SocialNetworkContainer network="instagram">
                <S.SocialNetworkImage src={Instagram} alt="" />
              </S.SocialNetworkContainer>
            </S.NetworkList>,
          );
          break;
        case 'FACEBOOKPAGEINSTAGRAM':
          elemsNet.push(
            <S.NetworkList>
              <S.SocialNetworkContainer network="instagram-pro">
                <S.SocialNetworkImage src={InstagramPro} alt="" />
              </S.SocialNetworkContainer>
            </S.NetworkList>,
          );
          break;
      }
    } else if (networkSelected !== undefined) {
      switch (el) {
        case 'FACEBOOKPAGE':
          elemsNet.push(
            <S.NetworkList>
              <S.SocialNetworkContainer network="facebook-pages">
                <S.SocialNetworkImage src={FacebookPages} alt="" />
              </S.SocialNetworkContainer>
            </S.NetworkList>,
          );
          break;
        case 'FACEBOOK':
          elemsNet.push(
            <S.NetworkList>
              <S.SocialNetworkContainer network="facebook">
                <S.SocialNetworkImage src={Facebook} alt="" />
              </S.SocialNetworkContainer>
            </S.NetworkList>,
          );
          break;
        case 'TWITTER':
          elemsNet.push(
            <S.NetworkList>
              <S.SocialNetworkContainer network="twitter">
                <S.SocialNetworkImage src={Twitter} alt="" />
              </S.SocialNetworkContainer>
            </S.NetworkList>,
          );
          break;
        case 'INSTAGRAM':
          elemsNet.push(
            <S.NetworkList>
              <S.SocialNetworkContainer network="instagram">
                <S.SocialNetworkImage src={Instagram} alt="" />
              </S.SocialNetworkContainer>
            </S.NetworkList>,
          );
          break;
        case 'FACEBOOKPAGEINSTAGRAM':
          elemsNet.push(
            <S.NetworkList>
              <S.SocialNetworkContainer network="instagram-pro">
                <S.SocialNetworkImage src={InstagramPro} alt="" />
              </S.SocialNetworkContainer>
            </S.NetworkList>,
          );
          break;
      }
    } else if (el) {
      switch (el) {
        case 'FACEBOOKPAGE':
          elemsNet.push(
            <S.NetworkList>
              <S.SocialNetworkContainer network="facebook-pages">
                <S.SocialNetworkImage src={FacebookPages} alt="" />
              </S.SocialNetworkContainer>
            </S.NetworkList>,
          );
          break;
        case 'FACEBOOK':
          elemsNet.push(
            <S.NetworkList>
              <S.SocialNetworkContainer network="facebook">
                <S.SocialNetworkImage src={Facebook} alt="" />
              </S.SocialNetworkContainer>
            </S.NetworkList>,
          );
          break;
        case 'TWITTER':
          elemsNet.push(
            <S.NetworkList>
              <S.SocialNetworkContainer network="twitter">
                <S.SocialNetworkImage src={Twitter} alt="" />
              </S.SocialNetworkContainer>
            </S.NetworkList>,
          );
          break;
        case 'INSTAGRAM':
          elemsNet.push(
            <S.NetworkList>
              <S.SocialNetworkContainer network="instagram">
                <S.SocialNetworkImage src={Instagram} alt="" />
              </S.SocialNetworkContainer>
            </S.NetworkList>,
          );
          break;
        case 'FACEBOOKPAGEINSTAGRAM':
          elemsNet.push(
            <S.NetworkList>
              <S.SocialNetworkContainer network="instagram-pro">
                <S.SocialNetworkImage src={InstagramPro} alt="" />
              </S.SocialNetworkContainer>
            </S.NetworkList>,
          );
          break;
      }
    }
  });
  return elemsNet;
};

export const renderSocialNetworkGrid = (
  data: any,
  key: any,
  bigSize?: boolean,
) => {
  if (data[key] === undefined || data[key].length <= 0) {
    return;
  }
  switch (data[key]) {
    case 'FACEBOOKPAGE':
      return (
        <S.SocialNetworkContainer size={bigSize} network="facebook-pages">
          <S.SocialNetworkImage size={bigSize} src={FacebookPages} alt="" />
        </S.SocialNetworkContainer>
      );
    case 'FACEBOOK':
      return (
        <S.SocialNetworkContainer size={bigSize} network="facebook">
          <S.SocialNetworkImage size={bigSize} src={Facebook} alt="" />
        </S.SocialNetworkContainer>
      );
    case 'TWITTER':
      return (
        <S.SocialNetworkContainer size={bigSize} network="twitter">
          <S.SocialNetworkImage size={bigSize} src={Twitter} alt="" />
        </S.SocialNetworkContainer>
      );
    case 'INSTAGRAM':
      return (
        <S.SocialNetworkContainer size={bigSize} network="instagram">
          <S.SocialNetworkImage size={bigSize} src={Instagram} alt="" />
        </S.SocialNetworkContainer>
      );
    case 'FACEBOOKPAGEINSTAGRAM':
      return (
        <S.SocialNetworkContainer size={bigSize} network="instagram-pro">
          <S.SocialNetworkImage size={bigSize} src={InstagramPro} alt="" />
        </S.SocialNetworkContainer>
      );
  }
};

export const renderSocialNetworkIcon = (key: string, bigSize?: boolean) => {
  if (key === undefined) {
    return;
  }
  switch (key) {
    case 'FACEBOOKPAGE':
      return (
        <S.SocialNetworkContainer size={bigSize} network="facebook-pages">
          <S.SocialNetworkImage size={bigSize} src={FacebookPages} alt="" />
        </S.SocialNetworkContainer>
      );
    case 'FACEBOOK':
      return (
        <S.SocialNetworkContainer size={bigSize} network="facebook">
          <S.SocialNetworkImage size={bigSize} src={Facebook} alt="" />
        </S.SocialNetworkContainer>
      );
    case 'TWITTER':
      return (
        <S.SocialNetworkContainer size={bigSize} network="twitter">
          <S.SocialNetworkImage size={bigSize} src={Twitter} alt="" />
        </S.SocialNetworkContainer>
      );
    case 'INSTAGRAM':
      return (
        <S.SocialNetworkContainer size={bigSize} network="instagram">
          <S.SocialNetworkImage size={bigSize} src={Instagram} alt="" />
        </S.SocialNetworkContainer>
      );
    case 'FACEBOOKPAGEINSTAGRAM':
      return (
        <S.SocialNetworkContainer size={bigSize} network="instagram-pro">
          <S.SocialNetworkImage size={bigSize} src={InstagramPro} alt="" />
        </S.SocialNetworkContainer>
      );
  }
};

export const SocialNetworkOptionsArray = [
  {
    value: 'FACEBOOKPAGEINSTAGRAM',
    label: (
      <S.SocialNetworkMainContainer>
        <S.SocialNetworkContainer network="instagram-pro">
          <S.SocialNetworkImage src={InstagramPro} alt="" />
        </S.SocialNetworkContainer>
        <S.SocialNetworkLabel>
          <span>Instagram Business</span>
        </S.SocialNetworkLabel>
      </S.SocialNetworkMainContainer>
    ),
  },
  {
    value: 'FACEBOOKPAGE',
    label: (
      <S.SocialNetworkMainContainer>
        <S.SocialNetworkContainer network="facebook-pages">
          <S.SocialNetworkImage src={FacebookPages} alt="" />
        </S.SocialNetworkContainer>
        <S.SocialNetworkLabel>
          <span>Facebook Page</span>
        </S.SocialNetworkLabel>
      </S.SocialNetworkMainContainer>
    ),
  },
  {
    value: 'FACEBOOK',
    label: (
      <S.SocialNetworkMainContainer>
        <S.SocialNetworkContainer network="facebook">
          <S.SocialNetworkImage src={Facebook} alt="" />
        </S.SocialNetworkContainer>
        <S.SocialNetworkLabel>
          <span>Facebook</span>
        </S.SocialNetworkLabel>
      </S.SocialNetworkMainContainer>
    ),
  },
  {
    value: 'INSTAGRAM',
    label: (
      <S.SocialNetworkMainContainer>
        <S.SocialNetworkContainer network="instagram">
          <S.SocialNetworkImage src={Instagram} alt="" />
        </S.SocialNetworkContainer>
        <S.SocialNetworkLabel>
          <span>Instagram</span>
        </S.SocialNetworkLabel>
      </S.SocialNetworkMainContainer>
    ),
  },
  {
    value: 'TWITTER',
    label: (
      <S.SocialNetworkMainContainer>
        <S.SocialNetworkContainer network="twitter">
          <S.SocialNetworkImage src={Twitter} alt="" />
        </S.SocialNetworkContainer>
        <S.SocialNetworkLabel>
          <span>Twitter</span>
        </S.SocialNetworkLabel>
      </S.SocialNetworkMainContainer>
    ),
  },
];

export const SocialNetworkShortVersionOptionsArray = [
  {
    value: 'FACEBOOKPAGEINSTAGRAM',
    label: (
      <S.SocialNetworkMainContainer>
        <S.SocialNetworkContainer network="instagram-pro">
          <S.SocialNetworkImage src={InstagramPro} alt="" />
        </S.SocialNetworkContainer>
        <S.SocialNetworkLabel>
          <span>Instagram Business</span>
        </S.SocialNetworkLabel>
      </S.SocialNetworkMainContainer>
    ),
  },
  {
    value: 'FACEBOOKPAGE',
    label: (
      <S.SocialNetworkMainContainer>
        <S.SocialNetworkContainer network="facebook-pages">
          <S.SocialNetworkImage src={FacebookPages} alt="" />
        </S.SocialNetworkContainer>
        <S.SocialNetworkLabel>
          <span>Facebook Page</span>
        </S.SocialNetworkLabel>
      </S.SocialNetworkMainContainer>
    ),
  },
  {
    value: 'TWITTER',
    label: (
      <S.SocialNetworkMainContainer>
        <S.SocialNetworkContainer network="twitter">
          <S.SocialNetworkImage src={Twitter} alt="" />
        </S.SocialNetworkContainer>
        <S.SocialNetworkLabel>
          <span>Twitter</span>
        </S.SocialNetworkLabel>
      </S.SocialNetworkMainContainer>
    ),
  },
];

export const InstagramBusinessTwitterOptionsArray = [
  {
    value: 'FACEBOOKPAGEINSTAGRAM',
    label: (
      <S.SocialNetworkMainContainer>
        <S.SocialNetworkContainer network="instagram-pro">
          <S.SocialNetworkImage src={InstagramPro} alt="" />
        </S.SocialNetworkContainer>
        <S.SocialNetworkLabel>
          <span>Instagram Business</span>
        </S.SocialNetworkLabel>
      </S.SocialNetworkMainContainer>
    ),
  },
  {
    value: 'TWITTER',
    label: (
      <S.SocialNetworkMainContainer>
        <S.SocialNetworkContainer network="twitter">
          <S.SocialNetworkImage src={Twitter} alt="" />
        </S.SocialNetworkContainer>
        <S.SocialNetworkLabel>
          <span>Twitter</span>
        </S.SocialNetworkLabel>
      </S.SocialNetworkMainContainer>
    ),
  },
];

export const SocialNetworkIconsOptionsArray = [
  {
    value: 'FACEBOOKPAGEINSTAGRAM',
    label: (
      <S.SocialNetworkContainer network="instagram-pro">
        <S.SocialNetworkImage src={InstagramPro} alt="" />
      </S.SocialNetworkContainer>
    ),
  },
  {
    value: 'FACEBOOKPAGE',
    label: (
      <S.SocialNetworkContainer network="facebook-pages">
        <S.SocialNetworkImage src={FacebookPages} alt="" />
      </S.SocialNetworkContainer>
    ),
  },
  {
    value: 'FACEBOOK',
    label: (
      <S.SocialNetworkContainer network="facebook">
        <S.SocialNetworkImage src={Facebook} alt="" />
      </S.SocialNetworkContainer>
    ),
  },
  {
    value: 'INSTAGRAM',
    label: (
      <S.SocialNetworkContainer network="instagram">
        <S.SocialNetworkImage src={Instagram} alt="" />
      </S.SocialNetworkContainer>
    ),
  },
  {
    value: 'TWITTER',
    label: (
      <S.SocialNetworkContainer network="twitter">
        <S.SocialNetworkImage src={Twitter} alt="" />
      </S.SocialNetworkContainer>
    ),
  },
];

export const GenderOptionsArray = [
  {
    value: 'MALE',
    label: (
      <S.SocialNetworkMainContainer>
        <S.SocialNetworkContainer>
          <S.MaleIcon />
        </S.SocialNetworkContainer>
        <S.SocialNetworkLabel>
          <span>Male</span>
        </S.SocialNetworkLabel>
      </S.SocialNetworkMainContainer>
    ),
  },
  {
    value: 'FEMALE',
    label: (
      <S.SocialNetworkMainContainer>
        <S.SocialNetworkContainer>
          <S.FemaleIcon />
        </S.SocialNetworkContainer>
        <S.SocialNetworkLabel>
          <span>Female</span>
        </S.SocialNetworkLabel>
      </S.SocialNetworkMainContainer>
    ),
  },
  {
    value: 'OTHER',
    label: (
      <S.SocialNetworkMainContainer>
        <S.SocialNetworkContainer>
          <S.OtherIcon />
        </S.SocialNetworkContainer>
        <S.SocialNetworkLabel>
          <span>Other</span>
        </S.SocialNetworkLabel>
      </S.SocialNetworkMainContainer>
    ),
  },
];

export const CategoriesOptionsArray = [
  { value: 'BRAND', label: 'Brand' },
  { value: 'BLOG', label: 'Blog' },
  { value: 'INFLUENCER', label: 'Influencer' },
  { value: 'SOCIALMEDIA', label: 'Media' },
];

const socialNetworks: any = {
  FACEBOOKPAGEINSTAGRAM: {
    sn: 'FACEBOOKPAGEINSTAGRAM',
    label: 'socialNetworks.FACEBOOKPAGEINSTAGRAM',
    icon: InstagramPro,
    color: 'instagram-pro',
    chartColor: '#7232BD',
  },
  INSTAGRAM: {
    sn: 'INSTAGRAM',
    label: 'socialNetworks.INSTAGRAM',
    icon: Instagram,
    color: 'instagram',
    chartColor: '#C32AA3',
  },
  FACEBOOKPAGE: {
    sn: 'FACEBOOKPAGE',
    label: 'socialNetworks.FACEBOOKPAGE',
    icon: FacebookPages,
    color: 'facebook-pages',
    chartColor: '#F48226',
  },
  FACEBOOK: {
    sn: 'FACEBOOK',
    label: 'socialNetworks.FACEBOOK',
    icon: Facebook,
    color: 'facebook',
    chartColor: '#1877F2',
  },
  TWITTER: {
    sn: 'TWITTER',
    label: 'socialNetworks.TWITTER',
    icon: Twitter,
    color: 'twitter',
    chartColor: '#1DA1F2',
  },
  GLOBAL: {
    sn: 'GLOBAL',
    label: 'socialNetworks.allNetworks',
    icon: Globe,
    color: 'globe',
    chartColor: '#14B56F',
  },
};

export const getSocialNetworkConfig = (socialnetwork: any) => {
  return socialNetworks[socialnetwork];
};

export const getListSocialNetworkConfig = (listSocialNetwork: any) => {
  const listSocialNetworkConfigs: any = [];
  listSocialNetwork.forEach((element: any) => {
    const socialNetworkConfig = socialNetworks[element];
    if (socialNetworkConfig) listSocialNetworkConfigs.push(socialNetworkConfig);
  });
  return listSocialNetworkConfigs;
};
