/* eslint-disable import/extensions */
import { useTheme } from 'styled-components';
import { useState, useEffect } from 'react';

import SVG from './Styles';

interface ICheckBoxCheckMarkIconProps {
  width?: string;
  height?: string;
  color?: string;
}

const CheckBoxCheckMarkIcon = (props: ICheckBoxCheckMarkIconProps) => {
  const themeGlobal = useTheme();
  const [svgColor, setSvgColor] = useState<string | undefined>();

  useEffect(() => {
    if (props.color) setSvgColor(props.color);
    else {
      setSvgColor((themeGlobal as any).checkBox['check-mark-icon-color']);
    }
  }, [props.color]);

  return (
    <SVG
      viewBox="0 0 32 32"
      width={props.width ? props.width : '100%'}
      height={props.height ? props.height : '100%'}
    >
      <path
        fill={
          props.color
            ? props.color
            : (themeGlobal as any).checkBox['check-mark-icon-color']
        }
        d="M11.905 26.668l-10.238-10.238 2.887-2.887 7.351 7.33 15.541-15.541 2.887 2.908-18.428 18.429z"
      />
    </SVG>
  );
};

export default CheckBoxCheckMarkIcon;
