/* eslint-disable import/extensions */
import { useTheme } from 'styled-components';

import SVG from './Styles';

const InputPasswordEyeIcon = () => {
  const themeGlobal = useTheme();
  const svgColor = (themeGlobal as any).inputFieldV2['password-eye-icon-color'];

  return (
    <SVG viewBox="0 0 32 32" width="100%" height="100%">
      <path
        fill={svgColor}
        d="M29.888 15.526c-0.204-0.408-5.1-9.996-13.888-9.996s-13.684 9.588-13.888 9.996c-0.149 0.298-0.149 0.649 0 0.947 0.204 0.408 5.1 9.997 13.888 9.997s13.684-9.588 13.888-9.996c0.149-0.299 0.149-0.649 0-0.947zM16 24.352c-6.452 0-10.673-6.518-11.732-8.352 1.059-1.834 5.28-8.352 11.732-8.352 6.454 0 10.674 6.521 11.733 8.354-1.055 1.834-5.261 8.351-11.733 8.351zM15.999 11.412c-2.53 0-4.588 2.058-4.588 4.588s2.058 4.589 4.588 4.589 4.588-2.058 4.588-4.589c0-2.53-2.058-4.588-4.588-4.588zM15.999 18.471c-1.362 0-2.47-1.108-2.47-2.471s1.108-2.47 2.47-2.47 2.47 1.108 2.47 2.47c0 1.363-1.108 2.471-2.47 2.471z"
      />
    </SVG>
  );
};

export default InputPasswordEyeIcon;
