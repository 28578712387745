import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InnerContainer = styled.div`
  width: 40%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 2rem;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
`;

export const CrossIconContainer = styled.div`
  border-radius: 100%;
  padding: 1rem;
  background-color: red;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
`;

export const FirstTitle = styled.div`
  font-weight: 500;
  font-size: 1.6rem;
  margin-bottom: 0.5rem;
`;

export const SecondTitle = styled.div`
  font-weight: 500;
  font-size: 1.6rem;
  margin-bottom: 2rem;
`;
