/* eslint-disable import/extensions */
/* eslint-disable eqeqeq */
import * as S from './Styles';
import { IToggleButtonProps } from './types';

const ToggleButton = (props: IToggleButtonProps) => {
  return (
    <S.Container
      marginTop={props.marginTop}
      marginBottom={props.marginBottom}
      marginLeft={props.marginLeft}
      marginRight={props.marginRight}
      marginBlockStart={props.marginBlockStart}
      paddingTop={props.paddingTop}
      paddingBottom={props.paddingBottom}
      paddingLeft={props.paddingLeft}
      paddingRight={props.paddingRight}
      width={props.width}
      height={props.height}
      display={props.display}
      justifyContent={props.justifyContent}
      alignItems={props.alignItems}
      cursor={props.toggleContainerCursor}
      onClick={() => props.setChecked(!props.checked)}
      position={props.position}
    >
      {props.label && props.labelPosition != 'right' && (
        <S.LabelContainer
          checked={props.checked}
          marginTop={props.labelMarginTop}
          marginBottom={props.labelMarginBottom}
          marginLeft={props.labelMarginLeft}
          marginRight={props.labelMarginRight}
          paddingTop={props.labelPaddingTop}
          paddingBottom={props.labelPaddingBottom}
          paddingLeft={props.labelPaddingLeft}
          paddingRight={props.labelPaddingRight}
          highlightLabelOnChecked={props.highlightLabelOnChecked}
        >
          {props.label}
        </S.LabelContainer>
      )}

      <S.ToggleButtonOuterContainer
        paddingTop={props.toggleButtonOuterContainerPaddingTop}
        paddingBottom={props.toggleButtonOuterContainerPaddingBottom}
        paddingLeft={props.toggleButtonOuterContainerPaddingLeft}
        paddingRight={props.toggleButtonOuterContainerPaddingRight}
        cursor={props.toggleButtonCursor}
      >
        <S.ToggleButtonContainer
          marginTop={props.toggleButtonContainerMarginTop}
          marginBottom={props.toggleButtonContainerMarginBottom}
          marginLeft={props.toggleButtonContainerMarginLeft}
          marginRight={props.toggleButtonContainerMarginRight}
        >
          <S.ToggleButtonCircle checked={props.checked} />
          <S.ToggleButtonTrack checked={props.checked} />
        </S.ToggleButtonContainer>
      </S.ToggleButtonOuterContainer>

      {props.label && props.labelPosition == 'right' && (
        <S.LabelContainer
          checked={props.checked}
          marginTop={props.labelMarginTop}
          marginBottom={props.labelMarginBottom}
          marginLeft={props.labelMarginLeft}
          marginRight={props.labelMarginRight}
          paddingTop={props.labelPaddingTop}
          paddingBottom={props.labelPaddingBottom}
          paddingLeft={props.labelPaddingLeft}
          paddingRight={props.labelPaddingRight}
          highlightLabelOnChecked={props.highlightLabelOnChecked}
        >
          {props.label}
        </S.LabelContainer>
      )}
    </S.Container>
  );
};

export default ToggleButton;
