/* eslint-disable import/extensions */
import { useMutation } from 'react-query';

import SkorrApi from '../../Services/SkorrApi/new';
import { logApiRequestError, jwtParser } from '../../Services/Utils/Utils';
import { IErrorObject, ISignUpPayload } from '../../Services/Utils/types';
import { ISigninData } from '../SignIn/types';

const { user } = SkorrApi;

export const useSignup = () => {
  return useMutation(
    async (params: ISignUpPayload) => {
      try {
        const { data }: { data: ISigninData } = await user.signup(params);
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      onSuccess: (data: ISigninData | undefined) => {
        jwtParser(data as ISigninData);
      },
      retry: false,
    },
  );
};
