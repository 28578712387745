/* eslint-disable import/extensions */
/* eslint-disable eqeqeq */
import * as S from './Styles';
import { ICheckBoxProps } from './types';
import CheckBoxCheckMarkIcon from '../../../assets/iconComponents/CheckBoxCheckMarkIcon';

const CheckBox = (props: ICheckBoxProps) => {
  return (
    <S.OuterContainer
      paddingTop={props.paddingTop}
      paddingBottom={props.paddingBottom}
      paddingLeft={props.paddingLeft}
      paddingRight={props.paddingRight}
      marginTop={props.marginTop}
      marginBottom={props.marginBottom}
      marginLeft={props.marginLeft}
      marginRight={props.marginRight}
      display={props.display}
      width={props.width}
      height={props.height}
      justifyContent={props.justifyContent}
      alignItems={props.alignItems}
      onClick={() => props.onChange(!props.checked)}
    >
      {props.label && props.labelPosition != 'right' && (
        <S.Label
          paddingLeft={props.labelPaddingLeft}
          paddingRight={props.labelPaddingRight}
        >
          {props.label}
        </S.Label>
      )}

      <S.CheckBoxContainer
        width={props.checkBoxContainerWidth}
        height={props.checkBoxContainerHeight}
      >
        <S.CheckBoxInnerContainer
          checked={props.checked}
          borderWidth={props.borderWidth}
          borderRadius={props.borderRadius}
          paddingTop={props.checkboxPaddingTop}
          paddingBottom={props.checkboxPaddingBottom}
          paddingLeft={props.checkboxPaddingLeft}
          paddingRight={props.checkboxPaddingRight}
        >
          <CheckBoxCheckMarkIcon
            color={props.checked ? undefined : 'transparent'}
            width={props.width}
            height={props.height}
          />
        </S.CheckBoxInnerContainer>
      </S.CheckBoxContainer>

      {props.label && props.labelPosition == 'right' && (
        <S.Label
          paddingLeft={props.labelPaddingLeft}
          paddingRight={props.labelPaddingRight}
        >
          {props.label}
        </S.Label>
      )}
    </S.OuterContainer>
  );
};

export default CheckBox;
