/* eslint-disable consistent-return */
/* eslint-disable import/extensions */
import styled, { css } from 'styled-components';

import {
  IToggleButtonCircleProps,
  IToggleButtonTrackProps,
  IToggleButtonContainerProps,
  IContainerProps,
  ILabelContainerProps,
  IToggleButtonOuterContainerProps,
} from './types';

export const Container = styled.div<IContainerProps>`
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
  ${props => {
    if (props.marginBlockStart) {
      return css`
        margin-block-start: ${props.marginBlockStart};
      `;
    }
  }};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '0')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '0')};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '0')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '0')};
  width: ${props => (props.width ? props.width : '100%')};
  height: ${props => (props.height ? props.height : '100%')};
  cursor: ${props => props.cursor ?? 'pointer'};
  display: ${props => (props.display ? props.display : 'flex')};
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : 'space-between'};
  align-items: ${props => (props.alignItems ? props.alignItems : 'stretch')};
  position: ${props => props.position ?? 'static'};
`;

export const ToggleButtonOuterContainer = styled.div<IToggleButtonOuterContainerProps>`
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '0')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '0')};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '0')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '0')};
  border: 0;
  outline: 0;
  display: flex;
  align-items: center;
  cursor: ${props => props.cursor ?? 'inherit'};
`;

export const ToggleButtonContainer = styled.div<IToggleButtonContainerProps>`
  display: inline-block;
  position: relative;
  width: 3.6rem;
  height: 1.4rem;
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
`;

export const ToggleButtonCircle = styled.div<IToggleButtonCircleProps>`
  position: absolute;
  top: -0.3rem;
  left: 0;
  right: auto;
  transition: transform linear 0.08s, background-color linear 0.08s;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  z-index: 2;
  box-shadow: 0 0.1rem 0.5rem 0
    ${props => props.theme['toggle-button']['circle-box-shadow-color']};
  background-color: ${props => {
    if (props.checked)
      return props.theme['toggle-button']['circle-background-color-checked'];
    return props.theme['toggle-button']['circle-background-color'];
  }};
  ${props => {
    if (props.checked) {
      return css`
        transform: translate(1.6rem, 0);
      `;
    }
  }};
`;

export const ToggleButtonTrack = styled.div<IToggleButtonTrackProps>`
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 0.8rem;
  pointer-events: none;
  transition: background-color linear 0.08s;
  background-color: ${props => {
    if (props.checked)
      return props.theme['toggle-button']['track-background-color-checked'];
    return props.theme['toggle-button']['track-background-color'];
  }};
`;

export const LabelContainer = styled.div<ILabelContainerProps>`
  color: ${props => {
    if (props.checked && props.highlightLabelOnChecked)
      return props.theme.text['color-26'];
    return props.theme.text['color-27'];
  }};
  user-select: none;
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '0')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '0')};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '0')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '0')};
  font-size: 2.56rem;
`;
