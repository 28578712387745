import styled from 'styled-components';

export const OuterContainer = styled.div`
  padding: 1rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`;

export const Title = styled.div`
  font-size: 2.4rem;
  margin-bottom: 2.6rem;
  margin-left: 1.2rem;
  margin-top: 1.2rem;
  font-weight: 700;
`;

export const PostsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
  margin-right: -1rem;

  @media (max-width: 1150px) {
    max-width: 100%;
    margin: 0;
  }
`;
