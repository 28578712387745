/* eslint-disable no-restricted-globals */
/* eslint-disable import/extensions */
import { withTranslation } from 'react-i18next';

import * as S from './Styles';
import CrossIcon_V2 from '../assets/iconComponents/CrossIcon_V2';
import Button from '../components/shared/Button/Button';

interface IErrorFallback {
  t: (arg: string) => string;
  error: any;
  resetErrorBoundary: any;
}

const ErrorFallback = (props: IErrorFallback) => {
  return (
    <S.Container>
      <S.InnerContainer>
        <S.CrossIconContainer>
          <CrossIcon_V2
            svgHeight="4rem"
            svgWidth="4rem"
            useCase="errorBoundary"
          />
        </S.CrossIconContainer>

        <S.FirstTitle>{props.t('errorBoundary.oups')}</S.FirstTitle>

        <S.SecondTitle>{props.t('errorBoundary.title')}</S.SecondTitle>

        {/* <pre>{props.error.message}</pre> */}

        <Button
          borderRadius={'0.6rem'}
          borderWidth={'0rem'}
          height={'4rem'}
          fontSize={'1.6rem'}
          display={'block'}
          textAlign={'center'}
          marginBlock={'2.4rem'}
          marginTop={'1rem'}
          fontWeight={'500'}
          onClick={() => history.go(0)}
        >
          {props.t('errorBoundary.button')}
        </Button>
      </S.InnerContainer>
    </S.Container>
  );
};

export default withTranslation()(ErrorFallback);
