/* eslint-disable import/extensions */
import { useQuery, useMutation } from 'react-query';

import SkorrApi from '../../Services/SkorrApi/new';
import { logApiRequestError } from '../../Services/Utils/Utils';
import { IErrorObject, ISignUpPayload } from '../../Services/Utils/types';

const { auth } = SkorrApi;

export const useSendAccountActivationCodeEmail = (
  setHasError: (hasError: boolean) => any,
  setMsgError: (errorMsg: string) => any,
  t: (path: string) => string,
) => {
  return useMutation(async () => {
    try {
      const { data }: { data: boolean } =
        await auth.sendAccountActivationCodeEmail();
      return data;
    } catch (error) {
      setHasError(true);
      if ((error as any).response!.data.message.includes('Thirty seconds')) {
        setMsgError(t('signin.generic.accountActivationCodeEmailTimeoutError'));
      } else if (
        (error as any).response!.data.message.includes('maximum ammount')
      ) {
        setMsgError(t('signin.generic.accountActivationCodeEmailCapError'));
      } else {
        setMsgError(t('signin.generic.accountActivationCodeCouldNotBeSent'));
      }
      logApiRequestError(error as IErrorObject);
      throw error;
    }
  });
};

export const useVerifyAccountActivationCode = () => {
  return useMutation(async ({ code }: { code: string }) => {
    try {
      const { data }: { data: boolean } =
        await auth.verifyAccountActivationCode(code);
      return data;
    } catch (error) {
      logApiRequestError(error as IErrorObject);
      throw error;
    }
  });
};

export const useCertifyActivationCodeEmailSent = () => {
  return useMutation(async (email: string) => {
    try {
      await auth.certifyActivationCodeEmailSent(email);
      return true;
    } catch (error) {
      logApiRequestError(error as IErrorObject);
      throw error;
    }
  });
};
