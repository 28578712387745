import styled from 'styled-components';

export const EvolutionPerformanceMainContainer = styled.div`
  position: static;
  padding-right: 1rem;
  padding-left: 1rem;
  width: auto;
`;

export const EvolutionPerformanceContainer = styled.div.attrs(() => ({
  className: '--evolution-performance-container',
}))`
  margin-right: -1rem;
  margin-left: -1rem;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 1510px) {
    margin: 0;
  }

  @media (max-width: 1510px) {
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
  }
`;

export const ChartPerformanceContainer = styled.div`
  position: static;
  padding: 0 1rem;
  max-width: 50%;
  width: 100%;

  @media (max-width: 1510px) {
    max-width: 100%;
    padding: 0;
  }
`;
