/* eslint-disable import/extensions */
import styled from 'styled-components';

import SVG from './Styles';

interface IPortfolioHorizontalLayoutTypeIconProps {
  containerWidth?: string;
  containerHeight?: string;
  containerPaddingTop?: string;
  containerPaddingBottom?: string;
  containerPaddingLeft?: string;
  containerPaddingRight?: string;
  containerMarginTop?: string;
  containerMarginBottom?: string;
  containerMarginLeft?: string;
  containerMarginRight?: string;
  svgWidth?: string;
  svgHeight?: string;
  cursor?: string;
  onClick?: any;
  id?: string;
}

interface IContainerProps {
  width?: string;
  height?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
}

const Container = styled.div<IContainerProps>`
  border-width: 0;
  outline: 0;
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '0')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '0')};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '0')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '0')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
  display: inline-flex;
  align-items: center;
`;

const PortfolioHorizontalLayoutTypeIcon = (
  props: IPortfolioHorizontalLayoutTypeIconProps,
) => {
  return (
    <Container
      width={props.containerWidth}
      height={props.containerHeight}
      paddingTop={props.containerPaddingTop}
      paddingBottom={props.containerPaddingBottom}
      paddingLeft={props.containerPaddingLeft}
      paddingRight={props.containerPaddingRight}
      marginTop={props.containerMarginTop}
      marginBottom={props.containerMarginBottom}
      marginLeft={props.containerMarginLeft}
      marginRight={props.containerMarginRight}
      id={props.id}
    >
      <SVG
        viewBox="0 0 80 120"
        width={props.svgWidth ? props.svgWidth : '100%'}
        height={props.svgHeight ? props.svgHeight : '100%'}
        cursor={props.cursor}
      >
        <g clip-path="url(#clip0_5440_25932)">
          <path
            d="M70.3615 0H9.63855C4.31533 0 0 4.31533 0 9.63857V109.88C0 115.203 4.31533 119.518 9.63855 119.518H70.3615C75.6847 119.518 80 115.203 80 109.88V9.63857C80 4.31533 75.6847 0 70.3615 0Z"
            fill="#D9DEE6"
          />
          <path d="M80 17.3494H0V60.7229H80V17.3494Z" fill="#EFF1F6" />
          <path
            d="M28.9157 23.6145C28.9157 17.2266 23.7373 12.0482 17.3495 12.0482C10.9616 12.0482 5.7832 17.2266 5.7832 23.6145C5.7832 30.0024 10.9616 35.1808 17.3495 35.1808C23.7373 35.1808 28.9157 30.0024 28.9157 23.6145Z"
            fill="#EFF1F6"
          />
          <path
            d="M26.988 23.6145C26.988 18.2913 22.6727 13.976 17.3495 13.976C12.0263 13.976 7.71094 18.2913 7.71094 23.6145C7.71094 28.9378 12.0263 33.2531 17.3495 33.2531C22.6727 33.2531 26.988 28.9378 26.988 23.6145Z"
            fill="#D9DEE6"
          />
          <path
            d="M56.385 21.2048H33.7344C32.6697 21.2048 31.8066 22.0679 31.8066 23.1325C31.8066 24.1972 32.6697 25.0603 33.7344 25.0603H56.385C57.4496 25.0603 58.3127 24.1972 58.3127 23.1325C58.3127 22.0679 57.4496 21.2048 56.385 21.2048Z"
            fill="#D9DEE6"
          />
          <path
            d="M34.2175 39.5181H10.1211C9.05642 39.5181 8.19336 40.3811 8.19336 41.4458C8.19336 42.5104 9.05642 43.3735 10.1211 43.3735H34.2175C35.2821 43.3735 36.1452 42.5104 36.1452 41.4458C36.1452 40.3811 35.2821 39.5181 34.2175 39.5181Z"
            fill="#D9DEE6"
          />
          <path
            d="M34.2175 45.3012H10.1211C9.05642 45.3012 8.19336 46.1643 8.19336 47.2289C8.19336 48.2936 9.05642 49.1566 10.1211 49.1566H34.2175C35.2821 49.1566 36.1452 48.2936 36.1452 47.2289C36.1452 46.1643 35.2821 45.3012 34.2175 45.3012Z"
            fill="#D9DEE6"
          />
          <path
            d="M50.6015 44.3374C50.6015 42.2081 48.8753 40.4819 46.746 40.4819C44.6168 40.4819 42.8906 42.2081 42.8906 44.3374C42.8906 46.4667 44.6168 48.1928 46.746 48.1928C48.8753 48.1928 50.6015 46.4667 50.6015 44.3374Z"
            fill="#D9DEE6"
          />
          <path
            d="M61.205 44.3374C61.205 42.2081 59.4789 40.4819 57.3496 40.4819C55.2203 40.4819 53.4941 42.2081 53.4941 44.3374C53.4941 46.4667 55.2203 48.1928 57.3496 48.1928C59.4789 48.1928 61.205 46.4667 61.205 44.3374Z"
            fill="#D9DEE6"
          />
          <path
            d="M71.8065 44.3374C71.8065 42.2081 70.0804 40.4819 67.9511 40.4819C65.8218 40.4819 64.0957 42.2081 64.0957 44.3374C64.0957 46.4667 65.8218 48.1928 67.9511 48.1928C70.0804 48.1928 71.8065 46.4667 71.8065 44.3374Z"
            fill="#D9DEE6"
          />
          <path
            d="M7.71094 60.7229C7.71094 59.1258 9.00554 57.8314 10.6025 57.8314H14.9399C16.5368 57.8314 17.8314 59.1258 17.8314 60.7229H7.71094Z"
            fill="#D9DEE6"
          />
          <path
            d="M7.71094 72.2891L7.71094 86.747C7.71094 88.8763 9.43707 90.6024 11.5664 90.6024H68.4338C70.5631 90.6024 72.2892 88.8763 72.2892 86.747V72.2891C72.2892 70.1598 70.5631 68.4337 68.4338 68.4337H11.5664C9.43707 68.4337 7.71094 70.1598 7.71094 72.2891Z"
            fill="#EFF1F6"
          />
          <path
            d="M61.6862 74.6989H37.5898C36.5252 74.6989 35.6621 75.562 35.6621 76.6266C35.6621 77.6913 36.5252 78.5543 37.5898 78.5543H61.6862C62.7509 78.5543 63.6139 77.6913 63.6139 76.6266C63.6139 75.562 62.7509 74.6989 61.6862 74.6989Z"
            fill="#D9DEE6"
          />
          <path
            d="M61.6862 80.4821H37.5898C36.5252 80.4821 35.6621 81.3451 35.6621 82.4098C35.6621 83.4744 36.5252 84.3375 37.5898 84.3375H61.6862C62.7509 84.3375 63.6139 83.4744 63.6139 82.4098C63.6139 81.3451 62.7509 80.4821 61.6862 80.4821Z"
            fill="#D9DEE6"
          />
          <path
            d="M26.5057 79.5181C26.5057 75.7919 23.485 72.7711 19.7587 72.7711C16.0324 72.7711 13.0117 75.7919 13.0117 79.5181C13.0117 83.2444 16.0324 86.2651 19.7587 86.2651C23.485 86.2651 26.5057 83.2444 26.5057 79.5181Z"
            fill="#D9DEE6"
          />
          <path
            d="M7.71094 97.3494L7.71094 107.952C7.71094 110.081 9.43707 111.807 11.5664 111.807H46.2652C48.3944 111.807 50.1206 110.081 50.1206 107.952V97.3494C50.1206 95.2201 48.3944 93.494 46.2652 93.494H11.5664C9.43707 93.494 7.71094 95.2201 7.71094 97.3494Z"
            fill="#EFF1F6"
          />
          <path
            d="M53.0117 97.3494V107.952C53.0117 110.081 54.7378 111.807 56.8671 111.807H68.4334C70.5627 111.807 72.2888 110.081 72.2888 107.952V97.3494C72.2888 95.2201 70.5627 93.494 68.4334 93.494H56.8671C54.7378 93.494 53.0117 95.2201 53.0117 97.3494Z"
            fill="#EFF1F6"
          />
        </g>
        <defs>
          <clipPath id="clip0_5440_25932">
            <rect width="80" height="119.518" fill="white" />
          </clipPath>
        </defs>
      </SVG>
    </Container>
  );
};

export default PortfolioHorizontalLayoutTypeIcon;
