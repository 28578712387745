/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import styled, { css } from 'styled-components';
import { UncontrolledTooltip } from 'reactstrap';

import {
  EditConfigurationTooltipProps,
  GearIconContainerProps,
  PortfolioPublicUserImageProps,
  SectionContainerProps,
  SocialAccountCardContainerProps,
} from './types';

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: start;
  padding-left: 0.8rem;
`;

export const InnerContainer = styled.div`
  width: 80%;
  border-radius: 2.4rem;
  height: calc(100vh - 10.5rem);
  background-color: ${props =>
    props.theme['additional-data-step']['background-color']};
  box-shadow: ${props =>
    `0rem 1.6rem 2.4rem ${props.theme['additional-data-step']['box-shadow-color-1']}, 0rem 0.6rem 3rem ${props.theme['additional-data-step']['box-shadow-color-2']}, 0rem 0.8rem 1rem ${props.theme['additional-data-step']['box-shadow-color-3']}`};
  margin: 2rem 0;

  @media (max-width: 1150px) {
    width: 98%;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: calc(100vh - 5.7rem - 4.4rem);
  }
`;

export const Card = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const HeaderOuterContainer = styled.div`
  height: 7.7rem;
  margin-bottom: 2rem;
  border-bottom: 0.1rem solid
    ${props => props.theme.signin['signin-content-divider-color']};
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  padding: 0 3.2rem;
  font-size: 2rem;
  font-weight: 600;
  color: ${props => props.theme.text['color-26']};

  @media (max-width: 600px) {
    padding: 0 1.6rem;
  }
`;

export const HeaderBackArrowContainer = styled.div`
  height: 4.2rem;
  width: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  transition: all 0.08s;
  opacity: 1;
  border-radius: 0.8rem;
  cursor: pointer;
  & path {
    fill: ${props =>
      props.theme.signin['signin-forgot-password-back-arrow-color']};
  }

  &:hover {
    background-color: ${props =>
      props.theme.signin[
        'signin-forgot-password-back-arrow-container-background-color-hover'
      ]};
  }
`;

export const HeaderTitleContainer = styled.div`
  font-size: 2rem;
  font-weight: 600;
  color: ${props => props.theme.text['text-26']};
  margin: 0 auto;
  text-align: center;
`;

export const HiddenHeaderElement = styled.div`
  height: 4rem;
  width: 4rem;
  visibility: hidden;
`;

export const BenchmarkListItemCard = styled.div<SectionContainerProps>`
  padding: 0;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0.3rem 0 rgb(239 244 255 / 0%);
  transition: all 0.3s ease;
  border-radius: 2rem;
  background-color: #fff;
  margin: 0 0 2.5rem 0.8rem;
  flex-wrap: wrap;

  ${props => {
    if (props.isPortfolio) {
      return css`
        @media (max-width: 1240px) {
          margin-bottom: 2.5rem;
        }

        @media (max-width: 713px) {
          margin: 0 0 0 0.8rem;
        }

        @media (max-width: 555px) {
          justify-content: center;
          text-align: center;
        }
      `;
    }

    return css`
      @media (max-width: 713px) {
        margin: 0 0 0 0.8rem;
      }
    `;
  }}
`;

export const CardInnerContainer = styled.div`
  padding: 0 3.2rem;
  display: flex;
  flex-direction: column;
  height: calc(100% - 18.5rem);

  @media (max-width: 600px) {
    padding: 0 1.6rem;
  }
`;

export const BenchmarkListItemNetwork = styled.div`
  flex: 0 0 8rem;
  display: flex;
  align-items: center;
  padding-right: 1rem;
  padding-left: 1rem;
  min-width: 0;
  max-width: 100%;
  position: relative;
  width: 100%;
`;

export const BenchmarkListItemNetworkIcon = styled.img`
  width: 75%;
  vertical-align: middle;
  border-style: none;
`;

export const SocialAccountCardContainer = styled.div<SocialAccountCardContainerProps>`
  width: ${props => (props.isPortfolio ? '7.5rem' : '23rem')};
  height: auto;

  ${props => {
    if (props.isPortfolio) {
      return css`
        @media (max-width: 1240px) {
          flex-basis: 8rem;
          width: 15%;

          & * {
            display: flex;
            justify-content: center;
          }
        }

        @media (max-width: 815px) {
          flex-basis: 7.5rem;
          width: 15%;
        }

        @media (max-width: 713px) {
          flex-basis: 100%;

          & * {
            display: flex;
            justify-content: center;
          }
        }
      `;
    }

    return css`
      @media (max-width: 1480px) {
        flex-basis: 25rem;
        display: flex;
        justify-content: center;

        & * {
          width: auto !important;
        }
      }

      @media (max-width: 713px) {
        flex-basis: 100%;
        display: flex;
        justify-content: center;

        & * {
          width: auto !important;
        }
      }
    `;
  }}
`;

export const NameContainer = styled.div<SectionContainerProps>`
  width: 24rem;
  padding: 0 1rem;
  height: 7rem;
  display: flex;
  align-items: center;

  ${props => {
    if (props.isPortfolio) {
      return css`
        @media (max-width: 1240px) {
          width: 50%;
          text-align: center;
        }

        @media (max-width: 815px) {
          width: 50%;
        }

        @media (max-width: 713px) {
          flex-basis: 100%;
          text-align: center;
          padding: 0;
          height: auto;
          margin-top: 1rem;

          & h2:first-of-type {
            margin-bottom: 0;
          }
        }
      `;
    }

    return css`
      @media (max-width: 1480px) {
        flex-basis: 30rem;
        flex-grow: 1;
        text-align: center;
      }

      @media (max-width: 918px) {
        flex-basis: calc(100% - 26rem);
        flex-grow: 1;
      }

      @media (max-width: 713px) {
        flex-basis: 100%;
        text-align: center;
        padding: 0;
        margin-top: 1rem;
        height: auto;

        & h2:first-of-type {
          margin-bottom: 0;
        }
      }
    `;
  }}}
`;

export const CreatedAtContainer = styled.div<SectionContainerProps>`
  width: 12rem;
  padding: 0 1rem;
  height: 7rem;
  display: flex;
  align-items: center;

  ${props => {
    if (props.isPortfolio) {
      return css`
        @media (max-width: 1240px) {
          width: 35%;
          text-align: center;
        }

        @media (max-width: 815px) {
          width: 35%;
        }

        @media (max-width: 713px) {
          flex-basis: 100%;
          text-align: center;
          padding: 0;
          height: auto;
          margin-top: 1rem;

          & h2:first-of-type {
            margin-bottom: 0;
          }
        }
      `;
    }

    return css`
      @media (max-width: 1480px) {
        flex-basis: 25%;
        text-align: center;
      }

      @media (max-width: 918px) {
        flex-basis: 25rem;
        margin-top: 1rem;
      }

      @media (max-width: 713px) {
        flex-basis: 100%;
        text-align: center;
        padding: 0;
        margin-top: 1rem;
        height: auto;

        & h2:first-of-type {
          margin-bottom: 0;
        }
      }
    `;
  }}
`;

export const DurationContainer = styled.div<SectionContainerProps>`
  width: 25rem;
  padding: 0 1rem;
  height: 7rem;
  display: flex;
  align-items: center;

  @media (max-width: 1480px) {
    flex-basis: 25rem;
    width: 25%;
    text-align: center;
    margin-top: 1rem;
  }

  @media (max-width: 918px) {
    flex-basis: calc(100% - 26rem);
    flex-grow: 1;
  }

  @media (max-width: 713px) {
    flex-basis: 100%;
    text-align: center;
    padding: 0;
    margin-top: 1rem;
    height: auto;

    & h2:first-of-type {
      margin-bottom: 0;
    }
  }
`;

export const PortfolioPublicUserImagesOuterContainer = styled.div<SectionContainerProps>`
  width: 22rem;
  display: flex;
  align-items: center;
  padding: 0.4rem 3rem 0 3rem;

  @media (max-width: 1240px) {
    width: 50%;
    margin-top: 1rem;
    padding: 0.4rem 6rem 0 6rem;

    & > div:first-child > div:first-child {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  @media (max-width: 1150px) {
    padding: 0.4rem 7rem 0 7rem;
  }

  @media (max-width: 900px) {
    padding: 0.4rem 5rem 0 5rem;
  }

  @media (max-width: 713px) {
    flex-basis: 100%;
    padding: 0 8rem 0 8rem;

    & h2:first-of-type {
      margin-bottom: 0.4rem;
    }
  }

  @media (max-width: 500px) {
    padding: 0.4rem 3rem 0 3rem;
  }

  @media (max-width: 380px) {
    padding: 0.4rem 0 0 0;
  }
`;

export const ViewsContainer = styled.div<SectionContainerProps>`
  width: 6rem;
  padding: 0 1rem;
  height: 7rem;
  display: flex;
  align-items: center;

  ${props => {
    if (props.isPortfolio) {
      return css`
        @media (max-width: 1240px) {
          flex-basis: 8rem;
          width: 15%;
          flex-grow: 1;
          margin-top: 1rem;

          & > div {
            display: flex;
            justify-content: center;
          }
        }

        @media (max-width: 713px) {
          flex-basis: 100%;
          text-align: center;
          padding: 0;
          height: auto;

          & > div {
            display: flex;
            justify-content: center;
          }

          & h2:first-of-type {
            margin-bottom: 0;
          }
        }
      `;
    }

    return css`
      @media (max-width: 1480px) {
        flex-basis: 30rem;
        flex-grow: 1;
        margin-top: 1rem;

        & > div {
          display: flex;
          justify-content: center;
        }
      }

      @media (max-width: 918px) {
        flex-basis: 25rem;
        flex-grow: 0;
      }

      @media (max-width: 713px) {
        flex-basis: 100%;
        padding: 0;
        margin-top: 1rem;
        height: auto;

        & > *:first-child {
          display: flex;
          justify-content: center;
        }

        & h2:first-of-type {
          margin-bottom: 0;
        }
      }
    `;
  }}
`;

export const ActionsContainer = styled.div<SectionContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1rem;

  ${props => {
    if (props.isPortfolio) {
      return css`
        @media (max-width: 1240px) {
          width: 35%;
          align-items: center;
          margin-top: 1rem;
        }

        @media (max-width: 713px) {
          flex-basis: 100%;
          align-items: center;
          padding: 0;

          & h2:first-of-type {
            margin-bottom: 0.4rem;
          }
        }
      `;
    }

    return css`
      @media (max-width: 1480px) {
        flex-basis: 25%;
        align-items: center;
        margin-top: 1rem;
      }

      @media (max-width: 918px) {
        flex-basis: calc(100% - 26rem);
        flex-grow: 1;
      }

      @media (max-width: 713px) {
        flex-basis: 100%;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin-top: 0.6rem;

        & h2:first-of-type {
          margin-bottom: 0.4rem;
        }
      }
    `;
  }}
`;

export const ActionsInnerContainer = styled.div`
  display: flex;
`;

export const CopyConfigurationUrlContainer = styled.div`
  width: 3rem;
  margin-right: 1rem;
`;

export const EditConfigurationContainer = styled.div`
  width: 3rem;
  margin-right: 1rem;
`;

export const OpenContainer = styled.div`
  width: 3rem;
`;

export const BenchmarkListItemCol = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  height: auto;
  align-items: center;
`;

export const BenchmarkListItemNameContainer = styled.div`
  width: 100%;
`;

export const BenchmarkListItemTitle = styled.h2`
  font-size: 1.28rem;
  padding-top: 0;
  color: #8492a6;
  font-weight: 300;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 1rem;
`;

export const PortfolioPublicUserImagesTitle = styled(BenchmarkListItemTitle)`
  margin-bottom: 0.5rem;
`;

export const BenchmarkListItemLegend = styled.p`
  font-weight: 400;
  font-size: 1.6rem;
  text-overflow: unset;
  width: 100%;
  overflow-x: auto;
  margin-bottom: 0;
  margin-top: 0;
  white-space: nowrap;
  padding-bottom: 0.2rem;

  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
`;

export const BenchmarkListItemType = styled.div`
  width: 100%;
  position: relative;
`;

export const ViewsColumnInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

export const EyeIconContainer = styled.div<SectionContainerProps>`
  & svg {
    cursor: pointer;
  }

  ${props => {
    if (props.isPortfolio) {
      return css`
        @media (max-width: 555px) {
          margin-top: 0;
        }
      `;
    }

    return css`
      @media (max-width: 713px) {
        margin-top: 0;
      }
    `;
  }}
`;

export const SocialAccountContainer = styled.div`
  height: 7rem;
  width: 23rem;
  display: flex;
  border-radius: 1.5rem;
  padding: 0.5rem;
`;

export const SocialAccountLeftContainer = styled.div`
  height: 6rem;
  width: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const SocialAccountImage = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 50%;
  object-fit: cover;
`;

export const SocialAccountNetworkIconContainer = styled.div`
  padding: 0.2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  position: absolute;
  top: 60%;
  left: 60%;
  background-color: white;
`;

export const SocialAccountRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding-left: 0.8rem;
`;

export const SocialAccountUsername = styled.div`
  padding-bottom: 0.9rem;
  color: ${props => props.theme.text['color-26']};
  font-size: 1.5rem;
  font-weight: 600;
  width: 13rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

export const SocialAccountNetworkName = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.2rem;
  color: ${props => props.theme.text['color-27']};
  position: relative;
  display: inline-block;
  margin-top: 0.9rem;
  height: 1.4rem;
  user-select: none;
  max-width: 13rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ConfigurationFiltersOuterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
  flex-wrap: wrap;
`;

export const ConfigurationFiltersContainer = styled.div`
  width: 20rem;
  padding-left: 0.8rem;

  @media (max-width: 477px) {
    width: 100%;
    padding-right: 0.8rem;
  }
`;

export const ConfigurationTypeFilterContainer = styled.div`
  display: flex;
  & > button:first-of-type {
    margin-right: 1rem;
  }

  @media (max-width: 477px) {
    width: 100%;
    margin-top: 1rem;
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    justify-content: space-between;

    & > button:first-of-type {
      margin-right: 0;
    }

    & button {
      width: 48%;
    }
  }

  @media (max-width: 315px) {
    flex-wrap: wrap;

    & button {
      width: 100%;
    }

    & button:first-of-type {
      margin-bottom: 1rem;
    }
  }
`;

export const EmptyInfoContainer = styled.div`
  background-color: #fff;
  padding: 2rem;
  border-radius: 0.6rem;
  margin-bottom: 2rem;
  margin-top: 4.8rem;
  display: flex;
  justify-content: center;
`;

export const ConfigurationUsernameTooltip = styled(UncontrolledTooltip)`
  & > *:first-child {
    z-index: 1000;
  }
  opacity: 1 !important;
  pointer-events: none;

  .tooltip-inner {
    display: inline-block;
    width: auto !important;
    position: relative;
    color: #333;
    background-color: #fff;
    border: 0.1rem solid #eeeeee;
    border-radius: 0.5rem;
    padding: 1rem 1.5rem;
    margin: 0 0 1rem 0;
    filter: drop-shadow(rgba(0, 0, 0, 0.6) 0 0.2rem 0.3rem);
    top: 0.5rem;
  }

  .arrow {
    display: none !important;
  }
`;

export const EditConfigurationTooltip = styled(
  UncontrolledTooltip,
)<EditConfigurationTooltipProps>`
  & > *:first-child {
    z-index: 1000;
  }
  opacity: 1 !important;
  pointer-events: ${props => (props.autohide === false ? 'all' : 'none')};

  .tooltip-inner {
    display: inline-block;
    width: auto !important;
    position: relative;
    color: #333;
    background-color: #fff;
    border: 0.1rem solid #eeeeee;
    border-radius: 0.5rem;
    padding: 1rem 1.5rem;
    margin: 0 0 1rem 0;
    filter: drop-shadow(rgba(0, 0, 0, 0.6) 0 0.2rem 0.3rem);
    top: 0rem;
  }

  .arrow {
    display: none !important;
  }
`;

export const PortfolioPublicUserImagesContainer = styled.div`
  width: 100%;
  margin-bottom: 0;
  margin-top: 0;
  overflow-x: auto;
  display: flex;
  min-height: 3.5rem;

  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }

  @-moz-document url-prefix() {
    padding-bottom: 1rem;
  }

  @media (max-width: 1240px) {
    padding-right: 2rem;
  }

  @media (max-width: 555px) {
    padding-right: 0;
  }
`;

export const PortfolioPublicUserImage = styled.img<PortfolioPublicUserImageProps>`
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  border: 3px solid white;
  margin-left: ${props => (props.index ? -11 : 0)}px;
  margin-bottom: 0.2rem;
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: end;
  padding: 2rem 3.2rem;
  border-top: 0.1rem solid
    ${props => props.theme.signin['signin-content-divider-color']};
`;

export const ConfigurationListContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: auto;
  margin: 0 -3.2rem;
  padding: 0 3.2rem;
  position: relative;

  @media (max-width: 600px) {
    margin: 0;
    padding: 0;
  }

  @media (max-width: 713px) {
    & > div {
      padding-bottom: 1.25rem;
    }

    & > div:not(:first-child) {
      padding-top: 1.25rem;
      transition: none;
      border-radius: 0;
      border-top: 0.1rem solid
        ${props => props.theme.signin['signin-content-divider-color']};
    }
  }
`;

export const CreateMediaKitTypeContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const MediaKitTypeContainer = styled.div`
  display: flex;
  width: calc(50% - 0.2rem);
  cursor: pointer;
  padding: 2rem 1rem;
  border: 1px solid #ffffff;

  &:hover {
    background-color: rgb(247, 247, 247);
    border: 1px solid #f8f8f8;
    border-radius: 1.5rem;
  }

  @media (max-width: 1100px) {
    width: 100%;
  }

  @media (max-width: 438px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const MediaKitTypeDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  padding-left: 2rem;
  width: 70%;

  & > div {
    margin-bottom: 2rem;
  }

  @media (max-width: 438px) {
    text-align: center;
    align-items: center;
    padding-left: 0;
    overflow-x: auto;
  }
`;

export const MediaKitTypeTitle = styled.div`
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.6rem;
  color: #292929;
  display: inline-flex;
  align-items: center;

  @media (max-width: 438px) {
    width: 100%;
  }
`;

export const MediaKitTypeSubtitle = styled.div`
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.6rem;
  color: #292929;
`;

export const MediaKitTypeDescription = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.6rem;
  color: #676767;
`;

export const PortfolioTypeTitle = styled.div`
  display: flex;
`;

export const PortfolioTypeTitleNew = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #feb038;
  font-weight: 500;
  color: #f7f8fa;
  margin-left: 0.8rem;
  padding: 0 0.4rem;
  border-radius: 0.4rem;
  font-size: 1.7rem;
`;

export const CopiedToClipboard = styled.span`
  position: absolute;
  opacity: 0;
  bottom: -1.3rem;
  left: -8rem;
  padding: 1rem 0.5rem;
  background-color: #14b56f;
  color: #fff;
  font-size: 600;
  width: 8rem;
  text-align: center;
  border-radius: 0.5rem;
  filter: drop-shadow(rgba(0, 0, 0, 0.6) 0 0.2rem 0.3rem);

  transition: all 0.6s ease-out;
`;

export const CopyUrlIconContainer = styled.div`
  position: relative;
  font-weight: 400;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const GearIconContainer = styled.div<GearIconContainerProps>`
  ${props => {
    if (props.disabled) {
      return css`
        & svg {
          cursor: default;
          opacity: 0.5;
        }
      `;
    }
  }}
`;

export const UnavaiableConfigurationSettingsTooltipMessage = styled.div`
  display: flex;
  align-items: center;
  max-width: 31rem;
`;

export const UnavailableConfigurationTooltipRightContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > div:first-child {
    line-height: 2.1rem;
  }
`;

export const UnavailableConfigurationTooltipLinkContainer = styled.div`
  display: flex;
  margin-top: 0.5rem;
  align-items: center;
  font-weight: 400;
  cursor: pointer;
  width: 10.2rem;
  color: #5870f6;

  &:hover {
    text-decoration: underline;
  }
`;

export const UnavailableConfigurationTooltipInfoIconContainer = styled.div`
  & svg {
    margin-bottom: 2.7rem;
  }
`;
