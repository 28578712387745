/* eslint-disable import/extensions */
import styled, { useTheme } from 'styled-components';
import { useState, useEffect } from 'react';

import SVG from './Styles';

interface IUploadIconV1Props {
  containerWidth?: string;
  containerHeight?: string;
  containerPaddingTop?: string;
  containerPaddingBottom?: string;
  containerPaddingLeft?: string;
  containerPaddingRight?: string;
  containerMarginTop?: string;
  containerMarginBottom?: string;
  containerMarginLeft?: string;
  containerMarginRight?: string;
  svgWidth?: string;
  svgHeight?: string;
  rotate?: string;
  cursor?: string;
}

interface IContainerProps {
  width?: string;
  height?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
}

const Container = styled.div<IContainerProps>`
  border-width: 0;
  outline: 0;
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '0')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '0')};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '0')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '0')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const UploadIconV1 = (props: IUploadIconV1Props) => {
  const themeGlobal = useTheme();
  const [svgColor, setSvgColor] = useState<string>(() => {
    return (themeGlobal as any).icons.uploadIconV1;
  });

  useEffect(() => {
    setSvgColor((themeGlobal as any).icons.uploadIconV1);
  }, [(themeGlobal as any).darkMode]);

  return (
    <Container
      width={props.containerWidth}
      height={props.containerHeight}
      paddingTop={props.containerPaddingTop}
      paddingBottom={props.containerPaddingBottom}
      paddingLeft={props.containerPaddingLeft}
      paddingRight={props.containerPaddingRight}
      marginTop={props.containerMarginTop}
      marginBottom={props.containerMarginBottom}
      marginLeft={props.containerMarginLeft}
      marginRight={props.containerMarginRight}
    >
      <SVG
        viewBox="0 0 32 32"
        width={props.svgWidth ? props.svgWidth : '100%'}
        height={props.svgHeight ? props.svgHeight : '100%'}
        rotateNotNative={props.rotate}
        cursor={props.cursor}
      >
        <path
          fill={svgColor}
          d="M25 29h-18c-1.071 0-2.077-0.421-2.833-1.188-0.753-0.768-1.167-1.781-1.167-2.857v-5.327c0-0.553 0.447-1 1-1s1 0.447 1 1v5.327c0 0.549 0.209 1.064 0.592 1.453 0.376 0.381 0.877 0.592 1.408 0.592h18c0.524 0 1.037-0.217 1.408-0.593 0.381-0.387 0.592-0.903 0.592-1.451v-5.327c0-0.553 0.447-1 1-1s1 0.447 1 1v5.327c0 1.076-0.415 2.089-1.167 2.856-0.745 0.755-1.779 1.188-2.833 1.188zM16.041 1.007v0c0.119 0.005 0.232 0.031 0.335 0.072 0.023 0.009 0.045 0.020 0.067 0.031 0.001 0 0.001 0 0.001 0 0.001 0 0.001-0.001 0.004 0.001v0c0.091 0.045 0.176 0.104 0.252 0.177l6.852 6.587c0.399 0.383 0.411 1.016 0.028 1.415-0.381 0.399-1.017 0.407-1.415 0.028l-5.164-4.964v14.903c0 0.553-0.447 1-1 1s-1-0.447-1-1v-14.903l-5.164 4.964c-0.397 0.384-1.031 0.371-1.413-0.027-0.383-0.399-0.371-1.032 0.027-1.415l6.855-6.589c0.073-0.071 0.155-0.129 0.243-0.172v0c0.001 0 0.001-0.001 0.001-0.001h0.003c0.031-0.016 0.063-0.029 0.095-0.043 0.097-0.037 0.203-0.059 0.312-0.064h0.001c0.001 0 0.001 0 0.001 0 0.015 0 0.028 0 0.041 0v0 0c0.013 0 0.049-0.015 0.039 0z"
        ></path>
      </SVG>
    </Container>
  );
};

export default UploadIconV1;
