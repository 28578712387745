/* eslint-disable import/extensions */
import { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';

import SVG from './Styles';

interface ICopyIconProps {
  containerWidth?: string;
  containerHeight?: string;
  containerPaddingTop?: string;
  containerPaddingBottom?: string;
  containerPaddingLeft?: string;
  containerPaddingRight?: string;
  containerMarginTop?: string;
  containerMarginBottom?: string;
  containerMarginLeft?: string;
  containerMarginRight?: string;
  svgWidth?: string;
  svgHeight?: string;
  cursor?: string;
  onClick?: any;
  id?: string;
}

interface IContainerProps {
  width?: string;
  height?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
}

const Container = styled.div<IContainerProps>`
  border-width: 0;
  outline: 0;
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '0')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '0')};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '0')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '0')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
  display: inline-flex;
  align-items: center;
`;

const CopyIcon = (props: ICopyIconProps) => {
  const themeGlobal = useTheme();
  const [svgColor, setSvgColor] = useState<string>(() => {
    return (themeGlobal as any).icons.copyIconV1;
  });

  useEffect(() => {
    setSvgColor((themeGlobal as any).icons.copyIconV1);
  }, [(themeGlobal as any).darkMode]);

  return (
    <Container
      width={props.containerWidth}
      height={props.containerHeight}
      paddingTop={props.containerPaddingTop}
      paddingBottom={props.containerPaddingBottom}
      paddingLeft={props.containerPaddingLeft}
      paddingRight={props.containerPaddingRight}
      marginTop={props.containerMarginTop}
      marginBottom={props.containerMarginBottom}
      marginLeft={props.containerMarginLeft}
      marginRight={props.containerMarginRight}
      id={props.id}
    >
      <SVG
        viewBox="0 0 32 32"
        width={props.svgWidth ? props.svgWidth : '100%'}
        height={props.svgHeight ? props.svgHeight : '100%'}
        cursor={props.cursor}
      >
        <path
          fill={svgColor}
          d="M27.636 32h-13.091c-2.41 0-4.364-1.954-4.364-4.364v0-13.091c0-2.41 1.954-4.364 4.364-4.364v0h13.091c2.41 0 4.364 1.954 4.364 4.364v0 13.091c0 2.41-1.954 4.364-4.364 4.364v0zM14.545 13.091c-0.803 0-1.455 0.651-1.455 1.455v0 13.091c0 0.803 0.651 1.455 1.455 1.455v0h13.091c0.803 0 1.455-0.651 1.455-1.455v0-13.091c0-0.803-0.651-1.455-1.455-1.455v0zM7.273 20.364c0-0.803-0.651-1.455-1.455-1.455v0h-1.455c-0.803 0-1.455-0.651-1.455-1.455v0-13.091c0-0.803 0.651-1.455 1.455-1.455v0h13.091c0.803 0 1.455 0.651 1.455 1.455v0 1.455c0 0.803 0.651 1.455 1.455 1.455s1.455-0.651 1.455-1.455v0-1.455c0-2.41-1.954-4.364-4.364-4.364v0h-13.091c-2.41 0-4.364 1.954-4.364 4.364v0 13.091c0 2.41 1.954 4.364 4.364 4.364v0h1.455c0.803 0 1.455-0.651 1.455-1.455v0z"
        />
      </SVG>
    </Container>
  );
};

export default CopyIcon;
