/* eslint-disable import/extensions */
/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
/* eslint-disable no-return-assign */
import React from 'react';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';

import { roundTo } from '../Util/UserCardUtils/UserCardUtils';
import { convertNumberToHumanStringfiedFormat } from '../../../Services/Utils/Utils';
import * as S from './Styles';
import { IMetricsInputControlProps } from './types';

const formatter = new Intl.NumberFormat('pt-PT', { maximumFractionDigits: 1 });
const formatter_2_digits = new Intl.NumberFormat('pt-PT', {
  maximumFractionDigits: 2,
});
const formatter_3_digits = new Intl.NumberFormat('pt-PT', {
  maximumFractionDigits: 3,
});
const formatter_4_digits = new Intl.NumberFormat('pt-PT', {
  maximumFractionDigits: 4,
});

const MetricsInputControl: React.FC<IMetricsInputControlProps> = (
  props: IMetricsInputControlProps,
): any => {
  const handleChangeInput = (name: string) => (event: any) => {
    let v = event.target.value;

    if (props.fieldType === 'checkbox') {
      v = !(v === 'true' || v === '1' || v === true);
    }

    if (props.handleChange) {
      if (props.min) {
        const { min } = props;
        if (Number(min) > Number(event.target.value)) {
          if (props.notify !== false) {
            toast.warn(props.t('generic.invalidValue'));
          } else {
            props.handleChange({ inputInvalid: [name] });
          }
        } else {
          props.handleChange({ [name]: v });
        }
      } else {
        props.handleChange({ [name]: v });
      }
    }
  };

  const renderInputValue = () => {
    let v = props.value;
    if (
      props.fieldType === 'text' ||
      props.fieldType === 'email' ||
      props.fieldType === 'textarea' ||
      props.fieldType === 'password'
    ) {
      return v;
    }
    if (props.fieldType === 'money') {
      v = `${formatter.format(v)} pts`;
    } else if (props.fieldType === 'moneyeuro') {
      v = `€ ${formatter_2_digits.format(v)}`;
    } else if (props.fieldType === 'moneyptstoeuro') {
      if (props.cpr) {
        return (v = `€ ${formatter_3_digits.format(v / 10)}`);
      }
      v = `€ ${formatter_2_digits.format(v / 10)}`;
    } else if (props.fieldType === 'moneyptstoeuro_4') {
      return (v = `€ ${formatter_4_digits.format(v / 10)}`);
    } else if (props.fieldType === 'perc') {
      v = `${formatter.format(v)} %`;
    } else if (props.fieldType === 'pp') {
      v = `${formatter.format(v)}pp`;
    } else {
      v = normalizeNumber(v);
    }
    return v;
  };

  const normalizeNumber = (v: any) => {
    v = Number(v);
    if (props.roundNumber) {
      v = roundTo(v);
    }

    if (props.roundUp) {
      v = Math.round(v);
    }

    if (props.normalizeNumber) {
      v = formatter.format(v);
    }
    return v;
  };

  const renderFieldType = () => {
    if (
      props.fieldType === 'money' ||
      props.fieldType === 'moneyeuro' ||
      props.fieldType === 'avg' ||
      props.fieldType === 'moneyptstoeuro'
    ) {
      return 'number';
    }
    return props.fieldType;
  };

  const checked = () => {
    if (props.fieldType !== 'checkbox') return {};
    if (props.value || props.value === 'true') {
      return { checked: true };
    }
    return { checked: false };
  };

  const renderElem = () => {
    if (props.freeText) {
      return props.convertNumberToReadableString
        ? convertNumberToHumanStringfiedFormat(props.value, 2)
        : renderInputValue();
    }
    if (props.fieldType !== 'checkbox') {
      return (
        <div>
          <S.CustomInput
            width={props.width}
            margin={props.margin}
            {...checked()}
            {...props}
            type={renderFieldType()}
            disabled={props.disableControl}
            maxLength={props.maxLength}
            placeholder={props.placeholder}
            value={renderInputValue()}
            onChange={handleChangeInput(props.valueKey)}
            onKeyDown={event => {
              if (
                props.onEnterKeyPress() !== undefined &&
                event.keyCode === 13
              ) {
                props.onEnterKeyPress();
              }
            }}
          />
          {props.maxLength && (
            <span>
              <small>
                {renderInputValue() ? renderInputValue().length : 0}/
                {props.maxLength}
              </small>
            </span>
          )}
        </div>
      );
    }
    return (
      <input
        {...checked()}
        {...props}
        type={renderFieldType()}
        disabled={props.disableControl}
        maxLength={props.maxLength}
        placeholder={props.placeholder}
        value={renderInputValue()}
        onChange={handleChangeInput(props.valueKey)}
        style={{ display: 'none' }}
      />
    );
  };

  const autoFocusError = () => {
    return props.showError;
  };

  return [
    renderElem(),
    autoFocusError() && (
      <div>
        <S.FieldError>
          {props.errorMessage
            ? props.errorMessage
            : props.t('generic.mandatoryField')}
        </S.FieldError>
      </div>
    ),
  ];
};

export default withTranslation()(MetricsInputControl);
