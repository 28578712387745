/* eslint-disable import/extensions */
import Lottie from 'react-lottie-player';

import lottieJson from './lottie.json';
import { Container } from './Styles';
import { IInputSpinnerIconProps } from './types';

const InputSpinnerIcon = (props: IInputSpinnerIconProps) => {
  return (
    <Container
      width={props.containerWidth}
      height={props.containerHeight}
      display={props.containerDisplay}
      marginLeft={props.containerMarginLeft}
      marginRight={props.containerMarginRight}
    >
      <Lottie
        loop
        animationData={lottieJson}
        play
        style={{
          position: 'absolute',
          top: '0',
          right: '0',
          width: '100%',
          height: '100%',
        }}
      />
    </Container>
  );
};

export default InputSpinnerIcon;
