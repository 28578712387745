export default {
  ENDPOINTS: {
    SKORR_API_BASE_URL: process.env.REACT_APP_API_URL,

    AUTH: {
      SIGN_UP: 'public/v1/partner/signup',
      SIGN_IN: 'public/v1/authentication/signin',
      SELECT_BUSINESS_PROFILE:
        'private/v1/authentication/create-business-profile-token',
      DEACTIVATE_BUSINESS_PROFILE:
        'public/v1/authentication/deactivate-business-profile',
      GET_ROLES: 'private/v1/authorization/get-roles',
      UPDATE_ROLES: 'private/v1/authorization/update-roles',
      IS_OPERATOR_OR_PARTNER_ADMIN:
        'private/v1/authorization/is-operator-or-partner-admin',
      SEND_FORGOT_PASSWORD_CODE_EMAIL: 'public/v1/credentials/forgot-password',
      VERIFY_FORGOT_PASSWORD_CODE:
        'public/v1/credentials/verify-forgot-password-code',
      UPDATE_PASSWORD_WITH_CODE: 'public/v1/credentials/recover-password',
      IS_EMAIL_VERIFIED: 'private/v1/credentials/is-email-verified',
      VERIFY_ACCOUNT_ACTIVATION_CODE:
        'private/v1/credentials/verify-account-activation-code',
      SEND_ACCOUNT_ACTIVATION_CODE_EMAIL:
        'private/v1/credentials/send-account-activation-code-email',
      GET_TOKEN: 'private/v1/authentication/get-token',
      IS_EMAIL_IN_USE: '/public/v1/credentials/is-email-in-use',
      CERTIFY_ACTIVATION_CODE_EMAIL_SENT:
        '/private/v1/credentials/certify-activation-code-email-sent',
    },

    USER: {
      CREATE_BUSINESS_PROFILE: '/private/v1/business-profile/create',
      UPDATE_BUSINESS_PROFILE: '/private/v1/business-profile/update',
      DELETE_BUSINESS_PROFILE: '/private/v1/business-profile/delete-one-by-id',
      GET_BUSINESS_PROFILES:
        '/private/v1/business-profile/get-business-profiles-by-partner-id',
      GET_BUSINESS_PROFILE_BY_ID: '/private/v1/business-profile/get-by-id',
      GET_BUSINESS_PROFILE_BY_PARTNER_ID:
        '/private/v1/business-profile/get-business-profiles-by-partner-id',
      GET_ALREADY_EXISTING_SOCIAL_NETWORK_ACCOUNTS:
        'private/v1/user/get-already-existing-social-network-accounts',
      GET_SOCIAL_TOKENS_BY_USER_ID: '/private/v1/user/get-all-tokens',
      IS_ADDITIONAL_DATA_STEP_COMPLETE:
        'private/v1/partner/is-additional-data-step-complete',
      COMPLETE_ADDITIONAL_DATA_STEP:
        'private/v1/partner/complete-additional-data-step',
      GET_PARTNER_WEBSITE: 'private/v1/partner/get-partner-website',
      IS_AGENT_SKORR_ADMIN: 'private/v1/agent/is-skorr-admin',
      GET_AGENT_ROLE_IDS: 'private/v1/agent/get-agent-role-ids',
      DELETE_PARTNER: 'private/v1/user/delete',
      DELETE_AGENT_BY_ID: 'private/v1/agent/delete-by-id',
      UPDATE_AGENT: 'private/v1/agent/update',
      UPDATE_AGENT_PASSWORD: 'private/v1/credentials/update-password',
      CREATE_AGENT: 'private/v1/agent/create',
      GET_AGENTS_BY_PARTNER_ID: 'private/v1/agent/get-by-partner-id',
      GET_AGENT_BY_ID: 'private/v1/agent/get-by-id',
      GET_PARTNER_BY_ID: 'private/v1/partner/get-by-id',
      SEND_ACCOUNT_DELETION_EMAIL:
        'public/v1/partner/send-account-deletion-code-email',
      GET_USER_BY_ID: 'private/v1/user/get-by-id',
      UPDATE_ACCOUNT: 'private/v1/partner/update-account',
      GET_PUBLIC_USER_IMAGE_BY_ID:
        'private/v1/public-user/get-public-user-image-by-id',
      GET_PUBLIC_USER_BASIC_INFORMATION_WITH_EXTERNAL_PROVIDER:
        'private/v1/public-user/get-public-user-basic-information',
      UPDATE_USER_PUBLIC_PROFILES: 'private/v1/user/update-public-profiles',
      GET_KPIS_OVERVIEW: 'private/v1/public-user/get-kpis-overview',
      GET_PUBLIC_USERS_BY_ID: 'private/v1/public-user/get-public-user',
      GET_KPIS_OVERVIEW_DAILY: 'private/v1/public-user/get-kpis-overview-daily',
      GET_PUBLIC_USER_AUDIENCE_GENDERS_PER_AGE:
        'private/v1/public-user/get-audience-genders-per-age',
      GET_PUBLIC_USER_AUDIENCE_GENDERS:
        'private/v1/public-user/get-audience-gender',
      GET_PUBLIC_USER_AUDIENCE_GEO:
        'private/v1/public-user/get-audience-demographic',
      GET_PUBLIC_USER_AUDIENCE_TYPES:
        'private/v1/public-user/get-audience-types',
      GET_PUBLIC_USER_AUDIENCE_REACHABILITY:
        'private/v1/public-user/get-audience-reachability',
    },

    POST: {
      GET_PUBLIC_USER_HASHTAGS_SORTED_BY_METRICS:
        'private/v1/public-post/get-public-user-hashtags-sorted-by-metrics',
      GET_PUBLIC_POSTS_BY_PUBLIC_USER_ID:
        'private/v1/public-post/get-public-posts-by-public-user-id',
      GET_PUBLIC_POSTS_BY_PUBLIC_USER_ID_FILTERED_BY_KEYWORDS:
        'private/v1/public-post/get-public-posts-filtered-by-keywords',
    },

    GENERIC: {
      GET_LOCATIONS: 'private/v1/location/get-locations',
      GET_TOPICS_INTEREST: 'private/v1/topics-interest/get-topics-interest',
      GET_ALL_BRAND_PICTURES: 'private/v1/brands/get-all-brand-pictures',
      PUBLIC: {
        GET_TAGS_BY_KEY: 'public/v1/topics-interest/get-tags-by-key',
      },
    },

    EXTERNAL_MEDIA_KIT: {
      GET_EXTERNAL_MEDIA_KIT_CONFIGURATION_BY_ID:
        'private/v1/external-media-kit/get-by-id',
      GET_EXTERNAL_MEDIA_KIT_CONFIGURATIONS_BY_USER_ID:
        'private/v1/external-media-kit/get-by-user-id',
      GENERATE_EXTERNAL_MEDIA_KIT_URL:
        'private/v1/external-media-kit/generate-external-media-kit-url',
      GENERATE_EXTERNAL_PORTFOLIO_URL:
        'private/v1/external-media-kit/generate-external-portfolio-url',
      UPDATE_EXTERNAL_MEDIA_KIT: 'private/v1/external-media-kit/update-by-id',
      GET_PORTFOLIO_BY_BRAND_PICTURE:
        'private/v1/external-media-kit/get-portfolio-by-brand-picture',
      UPLOAD_PORTFOLIO_IMAGE:
        'private/v1/external-media-kit/upload-portfolio-image',
      UPLOAD_USER_BRAND_PICTURE:
        'private/v1/external-media-kit/upload-user-brand-picture',
      DELETE_USER_BRAND_PICTURE:
        'private/v1/external-media-kit/delete-user-brand-picture',
      GET_USER_BRAND_PICTURES:
        'private/v1/external-media-kit/get-user-brand-pictures',
    },

    FILES: {
      CREATE_CLOUD_URL: 'private/v1/file/send-file',
    },

    EXPORTER: {
      EXPORT_PDF: 'private/v1/exporter/export-pdf',
    },

    OLD: {
      SKORR_API_BASE_URL: process.env.REACT_APP_API_URL_OLD,
      IS_OPERATOR_OR_PARTNER_ADMIN:
        '/api/v1/partner/private/isOperatorOrPartnerAdmin',
      LOAD_PARTNER_BRAND_PROFILES:
        '/api/v1/partner/private/loadPartnerBusinessProfiles',
      ACTIVATE_BRAND_PROFILE: '/api/v1/agent/private/activateBusinessProfile',
      UPLOAD_FILE: '/api/v1/file/upload/uploadFile',
      UPLOAD_IMAGE: '/api/v1/file/upload/private/uploadImage',
      GET_OAUTH_TOKEN: 'api/v1/partner/getOAuthToken?endpoint=',
      GET_PARTNER_PROFILE: 'api/v1/partner/private/getPartnerProfile',
      GET_ALREADY_EXISTING_SOCIAL_NETWORK_ACCOUNTS:
        '/api/v1/auth/getAlreadyExistingSocialNetworkAccounts',
      REGISTER_PARTNER_SOCIAL_NETWORK:
        'api/v1/partner/private/registerPartnerSocialNetwork',
      LOAD_PARTNER_BUSINESS_PROFILE:
        'api/v1/partner/private/loadPartnerBusinessProfile/',
      SIGNUP_SMS: 'api/v1/partner/signup/sms',
      SIGNUP_SMS_VALIDATION: 'api/v1/partner/signup/sms-validation',
    },
  },
};
