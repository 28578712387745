/* eslint-disable no-param-reassign */
/* eslint-disable import/extensions */
import { useMutation } from 'react-query';

import { logApiRequestError } from '../../Services/Utils/Utils';
import { DEFAULT_USER_AVATAR, IErrorObject } from '../../Services/Utils/types';
import { IUseUpdateInfluencerProfileParams } from './types';
import SkorrApi from '../../Services/SkorrApi/new';

const { user } = SkorrApi;
const { files } = SkorrApi;

export const useUpdateInfluencerProfile = () => {
  return useMutation(
    async (params: IUseUpdateInfluencerProfileParams) => {
      try {
        const { data: imageUrl }: { data: string } =
          params.influencerPictureData
            ? await files.uploadImage(params.influencerPictureData!)
            : { data: '' };
        if (imageUrl) {
          params.influencerProfileUpdateData.displayPicture = imageUrl;
        } else {
          params.influencerProfileUpdateData.displayPicture =
            params.influencerInitialPicture || DEFAULT_USER_AVATAR;
        }
        const { data: modifiedCount } = await user.updateAccount(
          params.influencerProfileUpdateData,
        );
        return modifiedCount;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};
