/* eslint-disable import/extensions */
import styled, { useTheme } from 'styled-components';
import { useState, useEffect } from 'react';

import SVG from './Styles';

interface ICrossIconProps {
  containerWidth?: string;
  containerHeight?: string;
  containerPaddingTop?: string;
  containerPaddingBottom?: string;
  containerPaddingLeft?: string;
  containerPaddingRight?: string;
  containerMarginTop?: string;
  containerMarginBottom?: string;
  containerMarginLeft?: string;
  containerMarginRight?: string;
  useCase?:
    | 'socialNetworkAccountSelectionDropdownFilter'
    | 'socialNetworkAccountSelectionAddAccount'
    | 'error-notify';
  svgWidth?: string;
  svgHeight?: string;
  rotate?: string;
  cursor?: string;
}

interface IContainerProps {
  width?: string;
  height?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
}

const Container = styled.div<IContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 0;
  outline: 0;
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '0')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '0')};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '0')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '0')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
`;

const CrossIcon = (props: ICrossIconProps) => {
  const themeGlobal = useTheme();
  const [svgColor, setSvgColor] = useState<string>(() => {
    switch (props.useCase) {
      case 'socialNetworkAccountSelectionDropdownFilter': {
        return (themeGlobal as any).dropdownMenu['dropdown-menu-cross-color'];
      }
      case 'socialNetworkAccountSelectionAddAccount': {
        return (themeGlobal as any).socialNetworkAccountSelectionComponent[
          'add-social-network-account-color'
        ];
      }
      case 'error-notify': {
        return (themeGlobal as any).signin['error-notify']['cross-icon-color'];
      }
      default: {
        return (themeGlobal as any).dropdownMenu['dropdown-menu-cross-color'];
      }
    }
  });

  useEffect(() => {
    switch (props.useCase) {
      case 'socialNetworkAccountSelectionDropdownFilter': {
        setSvgColor(
          (themeGlobal as any).dropdownMenu['dropdown-menu-cross-color'],
        );
        break;
      }
      case 'socialNetworkAccountSelectionAddAccount': {
        setSvgColor(
          (themeGlobal as any).socialNetworkAccountSelectionComponent[
            'add-social-network-account-color'
          ],
        );
        break;
      }
      case 'error-notify': {
        setSvgColor(
          (themeGlobal as any).signin['error-notify']['cross-icon-color'],
        );
        break;
      }
      default: {
        setSvgColor(
          (themeGlobal as any).dropdownMenu['dropdown-menu-cross-color'],
        );
      }
    }
  }, [(themeGlobal as any).darkMode]);

  return (
    <Container
      width={props.containerWidth}
      height={props.containerHeight}
      paddingTop={props.containerPaddingTop}
      paddingBottom={props.containerPaddingBottom}
      paddingLeft={props.containerPaddingLeft}
      paddingRight={props.containerPaddingRight}
      marginTop={props.containerMarginTop}
      marginBottom={props.containerMarginBottom}
      marginLeft={props.containerMarginLeft}
      marginRight={props.containerMarginRight}
    >
      <SVG
        viewBox="0 0 32 32"
        width={props.svgWidth ? props.svgWidth : '100%'}
        height={props.svgHeight ? props.svgHeight : '100%'}
        rotateNotNative={props.rotate}
        cursor={props.cursor}
      >
        <path
          fill={svgColor}
          d="M31.708 25.708c-0-0-0-0-0-0l-9.708-9.708 9.708-9.708c0-0 0-0 0-0 0.105-0.105 0.18-0.227 0.229-0.357 0.133-0.356 0.057-0.771-0.229-1.057l-4.586-4.586c-0.286-0.286-0.702-0.361-1.057-0.229-0.13 0.048-0.252 0.124-0.357 0.228 0 0-0 0-0 0l-9.708 9.708-9.708-9.708c-0-0-0-0-0-0-0.105-0.104-0.227-0.18-0.357-0.228-0.356-0.133-0.771-0.057-1.057 0.229l-4.586 4.586c-0.286 0.286-0.361 0.702-0.229 1.057 0.049 0.13 0.124 0.252 0.229 0.357 0 0 0 0 0 0l9.708 9.708-9.708 9.708c-0 0-0 0-0 0-0.104 0.105-0.18 0.227-0.229 0.357-0.133 0.355-0.057 0.771 0.229 1.057l4.586 4.586c0.286 0.286 0.702 0.361 1.057 0.229 0.13-0.049 0.252-0.124 0.357-0.229 0-0 0-0 0-0l9.708-9.708 9.708 9.708c0 0 0 0 0 0 0.105 0.105 0.227 0.18 0.357 0.229 0.356 0.133 0.771 0.057 1.057-0.229l4.586-4.586c0.286-0.286 0.362-0.702 0.229-1.057-0.049-0.13-0.124-0.252-0.229-0.357z"
        />
      </SVG>
    </Container>
  );
};

export default CrossIcon;
