/* eslint-disable import/extensions */
import { useMutation } from 'react-query';

import SkorrApi from '../../Services/SkorrApi/new';
import { logApiRequestError } from '../../Services/Utils/Utils';
import {
  IErrorObject,
  ISendAccountDeletionEmailPayload,
} from '../../Services/Utils/types';

const { user } = SkorrApi;

export const useSendAccountDeletionEmail = () => {
  return useMutation(async (params: ISendAccountDeletionEmailPayload) => {
    try {
      const { data }: { data: boolean } = await user.sendAccountDeletionEmail(
        params,
      );
      return data;
    } catch (error) {
      logApiRequestError(error as IErrorObject);
      throw error;
    }
  });
};
