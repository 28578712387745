/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable default-case */
/* eslint-disable import/extensions */
import { useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import * as S from './Styles';
import { useGetExternalMediaKitConfigurationsByUserId } from './RQCustomHooks';
import Loader from '../../components/shared/LoaderFixed/LoaderFixed';
import {
  getSocialNetworkIcon,
  isAuthenticated,
} from '../../Services/Utils/Utils';
import GenericModal from '../../components/shared/GenericModal/GenericModal';
import { MediaKitConfigurationsListProps } from './types';
import BackArrowIcon from '../../assets/iconComponents/BackArrowIcon';
import {
  useGetPublicUserById,
  useGetSelfUser,
} from '../../Services/Utils/CustomHooks';
import {
  ExternalMediaKitConfiguration,
  ExternalMediaKitConfigurationTypes,
} from '../../Services/SkorrApi/new/ExternalMediaKit/types';
import { DEFAULT_USER_AVATAR } from '../../Services/Utils/types';
import {
  getShortenedMonthResourceKey,
  getTimeIntervalInDays,
} from '../../Services/Utils/dateUtils';
import ComboBox from '../../components/shared/ComboBox/ComboBox';
import EmptyInfo from '../../components/shared/EmptyInfo/EmptyInfo';
import ExclamationPointIcon from '../../assets/iconComponents/ExclamationPointIcon';
import Button from '../../components/shared/Button/Button';
import MediaKitTypeIcon from '../../assets/iconComponents/MediaKitTypeIcon';
import PortfolioHorizontalLayoutTypeIcon from '../../assets/iconComponents/PortfolioHorizontalLayoutTypeIcon';
import CopyIcon from '../../assets/iconComponents/CopyIcon';
import EyeIconV3 from '../../assets/iconComponents/EyeIconV3';
import GearIconV1 from '../../assets/iconComponents/GearIconV1';
import InfoV2Icon from '../../assets/iconComponents/InfoIconV2';
import RightArrowIconV1 from '../../assets/iconComponents/RightArrowIconV1';

const CopyToClipboard = require('react-copy-to-clipboard');

const getConfigurationFilterValuesAvailable = (t: (path: string) => string) => {
  return [
    {
      value: 'ALL',
      label: t('externalMediaKitConfigurationsList.configurationFilterAll'),
    },
    {
      value: 'LIVE',
      label: t('externalMediaKitConfigurationsList.configurationFilterLive'),
    },
    {
      value: 'EXPIRED',
      label: t('externalMediaKitConfigurationsList.configurationFilterExpired'),
    },
  ];
};

export const copySmartLinkButtonClicked = (event: any) => {
  event.stopPropagation();
  const notification = event.currentTarget.querySelector('span');
  notification.style.opacity = '1';
  notification.style.left = '-9rem';
  // eslint-disable-next-line no-return-assign
  setTimeout(() => {
    notification.style.opacity = '0';
    notification.style.left = '-8rem';
  }, 600);
};

const MediaKitConfigurationsList = (props: MediaKitConfigurationsListProps) => {
  const navigate = useNavigate();
  const themeGlobal = useTheme();
  const [isGetSelfUserEnabled, setIsGetSelfUserEnabled] = useState(true);
  const [
    getConfigurationsByUserIdQueryKey,
    setGetConfigurationsByUserIdQueryKey,
  ] = useState(String(new Date().getTime()));
  const [isGetConfigurationsEnabled, setIsGetConfigurationsEnabled] =
    useState(false);
  const [isGetPublicUsersByIdEnabled, setIsGetPublicUsersByIdEnabled] =
    useState(false);
  const [publicUserIds, setPublicUserIds] = useState<string[]>([]);
  const [filterSelected, setFilterSelected] = useState('ALL');
  const [showPortfolios, setShowPortfolios] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState('');
  const {
    data: selfUser,
    isFetching: isFetchingGetSelfUser,
    isSuccess: isSuccessGetSelfUser,
    isError: isErrorGetSelfUser,
  } = useGetSelfUser(isGetSelfUserEnabled);
  const {
    data: configurations,
    isFetching: isFetchingGetConfigurations,
    isSuccess: isSuccessGetConfigurations,
    isError: isErrorGetConfigurations,
  } = useGetExternalMediaKitConfigurationsByUserId(
    isGetConfigurationsEnabled,
    isAuthenticated()?.businessProfileUserId! ?? '',
    getConfigurationsByUserIdQueryKey,
  );
  const {
    data: publicUsers,
    isFetching: isFetchingGetPublicUsers,
    isSuccess: isSuccessGetPublicUsers,
    isError: isErrorGetPublicUsers,
  } = useGetPublicUserById(isGetPublicUsersByIdEnabled, publicUserIds);
  const { mediaKitsCount, portfoliosCount } = useMemo(() => {
    if (isSuccessGetConfigurations) {
      if (!configurations.length) {
        return { mediaKitsCount: 0, portfoliosCount: 0 };
      }

      return {
        mediaKitsCount: configurations.filter(
          configuration =>
            configuration.type ===
            ExternalMediaKitConfigurationTypes.EMK_PUBLIC_USER,
        ).length,
        portfoliosCount: configurations.filter(
          configuration =>
            configuration.type ===
            ExternalMediaKitConfigurationTypes.EMK_PORTFOLIO,
        ).length,
      };
    }

    return { mediaKitsCount: 0, portfoliosCount: 0 };
  }, [isSuccessGetConfigurations, configurations]);
  const canRenderList = useMemo(() => {
    if (
      !(!showPortfolios && !isSuccessGetSelfUser) &&
      isSuccessGetConfigurations &&
      configurations.length
    ) {
      if (showPortfolios && portfoliosCount) {
        return true;
      }
      if (mediaKitsCount && isSuccessGetPublicUsers && publicUsers.length) {
        return true;
      }
    }

    return false;
  }, [
    isSuccessGetConfigurations,
    configurations,
    isSuccessGetPublicUsers,
    publicUsers,
    showPortfolios,
    mediaKitsCount,
    portfoliosCount,
    isSuccessGetSelfUser,
  ]);
  const displayEmptyInfo = useMemo(() => {
    if (isSuccessGetConfigurations) {
      if (showPortfolios) {
        return !portfoliosCount;
      }
      return !isSuccessGetSelfUser || !mediaKitsCount;
    }
  }, [
    isSuccessGetConfigurations,
    showPortfolios,
    portfoliosCount,
    mediaKitsCount,
    isSuccessGetSelfUser,
  ]);
  const editableMediaKitConfigurations = useMemo(() => {
    if (isSuccessGetSelfUser && isSuccessGetConfigurations) {
      const result = new Set();

      configurations.forEach(config => {
        if (
          config.type === ExternalMediaKitConfigurationTypes.EMK_PUBLIC_USER
        ) {
          if (
            selfUser.publicProfiles?.find(
              pp => pp.publicUserId === config.targetId,
            )
          ) {
            result.add(config.id);
          }
        }
      });

      return result;
    }
    return new Set();
  }, [
    isSuccessGetSelfUser,
    isSuccessGetConfigurations,
    configurations,
    selfUser,
  ]);

  useEffect(() => {
    if (isAuthenticated()?.businessProfileUserId) {
      setIsGetConfigurationsEnabled(true);
    }
  }, [isAuthenticated()?.businessProfileUserId]);

  useEffect(() => {
    if (isSuccessGetConfigurations) {
      setIsGetConfigurationsEnabled(false);

      if (configurations.length) {
        const uniquePublicUserIds: string[] = [];

        configurations.forEach(configuration => {
          if (
            configuration.targetId &&
            !uniquePublicUserIds.includes(configuration.targetId)
          ) {
            uniquePublicUserIds.push(configuration.targetId);
          }
        });

        if (uniquePublicUserIds.length) {
          setPublicUserIds(uniquePublicUserIds);
          setIsGetPublicUsersByIdEnabled(true);
        }
      }
    }
  }, [isSuccessGetConfigurations]);

  useEffect(() => {
    if (
      isErrorGetConfigurations ||
      isErrorGetPublicUsers ||
      isErrorGetSelfUser
    ) {
      setIsGetConfigurationsEnabled(false);
      setIsGetPublicUsersByIdEnabled(false);
      setIsGetSelfUserEnabled(false);
      setHasError(true);
      setMsgError(props.t('generic.couldNotGetData'));
    }
  }, [isErrorGetConfigurations, isErrorGetPublicUsers, isErrorGetSelfUser]);

  const getLoaderMessage = () => {
    if (
      isFetchingGetConfigurations ||
      isFetchingGetPublicUsers ||
      isFetchingGetSelfUser
    ) {
      return props.t('generic.gettingData');
    }
    return '';
  };

  const errorModalCloseButtonClicked = () => {
    setHasError(false);
    if (isErrorGetConfigurations) {
      setIsGetConfigurationsEnabled(true);
      setGetConfigurationsByUserIdQueryKey(() => String(new Date().getTime()));
    }
    if (isErrorGetPublicUsers) {
      setIsGetPublicUsersByIdEnabled(true);
    }
    if (isErrorGetSelfUser) {
      setIsGetSelfUserEnabled(true);
    }
  };

  const getTimeIntervalLabel = (
    configuration: ExternalMediaKitConfiguration,
  ) => {
    const startDate = new Date(
      Date.parse(configuration.details?.startAt! as unknown as string),
    );

    if (configuration.details?.endAt == null) {
      const diffInDays =
        (new Date().getTime() - startDate.getTime()) / 1000 / 60 / 60 / 24;

      return `${props.t(
        'externalMediaKitConfigurationsList.slidingWindow',
      )} (${props.t('generic.last')} ${Math.round(diffInDays)} ${props.t(
        'generic.days',
      )})`;
    }

    const endDate = configuration.details?.endAt
      ? new Date(Date.parse(configuration.details?.endAt as unknown as string))
      : new Date();

    const getStartDateLabel = () => {
      return `${props.t(
        getShortenedMonthResourceKey(startDate),
      )} ${startDate.getDate()}${
        startDate.getFullYear() !== endDate.getFullYear()
          ? `, ${startDate.getFullYear()}`
          : ''
      }`;
    };

    const getEndDateLabel = () => {
      return `${props.t(
        getShortenedMonthResourceKey(endDate),
      )} ${endDate.getDate()}, ${endDate.getFullYear()}`;
    };

    const daysSpan = getTimeIntervalInDays(startDate, endDate);
    let daysSpanText = '';

    if (daysSpan) {
      daysSpanText = ` (${daysSpan} ${props.t(
        `mediaKit.userPresentationalCard.${daysSpan === 1 ? 'day' : 'days'}`,
      )})`;
    }

    return `${getStartDateLabel()} - ${getEndDateLabel()}${daysSpanText}`;
  };

  const getCreatedAtLabel = (configuration: ExternalMediaKitConfiguration) => {
    const createdAtDate = new Date(
      Date.parse(configuration.createdAt! as unknown as string),
    );

    const getCreatedAtDateLabel = () => {
      return `${props.t(
        getShortenedMonthResourceKey(createdAtDate),
      )} ${createdAtDate.getDate()}${`, ${createdAtDate.getFullYear()}`}`;
    };

    return `${getCreatedAtDateLabel()}`;
  };

  const renderSocialAccountCard = (
    configuration: ExternalMediaKitConfiguration,
  ) => {
    const publicUser = publicUsers?.find(
      publicUser => publicUser._id == configuration.targetId,
    );
    const image =
      (configuration.type !== ExternalMediaKitConfigurationTypes.EMK_PORTFOLIO
        ? publicUser?.image
        : configuration.details?.picture) ?? DEFAULT_USER_AVATAR;

    return (
      <S.SocialAccountContainer>
        <S.SocialAccountLeftContainer>
          <S.SocialAccountImage src={image} />
          {configuration.type !==
            ExternalMediaKitConfigurationTypes.EMK_PORTFOLIO && (
            <S.SocialAccountNetworkIconContainer>
              {getSocialNetworkIcon({
                socialNetwork: configuration.socialNetwork!,
                width: '2rem',
                height: '2rem',
                gradientColored: true,
              })}
            </S.SocialAccountNetworkIconContainer>
          )}
        </S.SocialAccountLeftContainer>

        {configuration.type !==
          ExternalMediaKitConfigurationTypes.EMK_PORTFOLIO && (
          <S.SocialAccountRightContainer>
            <S.SocialAccountUsername
              id={`media-kit-configuration-username-${configuration.id}`}
            >
              {`@${publicUser?.socialNetwork.username}`}
            </S.SocialAccountUsername>
            {publicUser && publicUser?.socialNetwork.username.length > 15 && (
              <S.ConfigurationUsernameTooltip
                placement="top"
                target={`media-kit-configuration-username-${configuration.id}`}
              >
                {publicUser?.socialNetwork.username}
              </S.ConfigurationUsernameTooltip>
            )}
            <S.SocialAccountNetworkName>
              {configuration.socialNetwork![0] +
                configuration.socialNetwork!.slice(1).toLowerCase()}
            </S.SocialAccountNetworkName>
          </S.SocialAccountRightContainer>
        )}
      </S.SocialAccountContainer>
    );
  };

  const renderConfigurationList = () => {
    return configurations!
      .filter(configuration =>
        !showPortfolios
          ? configuration.type ===
            ExternalMediaKitConfigurationTypes.EMK_PUBLIC_USER
          : configuration.type ===
            ExternalMediaKitConfigurationTypes.EMK_PORTFOLIO,
      )
      .filter(configuration => {
        switch (filterSelected) {
          case 'ALL':
            return true;
          case 'LIVE': {
            if (
              configuration.expiredAt == null ||
              Date.parse(configuration.expiredAt! as unknown as string) >=
                new Date().getTime()
            ) {
              return true;
            }
            return false;
          }
          case 'EXPIRED': {
            if (
              configuration.expiredAt &&
              Date.parse(configuration.expiredAt! as unknown as string) <
                new Date().getTime()
            ) {
              return true;
            }
            return false;
          }
        }
      })
      .map(configuration => (
        <S.BenchmarkListItemCard isPortfolio={showPortfolios}>
          <S.SocialAccountCardContainer isPortfolio={showPortfolios}>
            <S.BenchmarkListItemCol>
              {renderSocialAccountCard(configuration)}
            </S.BenchmarkListItemCol>
          </S.SocialAccountCardContainer>

          <S.NameContainer isPortfolio={showPortfolios}>
            <S.BenchmarkListItemCol>
              <S.BenchmarkListItemNameContainer>
                <S.BenchmarkListItemTitle>
                  {props.t(
                    'externalMediaKitConfigurationsList.configurationNameLabel',
                  )}
                </S.BenchmarkListItemTitle>
                <S.BenchmarkListItemLegend>
                  {configuration.name}
                </S.BenchmarkListItemLegend>
              </S.BenchmarkListItemNameContainer>
            </S.BenchmarkListItemCol>
          </S.NameContainer>

          <S.CreatedAtContainer isPortfolio={showPortfolios}>
            <S.BenchmarkListItemCol>
              <S.BenchmarkListItemType>
                <S.BenchmarkListItemTitle>
                  {props.t(
                    'externalMediaKitConfigurationsList.configurationCreatedAtLabel',
                  )}
                </S.BenchmarkListItemTitle>
                <S.BenchmarkListItemLegend>
                  {getCreatedAtLabel(configuration)}
                </S.BenchmarkListItemLegend>
              </S.BenchmarkListItemType>
            </S.BenchmarkListItemCol>
          </S.CreatedAtContainer>

          {configuration.type !==
            ExternalMediaKitConfigurationTypes.EMK_PORTFOLIO && (
            <S.DurationContainer>
              <S.BenchmarkListItemCol>
                <S.BenchmarkListItemType>
                  <S.BenchmarkListItemTitle>
                    {props.t(
                      'externalMediaKitConfigurationsList.configurationDurationLabel',
                    )}
                  </S.BenchmarkListItemTitle>

                  <S.BenchmarkListItemLegend>
                    {getTimeIntervalLabel(configuration)}
                  </S.BenchmarkListItemLegend>
                </S.BenchmarkListItemType>
              </S.BenchmarkListItemCol>
            </S.DurationContainer>
          )}

          <S.ViewsContainer isPortfolio={showPortfolios}>
            <S.BenchmarkListItemCol>
              <S.ViewsColumnInnerContainer>
                <S.BenchmarkListItemTitle>
                  {props.t(
                    'externalMediaKitConfigurationsList.configurationViewsLabel',
                  )}
                </S.BenchmarkListItemTitle>
                <S.BenchmarkListItemLegend>
                  {configuration.insights?.views}
                </S.BenchmarkListItemLegend>
              </S.ViewsColumnInnerContainer>
            </S.BenchmarkListItemCol>
          </S.ViewsContainer>

          {configuration.type ==
            ExternalMediaKitConfigurationTypes.EMK_PORTFOLIO && (
            <S.PortfolioPublicUserImagesOuterContainer>
              <S.BenchmarkListItemCol>
                <S.BenchmarkListItemType>
                  <S.PortfolioPublicUserImagesTitle>
                    {props.t(
                      'externalMediaKitConfigurationsList.configurationPublicUserImagesLabel',
                    )}
                  </S.PortfolioPublicUserImagesTitle>

                  <S.PortfolioPublicUserImagesContainer>
                    {configuration.userMkImages?.map((image, index) => (
                      <S.PortfolioPublicUserImage index={index} src={image} />
                    ))}
                  </S.PortfolioPublicUserImagesContainer>
                </S.BenchmarkListItemType>
              </S.BenchmarkListItemCol>
            </S.PortfolioPublicUserImagesOuterContainer>
          )}

          <S.ActionsContainer isPortfolio={showPortfolios}>
            <S.BenchmarkListItemTitle>
              {props.t(
                'externalMediaKitConfigurationsList.configurationActionsLabel',
              )}
            </S.BenchmarkListItemTitle>
            <S.ActionsInnerContainer>
              <S.CopyConfigurationUrlContainer>
                <S.ViewsColumnInnerContainer>
                  <S.EyeIconContainer>
                    <S.CopyUrlIconContainer
                      onClick={event => copySmartLinkButtonClicked(event)}
                    >
                      <S.CopiedToClipboard>
                        {props.t('generic.copied')}
                      </S.CopiedToClipboard>
                      <CopyToClipboard
                        text={configuration.url!}
                        id={`copy-configuration-url-${configuration.id}`}
                      >
                        <span>
                          <CopyIcon
                            containerWidth="2.4rem"
                            containerHeight="2.4rem"
                          />
                        </span>
                      </CopyToClipboard>
                      <S.EditConfigurationTooltip
                        placement="top"
                        target={`copy-configuration-url-${configuration.id}`}
                      >
                        {props.t(
                          'externalMediaKitConfigurationsList.copyConfigurationUrl',
                        )}
                      </S.EditConfigurationTooltip>
                    </S.CopyUrlIconContainer>
                  </S.EyeIconContainer>
                </S.ViewsColumnInnerContainer>
              </S.CopyConfigurationUrlContainer>

              <S.EditConfigurationContainer>
                <S.ViewsColumnInnerContainer>
                  <S.EyeIconContainer
                    id={`edit-configuration-${configuration.id}`}
                    isPortfolio={showPortfolios}
                  >
                    <span
                      onClick={() => {
                        if (
                          showPortfolios ||
                          editableMediaKitConfigurations?.has(configuration.id)
                        ) {
                          navigate(
                            `/${
                              showPortfolios ? 'portfolio' : 'media-kit'
                            }-configuration/edit/${configuration.id}`,
                          );
                        }
                      }}
                    >
                      <S.GearIconContainer
                        disabled={
                          !showPortfolios &&
                          !editableMediaKitConfigurations?.has(configuration.id)
                        }
                      >
                        <GearIconV1
                          containerWidth="2.4rem"
                          containerHeight="2.4rem"
                        />
                      </S.GearIconContainer>
                    </span>
                  </S.EyeIconContainer>

                  <S.EditConfigurationTooltip
                    placement="top"
                    target={`edit-configuration-${configuration.id}`}
                    autohide={false}
                  >
                    {!showPortfolios &&
                    !editableMediaKitConfigurations?.has(configuration.id) ? (
                      <S.UnavaiableConfigurationSettingsTooltipMessage>
                        <S.UnavailableConfigurationTooltipInfoIconContainer>
                          <InfoV2Icon
                            containerWidth="4rem"
                            containerHeight="4rem"
                            containerMarginRight="1rem"
                          />
                        </S.UnavailableConfigurationTooltipInfoIconContainer>
                        <S.UnavailableConfigurationTooltipRightContainer>
                          <div>
                            {props.t(
                              'externalMediaKitConfigurationsList.configurationEditionDisabledMessage',
                            )}
                          </div>
                          <S.UnavailableConfigurationTooltipLinkContainer
                            onClick={() =>
                              navigate('/social-accounts-management')
                            }
                          >
                            {props.t('generic.reconnect')}

                            {/* <RightArrowIconV1
                              containerWidth="2rem"
                              containerHeight="2rem"
                            /> */}
                          </S.UnavailableConfigurationTooltipLinkContainer>
                        </S.UnavailableConfigurationTooltipRightContainer>
                      </S.UnavaiableConfigurationSettingsTooltipMessage>
                    ) : (
                      props.t('generic.settings')
                    )}
                  </S.EditConfigurationTooltip>
                </S.ViewsColumnInnerContainer>
              </S.EditConfigurationContainer>

              <S.OpenContainer>
                <S.ViewsColumnInnerContainer>
                  <S.EyeIconContainer
                    isPortfolio={showPortfolios}
                    id={`view-configuration-${configuration.id}`}
                  >
                    <span
                      onClick={() => {
                        window.open(
                          `
                          ${
                            process.env.REACT_APP_EXTERNAL_MEDIA_KIT_BASE_URL
                          }/${
                            configuration.type ===
                            ExternalMediaKitConfigurationTypes.EMK_PORTFOLIO
                              ? 'portfolio'
                              : 'p'
                          }/${configuration.id}`,
                          '_blank',
                        );
                      }}
                    >
                      <EyeIconV3
                        containerWidth="2.4rem"
                        containerHeight="2.4rem"
                      />
                    </span>
                  </S.EyeIconContainer>
                  <S.EditConfigurationTooltip
                    placement="top"
                    target={`view-configuration-${configuration.id}`}
                  >
                    {props.t('generic.view')}
                  </S.EditConfigurationTooltip>
                </S.ViewsColumnInnerContainer>
              </S.OpenContainer>
            </S.ActionsInnerContainer>
          </S.ActionsContainer>
        </S.BenchmarkListItemCard>
      ));
  };

  return (
    <S.Container>
      <Loader
        show={
          isFetchingGetConfigurations ||
          isFetchingGetPublicUsers ||
          isFetchingGetSelfUser
        }
        message={getLoaderMessage()}
      />

      <S.InnerContainer>
        <S.Card>
          <S.HeaderOuterContainer>
            <S.HeaderContainer>
              <S.HeaderBackArrowContainer
                onClick={() => {
                  navigate(-1);
                }}
              >
                <BackArrowIcon width={'4rem'} height={'4rem'} />
              </S.HeaderBackArrowContainer>
              <S.HeaderTitleContainer>
                {!showPortfolios
                  ? props.t('externalMediaKitConfigurationsList.mediaKitsTitle')
                  : props.t(
                      'externalMediaKitConfigurationsList.portfoliosTitle',
                    )}
              </S.HeaderTitleContainer>
              <S.HiddenHeaderElement />
            </S.HeaderContainer>
          </S.HeaderOuterContainer>

          <S.CardInnerContainer>
            <>
              {canRenderList === true && (
                <S.ConfigurationFiltersOuterContainer>
                  <S.ConfigurationFiltersContainer>
                    <ComboBox
                      valueKey={'filterSelected'}
                      value={filterSelected}
                      type={'single'}
                      width={'100%'}
                      borderRadius={'0.6rem'}
                      valuesAvailable={getConfigurationFilterValuesAvailable(
                        props.t,
                      )}
                      isFilterable={false}
                      isClearable={false}
                      onChange={(obj: { filterSelected: string }) => {
                        setFilterSelected(obj.filterSelected);
                      }}
                      height={'4.4rem'}
                      availableOptionsContainerBorderRadius={'0.6rem'}
                      optionContainerBorderRadius={'0.6rem'}
                      innerOptionsContainerMarginTop={'1.2rem'}
                      innerOptionsContainerMarginBottom={'1.2rem'}
                      innerOptionsContainerPaddingLeft={'1.2rem'}
                      innerOptionsContainerPaddingRight={'1.2rem'}
                      optionContainerPaddingTop={'2rem'}
                      optionContainerPaddingBottom={'2rem'}
                      optionContainerPaddingLeft={'2rem'}
                      optionContainerPaddingRight={'2rem'}
                      themeStyles={
                        (themeGlobal as any).comboBoxTopicsOfInterest
                      }
                      changeBackgroundColorOnHover={false}
                      innerOptionsContainerMaxHeight={'25rem'}
                    />
                  </S.ConfigurationFiltersContainer>

                  <S.ConfigurationTypeFilterContainer>
                    <Button
                      borderRadius={'0.6rem'}
                      borderWidth={'0rem'}
                      height={'4.4rem'}
                      fontSize={'1.6rem'}
                      textAlign={'center'}
                      fontWeight={'500'}
                      cursorOnDisable={'pointer'}
                      blockHoverStylesOnDisable={false}
                      pointerEventsOnDisable={'all'}
                      paddingRight={'1rem'}
                      themeStyles={
                        (themeGlobal as any).listExternalMediaKitConfigurations
                          .configurationTypeBeingDisplayed
                      }
                      disabledNotNative={showPortfolios}
                      onClick={() => setShowPortfolios(false)}
                    >
                      {props.t('generic.mediaKits')}
                    </Button>

                    <Button
                      borderRadius={'0.6rem'}
                      borderWidth={'0rem'}
                      height={'4.4rem'}
                      fontSize={'1.6rem'}
                      display={'block'}
                      textAlign={'center'}
                      fontWeight={'500'}
                      cursorOnDisable={'pointer'}
                      blockHoverStylesOnDisable={false}
                      pointerEventsOnDisable={'all'}
                      themeStyles={
                        (themeGlobal as any).listExternalMediaKitConfigurations
                          .configurationTypeBeingDisplayed
                      }
                      disabledNotNative={!showPortfolios}
                      onClick={() => setShowPortfolios(true)}
                    >
                      {props.t('generic.portfolios')}
                    </Button>
                  </S.ConfigurationTypeFilterContainer>
                </S.ConfigurationFiltersOuterContainer>
              )}

              {canRenderList === true && (
                <S.ConfigurationListContainer>
                  {renderConfigurationList()}
                </S.ConfigurationListContainer>
              )}
            </>

            {displayEmptyInfo && (
              <S.EmptyInfoContainer>
                <EmptyInfo
                  width="auto"
                  icon={
                    <ExclamationPointIcon
                      containerWidth="4rem"
                      containerHeight="5rem"
                    />
                  }
                  header={props.t(
                    'externalMediaKitConfigurationsList.emptyInfoTitle',
                  )}
                  legend={props.t(
                    'externalMediaKitConfigurationsList.emptyInfoSubtitle',
                  )}
                />
              </S.EmptyInfoContainer>
            )}
          </S.CardInnerContainer>

          <S.FooterContainer>
            <Button
              borderRadius={'0.6rem'}
              borderWidth={'0rem'}
              height={'4rem'}
              fontSize={'1.6rem'}
              textAlign={'center'}
              fontWeight={'500'}
              paddingRight={'1rem'}
              onClick={() => setShowCreateModal(true)}
            >
              {props.t('generic.create')}
            </Button>
          </S.FooterContainer>
        </S.Card>
      </S.InnerContainer>

      <GenericModal
        show={hasError}
        title={props.t('generic.uhoh')}
        body={msgError}
        primaryButtonText={props.t('generic.close')}
        primaryButtonAction={() => errorModalCloseButtonClicked()}
      />

      <GenericModal
        show={showCreateModal}
        title={props.t(
          'externalMediaKitConfigurationsList.createMediaKit.selectType',
        )}
        body={
          <S.CreateMediaKitTypeContainer>
            <S.MediaKitTypeContainer
              onClick={() => navigate(`/media-kit-configuration/create`)}
            >
              <MediaKitTypeIcon
                containerWidth="20rem"
                containerHeight="20rem"
              />
              <S.MediaKitTypeDescriptionContainer>
                <S.MediaKitTypeTitle>
                  {props.t(
                    'externalMediaKitConfigurationsList.createMediaKit.mediaKitTitle',
                  )}
                </S.MediaKitTypeTitle>
                <S.MediaKitTypeSubtitle>
                  {props.t(
                    'externalMediaKitConfigurationsList.createMediaKit.mediaKitSubtitle',
                  )}
                </S.MediaKitTypeSubtitle>
                <S.MediaKitTypeDescription>
                  {props.t(
                    'externalMediaKitConfigurationsList.createMediaKit.mediaKitDescription',
                  )}
                </S.MediaKitTypeDescription>
              </S.MediaKitTypeDescriptionContainer>
            </S.MediaKitTypeContainer>
            <S.MediaKitTypeContainer
              onClick={() => navigate(`/portfolio-configuration/create`)}
            >
              <PortfolioHorizontalLayoutTypeIcon
                containerWidth="20rem"
                containerHeight="20rem"
              />
              <S.MediaKitTypeDescriptionContainer>
                <S.PortfolioTypeTitle>
                  <S.MediaKitTypeTitle>
                    {props.t(
                      'externalMediaKitConfigurationsList.createMediaKit.portfolioTitle',
                    )}
                  </S.MediaKitTypeTitle>
                  <S.PortfolioTypeTitleNew>
                    {props.t('generic.new')}
                  </S.PortfolioTypeTitleNew>
                </S.PortfolioTypeTitle>
                <S.MediaKitTypeSubtitle>
                  {props.t(
                    'externalMediaKitConfigurationsList.createMediaKit.portfolioSubtitle',
                  )}
                </S.MediaKitTypeSubtitle>
                <S.MediaKitTypeDescription>
                  {props.t(
                    'externalMediaKitConfigurationsList.createMediaKit.portfolioDescription',
                  )}
                </S.MediaKitTypeDescription>
              </S.MediaKitTypeDescriptionContainer>
            </S.MediaKitTypeContainer>
          </S.CreateMediaKitTypeContainer>
        }
        modalWidth={'70%'}
        isCancelable={true}
        cancelAction={() => setShowCreateModal(false)}
      />
    </S.Container>
  );
};

export default withTranslation()(MediaKitConfigurationsList);
