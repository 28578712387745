/* eslint-disable import/extensions */
import { AxiosInstance } from 'axios';
import queryString from 'query-string';

import config from '../../../config';
import {
  GetAllBrandPicturesOutput,
  GetTagsByKeyInput,
  GetTagsByKeyOutput,
} from './types';

const TAG_KEYS = [
  'tag_00001',
  'tag_00002',
  'tag_00003',
  'tag_00004',
  'tag_00005',
  'tag_00006',
  'tag_00007',
  'tag_00008',
  'tag_00009',
  'tag_00010',
  'tag_00011',
  'tag_00012',
  'tag_00013',
  'tag_00014',
  'tag_00015',
  'tag_00016',
  'tag_00017',
  'tag_00018',
  'tag_00019',
  'tag_00020',
  'tag_00021',
  'tag_00022',
  'tag_00023',
  'tag_00024',
  'tag_00025',
  'tag_00026',
  'tag_00027',
  'tag_00028',
  'tag_00029',
  'tag_00030',
  'tag_00031',
  'tag_00032',
  'tag_00033',
  'tag_00034',
  'tag_00035',
  'tag_00036',
  'tag_00037',
  'tag_00038',
  'tag_00039',
  'tag_00040',
  'tag_00041',
  'tag_00042',
  'tag_00043',
  'tag_00044',
  'tag_00045',
  'tag_00046',
  'tag_00048',
  'tag_00049',
  'tag_00050',
  'tag_00051',
  'tag_00052',
  'tag_00053',
  'tag_00054',
  'tag_00055',
  'tag_00056',
  'tag_00057',
  'tag_00058',
  'tag_00059',
  'tag_00060',
  'tag_00061',
  'tag_00062',
  'tag_00063',
  'tag_00064',
  'tag_00065',
  'tag_00066',
  'tag_00067',
  'tag_00068',
  'tag_00069',
  'tag_00070',
];

const createGenericInstanceApi = (instance: AxiosInstance) => {
  const getLocations = () =>
    instance.get(config.ENDPOINTS.GENERIC.GET_LOCATIONS);

  const getTopicsInterest = () =>
    instance.get(config.ENDPOINTS.GENERIC.GET_TOPICS_INTEREST);

  const getTagsByKey = async ({ language, keys }: GetTagsByKeyInput) => {
    const query = queryString.stringify(
      {
        language,
        keys: keys.length ? keys : TAG_KEYS,
      },
      { arrayFormat: 'bracket' },
    );

    const url = `${config.ENDPOINTS.GENERIC.PUBLIC.GET_TAGS_BY_KEY}?${query}`;

    const { data: result } = await instance.get<{
      data: GetTagsByKeyOutput;
    }>(url);

    return result as unknown as GetTagsByKeyOutput;
  };

  const getAllBrandPictures = async () => {
    const url = `${config.ENDPOINTS.GENERIC.GET_ALL_BRAND_PICTURES}`;

    const { data: result } = await instance.get<{
      data: GetAllBrandPicturesOutput;
    }>(url);

    return result as unknown as GetAllBrandPicturesOutput;
  };

  return {
    getLocations,
    getTopicsInterest,
    getTagsByKey,
    getAllBrandPictures,
  };
};

export default {
  createGenericInstanceApi,
};
