import styled from 'styled-components';

type Props = {
  margin?: string;
};

export const CustomInput = styled.input<Props>`
  font-weight: 300;
  border: 0.2rem solid rgba(199, 202, 216, 0);
  height: 4rem;
  border-radius: 2rem;
  background: #f1f5f9;
  color: #162b39;
  font-size: 1.44rem;
  box-shadow: none;
  padding: 0.6rem 1.2rem;
  display: block;
  width: ${({ width }) => width || '100%'};
  margin: ${({ margin }) => margin};
  line-height: 1.5;

  &:focus {
    color: #495057;
    background-color: rgba(241, 245, 249, 0.38823529411764707);
    border: 0.2rem solid #dfe6ed;
    outline: 0;
  }
`;

export const FieldError = styled.span`
  color: #ea560d;
  font-weight: 300;
  font-size: 1.2rem;
`;
