/* eslint-disable import/extensions */
import styled from 'styled-components';

import { MessageContainerProps } from './types';

export const MessageContainer = styled.div<MessageContainerProps>`
  height: ${props => props.height ?? '100vh'};
  display: flex;
  justify-content: center;
  background-color: ${props => props.theme.container['background-color-1']};
  margin-top: ${props => props.marginTop ?? '0'};
  margin-bottom: ${props => props.marginBottom ?? '0'};
  margin-left: ${props => props.marginLeft ?? '0'};
  margin-right: ${props => props.marginRight ?? '0'};
  padding-top: ${props => props.paddingTop ?? '6rem'};
  padding-bottom: ${props => props.paddingBottom ?? '0'};
  padding-left: ${props => props.paddingLeft ?? '0'};
  padding-right: ${props => props.paddingRight ?? '0'};
`;

export const Message = styled.div`
  display: inline-block;
  color: #292929;
  font-size: 1.92rem;
  font-weight: 400;
`;
