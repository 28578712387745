/* eslint-disable import/extensions */
import { useQuery } from 'react-query';

import SkorrApi from '../../Services/SkorrApi/new';
import { IErrorObject } from '../../Services/Utils/types';
import {
  isAuthenticated,
  logApiRequestError,
} from '../../Services/Utils/Utils';

const { auth } = SkorrApi;

export const useIsOperatorOrPartnerAdmin = () => {
  const agentId = isAuthenticated()?.agentId;
  return useQuery(
    ['isOperatorOrPartnerAdmin', agentId],
    async () => {
      try {
        const { data }: { data: boolean } =
          await auth.isOperatorOrPartnerAdmin();
        return data;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      enabled: !!agentId,
      retry: 3,
      refetchOnWindowFocus: false,
    },
  );
};
