/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import * as S from './Styles';
import { IEmptyInfoProps } from './types';

const EmptyInfo: React.FC<IEmptyInfoProps> = (props: IEmptyInfoProps) => {
  const navigate = useNavigate();

  const renderItems = () => {
    const arr = [];
    for (const i in props.items) {
      const item = props.items[i];
      arr.push(<li>{item}</li>);
    }
    return arr;
  };

  return (
    <S.EmptyCardContainer
      display={props.display}
      justifyContent={props.justifyContent}
      alignItems={props.alignItems}
      height={props.height}
      width={props.width}
      marginTop={props.marginTop}
    >
      <S.EmptyCardIcon>
        <i>{props.icon}</i>
      </S.EmptyCardIcon>
      <S.EmptyCardDescription>
        {props.header && (
          <S.EmptyCardDescriptionTitle>
            {props.header}
          </S.EmptyCardDescriptionTitle>
        )}
        {props.legend && (
          <S.EmptyCardDescriptionParagraph>
            {props.legend}
          </S.EmptyCardDescriptionParagraph>
        )}
        {props.items && <ul>{renderItems()}</ul>}
        {props.clickText && (
          <S.LinkText onClick={() => navigate(props.clickRedirect)}>
            {props.clickText}
          </S.LinkText>
        )}
      </S.EmptyCardDescription>
    </S.EmptyCardContainer>
  );
};

export default withTranslation()(EmptyInfo);
