/* eslint-disable import/extensions */
import styled from 'styled-components';

import SVG from './Styles';

interface IMediaKitTypeIconProps {
  containerWidth?: string;
  containerHeight?: string;
  containerPaddingTop?: string;
  containerPaddingBottom?: string;
  containerPaddingLeft?: string;
  containerPaddingRight?: string;
  containerMarginTop?: string;
  containerMarginBottom?: string;
  containerMarginLeft?: string;
  containerMarginRight?: string;
  svgWidth?: string;
  svgHeight?: string;
  cursor?: string;
  onClick?: any;
  id?: string;
}

interface IContainerProps {
  width?: string;
  height?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
}

const Container = styled.div<IContainerProps>`
  border-width: 0;
  outline: 0;
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '0')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '0')};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '0')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '0')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
  display: inline-flex;
  align-items: center;
`;

const MediaKitTypeIcon = (props: IMediaKitTypeIconProps) => {
  return (
    <Container
      width={props.containerWidth}
      height={props.containerHeight}
      paddingTop={props.containerPaddingTop}
      paddingBottom={props.containerPaddingBottom}
      paddingLeft={props.containerPaddingLeft}
      paddingRight={props.containerPaddingRight}
      marginTop={props.containerMarginTop}
      marginBottom={props.containerMarginBottom}
      marginLeft={props.containerMarginLeft}
      marginRight={props.containerMarginRight}
      id={props.id}
    >
      <SVG
        viewBox="0 0 80 120"
        width={props.svgWidth ? props.svgWidth : '100%'}
        height={props.svgHeight ? props.svgHeight : '100%'}
        cursor={props.cursor}
      >
        <g clip-path="url(#clip0_5417_25231)">
          <path
            d="M70.3615 0H9.63855C4.31533 0 0 4.31533 0 9.63857V109.88C0 115.203 4.31533 119.518 9.63855 119.518H70.3615C75.6847 119.518 80 115.203 80 109.88V9.63857C80 4.31533 75.6847 0 70.3615 0Z"
            fill="#D9DEE6"
          />
          <path
            d="M7.71094 11.5663L7.71094 26.0241C7.71094 28.1534 9.43707 29.8795 11.5664 29.8795L68.4338 29.8795C70.5631 29.8795 72.2893 28.1534 72.2893 26.0241V11.5663C72.2893 9.43697 70.5631 7.71084 68.4338 7.71084L11.5664 7.71084C9.43707 7.71084 7.71094 9.43697 7.71094 11.5663Z"
            fill="#EFF1F6"
          />
          <path
            d="M61.6872 13.9759H37.5908C36.5262 13.9759 35.6631 14.839 35.6631 15.9036C35.6631 16.9683 36.5262 17.8313 37.5908 17.8313H61.6872C62.7518 17.8313 63.6149 16.9683 63.6149 15.9036C63.6149 14.839 62.7518 13.9759 61.6872 13.9759Z"
            fill="#D9DEE6"
          />
          <path
            d="M61.6872 19.759H37.5908C36.5262 19.759 35.6631 20.6221 35.6631 21.6868C35.6631 22.7514 36.5262 23.6145 37.5908 23.6145H61.6872C62.7518 23.6145 63.6149 22.7514 63.6149 21.6868C63.6149 20.6221 62.7518 19.759 61.6872 19.759Z"
            fill="#D9DEE6"
          />
          <path
            d="M26.5057 18.3133C26.5057 14.587 23.485 11.5663 19.7587 11.5663C16.0324 11.5663 13.0117 14.587 13.0117 18.3133C13.0117 22.0395 16.0324 25.0603 19.7587 25.0603C23.485 25.0603 26.5057 22.0395 26.5057 18.3133Z"
            fill="#D9DEE6"
          />
          <path
            d="M7.71094 36.6266L7.71094 47.229C7.71094 49.3583 9.43707 51.0844 11.5664 51.0844H46.2652C48.3944 51.0844 50.1206 49.3583 50.1206 47.229V36.6266C50.1206 34.4973 48.3944 32.7711 46.2652 32.7711H11.5664C9.43707 32.7711 7.71094 34.4973 7.71094 36.6266Z"
            fill="#EFF1F6"
          />
          <path
            d="M53.0117 36.6266V47.229C53.0117 49.3583 54.7379 51.0844 56.8671 51.0844H68.4334C70.5627 51.0844 72.2888 49.3583 72.2888 47.229V36.6266C72.2888 34.4973 70.5627 32.7711 68.4334 32.7711H56.8671C54.7379 32.7711 53.0117 34.4973 53.0117 36.6266Z"
            fill="#EFF1F6"
          />
          <path
            d="M26.9883 68.4338V57.8314C26.9883 55.7021 25.2621 53.976 23.1329 53.976H11.5666C9.43729 53.976 7.71115 55.7021 7.71115 57.8314V68.4338C7.71115 70.5631 9.43729 72.2892 11.5666 72.2892H23.1329C25.2621 72.2892 26.9883 70.5631 26.9883 68.4338Z"
            fill="#EFF1F6"
          />
          <path
            d="M72.2891 68.4338V57.8314C72.2891 55.7021 70.5629 53.976 68.4336 53.976H33.7348C31.6056 53.976 29.8794 55.7021 29.8794 57.8314V68.4338C29.8794 70.5631 31.6056 72.2892 33.7348 72.2892H68.4336C70.5629 72.2892 72.2891 70.5631 72.2891 68.4338Z"
            fill="#EFF1F6"
          />
          <path
            d="M11.5666 111.807H23.4541C25.5834 111.807 27.3096 110.081 27.3096 107.952L27.3096 79.0361C27.3096 76.9068 25.5834 75.1807 23.4541 75.1807H11.5666C9.43729 75.1807 7.71116 76.9068 7.71116 79.0361L7.71116 107.952C7.71116 110.081 9.43729 111.807 11.5666 111.807Z"
            fill="#EFF1F6"
          />
          <path
            d="M34.0558 111.807H45.9434C48.0727 111.807 49.7988 110.081 49.7988 107.952L49.7988 79.0361C49.7988 76.9068 48.0727 75.1807 45.9434 75.1807H34.0558C31.9265 75.1807 30.2004 76.9068 30.2004 79.0361L30.2004 107.952C30.2004 110.081 31.9265 111.807 34.0558 111.807Z"
            fill="#EFF1F6"
          />
          <path
            d="M56.5461 111.807H68.4336C70.5629 111.807 72.2891 110.081 72.2891 107.952V79.0361C72.2891 76.9068 70.5629 75.1807 68.4336 75.1807H56.5461C54.4168 75.1807 52.6907 76.9068 52.6907 79.0361V107.952C52.6907 110.081 54.4168 111.807 56.5461 111.807Z"
            fill="#EFF1F6"
          />
        </g>
        <defs>
          <clipPath id="clip0_5417_25231">
            <rect width="80" height="119.518" fill="white" />
          </clipPath>
        </defs>
      </SVG>
    </Container>
  );
};

export default MediaKitTypeIcon;
