/* eslint-disable consistent-return */
/* eslint-disable default-case */
import styled from 'styled-components';
import { AiOutlineUser } from 'react-icons/ai';
import { BsGenderMale, BsGenderFemale } from 'react-icons/bs';

type Props = {
  size?: boolean;
  network?: string;
};

export const MaleIcon = styled(BsGenderMale)``;

export const FemaleIcon = styled(BsGenderFemale)``;

export const OtherIcon = styled(AiOutlineUser)``;

export const SocialNetworkMainContainer = styled.div`
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  min-height: 2.9rem;
`;

export const SocialNetworkContainer = styled.div<Props>`
  width: ${({ size }) => (size ? '4.7rem' : '2.5rem')};
  height: ${({ size }) => (size ? '4.7rem' : '2.5rem')};
  border-radius: 10rem;
  outline: 0.2rem solid white;
  display: flex;
  background-color: ${({ network }) => {
    switch (network) {
      case 'instagram-pro':
        return '#f1e6ff';
      case 'facebook':
        return '#e1eeff';
      case 'facebook-pages':
        return '#ffe6cb';
      case 'instagram':
        return '#ffe6fa';
      case 'twitter':
        return '#e0f3ff';
    }
  }};

  svg {
    margin: auto;
  }
`;

export const SocialNetworkImage = styled.img<Props>`
  width: ${({ size }) => (size ? '4.7rem' : '2.5rem')};
`;

export const SocialNetworkLabel = styled.div`
  margin-left: 1rem;
  font-weight: 400;
`;

export const NetworkList = styled.li`
  margin-left: -0.8rem;

  &:first-child {
    margin-left: 0;
  }
`;
