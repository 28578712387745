/* eslint-disable no-empty */
/* eslint-disable consistent-return */
/* eslint-disable import/extensions */
import styled, { css, useTheme } from 'styled-components';
import { useState, useEffect } from 'react';

import SVG from './Styles';

interface IQuestionMarkIconProps {
  width?: string;
  height?: string;
  color?: string;
  usage?: string;
  containerMarginTop?: string;
  containerMarginBottom?: string;
  containerMarginLeft?: string;
  containerMarginRight?: string;
  cursor?: string;
  id?: string;
}

interface IContainer {
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
  cursor?: string;
}

const Container = styled.div<IContainer>`
  margin-top: ${props => props.marginTop ?? '0'};
  margin-bottom: ${props => props.marginBottom ?? '0'};
  margin-left: ${props => props.marginLeft ?? '0'};
  margin-right: ${props => props.marginRight ?? '0'};
  display: flex;
  align-items: center;
  ${props => {
    if (props.cursor) {
      return css`
        cursor: ${props.cursor};
      `;
    }
  }};
`;

const QuestionMarkIcon = (props: IQuestionMarkIconProps) => {
  const themeGlobal = useTheme();
  const [svgColor, setSvgColor] = useState<string | undefined>();

  useEffect(() => {
    if (props.color) setSvgColor(props.color);
    else if (props.usage) {
    } else {
      setSvgColor((themeGlobal as any)['question-mark-icon'].color);
    }
  }, [props.color]);

  return (
    <Container
      marginTop={props.containerMarginTop ?? '0'}
      marginBottom={props.containerMarginBottom ?? '0'}
      marginLeft={props.containerMarginLeft ?? '0'}
      marginRight={props.containerMarginRight ?? '0'}
      cursor={props.cursor}
      id={props.id}
    >
      <SVG
        viewBox="0 0 32 32"
        width={props.width ? props.width : '100%'}
        height={props.height ? props.height : '100%'}
      >
        <path
          fill={svgColor}
          d="M26.563 15.352c0 0.415 0 0.829 0 1.243-0.055 0.403-0.095 0.807-0.165 1.207-0.463 2.583-1.684 4.749-3.739 6.373-3.237 2.559-6.872 3.171-10.687 1.6-4.116-1.695-6.309-4.916-6.615-9.356-0.144-2.096 0.379-4.075 1.479-5.871 1.68-2.747 4.113-4.427 7.285-5.019 0.399-0.075 0.804-0.111 1.207-0.164 0.415 0 0.829 0 1.244 0 0.053 0.012 0.107 0.031 0.16 0.035 1.496 0.101 2.912 0.496 4.22 1.221 2.985 1.657 4.815 4.165 5.447 7.528 0.075 0.397 0.111 0.801 0.164 1.203zM20.059 13.035c-0.011-0.841-0.317-1.559-0.915-2.149-0.623-0.615-1.385-0.945-2.24-1.067-1.421-0.203-2.744 0.028-3.865 0.987-0.696 0.596-1.132 1.345-1.196 2.281-0.029 0.443 0.18 0.759 0.54 0.977 0.585 0.355 1.259 0.095 1.533-0.579 0.125-0.305 0.232-0.62 0.377-0.917 0.18-0.368 0.404-0.713 0.799-0.891 0.54-0.243 1.105-0.267 1.656-0.072 1 0.353 1.369 1.495 0.736 2.3-0.111 0.14-0.232 0.275-0.361 0.397-0.507 0.479-1.032 0.94-1.525 1.432-0.627 0.624-0.889 1.4-0.843 2.277 0.031 0.571 0.385 0.935 0.931 0.979 0.541 0.044 0.899-0.229 1.040-0.795 0.031-0.12 0.064-0.24 0.087-0.361 0.083-0.443 0.288-0.813 0.621-1.119 0.54-0.495 1.079-0.989 1.608-1.496 0.62-0.595 1.033-1.292 1.017-2.185zM17.072 20.995c0.011-0.696-0.503-1.236-1.189-1.251-0.713-0.015-1.268 0.508-1.28 1.209-0.013 0.705 0.5 1.229 1.216 1.24 0.717 0.013 1.241-0.489 1.253-1.199z"
        />
      </SVG>
    </Container>
  );
};

export default QuestionMarkIcon;
