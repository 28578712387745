/* eslint-disable import/extensions */
import styled, { useTheme } from 'styled-components';
import { useState, useEffect } from 'react';

import SVG from './Styles';

interface ITrashCanIconV1Props {
  containerWidth?: string;
  containerHeight?: string;
  containerPaddingTop?: string;
  containerPaddingBottom?: string;
  containerPaddingLeft?: string;
  containerPaddingRight?: string;
  containerMarginTop?: string;
  containerMarginBottom?: string;
  containerMarginLeft?: string;
  containerMarginRight?: string;
  svgWidth?: string;
  svgHeight?: string;
  rotate?: string;
  cursor?: string;
}

interface IContainerProps {
  width?: string;
  height?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
}

const Container = styled.div<IContainerProps>`
  border-width: 0;
  outline: 0;
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '0')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '0')};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '0')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '0')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
  display: inline-flex;
  justify-content: center;
  align-items: center;
`;

const TrashCanIconV1 = (props: ITrashCanIconV1Props) => {
  const themeGlobal = useTheme();
  const [svgColor, setSvgColor] = useState<string>(() => {
    return (themeGlobal as any).icons.trashCanIconV1;
  });

  useEffect(() => {
    setSvgColor((themeGlobal as any).icons.trashCanIconV1);
  }, [(themeGlobal as any).darkMode]);

  return (
    <Container
      width={props.containerWidth}
      height={props.containerHeight}
      paddingTop={props.containerPaddingTop}
      paddingBottom={props.containerPaddingBottom}
      paddingLeft={props.containerPaddingLeft}
      paddingRight={props.containerPaddingRight}
      marginTop={props.containerMarginTop}
      marginBottom={props.containerMarginBottom}
      marginLeft={props.containerMarginLeft}
      marginRight={props.containerMarginRight}
    >
      <SVG
        viewBox="0 0 32 32"
        width={props.svgWidth ? props.svgWidth : '100%'}
        height={props.svgHeight ? props.svgHeight : '100%'}
        rotateNotNative={props.rotate}
        cursor={props.cursor}
      >
        <path
          fill={svgColor}
          d="M28 6.667h-5.333v-1.333c0-1.068-0.416-2.073-1.172-2.828-0.755-0.756-1.76-1.172-2.828-1.172h-5.333c-1.068 0-2.073 0.416-2.828 1.172-0.756 0.755-1.172 1.76-1.172 2.828v1.333h-5.333c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333h1.333v17.333c0 1.068 0.416 2.073 1.172 2.828 0.755 0.756 1.76 1.172 2.828 1.172h13.333c1.068 0 2.073-0.416 2.828-1.172s1.172-1.76 1.172-2.828v-17.333h1.333c0.737 0 1.333-0.597 1.333-1.333s-0.596-1.333-1.333-1.333zM12 5.333c0-0.351 0.143-0.695 0.391-0.943s0.592-0.391 0.943-0.391h5.333c0.351 0 0.695 0.143 0.943 0.391 0.249 0.248 0.391 0.592 0.391 0.943v1.333h-8v-1.333zM24 26.667c0 0.351-0.141 0.695-0.391 0.943s-0.592 0.391-0.943 0.391h-13.333c-0.351 0-0.695-0.141-0.943-0.391-0.248-0.248-0.391-0.592-0.391-0.943v-17.333h16v17.333zM14.667 14.667v8c0 0.737-0.597 1.333-1.333 1.333s-1.333-0.596-1.333-1.333v-8c0-0.736 0.597-1.333 1.333-1.333s1.333 0.597 1.333 1.333zM20 14.667v8c0 0.737-0.596 1.333-1.333 1.333s-1.333-0.596-1.333-1.333v-8c0-0.736 0.596-1.333 1.333-1.333s1.333 0.597 1.333 1.333z"
        ></path>
      </SVG>
    </Container>
  );
};

export default TrashCanIconV1;
