/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { UncontrolledTooltip } from 'reactstrap';

import {
  ActionsContainerProps,
  EditConfigurationTooltipProps,
  PortfolioPublicUserImageProps,
} from './types';

export const Container = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: start;
  padding-left: 0.8rem;
`;

export const InnerContainer = styled.div`
  width: 80%;
  border-radius: 2.4rem;
  height: calc(100vh - 10.5rem);
  background-color: ${props =>
    props.theme['additional-data-step']['background-color']};
  box-shadow: ${props =>
    `0rem 1.6rem 2.4rem ${props.theme['additional-data-step']['box-shadow-color-1']}, 0rem 0.6rem 3rem ${props.theme['additional-data-step']['box-shadow-color-2']}, 0rem 0.8rem 1rem ${props.theme['additional-data-step']['box-shadow-color-3']}`};
  margin: 2rem 0;

  @media (max-width: 1245px) {
    width: 95%;
  }

  @media (max-width: 600px) {
    width: 100%;
    height: calc(100vh - 5.7rem - 4.4rem);
  }
`;

export const Card = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const HeaderOuterContainer = styled.div`
  height: 7.7rem;
  margin-bottom: 2rem;
  border-bottom: 0.1rem solid
    ${props => props.theme.signin['signin-content-divider-color']};
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  padding: 0 3.2rem;
  font-size: 2rem;
  font-weight: 600;
  color: ${props => props.theme.text['color-26']};

  @media (max-width: 600px) {
    padding: 0 1.6rem;
  }
`;

export const HeaderBackArrowContainer = styled.div`
  height: 4.2rem;
  width: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  transition: all 0.08s;
  opacity: 1;
  border-radius: 0.8rem;
  cursor: pointer;
  & path {
    fill: ${props =>
      props.theme.signin['signin-forgot-password-back-arrow-color']};
  }

  &:hover {
    background-color: ${props =>
      props.theme.signin[
        'signin-forgot-password-back-arrow-container-background-color-hover'
      ]};
  }
`;

export const HeaderTitleContainer = styled.div`
  font-size: 2rem;
  font-weight: 600;
  color: ${props => props.theme.text['text-26']};
  margin: 0 auto;
  text-align: center;
`;

export const HiddenHeaderElement = styled.div`
  height: 4rem;
  width: 4rem;
  visibility: hidden;
`;

export const BenchmarkListItemCard = styled.div`
  padding: 0;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 0.3rem 0 rgb(239 244 255 / 0%);
  transition: all 0.3s ease;
  border-radius: 2rem;
  background-color: #fff;
  margin-left: 0.8rem;
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
  flex-wrap: wrap;
  row-gap: 1rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 0.1rem solid
    ${props => props.theme.signin['signin-content-divider-color']};
`;

export const CardInnerContainer = styled.div`
  padding: 0 3.2rem;
  display: flex;
  flex-direction: column;
  height: calc(100% - 18.5rem);
`;

export const BenchmarkListItemNetwork = styled.div`
  flex: 0 0 8rem;
  display: flex;
  align-items: center;
  padding-right: 1rem;
  padding-left: 1rem;
  min-width: 0;
  max-width: 100%;
  position: relative;
  width: 100%;
`;

export const BenchmarkListItemNetworkIcon = styled.img`
  width: 75%;
  vertical-align: middle;
  border-style: none;
`;

export const SocialAccountCardContainer = styled.div`
  width: 7rem;
  height: 7rem;

  @media (max-width: 1030px) {
    width: 30%;
  }

  @media (max-width: 820px) {
    width: 50%;
  }
`;

export const NameContainer = styled.div`
  width: 24rem;
  padding: 0 1rem;
  height: 7rem;
  display: flex;
  align-items: center;

  @media (max-width: 1030px) {
    width: 40%;
  }

  @media (max-width: 820px) {
    width: 50%;
  }
`;

export const CreatedAtContainer = styled.div`
  width: 12rem;
  padding: 0 1rem;
  height: 7rem;
  display: flex;
  align-items: center;

  @media (max-width: 1030px) {
    width: 30%;
  }

  @media (max-width: 820px) {
    width: 50%;
  }
`;

export const UpdatedAtContainer = styled.div`
  width: 19.5rem;
  padding: 0 1rem;
  height: 7rem;
  display: flex;
  align-items: center;

  @media (max-width: 1030px) {
    width: 30%;
  }

  @media (max-width: 820px) {
    width: 50%;
  }
`;

export const DurationContainer = styled.div`
  width: 25rem;
  padding: 0 1rem;
  height: 7rem;
  display: flex;
  align-items: center;
`;

export const ActionsContainer = styled.div<ActionsContainerProps>`
  display: flex;
  align-items: center;
  padding: 0 1rem;
  height: 7rem;
  width: ${props => (props.singleAction ? '7.3rem' : '11.4rem')};

  @media (max-width: 1030px) {
    width: 40%;
  }

  @media (max-width: 820px) {
    width: 50%;
  }
`;

export const ActionsInnerContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: start;
  width: 100%;
  height: auto;
`;

export const CopyConfigurationUrlContainer = styled.div`
  width: 3rem;
  margin-right: 1rem;
`;

export const EditConfigurationContainer = styled.div`
  width: auto;
  height: auto;
  margin-right: 0.5rem;
`;

export const OpenContainer = styled.div`
  width: 3rem;
`;

export const ActiveContainer = styled.div`
  width: 6rem;
  padding: 0 1rem;
  height: 7rem;
  display: flex;
  align-items: center;

  @media (max-width: 1030px) {
    width: 30%;
  }

  @media (max-width: 820px) {
    width: 50%;
  }
`;

export const BenchmarkListItemCol = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  height: auto;
  align-items: center;
  flex-wrap: wrap;
`;

export const BenchmarkListItemNameContainer = styled.div`
  width: 100%;
`;

export const BenchmarkListItemTitle = styled.h2`
  font-size: 1.28rem;
  padding-top: 0;
  color: #8492a6;
  font-weight: 300;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 1rem;
`;

export const PortfolioPublicUserImagesTitle = styled(BenchmarkListItemTitle)`
  margin-bottom: 0.5rem;
`;

export const BenchmarkListItemLegend = styled.p`
  font-weight: 400;
  font-size: 1.6rem;
  text-overflow: unset;
  width: 100%;
  overflow-x: auto;
  margin-bottom: 0;
  margin-top: 0;
  white-space: nowrap;
  padding-bottom: 0.2rem;

  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
`;

export const BenchmarkListItemType = styled.div`
  width: 100%;
  position: relative;
`;

export const ViewsColumnInnerContainer = styled.div`
  width: auto;
`;

export const EyeIconContainer = styled.div`
  & svg {
    cursor: pointer;
  }
`;

export const SocialAccountContainer = styled.div`
  height: 7rem;
  width: 23rem;
  display: flex;
  border-radius: 1.5rem;
  padding: 0.5rem;
`;

export const SocialAccountLeftContainer = styled.div`
  height: 6rem;
  width: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;

export const SocialAccountImage = styled.img`
  height: 6.9rem;
  width: 7rem;
  border-radius: 50%;
  object-fit: cover;
`;

export const SocialAccountNetworkIconContainer = styled.div`
  padding: 0.2rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  position: absolute;
  top: 60%;
  left: 60%;
  background-color: white;
`;

export const SocialAccountRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding-left: 0.8rem;
`;

export const SocialAccountUsername = styled.div`
  padding-bottom: 0.9rem;
  color: ${props => props.theme.text['color-26']};
  font-size: 1.5rem;
  font-weight: 600;
  width: 13rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
`;

export const SocialAccountNetworkName = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.2rem;
  color: ${props => props.theme.text['color-27']};
  position: relative;
  display: inline-block;
  margin-top: 0.9rem;
  height: 1.4rem;
  user-select: none;
  max-width: 13rem;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ConfigurationFiltersOuterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
  flex-wrap: wrap;
`;

export const EmptyInfoContainer = styled.div`
  background-color: #fff;
  padding: 2rem;
  border-radius: 0.6rem;
  margin-bottom: 2rem;
  margin-top: 4.8rem;
  display: flex;
  justify-content: center;
`;

export const EditConfigurationTooltip = styled(
  UncontrolledTooltip,
)<EditConfigurationTooltipProps>`
  & > *:first-child {
    z-index: 1000;
  }
  opacity: 1 !important;
  pointer-events: ${props => (props.autohide === false ? 'all' : 'none')};

  .tooltip-inner {
    display: inline-block;
    width: auto !important;
    position: relative;
    color: #333;
    background-color: #fff;
    border: 0.1rem solid #eeeeee;
    border-radius: 0.5rem;
    padding: 1rem 1.5rem;
    margin: 0 0 1rem 0;
    filter: drop-shadow(rgba(0, 0, 0, 0.6) 0 0.2rem 0.3rem);
    top: 0rem;
  }

  .arrow {
    display: none !important;
  }
`;

export const PortfolioPublicUserImage = styled.img<PortfolioPublicUserImageProps>`
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 50%;
  border: 3px solid white;
  margin-left: ${props => (props.index ? -11 : 0)}px;
  margin-bottom: 0.2rem;
`;

export const FooterContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: end;
  padding: 2rem 3.2rem;
  border-top: 0.1rem solid
    ${props => props.theme.signin['signin-content-divider-color']};
`;

export const ConfigurationListContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  margin: 0 -3.2rem;
  padding: 0 3.2rem;
  position: relative;

  & > div:first-child {
    padding-top: 0;
  }

  & > div:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }
`;

export const GearIconContainer = styled.div`
  width: 2.4rem;
  height: 2.4rem;

  & svg path {
    fill: #8492a6;
  }
`;

export const ActiveCheckMarkIconContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;

  & svg path {
    fill: #14b56f;
  }
`;

export const UnavaiableConfigurationSettingsTooltipMessage = styled.div`
  display: flex;
  align-items: center;
  max-width: 31rem;
`;

export const UnavailableConfigurationTooltipRightContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > div:first-child {
    line-height: 2.1rem;
  }
`;

export const UnavailableConfigurationTooltipLinkContainer = styled.div`
  display: flex;
  margin-top: 0.5rem;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  width: 10.2rem;
`;

export const UnavailableConfigurationTooltipInfoIconContainer = styled.div`
  & svg {
    margin-bottom: 2.7rem;
  }
`;

export const NotifyInnerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
