/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import { ErrorBoundary } from 'react-error-boundary';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import {
  globalThemeLight,
  globalThemeDark,
  ThemeTogglerContext,
} from './css/ThemeGlobal';
/* Components */
import ErrorFallback from './Errors/ErrorBoundary';
import MenuNavbarV2 from './components/MenuNavbarV2/MenuNavbarV2';
/* Auth Pages */
import SignUp from './pages/SignUp/SignUp';
import SignIn from './pages/SignIn/SignIn';
/* Business/Brand Profile Pages */
import PublicUserMediaKit from './pages/PublicUserMediaKit/PublicUserMediaKit';
/* Other Pages */
import AccountActivation from './pages/AccountActivation/AccountActivation';
import AdditionalDataStep from './pages/AdditionalDataStep/AdditionalDataStep';
import DeleteAccount from './pages/DeleteAccount/DeleteAccount';
import InfluencerProfileEdit from './pages/InfluencerProfileEdit/InfluencerProfileEdit';
import SocialAccountsManagement from './pages/SocialAccountsManagement/SocialAccountsManagement';
import MediaKitConfigurationsList from './pages/MediaKitConfigurationsList/MediaKitConfigurationsList';
import MyAnalytics from './pages/MyAnalytics/MyAnalytics';
import CreateMediaKitConfiguration from './pages/CreateMediaKitConfiguration/CreateMediaKitConfiguration';
import CreatePortfolioConfiguration from './pages/CreatePortfolioConfiguration/CreatePortfolioConfiguration';
import { isAuthenticated } from './Services/Utils/Utils';
import BusinessProfiles from './pages/BusinessProfiles/BusinessProfiles';
import BusinessProfile from './pages/BusinessProfile/BusinessProfile';

function App() {
  const [themeGlobal, setThemeGlobal] = useState(() => {
    const darkMode = localStorage.getItem('darkMode') || 'false';
    if (darkMode === 'true') return globalThemeDark;
    return globalThemeLight;
  });

  const toggleTheme = () => {
    const darkMode = localStorage.getItem('darkMode') || 'false';
    if (darkMode === 'true') {
      setThemeGlobal(globalThemeLight);
      localStorage.setItem('darkMode', 'false');
    } else {
      setThemeGlobal(globalThemeDark);
      localStorage.setItem('darkMode', 'true');
    }
  };

  const AppProvider = ({ children }: any) => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
      if (
        !(
          location.pathname === '/' ||
          location.pathname === '/signup' ||
          location.pathname === '/delete-account'
        ) &&
        !isAuthenticated()
      ) {
        navigate('/');
      }
    }, [location.pathname]);

    return <>{children}</>;
  };

  return (
    <>
      <ThemeProvider theme={themeGlobal}>
        <ThemeTogglerContext.Provider value={toggleTheme}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Router>
              <AppProvider>
                <MenuNavbarV2>
                  <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                  />
                  <Routes>
                    {/* Auth Routes */}
                    <Route path="/" element={<SignIn />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/delete-account" element={<DeleteAccount />} />
                    <Route
                      path="/account-activation"
                      element={<AccountActivation />}
                    />
                    <Route
                      path="/additional-data-step"
                      element={<AdditionalDataStep />}
                    />

                    <Route
                      path="/influencer/profile-edit"
                      element={<InfluencerProfileEdit />}
                    />

                    <Route
                      path="/social-accounts-management"
                      element={<SocialAccountsManagement />}
                    />

                    {/* Media Kit Routes */}
                    <Route path="/my-analytics" element={<MyAnalytics />} />
                    <Route
                      path="/user-media-kit/:publicUserId"
                      element={<PublicUserMediaKit />}
                    />
                    <Route
                      path="/view-media-kit-configurations-list"
                      element={<MediaKitConfigurationsList />}
                    />
                    <Route
                      path="/media-kit-configuration/create"
                      element={<CreateMediaKitConfiguration />}
                    />
                    <Route
                      path="/media-kit-configuration/edit/:id"
                      element={<CreateMediaKitConfiguration />}
                    />
                    <Route
                      path="/portfolio-configuration/create"
                      element={<CreatePortfolioConfiguration />}
                    />
                    <Route
                      path="/portfolio-configuration/edit/:id"
                      element={<CreatePortfolioConfiguration />}
                    />

                    {/* Business Account Routes */}
                    <Route
                      path="/business-profiles"
                      element={<BusinessProfiles />}
                    />
                    <Route
                      path="/business-profile/create"
                      element={<BusinessProfile />}
                    />
                    <Route
                      path="/business-profile/edit/:id"
                      element={<BusinessProfile />}
                    />
                  </Routes>
                </MenuNavbarV2>
              </AppProvider>
            </Router>
          </ErrorBoundary>
        </ThemeTogglerContext.Provider>
      </ThemeProvider>
    </>
  );
}

export default App;
