import { AxiosInstance } from 'axios';
import queryString from 'query-string';

import config from '../../../config';
import {
  DeleteUserBrandPictureParams,
  DeleteUserBrandPictureResponse,
  ExternalMediaKitConfiguration,
  GenerateExternalMediaKitUrlParams,
  GenerateExternalMediaKitUrlResponse,
  GeneratePortfolioUrlParams,
  GeneratePortfolioUrlResponse,
  GetExternalMediaKitConfigurationsByUserIdResponse,
  GetPortfolioByBrandPictureInput,
  GetPortfolioByBrandPictureOutput,
  GetUserBrandPicturesResponse,
  UpdateExternalMediaKitByIdParams,
  UpdateExternalMediaKitByIdResponse,
  UpdatePortfolioByIdParams,
  UploadPortfolioImageInput,
  UploadPortfolioImageOutput,
  UploadUserBrandPictureParams,
} from './types';

const createExternalMediaKitInstanceApi = (instance: AxiosInstance) => {
  const getExternalMediaConfigurationById = async (id: string) => {
    const url = `${config.ENDPOINTS.EXTERNAL_MEDIA_KIT.GET_EXTERNAL_MEDIA_KIT_CONFIGURATION_BY_ID}/${id}`;

    const {
      data: configuration,
    }: {
      data: ExternalMediaKitConfiguration;
    } = await instance.get(url);

    return configuration;
  };

  const getExternalMediaConfigurationsByUserId = async (userId: string) => {
    const url = `${config.ENDPOINTS.EXTERNAL_MEDIA_KIT.GET_EXTERNAL_MEDIA_KIT_CONFIGURATIONS_BY_USER_ID}/${userId}`;

    const {
      data: configurations,
    }: {
      data: GetExternalMediaKitConfigurationsByUserIdResponse;
    } = await instance.get(url);

    return configurations;
  };

  const generateExternalMediaKitUrl = async (
    data: GenerateExternalMediaKitUrlParams,
  ) => {
    const url = `${config.ENDPOINTS.EXTERNAL_MEDIA_KIT.GENERATE_EXTERNAL_MEDIA_KIT_URL}`;

    const response: GenerateExternalMediaKitUrlResponse = await instance.post(
      url,
      data,
    );

    return response;
  };

  const updateExternalMediaKitById = async (
    data: UpdateExternalMediaKitByIdParams | UpdatePortfolioByIdParams,
  ) => {
    const url = `${config.ENDPOINTS.EXTERNAL_MEDIA_KIT.UPDATE_EXTERNAL_MEDIA_KIT}`;

    const response: UpdateExternalMediaKitByIdResponse = await instance.post(
      url,
      data,
    );

    return response;
  };

  const getPortfolioByBrandPicture = async ({
    brandPicture,
    limit,
  }: GetPortfolioByBrandPictureInput) => {
    const query = queryString.stringify(
      {
        brandPicture,
        limit,
      },
      { arrayFormat: 'bracket' },
    );

    const url = `${config.ENDPOINTS.EXTERNAL_MEDIA_KIT.GET_PORTFOLIO_BY_BRAND_PICTURE}?${query}`;

    const { data: result } = await instance.get<{
      data: GetPortfolioByBrandPictureOutput;
    }>(url);

    return result as unknown as GetPortfolioByBrandPictureOutput;
  };

  const uploadPortfolioImage = async (params: UploadPortfolioImageInput) => {
    const url = `${config.ENDPOINTS.EXTERNAL_MEDIA_KIT.UPLOAD_PORTFOLIO_IMAGE}`;

    const { data: result } = await instance.patch(url, params);

    return result as unknown as UploadPortfolioImageOutput;
  };

  const uploadUserBrandPicture = async (
    params: UploadUserBrandPictureParams,
  ) => {
    const url = `${config.ENDPOINTS.EXTERNAL_MEDIA_KIT.UPLOAD_USER_BRAND_PICTURE}`;

    const { data: result } = await instance.patch(url, params);

    return result as unknown as string;
  };

  const generatePortfolioUrl = async (data: GeneratePortfolioUrlParams) => {
    const url = `${config.ENDPOINTS.EXTERNAL_MEDIA_KIT.GENERATE_EXTERNAL_PORTFOLIO_URL}`;

    const response: GeneratePortfolioUrlResponse = await instance.post(
      url,
      data,
    );

    return response;
  };

  const deleteUserBrandPicture = async (data: DeleteUserBrandPictureParams) => {
    const query = queryString.stringify(
      {
        url: data.url,
      },
      { arrayFormat: 'bracket' },
    );

    const url = `${config.ENDPOINTS.EXTERNAL_MEDIA_KIT.DELETE_USER_BRAND_PICTURE}?${query}`;

    const response: DeleteUserBrandPictureResponse = await instance.put(url);

    return response;
  };

  const getUserBrandPictures =
    async (): Promise<GetUserBrandPicturesResponse> => {
      const url = `${config.ENDPOINTS.EXTERNAL_MEDIA_KIT.GET_USER_BRAND_PICTURES}`;

      const { data: response } = await instance.get(url);

      return response;
    };

  return {
    getExternalMediaConfigurationById,
    getExternalMediaConfigurationsByUserId,
    generateExternalMediaKitUrl,
    generatePortfolioUrl,
    updateExternalMediaKitById,
    getPortfolioByBrandPicture,
    uploadPortfolioImage,
    deleteUserBrandPicture,
    uploadUserBrandPicture,
    getUserBrandPictures,
  };
};

export default {
  createExternalMediaKitInstanceApi,
};
