import styled from 'styled-components';
import { TiTimes } from 'react-icons/ti';

import { ITitleProps } from './types';

export const OuterContainer = styled.div`
  height: 100%;
`;

export const Container = styled.div`
  width: 100%;
  min-height: calc(
    100vh - ${props => props.theme['back-navigation-button-navbar'].height}
  );
  display: flex;
  justify-content: center;
  align-items: start;
`;

export const InnerContainer = styled.div`
  width: 75%;
  border-radius: 2.4rem;
  height: 57rem;
  background-color: ${props =>
    props.theme['additional-data-step']['background-color']};
  box-shadow: ${props =>
    `0rem 1.6rem 2.4rem ${props.theme['additional-data-step']['box-shadow-color-1']}, 0rem 0.6rem 3rem ${props.theme['additional-data-step']['box-shadow-color-2']}, 0rem 0.8rem 1rem ${props.theme['additional-data-step']['box-shadow-color-3']}`};
  margin-top: 3rem;

  @media (max-width: 1180px) {
    width: 85%;
  }
  @media (max-width: 630px) {
    width: 95%;
  }
`;

export const HeaderOuterContainer = styled.div`
  height: 7.7rem;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  padding: 0 3.2rem;
  font-size: 2rem;
  font-weight: 600;
  color: ${props => props.theme.text['color-26']};
`;

export const HeaderBackArrowContainer = styled.div`
  height: 4.2rem;
  width: 4.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  transition: all 0.08s;
  opacity: 1;
  border-radius: 0.8rem;
  cursor: pointer;
  & path {
    fill: ${props =>
      props.theme.signin['signin-forgot-password-back-arrow-color']};
  }

  &:hover {
    background-color: ${props =>
      props.theme.signin[
        'signin-forgot-password-back-arrow-container-background-color-hover'
      ]};
  }
`;

export const HeaderTitleContainer = styled.div`
  font-size: 2rem;
  font-weight: 600;
  color: ${props => props.theme.text['text-26']};
  margin: 0 auto;
`;

export const HiddenHeaderElement = styled.div`
  height: 4rem;
  width: 4rem;
  visibility: hidden;
`;

export const HeaderDivider = styled.div`
  width: 100%;
  height: 0.1rem;
  background-color: ${props =>
    props.theme.signin['signin-content-divider-color']};
`;

export const CardInnerContainer = styled.div`
  margin-top: 4rem;
  padding: 0 5.5rem;
  height: calc(100% - 20.6rem);
  overflow-y: auto;

  @media (max-width: 630px) {
    padding: 0 2rem;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.4rem;
`;

export const Title = styled.div<ITitleProps>`
  color: ${props => props.theme.text['color-26']};
  font-size: 1.8rem;
  font-weight: 600;
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  text-align: left;
`;

export const BusinessProfileCard = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
`;

export const BPDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 0;
  min-height: 100%;
  margin-bottom: 0;
  border-bottom: 0.1rem solid ${props => props.theme.text['color-18']};

  @media (max-width: 975px) {
    width: 100%;
  }
`;

export const BPDetailsInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0;
  height: 100%;
`;

export const BPDetailsInnerFirstRowContainer = styled.div`
  display: flex;

  @media (max-width: 975px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const BPTopicsInterestCountryContainer = styled.div`
  display: flex;
`;

export const BPImagePickerContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 26rem;
  margin-right: 7rem;

  @media (max-width: 975px) {
    margin-right: 0;
    margin-bottom: 2rem;
  }
`;

export const BPImageContainer = styled.div`
  width: 26rem;
  height: 16rem;
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 0.5rem;
`;

export const BPImage = styled.img`
  width: 90%;
  height: 100%;
  object-fit: cover;
`;

export const BPImagePickerButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const BPImageCrossContainer = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const BPImageCross = styled(TiTimes)``;

export const BPNameAndDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  flex-grow: 1;

  @media (max-width: 975px) {
    width: 100%;
  }
`;

export const BPNameAndDescriptionInnerContainer = styled.div`
  width: 100%;

  & textarea {
    resize: vertical;
  }
`;

export const BPNameInputTitleContainer = styled.div`
  @media (max-width: 729px) {
    display: flex;
    width: 100%;
    justify-content: left;
  }
`;

export const BPNameInputControlContainer = styled.div`
  width: 41rem;

  @media (max-width: 729px) {
    width: 100%;
  }
`;

export const LegalDetailsContainer = styled.div`
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  border-bottom: 0.1rem solid ${props => props.theme.text['color-18']};
`;

export const BPDetailsInnerSecondRowContainer = styled.div`
  display: flex;
  justify-content: end;

  & button {
    border-radius: 0.6rem;
    padding-left: 1.4rem;
    padding-right: 1.4rem;
  }

  & > button:first-child {
    margin-right: 1rem;
  }
`;

export const BusinessProfileFooter = styled.div`
  display: flex;
  justify-content: end;
  padding: 0 5.5rem;
  margin-top: auto;
  margin-bottom: 2rem;

  & > button {
    border-radius: 0.6rem;
    padding-left: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 2rem;
  }

  & > button:first-child {
    margin-right: 1rem;
  }

  @media (max-width: 975px) {
    padding: 0 6rem;
  }

  @media (max-width: 630px) {
    padding: 0 2.5rem;
  }
`;

export const SaveButtonContainer = styled.div``;

export const NotifyInnerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
