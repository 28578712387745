/* eslint-disable eqeqeq */
/* eslint-disable consistent-return */
/* eslint-disable import/extensions */
import styled, { useTheme, css, DefaultTheme } from 'styled-components';
import { useState, useEffect } from 'react';

import SVG from './Styles';

interface ICrossIcon_V2Props {
  containerWidth?: string;
  containerHeight?: string;
  containerPaddingTop?: string;
  containerPaddingBottom?: string;
  containerPaddingLeft?: string;
  containerPaddingRight?: string;
  containerMarginTop?: string;
  containerMarginBottom?: string;
  containerMarginLeft?: string;
  containerMarginRight?: string;
  useCase?: 'topicsOfInterestComboBox' | 'errorBoundary';
  svgWidth?: string;
  svgHeight?: string;
  rotate?: string;
  cursor?: string;
}

interface IContainerProps {
  width?: string;
  height?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
  useCase?: 'topicsOfInterestComboBox' | 'errorBoundary';
  themeGlobal: DefaultTheme;
}

const Container = styled.div<IContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 0;
  outline: 0;
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '0')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '0')};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '0')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '0')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};

  ${props => {
    if (props.useCase == 'topicsOfInterestComboBox') {
      return css`
        &:hover {
          svg path {
            fill: ${(props.themeGlobal as any).comboBoxTopicsOfInterest[
              'option-selected-cross-hover-color'
            ]};
          }
        }
      `;
    }
  }}
`;

const CrossIcon_V2 = (props: ICrossIcon_V2Props) => {
  const themeGlobal = useTheme();
  const [svgColor, setSvgColor] = useState<string>(() => {
    switch (props.useCase) {
      case 'topicsOfInterestComboBox': {
        return (themeGlobal as any).comboBoxTopicsOfInterest[
          'option-selected-cross-color'
        ];
      }
      case 'errorBoundary': {
        return (themeGlobal as any)['error-boundary']['cross-icon-color'];
      }
      default: {
        return (themeGlobal as any).dropdownMenu['dropdown-menu-cross-color'];
      }
    }
  });

  useEffect(() => {
    switch (props.useCase) {
      case 'topicsOfInterestComboBox': {
        setSvgColor(
          (themeGlobal as any).comboBoxTopicsOfInterest[
            'option-selected-cross-color'
          ],
        );
        break;
      }
      case 'errorBoundary': {
        setSvgColor((themeGlobal as any)['error-boundary']['cross-icon-color']);
        break;
      }
      default: {
        setSvgColor(
          (themeGlobal as any).dropdownMenu['dropdown-menu-cross-color'],
        );
      }
    }
  }, [(themeGlobal as any).darkMode]);

  return (
    <Container
      width={props.containerWidth}
      height={props.containerHeight}
      paddingTop={props.containerPaddingTop}
      paddingBottom={props.containerPaddingBottom}
      paddingLeft={props.containerPaddingLeft}
      paddingRight={props.containerPaddingRight}
      marginTop={props.containerMarginTop}
      marginBottom={props.containerMarginBottom}
      marginLeft={props.containerMarginLeft}
      marginRight={props.containerMarginRight}
      useCase={props.useCase}
      themeGlobal={themeGlobal}
    >
      <SVG
        viewBox="0 0 32 32"
        width={props.svgWidth ? props.svgWidth : '100%'}
        height={props.svgHeight ? props.svgHeight : '100%'}
        rotateNotNative={props.rotate}
        cursor={props.cursor}
      >
        <path
          fill={svgColor}
          d="M24.707 23.293c0.391 0.391 0.391 1.024 0 1.415s-1.024 0.391-1.415 0l-7.292-7.293-7.293 7.293c-0.391 0.391-1.024 0.391-1.415 0s-0.391-1.024 0-1.415l7.293-7.293-7.292-7.293c-0.391-0.391-0.391-1.024 0-1.415s1.024-0.391 1.415 0l7.293 7.293 7.293-7.293c0.391-0.391 1.024-0.391 1.415 0s0.391 1.024 0 1.415l-7.295 7.293 7.292 7.293z"
        />
      </SVG>
    </Container>
  );
};

export default CrossIcon_V2;
