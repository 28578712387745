/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable default-case */
/* eslint-disable import/extensions */
import { useContext, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

import * as S from './Styles';
import { useGetBusinessProfilesByPartnerId } from './RQCustomHooks';
import Loader from '../../components/shared/LoaderFixed/LoaderFixed';
import { isAuthenticated } from '../../Services/Utils/Utils';
import GenericModal from '../../components/shared/GenericModal/GenericModal';
import { BusinessProfilesProps } from './types';
import BackArrowIcon from '../../assets/iconComponents/BackArrowIcon';
import {
  getFormattedTimeFromDate,
  getShortenedMonthResourceKey,
} from '../../Services/Utils/dateUtils';
import EmptyInfo from '../../components/shared/EmptyInfo/EmptyInfo';
import ExclamationPointIcon from '../../assets/iconComponents/ExclamationPointIcon';
import Button from '../../components/shared/Button/Button';
import GearIconV1 from '../../assets/iconComponents/GearIconV1';
import CheckMarkIcon from '../../assets/iconComponents/CheckMarkIcon';
import TrashCanIconV1 from '../../assets/iconComponents/TrashCanIconV1';
import { useIsOperatorOrPartnerAdmin } from '../SignIn/RQCustomHooks';
import NoPermissionToAccessPageWarning from '../../components/shared/NoPermissionToAccessPageWarning/NoPermissionToAccessPageWarning';
import { useDeleteBusinessProfile } from '../BusinessProfile/RQCustomHooks';
import NotifyCustom from '../../components/shared/NotifyCustom/NotifyCustom';
import InputSpinnerIcon from '../../assets/iconComponents/InputSpinnerIcon/InputSpinnerIcon';
import { useActivateBrandProfile } from '../../Services/Utils/CustomHooks';
import { OuterContext } from '../../components/MenuNavbarV2/MenuNavbarV2';

const BusinessProfiles = (props: BusinessProfilesProps) => {
  const navigate = useNavigate();
  const [isGetBusinessProfilesEnabled, setIsGetBusinessProfilesEnabled] =
    useState(true);
  const [isOperatorOrPartnerAdminEnabled, setIsOperatorOrPartnerAdminEnabled] =
    useState(true);
  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState('');
  const {
    data: isOperatorOrPartnerAdmin,
    isFetching: isFetchingIsOperatorOrPartnerAdmin,
    isSuccess: isSuccessIsOperatorOrPartnerAdmin,
    isError: isErrorIsOperatorOrPartnerAdmin,
  } = useIsOperatorOrPartnerAdmin(isOperatorOrPartnerAdminEnabled);
  const {
    data: businessProfiles,
    isFetching: isFetchingGetBusinessProfiles,
    isSuccess: isSuccessGetBusinessProfiles,
    isError: isErrorGetBusinessProfiles,
    refetch: refetchGetBusinessProfilesByPartnerId,
  } = useGetBusinessProfilesByPartnerId(
    isGetBusinessProfilesEnabled &&
      isSuccessIsOperatorOrPartnerAdmin &&
      isOperatorOrPartnerAdmin,
    isAuthenticated()?.partnerId!,
  );
  const {
    isLoading: isDeleteBusinessProfileLoading,
    isSuccess: isDeleteBusinessProfileSuccess,
    isError: isDeleteBusinessProfileError,
    mutate: deleteBusinessProfile,
  } = useDeleteBusinessProfile();
  const {
    isLoading: isActivateBusinessProfileLoading,
    isSuccess: isActivateBusinessProfileSuccess,
    isError: isActivateBusinessProfileError,
    mutate: activateBusinessProfile,
  } = useActivateBrandProfile();
  const [
    showSuccessfulActionNotification,
    setShowSuccessfulActionNotification,
  ] = useState<boolean>(false);
  const [
    successfulActionNotificationMessage,
    setSuccessfulActionNotificationMessage,
  ] = useState('');
  const [
    loadingActionNotificationMessage,
    setLoadingActionNotificationMessage,
  ] = useState('');
  const themeGlobal = useTheme();
  const context = useContext(OuterContext);

  useEffect(() => {
    if (isSuccessGetBusinessProfiles) {
      setIsGetBusinessProfilesEnabled(false);
    }
  }, [isSuccessGetBusinessProfiles]);

  useEffect(() => {
    if (isDeleteBusinessProfileSuccess) {
      setSuccessfulActionNotificationMessage(
        props.t('businessProfile.brandProfileDeleted'),
      );
      setShowSuccessfulActionNotification(true);
      setTimeout(() => {
        setShowSuccessfulActionNotification(false);
      }, 2000);
      refetchGetBusinessProfilesByPartnerId();
      context.refetchBusinessProfilesByPartnerId();
    }
  }, [isDeleteBusinessProfileSuccess]);

  useEffect(() => {
    if (isActivateBusinessProfileSuccess) {
      setSuccessfulActionNotificationMessage(
        props.t('businessProfile.brandProfileActivated'),
      );
      setShowSuccessfulActionNotification(true);
      setTimeout(() => {
        setShowSuccessfulActionNotification(false);
      }, 2000);
      setTimeout(() => context.refreshNavbar(), 1000);
    }
  }, [isActivateBusinessProfileSuccess]);

  useEffect(() => {
    if (isErrorGetBusinessProfiles) {
      setIsGetBusinessProfilesEnabled(false);
      setHasError(true);
      setMsgError(props.t('generic.couldNotGetData'));
    }
  }, [isErrorGetBusinessProfiles]);

  useEffect(() => {
    if (isErrorIsOperatorOrPartnerAdmin) {
      setIsOperatorOrPartnerAdminEnabled(false);
      setHasError(true);
      setMsgError(props.t('generic.couldNotGetData'));
    }
  }, [isErrorIsOperatorOrPartnerAdmin]);

  useEffect(() => {
    if (isDeleteBusinessProfileError) {
      setHasError(true);
      setMsgError(props.t('businessProfile.couldNotDeleteBrandProfile'));
    }
  }, [isDeleteBusinessProfileError]);

  useEffect(() => {
    if (isActivateBusinessProfileError) {
      setHasError(true);
      setMsgError(props.t('businessProfile.couldNotActivateBrandProfile'));
    }
  }, [isActivateBusinessProfileError]);

  const errorModalCloseButtonClicked = () => {
    setHasError(false);
    if (isErrorGetBusinessProfiles) {
      setIsGetBusinessProfilesEnabled(true);
    }
    if (isErrorIsOperatorOrPartnerAdmin) {
      setIsOperatorOrPartnerAdminEnabled(true);
    }
  };

  const getCreatedAtLabel = (date: Date) => {
    const createdAtDate = new Date(Date.parse(date as unknown as string));

    const getCreatedAtDateLabel = `${props.t(
      getShortenedMonthResourceKey(createdAtDate),
    )} ${createdAtDate.getDate()}${`, ${createdAtDate.getFullYear()}`}`;

    return `${getCreatedAtDateLabel}`;
  };

  const getUpdatedAtLabel = (date: Date) => {
    const updatedAtDate = new Date(Date.parse(date as unknown as string));

    const getUpdatedAtDateLabel = `${props.t(
      getShortenedMonthResourceKey(updatedAtDate),
    )} ${updatedAtDate.getDate()}${`, ${updatedAtDate.getFullYear()} - ${getFormattedTimeFromDate(
      updatedAtDate,
      { seconds: false },
    )}`}`;

    return `${getUpdatedAtDateLabel}`;
  };

  return (
    <S.Container>
      <Loader
        show={
          isFetchingGetBusinessProfiles || isFetchingIsOperatorOrPartnerAdmin
        }
        message={props.t('generic.gettingData')}
      />

      <S.InnerContainer>
        <S.Card>
          <S.HeaderOuterContainer>
            <S.HeaderContainer>
              <S.HeaderBackArrowContainer
                onClick={() => {
                  navigate(-1);
                }}
              >
                <BackArrowIcon width={'4rem'} height={'4rem'} />
              </S.HeaderBackArrowContainer>
              <S.HeaderTitleContainer>
                {props.t('businessProfiles.title')}
              </S.HeaderTitleContainer>
              <S.HiddenHeaderElement />
            </S.HeaderContainer>
          </S.HeaderOuterContainer>

          {isSuccessIsOperatorOrPartnerAdmin && isOperatorOrPartnerAdmin ? (
            <S.CardInnerContainer>
              {isSuccessGetBusinessProfiles && businessProfiles?.length && (
                <S.ConfigurationListContainer>
                  {(businessProfiles ?? []).map(businessProfile => (
                    <S.BenchmarkListItemCard>
                      <S.SocialAccountCardContainer>
                        <S.SocialAccountImage
                          src={businessProfile.displayPicture}
                        />
                      </S.SocialAccountCardContainer>

                      <S.NameContainer>
                        <S.BenchmarkListItemCol>
                          <S.BenchmarkListItemNameContainer>
                            <S.BenchmarkListItemTitle>
                              {props.t('generic.name')}
                            </S.BenchmarkListItemTitle>
                            <S.BenchmarkListItemLegend>
                              {businessProfile.name}
                            </S.BenchmarkListItemLegend>
                          </S.BenchmarkListItemNameContainer>
                        </S.BenchmarkListItemCol>
                      </S.NameContainer>

                      <S.CreatedAtContainer>
                        <S.BenchmarkListItemCol>
                          <S.BenchmarkListItemType>
                            <S.BenchmarkListItemTitle>
                              {props.t('generic.createdAt')}
                            </S.BenchmarkListItemTitle>
                            <S.BenchmarkListItemLegend>
                              {getCreatedAtLabel(businessProfile.createdAt!)}
                            </S.BenchmarkListItemLegend>
                          </S.BenchmarkListItemType>
                        </S.BenchmarkListItemCol>
                      </S.CreatedAtContainer>

                      <S.UpdatedAtContainer>
                        <S.BenchmarkListItemCol>
                          <S.BenchmarkListItemType>
                            <S.BenchmarkListItemTitle>
                              {props.t('generic.lastUpdatedAt')}
                            </S.BenchmarkListItemTitle>
                            <S.BenchmarkListItemLegend>
                              {getUpdatedAtLabel(businessProfile.updatedAt!)}
                            </S.BenchmarkListItemLegend>
                          </S.BenchmarkListItemType>
                        </S.BenchmarkListItemCol>
                      </S.UpdatedAtContainer>

                      <S.ActionsContainer
                        singleAction={businessProfiles.length === 1}
                      >
                        <S.BenchmarkListItemCol>
                          <S.BenchmarkListItemTitle>
                            {props.t('generic.actions')}
                          </S.BenchmarkListItemTitle>
                          <S.ActionsInnerContainer>
                            <S.EditConfigurationContainer>
                              <S.ViewsColumnInnerContainer>
                                <S.EyeIconContainer
                                  id={`edit-business-profile-${businessProfile._id}`}
                                >
                                  <S.GearIconContainer
                                    onClick={() => {
                                      navigate(
                                        `/business-profile/edit/${businessProfile._id}`,
                                      );
                                    }}
                                  >
                                    <GearIconV1 />
                                  </S.GearIconContainer>
                                </S.EyeIconContainer>

                                <S.EditConfigurationTooltip
                                  placement="top"
                                  target={`edit-business-profile-${businessProfile._id}`}
                                  autohide={false}
                                >
                                  {props.t('generic.settings')}
                                </S.EditConfigurationTooltip>
                              </S.ViewsColumnInnerContainer>
                            </S.EditConfigurationContainer>

                            {isAuthenticated()?.businessProfileId !==
                              businessProfile._id &&
                              businessProfiles.length > 1 && (
                                <S.EditConfigurationContainer>
                                  <S.ViewsColumnInnerContainer>
                                    <S.EyeIconContainer
                                      id={`delete-business-profile-${businessProfile._id}`}
                                    >
                                      <S.GearIconContainer
                                        onClick={() => {
                                          setLoadingActionNotificationMessage(
                                            props.t(
                                              'businessProfile.deletingBrandProfile',
                                            ),
                                          );
                                          deleteBusinessProfile(
                                            businessProfile._id,
                                          );
                                        }}
                                      >
                                        <TrashCanIconV1
                                          containerWidth="2.4rem"
                                          containerHeight="2.4rem"
                                        />
                                      </S.GearIconContainer>
                                    </S.EyeIconContainer>

                                    <S.EditConfigurationTooltip
                                      placement="top"
                                      target={`delete-business-profile-${businessProfile._id}`}
                                      autohide={false}
                                    >
                                      {props.t('generic.delete')}
                                    </S.EditConfigurationTooltip>
                                  </S.ViewsColumnInnerContainer>
                                </S.EditConfigurationContainer>
                              )}

                            {isAuthenticated()?.businessProfileId !==
                              businessProfile._id && (
                              <S.EditConfigurationContainer>
                                <S.ViewsColumnInnerContainer>
                                  <S.EyeIconContainer
                                    id={`activate-business-profile-${businessProfile._id}`}
                                  >
                                    <S.GearIconContainer
                                      onClick={() => {
                                        setLoadingActionNotificationMessage(
                                          props.t(
                                            'businessProfile.activatingBrandProfile',
                                          ),
                                        );
                                        activateBusinessProfile(
                                          businessProfile._id,
                                        );
                                      }}
                                    >
                                      <CheckMarkIcon
                                        containerWidth="2.4rem"
                                        containerHeight="2.4rem"
                                      />
                                    </S.GearIconContainer>
                                  </S.EyeIconContainer>

                                  <S.EditConfigurationTooltip
                                    placement="top"
                                    target={`activate-business-profile-${businessProfile._id}`}
                                    autohide={false}
                                  >
                                    {props.t(
                                      'businessProfiles.activateBusinessProfile',
                                    )}
                                  </S.EditConfigurationTooltip>
                                </S.ViewsColumnInnerContainer>
                              </S.EditConfigurationContainer>
                            )}
                          </S.ActionsInnerContainer>
                        </S.BenchmarkListItemCol>
                      </S.ActionsContainer>

                      <S.ActiveContainer>
                        <S.BenchmarkListItemCol>
                          <S.BenchmarkListItemType>
                            <S.BenchmarkListItemTitle>
                              {props.t('generic.active')}
                            </S.BenchmarkListItemTitle>
                            <S.ActiveCheckMarkIconContainer>
                              {isAuthenticated()?.businessProfileId ===
                              businessProfile._id ? (
                                <CheckMarkIcon
                                  containerWidth="2.4rem"
                                  containerHeight="2.4rem"
                                />
                              ) : (
                                <div style={{ height: '2.4rem' }}></div>
                              )}
                            </S.ActiveCheckMarkIconContainer>
                          </S.BenchmarkListItemType>
                        </S.BenchmarkListItemCol>
                      </S.ActiveContainer>
                    </S.BenchmarkListItemCard>
                  ))}
                </S.ConfigurationListContainer>
              )}

              {isSuccessGetBusinessProfiles && !businessProfiles.length && (
                <S.EmptyInfoContainer>
                  <EmptyInfo
                    width="auto"
                    icon={
                      <ExclamationPointIcon
                        containerWidth="4rem"
                        containerHeight="5rem"
                      />
                    }
                    header={props.t('businessProfiles.emptyInfoTitle')}
                    legend={props.t('businessProfiles.emptyInfoSubtitle')}
                  />
                </S.EmptyInfoContainer>
              )}
            </S.CardInnerContainer>
          ) : isSuccessIsOperatorOrPartnerAdmin && !isOperatorOrPartnerAdmin ? (
            <NoPermissionToAccessPageWarning containerHeight="auto" />
          ) : null}

          {isSuccessIsOperatorOrPartnerAdmin && isOperatorOrPartnerAdmin && (
            <S.FooterContainer>
              <Button
                borderRadius={'0.6rem'}
                borderWidth={'0rem'}
                height={'4rem'}
                fontSize={'1.6rem'}
                textAlign={'center'}
                fontWeight={'500'}
                paddingRight={'1rem'}
                onClick={() => {
                  navigate('/business-profile/create');
                }}
              >
                {props.t('generic.create')}
              </Button>
            </S.FooterContainer>
          )}
        </S.Card>
      </S.InnerContainer>

      <GenericModal
        show={hasError}
        title={props.t('generic.uhoh')}
        body={msgError}
        primaryButtonText={props.t('generic.close')}
        primaryButtonAction={() => errorModalCloseButtonClicked()}
      />

      <NotifyCustom
        location={'top'}
        show={showSuccessfulActionNotification}
        themeStyles={(themeGlobal as any).signin['success-notify']}
        topInitialOffset={'5rem'}
      >
        <S.NotifyInnerContainer>
          <CheckMarkIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
            useCase={'success-notify'}
          />
          {successfulActionNotificationMessage}
        </S.NotifyInnerContainer>
      </NotifyCustom>

      <NotifyCustom
        location={'top'}
        show={
          isDeleteBusinessProfileLoading || isActivateBusinessProfileLoading
        }
        themeStyles={(themeGlobal as any).signin['regular-notify']}
      >
        <S.NotifyInnerContainer>
          <InputSpinnerIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
          />
          {loadingActionNotificationMessage}
        </S.NotifyInnerContainer>
      </NotifyCustom>
    </S.Container>
  );
};

export default withTranslation()(BusinessProfiles);
