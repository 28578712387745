import { AxiosInstance } from 'axios';

import config from '../../../config';
import * as T from './types';

const createAuthInstanceApi = (instance: AxiosInstance) => {
  const signIn = (params: T.ISignInParams) =>
    instance.post(config.ENDPOINTS.AUTH.SIGN_IN, params, {
      headers: { source: 1 },
    });

  const selectBusinessProfile = (businessProfileId: string) =>
    instance.post(config.ENDPOINTS.AUTH.SELECT_BUSINESS_PROFILE, {
      businessProfileId,
    });

  const deactivateBusinessProfile = () =>
    instance.patch(config.ENDPOINTS.AUTH.DEACTIVATE_BUSINESS_PROFILE);

  const getRoles = () => instance.get(config.ENDPOINTS.AUTH.GET_ROLES);

  const isOperatorOrPartnerAdmin = () =>
    instance.get(config.ENDPOINTS.AUTH.IS_OPERATOR_OR_PARTNER_ADMIN);

  const sendForgotPasswordCodeEmail = (email: string) =>
    instance.post(config.ENDPOINTS.AUTH.SEND_FORGOT_PASSWORD_CODE_EMAIL, {
      email,
      locale: 'en-US',
    });

  const verifyForgotPasswordCode = (email: string, code: string) =>
    instance.post(config.ENDPOINTS.AUTH.VERIFY_FORGOT_PASSWORD_CODE, {
      email,
      code,
    });

  const updatePasswordWithCode = (
    email: string,
    code: string,
    password: string,
  ) =>
    instance.post(config.ENDPOINTS.AUTH.UPDATE_PASSWORD_WITH_CODE, {
      email,
      code,
      password,
    });

  const isEmailVerified = () =>
    instance.get(config.ENDPOINTS.AUTH.IS_EMAIL_VERIFIED);

  const verifyAccountActivationCode = (code: string) =>
    instance.post(config.ENDPOINTS.AUTH.VERIFY_ACCOUNT_ACTIVATION_CODE, {
      code,
    });

  const sendAccountActivationCodeEmail = () =>
    instance.patch(config.ENDPOINTS.AUTH.SEND_ACCOUNT_ACTIVATION_CODE_EMAIL);

  const getToken = (email: string) =>
    instance.post(config.ENDPOINTS.AUTH.GET_TOKEN, { email });

  const isEmailInUse = (email: string) =>
    instance.post(config.ENDPOINTS.AUTH.IS_EMAIL_IN_USE, { email });

  const certifyActivationCodeEmailSent = (email: string) =>
    instance.post(config.ENDPOINTS.AUTH.CERTIFY_ACTIVATION_CODE_EMAIL_SENT, {
      email,
    });

  return {
    signIn,
    selectBusinessProfile,
    deactivateBusinessProfile,
    getRoles,
    isOperatorOrPartnerAdmin,
    sendForgotPasswordCodeEmail,
    verifyForgotPasswordCode,
    updatePasswordWithCode,
    isEmailVerified,
    verifyAccountActivationCode,
    sendAccountActivationCodeEmail,
    getToken,
    isEmailInUse,
    certifyActivationCodeEmailSent,
  };
};

export default {
  createAuthInstanceApi,
};
