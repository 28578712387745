/* eslint-disable import/extensions */
import { withTranslation } from 'react-i18next';

import { MessageContainer, Message } from './Styles';
import { INoPermissionToAccessPageWarningProps } from './types';

const NoPermissionToAccessPageWarning = (
  props: INoPermissionToAccessPageWarningProps,
) => {
  return (
    <MessageContainer
      height={props.containerHeight}
      marginTop={props.containerMarginTop}
      marginBottom={props.containerMarginBottom}
      marginLeft={props.containerMarginLeft}
      marginRight={props.containerMarginRight}
      paddingTop={props.containerPaddingTop}
      paddingBottom={props.containerPaddingBottom}
      paddingLeft={props.containerPaddingLeft}
      paddingRight={props.containerPaddingRight}
    >
      <Message>{props.t('generic.noPermissionToAccessPageWarning')}</Message>
    </MessageContainer>
  );
};

export default withTranslation()(NoPermissionToAccessPageWarning);
