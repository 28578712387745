import styled from 'styled-components';

import { IEmptyCardContainerProps } from './types';

export const EmptyCardContainer = styled.div<IEmptyCardContainerProps>`
  display: ${props => props.display ?? 'flex'};
  justify-content: ${props => props.justifyContent ?? 'start'};
  align-items: ${props => props.alignItems ?? 'start'};
  height: ${props => props.height ?? 'auto'};
  width: ${props => props.width ?? '100%'};
  margin-top: ${props => props.marginTop ?? '0'};
  margin-bottom: ${props => props.marginBottom ?? '0'};
`;

export const EmptyCardIcon = styled.span`
  svg {
    font-size: 4.8rem;
    color: #8492a6;
  }
`;

export const EmptyCardDescription = styled.div`
  margin-left: 0.8rem;
`;

export const EmptyCardDescriptionTitle = styled.h2`
  color: #162b39;
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 0.32rem;
  padding-top: 0.48rem;
`;

export const EmptyCardDescriptionParagraph = styled.p`
  font-size: 1.36rem;
  color: #8492a6;
  font-weight: 400;
`;

export const LinkText = styled.p`
  cursor: pointer;
`;
