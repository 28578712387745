/* eslint-disable no-param-reassign */
/* eslint-disable eqeqeq */
/* eslint-disable import/extensions */
import { useMutation } from 'react-query';

import SkorrApi from '../../Services/SkorrApi/new';
import {
  logApiRequestError,
  isAuthenticated,
} from '../../Services/Utils/Utils';
import { IErrorObject } from '../../Services/Utils/types';
import { IUseCompleteAdditionalDataStepParams } from './types';

const { user } = SkorrApi;
const { files } = SkorrApi;
const { generic } = SkorrApi;

export const useCompleteAdditionalDataStep = () => {
  return useMutation(
    async (params: IUseCompleteAdditionalDataStepParams) => {
      try {
        if (isAuthenticated()!.partnerType != 'INFLUENCER') {
          const { data: imageUrl }: { data: string } =
            params.businessProfilePictureData
              ? await files.uploadImage(params.businessProfilePictureData!)
              : { data: '' };
          params.completeAdditionalDataStepParams.businessProfilePictureUrl =
            imageUrl;
        }
        await user.completeAdditionalDataStep(
          params.completeAdditionalDataStepParams,
        );
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
    },
  );
};
