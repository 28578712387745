/* eslint-disable eqeqeq */
/* eslint-disable import/extensions */
import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import * as S from './Styles';
import Loader from '../../components/shared/LoaderFixed/LoaderFixed';
import GenericModal from '../../components/shared/GenericModal/GenericModal';
import { MyAnalyticsProps } from './types';
import BackArrowIcon from '../../assets/iconComponents/BackArrowIcon';
import { useGetSelfUser } from '../../Services/Utils/CustomHooks';
import EmptyInfo from '../../components/shared/EmptyInfo/EmptyInfo';
import ExclamationPointIcon from '../../assets/iconComponents/ExclamationPointIcon';
import { DEFAULT_USER_AVATAR } from '../../Services/Utils/types';
import { getSocialNetworkIcon } from '../../Services/Utils/Utils';

const MyAnalytics = (props: MyAnalyticsProps) => {
  const [isGetSelfUserEnabled, setIsGetSelfUserEnabled] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState('');
  const {
    data: selfUser,
    isFetching: isFetchingGetSelfUser,
    isSuccess: isSuccessGetSelfUser,
    isError: isErrorGetSelfUser,
  } = useGetSelfUser(isGetSelfUserEnabled);
  const navigate = useNavigate();

  useEffect(() => {
    setIsGetSelfUserEnabled(true);
  }, []);

  useEffect(() => {
    if (isErrorGetSelfUser) {
      setIsGetSelfUserEnabled(false);
      setHasError(true);
      setMsgError(props.t('generic.couldNotGetData'));
    }
  }, [isErrorGetSelfUser]);

  const getLoaderMessage = () => {
    if (isFetchingGetSelfUser) {
      return props.t('generic.gettingData');
    }
    return '';
  };

  const errorModalCloseButtonClicked = () => {
    setHasError(false);
    if (isErrorGetSelfUser) {
      setIsGetSelfUserEnabled(true);
    }
  };

  return (
    <S.Container>
      <Loader show={isFetchingGetSelfUser} message={getLoaderMessage()} />

      <S.InnerContainer>
        <S.Card>
          <S.HeaderOuterContainer>
            <S.HeaderContainer>
              <S.HeaderBackArrowContainer
                onClick={() => {
                  navigate(-1);
                }}
              >
                <BackArrowIcon width={'4rem'} height={'4rem'} />
              </S.HeaderBackArrowContainer>
              <S.HeaderTitleContainer>
                {props.t('externalMediaKitConfigurationsList.title')}
              </S.HeaderTitleContainer>
              <S.HiddenHeaderElement />
            </S.HeaderContainer>
          </S.HeaderOuterContainer>

          <S.CardInnerContainer>
            {selfUser && selfUser.publicProfiles && (
              <S.SocialAccountsInnerContainer>
                {selfUser.publicProfiles?.map(publicProfile => (
                  <S.SocialAccountContainer
                    onClick={() => {
                      navigate(`/user-media-kit/${publicProfile.publicUserId}`);
                    }}
                  >
                    <S.SocialAccountLeftContainer>
                      <S.SocialAccountImage
                        src={
                          publicProfile.displayPicture ?? DEFAULT_USER_AVATAR
                        }
                      />
                      <S.SocialAccountNetworkIconContainer>
                        {getSocialNetworkIcon({
                          socialNetwork: publicProfile.socialNetwork,
                          width: '2rem',
                          height: '2rem',
                          gradientColored: true,
                        })}
                      </S.SocialAccountNetworkIconContainer>
                    </S.SocialAccountLeftContainer>

                    <S.SocialAccountRightContainer>
                      <S.SocialAccountUsername
                        id={`media-kit-username-${publicProfile.username}-${publicProfile.socialNetwork}`}
                      >
                        {`@${publicProfile.username}`}
                      </S.SocialAccountUsername>
                      <S.SocialAccountNetworkName>
                        {publicProfile.socialNetwork[0] +
                          publicProfile.socialNetwork.slice(1).toLowerCase()}
                      </S.SocialAccountNetworkName>
                      {publicProfile.username.length > 15 && (
                        <S.ConfigurationUsernameTooltip
                          placement="top"
                          target={`media-kit-username-${publicProfile.username}-${publicProfile.socialNetwork}`}
                        >
                          {publicProfile.username}
                        </S.ConfigurationUsernameTooltip>
                      )}
                    </S.SocialAccountRightContainer>
                  </S.SocialAccountContainer>
                ))}
              </S.SocialAccountsInnerContainer>
            )}

            {selfUser?.publicProfiles?.length == 0 && (
              <S.SocialAccountsEmpty>
                <S.SocialAccountsEmptyInner>
                  <div>
                    {props.t('menu-navbar.noSocialAccountsPrimaryMessage')}
                  </div>
                  <S.SocialAccountsEmptySecondaryMessage>
                    <S.SocialAccountsEmptyLink
                      onClick={() => navigate('/social-accounts-management')}
                    >
                      {props.t(
                        'menu-navbar.noSocialAccountsSecondaryMessagePart1',
                      )}
                    </S.SocialAccountsEmptyLink>
                    {props.t(
                      'menu-navbar.noSocialAccountsSecondaryMessagePart2',
                    )}
                  </S.SocialAccountsEmptySecondaryMessage>
                </S.SocialAccountsEmptyInner>
              </S.SocialAccountsEmpty>
            )}
          </S.CardInnerContainer>
        </S.Card>
      </S.InnerContainer>

      <GenericModal
        show={hasError}
        title={props.t('generic.uhoh')}
        body={msgError}
        primaryButtonText={props.t('generic.close')}
        primaryButtonAction={() => errorModalCloseButtonClicked()}
      />
    </S.Container>
  );
};

export default withTranslation()(MyAnalytics);
