/* eslint-disable import/extensions */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import { withTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

import UserDemographicChart from '../DemographicChart';
import { AudienceReachabilityDistribution } from '../../../../Services/SkorrApi/new/User/types';
import * as S from './styles';
import {
  AudienceAgeDistribution,
  AudienceCityDistribution,
  AudienceCountryDistribution,
  AudienceGenderDistribution,
  AudienceTypeDistribution,
  GenderObjectKeyType,
  IUserAudienceDistributionProps,
} from './types';

const hasCountryData = (data?: AudienceCountryDistribution): boolean => {
  let countryDataSum = 0;

  if (data) {
    for (const country in data) {
      countryDataSum += data[country].weight;
    }
  }

  return Boolean(countryDataSum);
};

const hasCityData = (data?: AudienceCityDistribution): boolean => {
  let cityDataSum = 0;

  if (data) {
    for (const city in data) {
      cityDataSum += data[city];
    }
  }

  return Boolean(cityDataSum);
};

const hasGenderData = (data?: AudienceGenderDistribution): boolean => {
  let genderDataSum = 0;

  if (data) {
    for (const gender in data) {
      genderDataSum += data[gender as GenderObjectKeyType];
    }
  }

  return Boolean(genderDataSum);
};

const hasAgeData = (data?: AudienceAgeDistribution): boolean => {
  let ageDataSum = 0;

  if (data) {
    for (const ageGroup in data) {
      ageDataSum += data[ageGroup as keyof AudienceAgeDistribution] ?? 0;
    }
  }

  return Boolean(ageDataSum);
};

const hasTypeData = (data?: AudienceTypeDistribution): boolean => {
  let typeDataSum = 0;

  if (data) {
    for (const type in data) {
      typeDataSum += data[type];
    }
  }

  return Boolean(typeDataSum);
};

const hasReachabilityData = (
  data?: AudienceReachabilityDistribution,
): boolean => {
  let reachabilityDataSum = 0;

  if (data) {
    for (const reachabilityRange in data) {
      reachabilityDataSum += data[reachabilityRange];
    }
  }

  return Boolean(reachabilityDataSum);
};

const AudienceDistribution = (data: IUserAudienceDistributionProps) => {
  const [numberOfGraphsToBeDisplayed, setNumberOfGraphsToBeDisplayed] =
    useState(0);
  const [countryDataExists, setCountryDataExists] = useState(false);
  const [cityDataExists, setCityDataExists] = useState(false);
  const [genderDataExists, setGenderDataExists] = useState(false);
  const [ageDataExists, setAgeDataExists] = useState(false);
  const [typeDataExists, setTypeDataExists] = useState(false);
  const [reachabilityDataExists, setReachabilityDataExists] = useState(false);

  useEffect(() => {
    const countryDataExistsLocal = hasCountryData(data.country);
    const cityDataExistsLocal = hasCityData(data.city);
    const genderDataExistsLocal = hasGenderData(data.gender);
    const ageDataExistsLocal = hasAgeData(data.age);
    const typeDataExistsLocal = hasTypeData(data.type);
    const reachabilityDataExistsLocal = hasReachabilityData(data.reachability);

    setNumberOfGraphsToBeDisplayed(
      Number(countryDataExistsLocal) +
        Number(cityDataExistsLocal) +
        Number(genderDataExistsLocal) +
        Number(ageDataExistsLocal) +
        Number(typeDataExistsLocal) +
        Number(reachabilityDataExistsLocal),
    );
    setCountryDataExists(countryDataExistsLocal);
    setCityDataExists(cityDataExistsLocal);
    setGenderDataExists(genderDataExistsLocal);
    setAgeDataExists(ageDataExistsLocal);
    setTypeDataExists(typeDataExistsLocal);
    setReachabilityDataExists(reachabilityDataExistsLocal);
  }, []);

  const renderCharts = (graphCount: number) => {
    return (
      <>
        {countryDataExists && (
          <S.UserDemographicCol graphCount={graphCount}>
            <S.UserDemographicCard>
              <UserDemographicChart
                data={data.country}
                title="country"
                centerDistributionTable={true}
                countryFlag={true}
                numberOfGraphs={graphCount}
              ></UserDemographicChart>
            </S.UserDemographicCard>
          </S.UserDemographicCol>
        )}
        {cityDataExists && (
          <S.UserDemographicCol graphCount={graphCount}>
            <S.UserDemographicCard>
              <UserDemographicChart
                data={data.city}
                title="city"
                centerDistributionTable={true}
                numberOfGraphs={graphCount}
              ></UserDemographicChart>
            </S.UserDemographicCard>
          </S.UserDemographicCol>
        )}
        {genderDataExists && (
          <S.UserDemographicCol graphCount={graphCount}>
            <S.UserDemographicCard>
              <UserDemographicChart
                data={data.gender}
                title="gender"
                centerDistributionTable={true}
                numberOfGraphs={graphCount}
              ></UserDemographicChart>
            </S.UserDemographicCard>
          </S.UserDemographicCol>
        )}
        {ageDataExists && (
          <S.UserDemographicCol graphCount={graphCount}>
            <S.UserDemographicCard>
              <UserDemographicChart
                data={data.age}
                title="age"
                centerDistributionTable={true}
                numberOfGraphs={graphCount}
              ></UserDemographicChart>
            </S.UserDemographicCard>
          </S.UserDemographicCol>
        )}
        {typeDataExists && (
          <S.UserDemographicCol graphCount={graphCount}>
            <S.UserDemographicCard>
              <UserDemographicChart
                data={data.type}
                title="type"
                centerDistributionTable={true}
                numberOfGraphs={graphCount}
              ></UserDemographicChart>
            </S.UserDemographicCard>
          </S.UserDemographicCol>
        )}
        {reachabilityDataExists && (
          <S.UserDemographicCol graphCount={graphCount}>
            <S.UserDemographicCard>
              <UserDemographicChart
                data={data.reachability}
                title="reachability"
                centerDistributionTable={true}
                numberOfGraphs={graphCount}
              ></UserDemographicChart>
            </S.UserDemographicCard>
          </S.UserDemographicCol>
        )}
      </>
    );
  };

  if (numberOfGraphsToBeDisplayed) {
    return (
      <S.OuterContainer>
        <S.Title>{data.t('mediaKit.audienceDemographic.title')}</S.Title>

        <S.InnerContainer graphCount={numberOfGraphsToBeDisplayed}>
          {renderCharts(numberOfGraphsToBeDisplayed)}
        </S.InnerContainer>
      </S.OuterContainer>
    );
  }

  return <></>;
};
export default withTranslation()(AudienceDistribution);
