/* eslint-disable consistent-return */
import styled, { css } from 'styled-components';

import {
  IForgotPasswordCaptchaContainerProps,
  IContainerProps,
  IForgotPasswordCodeInputContainerProps,
} from './types';
import SkorrOnboardingSecondBackgroundImage from '../../assets/skorr-onboarding-elements.png';

export const Container = styled.div<IContainerProps>`
  height: auto;
  max-width: 100vw;
  overflow-x: hidden;

  @media (max-width: 640px) {
    background-color: ${props =>
      props.theme.signin['signin-container-background-color-responsive']};
  }
`;

export const BackgroundImageContainer = styled.div`
  display: flex;
  position: fixed;
  flex-flow: row;
  width: 100%;

  @media (max-width: 640px) {
    display: none;
  }
`;

export const BackgroundImageInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  height: 100vh;
  max-width: 50vw;
`;

export const BackgroundImageLeft = styled.img`
  object-fit: cover;
  height: 100%;
  object-position: right center;
`;

export const BackgroundImageRight = styled(BackgroundImageLeft)`
  transform: scaleX(-1);
`;

export const Navbar = styled.div`
  padding: 6.2rem;
  width: 100%;
  height: 18.4rem;
  display: flex;
  justify-content: center;

  @media (max-width: 640px) {
    justify-content: flex-start;
    height: 15.2rem;
    padding: 5.6rem 5.6rem;
  }
`;

export const NavbarInnerContainer = styled.div`
  z-index: 2;
  cursor: pointer;
`;

export const NavbarAnchorTag = styled.a`
  cursor: pointer;
`;

export const NavbarImage = styled.img`
  height: 100%;

  @media (max-width: 640px) {
    display: none;
  }
`;

export const NavbarImageSmallWindow = styled.img`
  height: 100%;
  display: none;

  @media (max-width: 640px) {
    display: block;
  }
`;

export const FormSectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const FormOuterContainer = styled.div`
  width: 44rem;
  max-width: 44rem;
  position: relative;
  min-height: calc(100vh - 18.4rem);
  z-index: 2;

  @media (max-width: 640px) {
    width: 100%;
    max-width: unset;
  }
`;

export const FormContainer = styled.div`
  width: 44rem;
  max-width: 44rem;
  background: ${props => props.theme.container['background-color-1']};
  border-radius: 2.4rem;
  box-shadow: 0rem 0rem 2rem ${props => props.theme['box-shadow']['color-3']};
  margin-bottom: 12.4rem;

  @media (max-width: 640px) {
    box-shadow: none;
    margin-bottom: 8.4rem;
    width: 100%;
    max-width: unset;
  }
`;

export const FormContainerFirstHalf = styled.div`
  padding: 2.4rem 5.6rem 0 5.6rem;

  @media (max-width: 640px) {
    padding: 0 5.6rem 0 5.6rem;
  }
`;

export const FormContainerFirstHalfInnerContainer = styled.div``;

export const Title = styled.h1`
  margin-bottom: 3.2rem;
  margin-top: 2.4rem;
  color: ${props => props.theme.text['color-26']};
  font-size: 2.6rem;
  font-weight: 700;

  @media (max-width: 640px) {
    margin-top: 0;
  }
`;

export const FormInputContainer = styled.div`
  margin-block: 2.4rem;
`;

export const PasswordOptionsContainer = styled.div`
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-block-start: 2.4rem;
`;

export const ForgotPasswordLinkContainer = styled.div`
  color: ${props => props.theme.text['color-28']};

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const SignInMethodDivider = styled.div`
  position: relative;
  margin-block: 2.4rem;
  width: 100%;
  height: 0.1rem;
  background-color: ${props =>
    props.theme.signin['signin-method-divider-background-color']};

  &:after {
    content: 'OR';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props =>
      props.theme.signin[
        'signin-method-divider-pseudo-element-background-color'
      ]};
    width: 6rem;
    font-size: 1.2rem;
    color: ${props =>
      props.theme.signin['signin-method-divider-pseudo-element-color']};
    letter-spacing: 0.1rem;
  }
`;

export const SignInSocialButtonsContainer = styled.div`
  display: flex;

  @media (max-width: 640px) {
    justify-content: center;

    & > button:first-child > div:first-child > svg {
      margin-right: 0 !important;
    }
  }
`;

export const SignInFacebookButtonLabelContainer = styled.div`
  @media (max-width: 640px) {
    text-align: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;
  }
`;

export const SignInFormContentDivider = styled.div`
  margin-block: 3.2rem;
  width: 100%;
  height: 0.1rem;
  background-color: ${props =>
    props.theme.signin['signin-content-divider-color']};
`;

export const SignInFormFooter = styled.div`
  text-align: center;
  font-size: 1.4rem;
  padding-bottom: 3.2rem;
  color: ${props => props.theme.signin['signin-form-footer-color']};
`;

export const SignInFormFooterCaptcha = styled.div`
  text-align: center;
  font-size: 1.4rem;
  color: ${props => props.theme.signin['signin-form-footer-color']};
  padding-top: 1rem;
  white-space: pre;
  word-wrap: break-work;
`;

export const SignInFormFooterInner = styled.div`
  & div:first-child {
    margin-bottom: 1rem;
  }
`;

export const SignInFormFooterLink = styled.a`
  color: ${props => props.theme.text['color-28']};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const ForgotPasswordFooterResendEmailTimer = styled.span`
  color: ${props => props.theme.text['color-29']};
  text-decoration: none;
  outline: none;
  pointer: default;
  font-size: 1.4rem;
  white-space: pre;
`;

export const SignInPageFooter = styled.div`
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 4rem auto 1rem auto;
  width: 43rem;
  text-align: center;
  max-width: 100vw;
`;

export const SignInPageFooterLinksContainer = styled.div`
  margin: 1.2rem;
`;

export const SignInPageFooterLink = styled.span`
  color: ${props => props.theme.text['color-27']};
  font-size: 1.2rem;
  margin: 1.2rem;
  opacity: 0.8;
  outline: 0;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const SignInPageFooterLangCopyRightsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 1.2rem;
`;

export const CopyRightSpan = styled.span`
  color: ${props => props.theme.text['color-27']};
  font-size: 1.2rem;
  opacity: 0.8;
`;

export const ForgotPasswordHeaderContainer = styled.div`
  height: 6.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1.6rem;
  border-bottom: solid 1px
    ${props =>
      props.theme.signin['signin-forgot-password-header-divider-color']};
`;

export const ForgotPasswordBackArrowContainer = styled.div`
  height: 4rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  transition: all 0.08s;
  opacity: 1;
  border-radius: 0.8rem;
  cursor: pointer;
  & path {
    fill: ${props =>
      props.theme.signin['signin-forgot-password-back-arrow-color']};
  }

  &:hover {
    background-color: ${props =>
      props.theme.signin[
        'signin-forgot-password-back-arrow-container-background-color-hover'
      ]};
  }
`;

export const ForgotPasswordTitleContainer = styled.div`
  font-size: 2rem;
  color: ${props => props.theme.text['color-26']};
  font-weight: bold;
`;

export const ForgotPasswordHeaderHiddenFlexItem = styled.div`
  height: 4rem;
  width: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.8rem;
  border-radius: 0.8rem;
  pointer-events: none;
`;

export const ForgotPasswordCaptchaContainer = styled.div<IForgotPasswordCaptchaContainerProps>`
  & canvas {
    padding: 0 2rem;
  }

  & > div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & a {
    font-size: 1.4rem;
    color: ${props => props.theme.text['color-28']} !important;
    pointer-events: ${props => {
      if (props.disableReloadCaptcha) return 'none';
      return 'all';
    }};
  }
`;

export const ForgotPasswordCaptchaInputContainer = styled.div`
  padding: 1.2rem 4rem 1rem 4rem;
  display: flex;
  align-items: stretch;
`;

export const ValidateCaptchaButtonContainer = styled.div`
  position: relative;
  width: 10.5rem;
`;

export const CaptchaContinueButtonContainer = styled.div`
  padding: 2.2rem 4rem 0rem 4rem;
`;

export const ForgotPasswordContentOuterContainer = styled.div`
  padding: 1rem 4rem;
`;

export const ForgotPasswordContentTextContainer = styled.p`
  font-size: 1.4rem;
  color: ${props => props.theme.text['color-27']};
  display: block;
  line-height: 1.8rem;
  margin-bottom: 1.2rem;
  margin-block-start: 1.4rem;
`;

export const ForgotPasswordCodeInputContainer = styled.div<IForgotPasswordCodeInputContainerProps>`
  display: flex;
  justify-content: space-between;
  margin-block: 2.4rem;

  --ReactInputVerificationCode-itemWidth: 4.6rem;
  --ReactInputVerificationCode-itemHeight: 4.6rem;
  --ReactInputVerificationCode-itemSpacing: 2rem;

  .ReactInputVerificationCode__item {
    color: #262626;
    font-weight: 500;
  }

  .ReactInputVerificationCode__item {
    box-shadow: rgb(244, 244, 244) 0 0 0 2px inset;

    &:hover {
      box-shadow: rgb(41, 41, 41) 0 0 0 2px inset;
    }
  }

  ${props => {
    if (props.redBorder) {
      return css`
        .ReactInputVerificationCode__item {
          box-shadow: #b00020 0 0 0 2px inset !important;

          &:hover {
            box-shadow: #b00020 0 0 0 2px inset !important;
          }
        }
      `;
    }
  }}

  .ReactInputVerificationCode__item.is-active {
    box-shadow: rgb(88, 112, 246) 0 0 0 2px inset;
  }
`;

export const InvalidForgotPasswordVerificationCodeWarning = styled.div`
  margin-bottom: 3rem;
  font-size: 1.4rem;
  color: #b00020;
  width: 100%;
  line-height: 1.8rem;
  word-break: break-word;
`;

export const ForgotPasswordCodeSingleInputContainer = styled.div`
  max-width: 4.6rem;
`;

export const ForgotPasswordEmailInputContainer = styled.div`
  padding: 1.2rem 4rem 1rem 4rem;
`;

export const ForgotPasswordSubmitVerificationCodeButtonContainer = styled.div`
  width: 100%;
  padding-left: 4rem;
  padding-right: 4rem;
`;

export const SecondBackgroundImageContainer = styled.div`
  top: 5rem;
  min-height: 57rem;
  width: 134.6rem;
  max-width: 90vw;
  position: absolute;
  background: url(${SkorrOnboardingSecondBackgroundImage}) no-repeat;
  background-size: contain;
  background-position: top;
  z-index: 1;

  @media (max-width: 900px) {
    display: none;
  }
`;

export const NotifyInnerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
