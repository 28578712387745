/* eslint-disable import/extensions */
import SVG from './Styles';

interface IPostTypeTextIconProps {
  width?: string;
  height?: string;
  onClick?: any;
  fill?: string;
}

const PostTypeTextIcon = (props: IPostTypeTextIconProps) => {
  return (
    <SVG
      viewBox="0 0 32 32"
      width={props.width ? props.width : '100%'}
      height={props.height ? props.height : '100%'}
      onClick={props.onClick}
    >
      <path
        fill={props.fill || 'none'}
        d="M8.212 23.702c-0.721-0.164-1.384-0.421-1.976-0.832-1.606-1.116-2.46-2.661-2.48-4.613-0.031-2.987-0.028-5.974-0.003-8.961 0.024-2.813 2.010-5.070 4.795-5.501 0.291-0.045 0.589-0.058 0.884-0.058 4.385-0.003 8.771-0.006 13.156-0.001 2.748 0.003 4.983 1.797 5.548 4.459 0.084 0.397 0.119 0.812 0.121 1.218 0.010 2.909 0.011 5.817 0.004 8.726-0.007 2.791-1.848 5.039-4.563 5.569-0.381 0.074-0.778 0.091-1.168 0.093-1.975 0.009-3.95 0.001-5.925 0.010-0.161 0.001-0.344 0.058-0.478 0.146-2.023 1.34-4.040 2.688-6.056 4.037-0.26 0.174-0.531 0.302-0.851 0.267-0.598-0.065-1.002-0.51-1.007-1.132-0.008-1.045-0.003-2.090-0.003-3.135 0-0.094 0-0.189 0-0.292zM16.005 12.659c1.619 0 3.237 0 4.856 0 0.618 0 1.236 0.007 1.854-0.003 0.54-0.009 0.999-0.418 1.071-0.93 0.078-0.556-0.221-1.072-0.74-1.232-0.179-0.055-0.377-0.067-0.566-0.067-4.316-0.004-8.633-0.003-12.95-0.003-0.087 0-0.175-0.004-0.261 0.004-0.475 0.047-0.82 0.279-0.991 0.731-0.281 0.744 0.268 1.496 1.095 1.497 2.21 0.004 4.421 0.001 6.631 0.001zM15.978 17.114c0.879 0 1.757 0 2.636-0 0.635-0 1.27 0.012 1.905-0.007 0.684-0.021 1.16-0.644 1.031-1.316-0.109-0.565-0.548-0.897-1.198-0.898-2.897-0.001-5.794-0.001-8.691-0-0.070 0-0.139 0.001-0.209 0.006-0.471 0.034-0.876 0.38-0.983 0.838-0.17 0.732 0.344 1.371 1.124 1.375 1.461 0.007 2.923 0.002 4.384 0.002z"
      ></path>
    </SVG>
  );
};

export default PostTypeTextIcon;
