/* eslint-disable import/extensions */
/* eslint-disable eqeqeq */
import { UncontrolledTooltip } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useRef, useState } from 'react';

import * as S from './Styles';
import { ILoaderLocalProps } from './types';
import { useGetTooltipClassName } from '../../../Services/Utils/CustomHooks';

const LoaderLocal = (props: ILoaderLocalProps) => {
  const [spinnerId, setSpinnerId] = useState<string>();

  const tooltipClassName = useRef<string>();
  tooltipClassName.current = useGetTooltipClassName();

  useEffect(() => {
    setSpinnerId(uuidv4());
  }, []);

  if (props.show != true) {
    return <>{props.children && props.children}</>;
  }

  return spinnerId ? (
    <S.OuterContainer borderRadius={props.borderRadius}>
      {props.children && props.children}
      <S.Container
        borderRadius={props.borderRadius}
        backgroundColor={props.backgroundColor}
      >
        {props.message ? (
          <S.MessageContainer fontSize={props.messageFontSize}>
            {props.message}
            {props.spinner && (
              <S.SpinnerContainer
                marginTop={props.spinnerContainerMarginTop}
                marginBottom={props.spinnerContainerMarginBottom}
                marginLeft={props.spinnerContainerMarginLeft}
                marginRight={props.spinnerContainerMarginRight}
                paddingTop={props.spinnerContainerPaddingTop}
                paddingBottom={props.spinnerContainerPaddingBottom}
                paddingLeft={props.spinnerContainerPaddingLeft}
                paddingRight={props.spinnerContainerPaddingRight}
              >
                <S.Spinner fontSize={props.spinnerFontSize} />
              </S.SpinnerContainer>
            )}
          </S.MessageContainer>
        ) : (
          <>
            <S.SpinnerContainer
              marginTop={props.spinnerContainerMarginTop}
              marginBottom={props.spinnerContainerMarginBottom}
              marginLeft={props.spinnerContainerMarginLeft}
              marginRight={props.spinnerContainerMarginRight}
              paddingTop={props.spinnerContainerPaddingTop}
              paddingBottom={props.spinnerContainerPaddingBottom}
              paddingLeft={props.spinnerContainerPaddingLeft}
              paddingRight={props.spinnerContainerPaddingRight}
            >
              <S.Spinner id={spinnerId} fontSize={props.spinnerFontSize} />
            </S.SpinnerContainer>
            {props.spinnerTooltip && (
              <UncontrolledTooltip
                placement="top"
                target={spinnerId}
                innerClassName={tooltipClassName.current}
              >
                {props.spinnerTooltip}
              </UncontrolledTooltip>
            )}
          </>
        )}
      </S.Container>
    </S.OuterContainer>
  ) : (
    <></>
  );
};

export default LoaderLocal;
