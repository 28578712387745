/* eslint-disable import/extensions */
import styled from 'styled-components';

import SVG from './Styles';

interface IFunnelIconProps {
  containerWidth?: string;
  containerHeight?: string;
  containerPaddingTop?: string;
  containerPaddingBottom?: string;
  containerPaddingLeft?: string;
  containerPaddingRight?: string;
  containerMarginTop?: string;
  containerMarginBottom?: string;
  containerMarginLeft?: string;
  containerMarginRight?: string;
  svgWidth?: string;
  svgHeight?: string;
  cursor?: string;
  onClick?: any;
  id?: string;
}

interface IContainerProps {
  width?: string;
  height?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
}

const Container = styled.div<IContainerProps>`
  border-width: 0;
  outline: 0;
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '0')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '0')};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '0')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '0')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
  display: inline-flex;
  align-items: center;
`;

const FunnelIcon = (props: IFunnelIconProps) => {
  return (
    <Container
      width={props.containerWidth}
      height={props.containerHeight}
      paddingTop={props.containerPaddingTop}
      paddingBottom={props.containerPaddingBottom}
      paddingLeft={props.containerPaddingLeft}
      paddingRight={props.containerPaddingRight}
      marginTop={props.containerMarginTop}
      marginBottom={props.containerMarginBottom}
      marginLeft={props.containerMarginLeft}
      marginRight={props.containerMarginRight}
      id={props.id}
    >
      <SVG
        viewBox="0 0 22 28"
        width={props.svgWidth ? props.svgWidth : '100%'}
        height={props.svgHeight ? props.svgHeight : '100%'}
        cursor={props.cursor}
      >
        <path
          fill="#BFBFBF"
          d="M21.922 4.609c0.156 0.375 0.078 0.812-0.219 1.094l-7.703 7.703v11.594c0 0.406-0.25 0.766-0.609 0.922-0.125 0.047-0.266 0.078-0.391 0.078-0.266 0-0.516-0.094-0.703-0.297l-4-4c-0.187-0.187-0.297-0.438-0.297-0.703v-7.594l-7.703-7.703c-0.297-0.281-0.375-0.719-0.219-1.094 0.156-0.359 0.516-0.609 0.922-0.609h20c0.406 0 0.766 0.25 0.922 0.609z"
        ></path>
      </SVG>
    </Container>
  );
};

export default FunnelIcon;
