/* eslint-disable import/extensions */
/* eslint-disable consistent-return */
import styled, { useTheme } from 'styled-components';
import { useState, useEffect } from 'react';

import SVG from './Styles';

interface IExclamationPointIconProps {
  containerWidth?: string;
  containerHeight?: string;
  containerPaddingTop?: string;
  containerPaddingBottom?: string;
  containerPaddingLeft?: string;
  containerPaddingRight?: string;
  containerMarginTop?: string;
  containerMarginBottom?: string;
  containerMarginLeft?: string;
  containerMarginRight?: string;
  useCase?:
    | 'socialNetworkAccountSelectionExpiredToken'
    | 'error-notify'
    | 'media-kit';
  svgWidth?: string;
  svgHeight?: string;
  cursor?: string;
}

interface IContainerProps {
  width?: string;
  height?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
}

const Container = styled.div<IContainerProps>`
  border-width: 0;
  outline: 0;
  width: ${props => (props.width ? props.width : 'auto')};
  height: ${props => (props.height ? props.height : 'auto')};
  padding-top: ${props => (props.paddingTop ? props.paddingTop : '0')};
  padding-bottom: ${props => (props.paddingBottom ? props.paddingBottom : '0')};
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : '0')};
  padding-right: ${props => (props.paddingRight ? props.paddingRight : '0')};
  margin-top: ${props => (props.marginTop ? props.marginTop : '0')};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : '0')};
  margin-left: ${props => (props.marginLeft ? props.marginLeft : '0')};
  margin-right: ${props => (props.marginRight ? props.marginRight : '0')};
`;

const ExclamationPointIcon = (props: IExclamationPointIconProps) => {
  const themeGlobal = useTheme();
  const [svgColor, setSvgColor] = useState<string>(() => {
    switch (props.useCase) {
      case 'socialNetworkAccountSelectionExpiredToken': {
        return (themeGlobal as any).socialNetworkAccountSelectionComponent[
          'expired-token-icon-background-color'
        ];
      }
      case 'error-notify': {
        return (themeGlobal as any)['exclamation-point-icon'][
          'error-notify-color'
        ];
      }
      case 'media-kit': {
        return (themeGlobal as any)['exclamation-point-icon'][
          'info-tooltip-color'
        ];
      }
      default: {
        return (themeGlobal as any).socialNetworkAccountSelectionComponent[
          'expired-token-icon-background-color'
        ];
      }
    }
  });

  useEffect(() => {
    switch (props.useCase) {
      case 'socialNetworkAccountSelectionExpiredToken': {
        setSvgColor(
          (themeGlobal as any).socialNetworkAccountSelectionComponent[
            'expired-token-icon-background-color'
          ],
        );
        break;
      }
      case 'error-notify': {
        setSvgColor(
          (themeGlobal as any)['exclamation-point-icon']['error-notify-color'],
        );
        break;
      }
      case 'media-kit': {
        setSvgColor(
          (themeGlobal as any)['exclamation-point-icon']['info-tooltip-color'],
        );
        break;
      }
      default: {
        setSvgColor(
          (themeGlobal as any).socialNetworkAccountSelectionComponent[
            'expired-token-icon-background-color'
          ],
        );
        break;
      }
    }
  }, [(themeGlobal as any).darkMode]);

  return (
    <Container
      width={props.containerWidth}
      height={props.containerHeight}
      paddingTop={props.containerPaddingTop}
      paddingBottom={props.containerPaddingBottom}
      paddingLeft={props.containerPaddingLeft}
      paddingRight={props.containerPaddingRight}
      marginTop={props.containerMarginTop}
      marginBottom={props.containerMarginBottom}
      marginLeft={props.containerMarginLeft}
      marginRight={props.containerMarginRight}
    >
      <SVG
        viewBox="0 0 32 32"
        width={props.svgWidth ? props.svgWidth : '100%'}
        height={props.svgHeight ? props.svgHeight : '100%'}
        cursor={props.cursor}
      >
        <path
          fill={svgColor}
          d="M16 3.651c-6.82 0-12.349 5.529-12.349 12.349s5.529 12.349 12.349 12.349c6.82 0 12.349-5.529 12.349-12.349s-5.529-12.349-12.349-12.349zM15.050 10.934c0-0.525 0.425-0.95 0.95-0.95s0.95 0.425 0.95 0.95v5.066c0 0.525-0.425 0.95-0.95 0.95s-0.95-0.425-0.95-0.95v-5.066zM16.013 22.016h-0.013c-0.525 0-0.95-0.425-0.95-0.95s0.425-0.95 0.95-0.95h0.013c0.525 0 0.95 0.425 0.95 0.95s-0.425 0.95-0.95 0.95z"
        />
      </SVG>
    </Container>
  );
};

export default ExclamationPointIcon;
