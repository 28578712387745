/* eslint-disable import/extensions */
import SVG from './Styles';

interface IPostTypeVideoIconProps {
  width?: string;
  height?: string;
  onClick?: any;
  fill?: string;
}

const PostTypeVideoIcon = (props: IPostTypeVideoIconProps) => {
  return (
    <SVG
      viewBox="0 0 32 32"
      width={props.width ? props.width : '100%'}
      height={props.height ? props.height : '100%'}
      onClick={props.onClick}
    >
      <path
        fill={props.fill || 'none'}
        d="M29.333 20.74c-0.132 0.512-0.376 0.948-0.841 1.229-0.519 0.315-1.071 0.372-1.612 0.099-0.595-0.3-1.167-0.645-1.747-0.976-0.925-0.528-1.847-1.065-2.776-1.587-0.163-0.092-0.232-0.187-0.231-0.381 0.008-2.091 0.008-4.181 0-6.272 0-0.191 0.061-0.292 0.225-0.384 1.392-0.795 2.799-1.567 4.161-2.409 1.196-0.74 2.6-0.081 2.793 1.136 0.004 0.024 0.017 0.045 0.027 0.068 0 3.16 0 6.319 0 9.477zM5.695 8.175c-1.777 0.007-3.020 1.263-3.020 3.043-0.001 3.193-0.003 6.385 0.003 9.579 0 0.259 0.020 0.523 0.079 0.773 0.307 1.308 1.396 2.24 2.741 2.248 4.121 0.023 8.243 0.008 12.363 0.007 0.129 0 0.261-0.012 0.388-0.036 1.457-0.273 2.423-1.448 2.425-2.977 0.007-3.201 0.004-6.403-0.001-9.604 0-0.241-0.019-0.488-0.069-0.723-0.307-1.412-1.449-2.303-2.96-2.307-1.987-0.005-3.973-0.001-5.96-0.001-1.996-0.003-3.992-0.008-5.988-0.001z"
      ></path>
    </SVG>
  );
};

export default PostTypeVideoIcon;
