/* eslint-disable consistent-return */
/* eslint-disable import/extensions */
import styled, { css } from 'styled-components';

import { IStyledButtonProps } from './types';

export const StyledButton = styled.button<IStyledButtonProps>`
  display: ${props => {
    if (props.display) return props.display;
    if (props.themeStyles && props.themeStyles.display)
      return props.themeStyles.display;
    return 'unset';
  }};
  position: ${props => (props.position ? props.position : 'unset')};
  background-color: ${props => {
    if (props.disabledNotNative || props.disabled) {
      if (props.disabledBackgroundColor) return props.disabledBackgroundColor;
      if (props.themeStyles && props.themeStyles['disabled-background-color'])
        return props.themeStyles['disabled-background-color'];
      return props.theme.button.shared['disabled-background-color'];
    }
    if (props.backgroundColor) return props.backgroundColor;
    if (props.themeStyles && props.themeStyles['background-color'])
      return props.themeStyles['background-color'];
    return props.theme.button.primary['background-color'];
  }};
  color: ${props => {
    if (props.disabledNotNative || props.disabled) {
      if (props.themeStyles && props.themeStyles['disabled-color'])
        return props.themeStyles['disabled-color'];
      return props.theme.button.shared['disabled-color'];
    }
    if (props.color) return props.color;
    if (props.themeStyles && props.themeStyles.color)
      return props.themeStyles.color;
    return props.theme.button.primary.color;
  }};
  border-color: ${props => {
    if (props.disabledNotNative || props.disabled) {
      if (props.disabledBorderColor) return props.disabledBorderColor;
      if (props.themeStyles && props.themeStyles['disabled-border-color'])
        return props.themeStyles['disabled-border-color'];
      return props.theme.button.shared['disabled-border-color'];
    }
    if (props.borderColor) return props.borderColor;
    if (props.themeStyles && props.themeStyles['border-color'])
      return props.themeStyles['border-color'];
    return props.theme.button.primary['border-color'];
  }};
  border-radius: ${props => {
    if (props.borderRadius) return props.borderRadius;
    if (props.themeStyles && props.themeStyles['border-radius'])
      return props.themeStyles['border-radius'];
    return props.theme.button.shared['border-radius'];
  }};
  border-style: ${props => {
    if (props.borderStyle) return props.borderStyle;
    if (props.themeStyles && props.themeStyles['border-style'])
      return props.themeStyles['border-style'];
    return props.theme.button.shared['border-style'];
  }};
  border-width: ${props => {
    if (props.borderWidth) return props.borderWidth;
    if (props.themeStyles && props.themeStyles['border-width'])
      return props.themeStyles['border-width'];
    return props.theme.button.shared['border-width'];
  }};
  margin-block: ${props => (props.marginBlock ? props.marginBlock : 'unset')};
  font-size: ${props => {
    if (props.fontSize) return props.fontSize;
    if (props.themeStyles && props.themeStyles['font-size'])
      return props.themeStyles['font-size'];
    return props.theme.button.shared['font-size'];
  }};
  font-weight: ${props => {
    if (props.fontWeight) return props.fontWeight;
    if (props.themeStyles && props.themeStyles['font-weight'])
      return props.themeStyles['font-weight'];
    return props.theme.button.shared['font-weight'];
  }};
  font-family: ${props => {
    if (props.fontFamily) return props.fontFamily;
    if (props.themeStyles && props.themeStyles['font-family'])
      return props.themeStyles['font-family'];
    return props.theme.button.shared['font-family'];
  }};
  width: ${props => {
    if (props.width) return props.width;
    if (props.themeStyles && props.themeStyles.width)
      return props.themeStyles.width;
    return props.theme.button.shared.width;
  }};
  max-width: ${props => {
    if (props.maxWidth) return props.maxWidth;
    if (props.themeStyles && props.themeStyles['max-width'])
      return props.themeStyles['max-width'];
    return 'unset';
  }};
  height: ${props => {
    if (props.height) return props.height;
    if (props.themeStyles && props.themeStyles.height)
      return props.themeStyles.height;
    return props.theme.button.shared.height;
  }};
  margin-top: ${props => {
    if (props.marginTop) return props.marginTop;
    if (props.themeStyles && props.themeStyles['margin-top'])
      return props.themeStyles['margin-top'];
    return props.theme.button.shared['margin-top'];
  }};
  margin-bottom: ${props => {
    if (props.marginBottom) return props.marginBottom;
    if (props.themeStyles && props.themeStyles['margin-bottom'])
      return props.themeStyles['margin-bottom'];
    return props.theme.button.shared['margin-bottom'];
  }};
  margin-left: ${props => {
    if (props.marginLeft) return props.marginLeft;
    if (props.themeStyles && props.themeStyles['margin-left'])
      return props.themeStyles['margin-left'];
    return props.theme.button.shared['margin-left'];
  }};
  margin-right: ${props => {
    if (props.marginRight) return props.marginRight;
    if (props.themeStyles && props.themeStyles['margin-right'])
      return props.themeStyles['margin-right'];
    return props.theme.button.shared['margin-right'];
  }};
  padding-top: ${props => {
    if (props.paddingTop) return props.paddingTop;
    if (props.themeStyles && props.themeStyles['padding-top'])
      return props.themeStyles['padding-top'];
    return props.theme.button.shared['padding-top'];
  }};
  padding-bottom: ${props => {
    if (props.paddingBottom) return props.paddingBottom;
    if (props.themeStyles && props.themeStyles['padding-bottom'])
      return props.themeStyles['padding-bottom'];
    return props.theme.button.shared['padding-bottom'];
  }};
  padding-left: ${props => {
    if (props.paddingLeft) return props.paddingLeft;
    if (props.themeStyles && props.themeStyles['padding-left'])
      return props.themeStyles['padding-left'];
    return props.theme.button.shared['padding-left'];
  }};
  padding-right: ${props => {
    if (props.paddingRight) return props.paddingRight;
    if (props.themeStyles && props.themeStyles['padding-right'])
      return props.themeStyles['padding-right'];
    return props.theme.button.shared['padding-right'];
  }};
  cursor: ${props => {
    if (props.disabledNotNative || props.disabled) {
      if (props.cursorOnDisable) return props.cursorOnDisable;
      if (props.themeStyles && props.themeStyles['cursor-on-disable'])
        return props.themeStyles['cursor-on-disable'];
    }
    if (props.cursor) return props.cursor;
    if (props.themeStyles && props.themeStyles.cursor)
      return props.themeStyles.cursor;
    return props.theme.button.shared.cursor;
  }};
  pointer-events: ${props => {
    if (props.disabledNotNative || props.disabled) {
      if (props.pointerEventsOnDisable) return props.pointerEventsOnDisable;
      if (props.themeStyles && props.themeStyles['pointer-events-on-disable'])
        return props.themeStyles['pointer-events-on-disable'];
      return 'none';
    }
    if (props.pointerEvents) return props.pointerEvents;
    return 'all';
  }};
  opacity: ${props => (props.opacity ? props.opacity : '100%')};
  transition: ${props => (props.transition ? props.transition : 'none')};
  text-align: ${props => (props.textAlign ? props.textAlign : 'unset')};
  outline: ${props => {
    if (props.outline) return props.outline;
    if (props.themeStyles && props.themeStyles.outline)
      return props.themeStyles.outline;
    return props.theme.button.shared.outline;
  }};
  flex-grow: ${props => {
    if (props.flexGrow) return props.flexGrow;
    if (props.themeStyles && props.themeStyles['flex-grow'])
      return props.themeStyles['flex-grow'];
    return 'unset';
  }};
  align-items: ${props => {
    if (props.alignItems) return props.alignItems;
    if (props.themeStyles && props.themeStyles['align-items'])
      return props.themeStyles['align-items'];
    return 'unset';
  }};
  justify-content: ${props => {
    if (props.justifyContent) return props.justifyContent;
    if (props.themeStyles && props.themeStyles['justify-content'])
      return props.themeStyles['justify-content'];
    return 'unset';
  }};
  transition: ${props => {
    if (props.transition) return props.transition;
    if (props.themeStyles && props.themeStyles.transition)
      return props.themeStyles.transition;
    return 'unset';
  }};
  flex-basis: ${props => {
    if (props.flexBasis) return props.flexBasis;
    if (props.themeStyles && props.themeStyles['flex-basis'])
      return props.themeStyles['flex-basis'];
    return 'unset';
  }};
  top: ${props => {
    if (props.topOffset) return props.topOffset;
    return 'unset';
  }};
  bottom: ${props => {
    if (props.bottomOffset) return props.bottomOffset;
    return 'unset';
  }};
  left: ${props => {
    if (props.leftOffset) return props.leftOffset;
    return 'unset';
  }};
  right: ${props => {
    if (props.rightOffset) return props.rightOffset;
    return 'unset';
  }};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignItems }) => alignItems};

  &:hover {
    background-color: ${props => {
      if (
        !props.blockHoverStyles &&
        (props.disabledNotNative || props.disabled) &&
        !props.blockHoverStylesOnDisable
      ) {
        if (props.hoverDisabledBackgroundColor)
          return props.hoverDisabledBackgroundColor;
        if (
          props.themeStyles &&
          props.themeStyles['hover-disabled-background-color']
        )
          return props.themeStyles['hover-disabled-background-color'];
        return props.theme.button.shared['hover-disabled-background-color'];
      }
      if (
        !props.blockHoverStyles &&
        !props.disabledNotNative &&
        !props.disabled
      ) {
        if (props.hoverBackgroundColor) return props.hoverBackgroundColor;
        if (props.themeStyles && props.themeStyles['hover-background-color'])
          return props.themeStyles['hover-background-color'];
        return props.theme.button.primary['hover-background-color'];
      }
    }};
    border-color: ${props => {
      if (
        !props.blockHoverStyles &&
        (props.disabledNotNative || props.disabled) &&
        !props.blockHoverStylesOnDisable
      ) {
        if (props.hoverDisabledBorderColor)
          return props.hoverDisabledBorderColor;
        if (
          props.themeStyles &&
          props.themeStyles['hover-disabled-border-color']
        )
          return props.themeStyles['hover-disabled-border-color'];
        return props.theme.button.shared['hover-disabled-border-color'];
      }
      if (
        !props.blockHoverStyles &&
        !props.disabledNotNative &&
        !props.disabled
      ) {
        if (props.hoverBorderColor) return props.hoverBorderColor;
        if (props.themeStyles && props.themeStyles['hover-border-color'])
          return props.themeStyles['hover-border-color'];
        return props.theme.button.primary['hover-border-color'];
      }
    }};
    color: ${props => {
      if (
        !props.blockHoverStyles &&
        !props.disabledNotNative &&
        !props.disabled
      ) {
        if (props.hoverColor) return props.hoverColor;
        if (props.themeStyles && props.themeStyles['hover-color'])
          return props.themeStyles['hover-color'];
        return props.theme.button.primary['hover-color'];
      }
    }};
  }

  ${props => {
    if (
      props.themeStyles &&
      (props.themeStyles['signin-facebook-icon-color'] ||
        props.themeStyles['signin-facebook-icon-color-hover'])
    ) {
      return css`
        && path {
          fill: ${props.themeStyles['signin-facebook-icon-color']};
        }

        &&:hover path {
          fill: ${props.themeStyles['signin-facebook-icon-color-hover']};
        }
      `;
    }
  }}

  ${props => {
    if (
      props.themeStyles &&
      (props.themeStyles['signin-twitter-icon-color'] ||
        props.themeStyles['signin-twitter-icon-color-hover'])
    ) {
      return css`
        && path {
          fill: ${props.themeStyles['signin-twitter-icon-color']};
        }

        &&:hover path {
          fill: ${props.themeStyles['signin-twitter-icon-color-hover']};
        }
      `;
    }
  }}
`;
