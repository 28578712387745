/* eslint-disable consistent-return */
/* eslint-disable default-case */
import styled from 'styled-components';
import {
  BsChevronDoubleDown,
  BsChevronDoubleUp,
  BsInfoCircle,
} from 'react-icons/bs';
import { CgMathEqual } from 'react-icons/cg';

type Props = {
  bg?: string;
};

export const UpIcon = styled(BsChevronDoubleUp)`
  color: #64ae2f;
`;

export const DownIcon = styled(BsChevronDoubleDown)`
  color: #ca3d20;
`;

export const EqualIcon = styled(CgMathEqual)`
  color: #ff6b0d;
`;

export const InfoIcon = styled(BsInfoCircle)``;

export const ChartValuesCircleContent = styled.div`
  align-items: center;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-right: 5%;
  margin-top: 3.2rem;
`;

export const ChartValuesCircleInnerContainer = styled.div<Props>`
  background: none;
  width: 3.4rem;
  height: 3.4rem;
  margin-right: 2rem;
  border-radius: 10rem;
  text-align: center;
  line-height: 4.2rem;

  @media (max-width: 650px) {
    display: none;
  }
`;

export const ChartValuesCircle = styled.div<Props>`
  border-width: 0px;
  outline: 0px;
  width: auto;
  height: auto;
  padding: 0px;
  margin: 0px;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
`;

export const ChartValuesCircleIcon = styled.img`
  width: 100%;
`;

export const ChartValuesCircleInfoContainer = styled.div`
  padding-top: 0.64rem;
  margin-right: 1.6rem;
`;

export const ChartValuesCircleInfoValue = styled.div`
  display: flex;
  align-items: center;
`;

export const ChartValuesCircleInfoValueText = styled.span`
  color: rgb(22, 43, 57);
  font-weight: 700;
  font-size: 2.72rem;
  line-height: 3.2rem;
  text-align: left;
  display: block;
`;

export const ChartValuesCircleInfoValueUpDownContainer = styled.div`
  font-size: 2.56rem;
  line-height: 2.56rem;
`;

export const ChartValuesCircleInfoLegend = styled.span`
  color: #8492a6;
  font-weight: 400;
  font-size: 1.2rem;
  text-align: left;
  display: block;
  max-width: 14rem;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
`;

export const ChartPerformanceContainer = styled.div`
  background-color: #fff;
  padding: 2rem;
  border-radius: 2rem;
  margin-bottom: 2rem;
  width: 100%;
  min-height: 52.68rem;
  border: solid 0.1rem #eee;

  @media (max-width: 1510px) {
    width: 100%;
  }
`;

export const ChartPerformanceHeader = styled.div`
  margin: 0.4rem 0.8rem;
  display: flex;
  flex-wrap: wrap;
`;

export const ChartPerformanceHeaderTitle = styled.h3`
  color: #8492a6;
  font-weight: 400;
  font-size: 1.44rem;
  text-align: center;
  display: block;
`;

export const ChartPerformanceBody = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  
  & > div:first-child {
   margin-left: -1.4rem; 
   width: 100%;
  }
}
`;

export const ChartValuesCircleContainer = styled.div`
  height: auto;
  display: flex;
  flex-wrap: wrap;
  margin: 0.8rem;

  @media (max-width: 1510px) {
  }
`;
